import React, { Component } from "react";
import "./Createevent.css";
import {Link} from 'react-router-dom'
import HeaderInner from "./../../components/HeaderInner";
import DoneArrow from './../../assets/ic_done.png';
import EventPic from './../../assets/event/birthday.png';
import {IoIosArrowBack} from 'react-icons/io'
import {API_BASE_URL} from './../../constant'
import axios from "axios";
import {connect} from 'react-redux';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class ProfessionalCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceDetail : {
            about : [],
            images : [],
            features : [],
            location : "",
            serviceName : "",
            guests : 0,
            price: 0,
            minGuestRange: 0,
            linkedServices : [
                {
                    price : 0,
                    guests : 0
                }
            ],               
        },
        linkedServices : [],
        appliedfilter: [],
        active : false,
        services : [],
        show:false,
        photoIndex: 0,
        isOpen: false,
        title : "",
        eventImg : "",
        totalPrice  : "",
        firstName : "",
        lastName:"",
        email:"",
        phoneNumber:"",
        personalMsg:"",
        bill_add:"",
        bill_city:"",
        bill_state:"",
        bill_zip:"",
        bill_country:"",
        billingAddMsg:"",
        ship_add:"",
        ship_city:"",
        ship_state:"",
        ship_zip:"",
        ship_country:"",
        shipingAddMsg:"",
        totalPay:"0",
        paymentType : '2',
        isMailVerified: false,
        isMobileVerified: false,
        phoneError:'',
        isMob:'',
        states:'',
        locations:[],
        coupon_code:'',
        couponSuccDiv:'none',
        coupon_err_msg:'',
        couponDiv:'',
        payment_milestone:'',
        couponApplied: false,
        isVenueSelected: true,
        isshippingSame : false,
        halfPaymentdisable : false,
        eventDate:'',
        paymentMsg:false,
        tc: false,
        discount:0
    }
    this.personalhandler = this.personalhandler.bind(this);
    this.submitPersonal = this.submitPersonal.bind(this);
    this.submitBilling = this.submitBilling.bind(this);
    this.submitShiping = this.submitShiping.bind(this);
    this.getPayAmount = this.getPayAmount.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        const uid = this.props.match.params.userId;
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        //get states data from colection
        axios.get(`${API_BASE_URL}get-locations`)
        .then(resp=>{ 
            const states = [];
            resp.data.data.map((st,index)=>{
                states.push(st.stateName)  
            })
            this.setState({locations:states})
        })
        let token = localStorage.getItem("user-auth-token");
            axios.get(`${API_BASE_URL}getDraft?userEventId=${userEventId}&userId=${uid}`)
            .then(r => { 
                if(r.data.data.data.length===0){
                    this.props.history.push(`/professionallisting/${eventTypeId}/${userEventId}`)
                }
                    const services = r.data.data.data[0].vendorServices;
                    const newservices = [];
                    let totalPrice = r.data.data.data[0]._id.packagePrice;
                    let eventDate = r.data.data.data[0]._id.eventDate;
                    var now = moment(new Date()); //todays date
                    var end = moment(eventDate); // another date
                    var duration = moment.duration(end.diff(now));
                    var days = duration.asDays();
                    if(Math.ceil(days)>3){
                        this.setState({halfPaymentdisable:true})   
                    }
                    services.forEach((service , index) =>{
                            let Obj;
                                Obj = {
                                    serviceId : service.serviceId,
                                    vendorServiceId : service.vendorServiceId,
                                    linkedServiceId : service.linkedServiceId,
                                    maxGuestRange: service.maxGuestRange,
                                    minGuestRange:  service.minGuestRange,
                                    price : service.price,
                                    priceType : service.priceType,
                                    guests : service.guests ,
                                    isCompulsory : service.isCompulsory,
                                    title : service.title,
                                    type : service.type,
                                }
                                newservices.push(Obj);
                                if(service.type==='Venue'){
                                    this.setState({isVenueSelected: false}) 
                                 }
                    })
              
                this.setState({totalPrice:totalPrice,totalPay:totalPrice});
                this.setState({linkedServices : r.data.data.data[0].vendorServices  , services : newservices  , serviceDetail :r.data.data.data[0].vendorServices[0]})
                this.setState({title:r.data.data.data[0]._id.eventName,appliedfilter:r.data.data.data[0]._id.selectedFilters,eventImg:r.data.data.data[0]._id.eventTamplateData.image})
            })
            .catch(e =>{
                console.log('save draft error' , e)
            })
        const headers = {
            'Content-Type': 'application/json',
            'authorization': token
        };
        const data = {
          'token':token
        }
        axios
            .post(`${API_BASE_URL}UserProfile`, data,{ headers })
            .then(response => { 
                this.setState({isMob:response.data.data[0].phoneNumber!==''?'none':''});
              this.setState({
                  firstName:response.data.data[0].firstName,
                  lastName:response.data.data[0].lastName,
                  email:response.data.data[0].email,
                  phoneNumber:response.data.data[0].phoneNumber,
                  bill_add:response.data.data[0].bill_add.address,
                  bill_city:response.data.data[0].bill_add.city,
                  bill_state:response.data.data[0].bill_add.state,
                  bill_zip:response.data.data[0].bill_add.zip,
                  bill_country:response.data.data[0].bill_add.country,
                  ship_add:response.data.data[0].ship_add.address,
                  ship_city:response.data.data[0].ship_add.city,
                  ship_state:response.data.data[0].ship_add.state,
                  ship_zip:response.data.data[0].ship_add.zip,
                  ship_country:response.data.data[0].ship_add.country,
                  isMobileVerified : response.data.data[0].isMobileVerified,
                  isMailVerified : response.data.data[0].isMailVerified,
                });
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    checkShipping = ()=>{
        this.setState({"isshippingSame" : !this.state.isshippingSame})
        setTimeout(()=>{
            if(this.state.isshippingSame){
                this.setState({ship_add: this.state.bill_add})
                this.setState({ship_city: this.state.bill_city})
                this.setState({ship_state: this.state.bill_state})
                this.setState({ship_zip: this.state.bill_zip})
                this.setState({ship_country: this.state.bill_country})
            }
            else{
                this.setState({ship_add: ''})
                this.setState({ship_city: ''})
                this.setState({ship_state: ''})
                this.setState({ship_zip: ''})
                this.setState({ship_country: ''})
            }
        },500);
        
    }

    
    submitBooking = ()=>{
        if(this.state.firstName ==='' || this.state.lastName === '' || this.state.email ==='' || this.state.phoneNumber===''){
           this.setState({personalMsg:'Please update the personal details'}) 
           this.setState({submitErr:'Please update the personal details'})
           return false;
        }
        else if(this.state.bill_add ==='' || this.state.bill_city === '' || this.state.bill_state === '' || this.state.bill_zip === '' || this.state.bill_country  === ''){
            this.setState({billingAddMsg:'Please update the billing information!'});
            this.setState({submitErr:'Please update the billing information'})
        }
        else{
            if(this.state.isVenueSelected ){ 
                if(this.state.ship_add ==='' || this.state.ship_city === '' || this.state.ship_state === '' || this.state.ship_zip === '' || this.state.ship_country  === ''){
                    this.setState({shipingAddMsg:'Please update the shipping information!'});
                    this.setState({submitErr:'Please update the shipping information'})
                    return false;
                }
            }
        const bookingDetails = {
            userId : this.props.match.params.userId,
            userEventId: this.props.match.params.userEventId,
            totalPrice: this.state.totalPrice,
            intialPaymentPaid: this.state.totalPay,
            PaymentType:this.state.paymentType,
        }
        axios.post(`${API_BASE_URL}bookEvent`,bookingDetails)
        .then((r)=>{ 
            window.location.href = "/paymentnew/" + r.data.data.userEventId + "/" + r.data.data.userId + "/" + r.data.data._id;
           // <Link to={`/paymentnew/${this.props.match.params.userEventId}/${this.props.match.params.userId}`} className="d-block  btn btn-warning mt-4"><span className="pr-4 d-inline-block border-right">${parseInt(this.state.totalPay)}</span><span className="pl-3">Book Now by Paying</span></Link>
        })
        .catch((error)=>{
            console.log('Prebooking saving error',error);
            this.setState({submitErr:'Somthing wrong!Try again'})
        })
        }
    }

    getPayAmount(e){
        const pp = e.target.value;
        this.setState({"totalPay":pp==='2'?this.state.totalPrice:(this.state.totalPay/2),"paymentType":pp});
        this.setState({"couponDiv":pp==='2'?'':'none',"paymentMsg":pp==='2'? false : true });
    }

    apply_coupon = ()=>{
        let couponCode = this.state.coupon_code;
        let totalAmount = this.state.totalPay;
        axios.post(`${API_BASE_URL}applycouponcode`,{couponCode:couponCode,totalAmount:totalAmount})
        .then(res=>{ 
            //totalPay
            this.setState({discount:res.data.data})
            let couponValue = res.data.data
            let payVal = this.state.totalPay - couponValue
            this.setState({totalPay:payVal.toFixed(1),coupon_err_msg:'',couponDiv:'none',couponSuccDiv:'',payment_milestone:'none'});
            this.setState({couponApplied:true})
        })
        .catch(err=>{ 
            this.setState({coupon_err_msg:err.response.data.message,coupon_succ_msg:""})
        })
    }

    isNumber(n) {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n); 
    }

    personalhandler(e){
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]:value
        })
    }

    takeMobileInput = (e)=>{ 
        const re = /^[0-9\b]+$/;
        const mob = e.target.value;
        if (re.test(mob) && mob.length<10) {
            this.setState({phoneNumber: mob})
            this.setState({phoneError: ''}) 
        }
        else{
            this.setState({phoneNumber: ''}) 
            this.setState({phoneError: 'Invalide phone number'}) 
        }
    }

    submitPersonal()
    {
        const mob = this.state.phoneNumber; 
        if(this.state.firstName ==='' || this.state.lastName === '' || this.state.email ==='' || this.state.phoneNumber===''){
            this.setState({personalMsg:'All fields are mandatory'}) 
            return false;
        }
       // else if(mob.length !== 9 ){
        //    this.setState({phoneNumber: ''}) 
          //  this.setState({phoneError: 'Invalide phone number'}) 
          //  return false;
       // }
        else{
        const data = {
            "firstName" : this.state.firstName,
            "lastName" :  this.state.lastName,
            "email" : this.state.email,
            "phoneNumber": this.state.phoneNumber
        } 
        const header = {
        'authorization': localStorage.getItem('user-auth-token')
        }
        axios.post(`${API_BASE_URL}updateAccount`,data,{headers: header})
        .then(r=>{
            this.setState({"personalMsg":'Updated!'});
        })
        .catch(error=>{
            console.log(error)
            this.setState({"personalMsg":'All fields are mandatory'});
        })
        }
    }

    submitBilling(e){
        e.preventDefault();
        const data ={ 
            "address" : this.state.bill_add,
            "city" : this.state.bill_city,
            "state" : this.state.bill_state,
            "zip" : this.state.bill_zip,
            "country" : this.state.bill_country
        }
        const header = {
            'authorization': localStorage.getItem('user-auth-token')
          }
          axios.post(`${API_BASE_URL}updateBillingAddress`,data,{headers: header})
          .then(r=>{
              this.setState({"billingAddMsg":'Updated!'});
          })
          .catch(error=>{
              console.log(error)
        })  
    }

    submitShiping(e){
        e.preventDefault();
        const data ={ 
            "address" : this.state.ship_add,
            "city" : this.state.ship_city,
            "state" : this.state.ship_state,
            "zip" : this.state.ship_zip,
            "country" : this.state.ship_country
        }
        
        const header = {
            'authorization': localStorage.getItem('user-auth-token')
          }
          axios.post(`${API_BASE_URL}updateShipingAddress`,data,{headers: header})
          .then(r=>{
              this.setState({"shipingAddMsg":'Updated!'});
          })
          .catch(error=>{
              console.log(error)
        })
        
    }

    // for the Back
    Back_user = () =>{
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        this.props.history.push(`/professionallisting/${eventTypeId}/${userEventId}`)
    }

    // for remove the service
    removeService = (objs) =>{
        confirmAlert({
            title: '',
            message: 'Do you want to remove this service',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {    
                    const userEventId = this.props.match.params.userEventId;
                    const uid = this.props.match.params.userId;
                    let id ;
                    if(typeof(objs) === "object"){
                        id = objs._id
                    } 
                    else {
                        id = objs
                    }
                    axios.get(`${API_BASE_URL}removeSelectedService?serviceId=${id}&userEventId=${userEventId}&userId=${uid}`)
                    .then(re => {
                        window.location.reload();
                    })
                    .catch(e =>{
                        console.log('Service Remove Error')
                    })  
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
          });     
    }


    render() {
    const userEventId = this.props.match.params.userEventId
    const eventTypeId = this.props.match.params.eventTypeId
    var applied = [];
    this.state.appliedfilter.forEach((appliedfilter, index) => {

      switch (appliedfilter.type) {
        case 1:
          applied[appliedfilter.type] = appliedfilter.singleSelect + "#" + appliedfilter.filterId
          break;
        case 2:
          applied[appliedfilter.type] = appliedfilter.multiSelect + "#" + appliedfilter.filterId;
          break;
        case 3:
          var date = moment(parseInt(appliedfilter.date)).format("dddd, MMMM Do YYYY")
          applied[appliedfilter.type] = date + "#" + appliedfilter.filterId
          break;
        case 4:
          var date1 = moment(parseInt(appliedfilter.startDate)).format("DD-MM-YYYY")
          var date2 = moment(parseInt(appliedfilter.endDate)).format("DD-MM-YYYY")
          applied[appliedfilter.type] = date1.concat(' / ', date2) + "#" + appliedfilter.filterId
          break;
        case 5:
          var time = moment(parseInt(appliedfilter.time)).format("hh:mm A")
          applied[appliedfilter.type] = time + "#" + appliedfilter.filterId
          break;
        case 6:
          var time1 = moment(parseInt(appliedfilter.startTime)).format("hh:mm A")
          var time2 = moment(parseInt(appliedfilter.endTime)).format("hh:mm A")
          applied[appliedfilter.type] = time1 + "-" + time2 + "#" + appliedfilter.filterId
          break;
        case 7:
          applied[appliedfilter.type] = appliedfilter.membersMinRange + ' - ' + appliedfilter.membersMaxRange + "#" + appliedfilter.filterId
          break;
        case 8:
            applied[appliedfilter.type] = appliedfilter.singleSelect + "#" + appliedfilter.filterId
          break;
          default:
              applied[appliedfilter.type]='';
      }
    })
        return (
            <div className='checkout'>
            <HeaderInner />
                <div className="container" style={{padding  :"30px 0px"}}>
        <p className="para-container">
        <a className="remove_anchor bold_me backa" href="#" onClick={this.Back_user} >
            <IoIosArrowBack />BACK</a>
        </p>
    <div className="row">
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div className="border-bottom pb-2 ">
                <div id="services" className="tab-pane active  my-4">
                        <h2 className="heading ">Event Details</h2>
                        <div className="border-outer mb-4">
                            <div className=" p-4 d-flex justify-content-between align-items-center">
                                <div className="inner-left">
                                    <h3 className="event-title" style={{ textTransform: "capitalize" }}>{this.state.title}</h3>
                                   
                                    {
                                        applied.map((filter, index) => {
                                        var filtervalues = filter.split("#");
                                        var filtervalue = filtervalues[0]
                                        if(index===6){
                                            return <p style={{color:'black'}} key={index}><span style={{'font-weight': 900}}>Event Time :</span> {filtervalue}</p>
                                        }
                                        if(index===8){
                                            return <p style={{color:'black'}} key={index}><span style={{'font-weight': 900}}>Event Location :</span> {filtervalue}</p>
                                        }
                                        if(index===3){
                                            return <p style={{color:'black'}} key={index}><span style={{'font-weight': 900}}>Event Date :</span> {filtervalue}</p>
                                        }
                                        })
                                    }
                                        
                                   
								</div>
                                <div className="inner-right">
                                    <img alt='' style={{height:'150px', width:"150px"}} src={this.state.eventImg!=='' ? this.state.eventImg : EventPic} />
                                </div>
							</div>
                            <div className="price border-top px-4  py-3 d-flex justify-content-between align-items-center">
                                <span >&nbsp;</span>
                                <span ><Link onClick={this.Back_user} className="btn ">Update</Link></span>
                            </div>
                        </div>

						<h2 className="heading ">Personal Details</h2>
                        <div className="border-outer mb-4">
                            <div className="border-top px-4  py-3 ">
                             <div className="row">
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">First name<span style={{color:'red'}}>*</span></label> */}
                                    <input required type="text" className="form-control" name="firstName" placeholder="Enter First Name*" value={this.state.firstName} onChange={this.personalhandler} onBlur={this.submitPersonal}/>
                                 </div>
                              </div>
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Last name<span style={{color:'red'}}>*</span></label> */}
                                    <input required type="text" className="form-control"  name="lastName" placeholder="Enter Last Name*" value={this.state.lastName} onChange={this.personalhandler} onBlur={this.submitPersonal}/>
                                 </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Email ID<span style={{color:'red'}}>*</span></label> */}
                                    <input required type="email" className="form-control" name="email" readOnly={this.state.isMailVerified?'readOnly':''}  placeholder="Enter Email*" value={this.state.email} onChange={this.personalhandler} onBlur={this.submitPersonal}/>
                                 </div>
                              </div>
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Contact number<span style={{color:'red'}}>*</span></label> */}
                                    <div className="input-group mb-2">
                                    <div className="input-group-prepend" style={{display:this.state.isMob}}>
                                    <div className="input-group-text">+971</div>
                                    </div>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        name="phoneNumber" 
                                        readOnly={this.state.isMobileVerified?'readOnly':''} 
                                        placeholder="Phone Number*" 
                                        value={this.state.phoneNumber} 
                                        onChange={this.takeMobileInput}
                                        onBlur={this.submitPersonal} />
                                    </div>
                                    <span style={{color:'red'}}>{this.state.phoneError}</span>
                                 </div>
                              </div>
                           </div>
                            </div>
                            <div className="price border-top px-4  py-3 d-flex justify-content-between align-items-center">
                                <span >&nbsp;</span>
                                <span style={{'color':'green'}}>{this.state.personalMsg}</span>
                                {/* <span ><button onClick={this.submitPersonal} className="btn" style={{backgroundColor:'#8489B2',color:'white'}} >Update</button></span> */}
                            </div>
                        </div>

						<h2 className="heading ">Billing Address</h2>
                        <div className="border-outer mb-4">
                            <div className="border-top px-4  py-3 ">
                             <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Adress<span style={{color:'red'}}>*</span></label> */}
                                    <input type="text" placeholder="Enter address*" className="form-control" name="bill_add" value={this.state.bill_add} onChange={this.personalhandler} onBlur={this.submitBilling}/>
                                 </div>
                              </div>
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">City<span style={{color:'red'}}>*</span></label> */}
                                    <input type="text" placeholder="Enter zip*" className="form-control" name="bill_city" value={this.state.bill_city} onChange={this.personalhandler} onBlur={this.submitBilling}/>
                                 </div>
                              </div>
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Zip<span style={{color:'red'}}>*</span></label> */}
                                    <input type="text" placeholder="Enter zip*" className="form-control" name="bill_zip" value={this.state.bill_zip} onChange={this.personalhandler} onBlur={this.submitBilling}/>
                                 </div>
                              </div>
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">State<span style={{color:'red'}}>*</span></label> */}
                                    <select className="form-control" placeholder="Enter state*" value={this.state.bill_state} name="bill_state" onChange={this.personalhandler} onBlur={this.submitBilling}>
                                        <option value="">Choose state</option>
                                        {this.state.locations.map((stateNames,index)=>{ 
                                            return <option value={stateNames}>{stateNames}</option>
                                        })}
                                    </select>
                                 </div>
                              </div>
							 
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Country<span style={{color:'red'}}>*</span></label> */}
                                    {/* <input type="text" placeholder="Enter Country*" className="form-control" name="bill_country" value={this.state.bill_country} onChange={this.personalhandler}/>  */}
                                    <select className="form-control" value={this.state.bill_country} placeholder="Enter country*" name="bill_country" onChange={this.personalhandler} onBlur={this.submitBilling}>
                                        <option value="">Select Country</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                            </div>

                            <div className="price border-top px-4  py-3 d-flex justify-content-between align-items-center">
                                <span >&nbsp;</span>
                                <span style={{'color':'green'}}>{this.state.billingAddMsg}</span>
                                {/* <span ><button type="submit" onClick={this.submitBilling} className="btn" style={{backgroundColor:'#8489B2',color:'white'}} >Update</button></span> */}
                            </div>
                        </div>
						{this.state.isVenueSelected?<>
						<h2 className="heading ">Shipping Address</h2>
                        <input type="Checkbox" onChange={this.checkShipping } /> Same as billing address
                        <div className="border-outer mb-4">
                            <div className="border-top px-4  py-3 ">
                             <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Adress</label> */}
                                    <input type="text" className="form-control" name="ship_add" placeholder="Shipping Address*" value={this.state.ship_add} onChange={this.personalhandler} onBlur={this.submitShiping}/>
                                 </div>
                              </div>
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">City</label> */}
                                    <input type="text" className="form-control" name="ship_city" placeholder="City*" value={this.state.ship_city} onChange={this.personalhandler} onBlur={this.submitShiping}/>
                                 </div>
                              </div>
                           
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">State</label> */}
                                    {/* <input type="text" className="form-control" name="ship_state" placeholder="State" value={this.state.ship_state} onChange={this.personalhandler}/> */}
                                    <select className="form-control" placeholder="Enter state*" value={this.state.ship_state} name="ship_state" onChange={this.personalhandler} onBlur={this.submitShiping}>
                                        <option value="">Choose state</option>
                                        {this.state.locations.map((stateNames,index)=>{ 
                                            return <option value={stateNames}>{stateNames}</option>
                                        })}
                                    </select>
                                 </div>
                              </div>
							  <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Zip</label> */}
                                    <input type="text" className="form-control" name="ship_zip" placeholder="Zip*" value={this.state.ship_zip} onChange={this.personalhandler} onBlur={this.submitShiping}/>
                                 </div>
                              </div>
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    {/* <label for="exampleFormControlInput1">Country</label> */}
                                    {/* <input type="text" className="form-control" name="ship_country" placeholder="Dubai"  value={this.state.ship_country} onChange={this.personalhandler}/> */}
                                    <select className="form-control" value={this.state.ship_country} placeholder="Enter country*" name="ship_country" onChange={this.personalhandler} onBlur={this.submitShiping}>
                                        <option value="">Select Country</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                            </div>

                            <div className="price border-top px-4  py-3 d-flex justify-content-between align-items-center">
                            <span >&nbsp;</span>
                                <span style={{'color':'green'}}>{this.state.shipingAddMsg}</span>
                                {/* <span ><button type="submit" onClick={this.submitShiping} className="btn" style={{backgroundColor:'#8489B2',color:'white'}} >Update</button></span> */}
                            </div>
                        </div>
                        </>:''
                        }
				</div>
            </div>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="box-shadow p-4 mb-4">
                <h2 className="page-title my-3 d-inline-block" style={{ textTransform: "capitalize" }}>{this.state.title}</h2>
                {this.state.services.map((service,index)=>{
                    service.guests = service.guests!==0 ? service.guests : 1;
                    return <div key={service._id} className="border border-warning mb-3 position-relative add-on-box d-flex align-items-center justify-content-between">
                    <div className="add-on">
                        <h4 className="heading mb-0">{service.type}&nbsp;&nbsp;&nbsp;AED {service.price*service.guests}</h4>
                        <p className="mb-0">{service.title}</p>
                    </div>
                    <Link to={`/professionaldetails/${service.vendorServiceId}/${userEventId}/${eventTypeId}/${service.serviceId}/${service.type}/true`} className="colored edit">edit</Link>
					<a onClick={() => this.removeService(service.serviceId)} className="colored edit" style={{cursor : "pointer"}}>remove</a>
                    <div className="position-absolute icon-done">
                    <img src={DoneArrow} alt=""/> 
                    </div>
                </div>
                })}
				
				<div className="row">
					<div className="col-md-8 col-lg-8 col-sm-8">
						<h2 className="heading">Total Amount</h2>
					</div>
					<div className="col-md-4 col-lg-4 col-sm-4">
						<h2 className="heading">AED {this.state.totalPrice}</h2>
					</div>
					<br/><br/>
					<div className="col-md-12 col-lg-12 col-sm-12">
                        <h1 className="heading">Select Payment Milestone</h1>
					</div>
					<br/>
                    <br/>
                    <div className="col-md-12 col-lg-12 col-sm-12">
						
                        {this.state.halfPaymentdisable ?
                        <select className="form-control" name="pp" onChange={this.getPayAmount} >
                            <option value="2">Full Payment (100%)</option>
                            <option value="1">Half Payment (50%)</option>
                        </select>:
                        <select className="form-control" name="pp" onChange={this.getPayAmount} >
                            <option value="2">Full Payment (100%)</option>
                            <option disabled value="1">Half Payment (50%)</option>
                        </select>}
						
					</div>
				</div>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <h1 className="heading">Payment will be deducted once booking is confirmed from our partners</h1>
					</div>
                </div>
                <div className="row" style={{display:this.state.couponDiv}}>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <h1 className="heading">Have Coupon?</h1>
                    <input type="text" style={{textTransform:"uppercase", width:'60%', float:'left'}} className="form-control" placeholder="Coupon code" name="coupon_code" onChange={this.personalhandler} value={this.state.coupon_code} />
                    <div className="input-group-append">
                        <button onClick={this.apply_coupon} className="btn btn-outline-secondary" style={{backgroundColor: "#f47824",color: "white"}}>Apply</button>
                    </div>
                </div>
                </div>
                <div className="row" style={{display:this.state.couponSuccDiv}}>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                    <h2 className="heading" style={{color:"green"}}>Discount</h2>
                    <h2 className="heading" style={{color:"green"}}>Coupon applied</h2>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6" style={{textAlign : "right"}}>
                        <h2 style={{color:"black"}} className="heading">AED {this.state.discount}</h2>
                        <h2 style={{color:"blue",cursor: "pointer"}} className="heading" onClick={()=>{window.location.reload()}}>Remove</h2>
                    </div>
                </div>
                <span style={{color:"red"}}>{this.state.coupon_err_msg}</span>
				<Link onClick={this.submitBooking} className="d-block  btn btn-warning mt-4"><span className="pr-4 d-inline-block border-right">AED {parseInt(this.state.totalPay)}</span><span className="pl-3">Book Now by Paying</span></Link>
				<spam style={{color:'red'}}>{this.state.submitErr}</spam>
                {/*<Link to={`/paymentnew/${this.props.match.params.userEventId}/${this.props.match.params.userId}`} className="d-block  btn btn-warning mt-4"><span className="pr-4 d-inline-block border-right">${parseInt(this.state.totalPay)}</span><span className="pl-3">Book Now by Paying</span></Link>*/}
            </div>
            
        </div>
    </div>
</div>
</div>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        userDetails : state.userDetailsReducer.userDetails,
    }
}

export default connect(mapStateToProps,null)(ProfessionalCheckout);

