import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/campton/campton-book.ttf';
import './assets/campton/campton-semibold.ttf';
import './assets/campton/campton-light.ttf';
import './assets/campton/campton-medium.ttf';
import {Provider} from "react-redux"
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from './components/reducers/rootreducer';


const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);
ReactDOM.render(<Provider store={store}><App /></Provider>,document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
