import React, { Component } from "react";
import './AppStyle.css';
import {Link} from 'react-router-dom'
import HeaderInner from "./HeaderUser";
import axios from "axios";
import {IoIosArrowBack, IoIosSearch, IoIosAddCircle} from 'react-icons/io';
import LeftMenu from "./UserLeftMenu";
import { API_BASE_URL,BASE_URL } from "../../constant";
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; 
import { typeOf } from "react-weblineindia-time-picker";


class MyEvents extends Component {
   constructor(props){
      super(props);
      this.state={
         event_status:'',
         bookedEvents:[],
         uid :localStorage.getItem('user-auth-id'),
      }
   }

   payBalance = (userEventId)=>{
      let data = {
         UserEventId:userEventId,
         uid : this.state.uid
      }
      axios.post(`${API_BASE_URL}payBalance`,data)
      .then((r)=>{
         console.log(r);
         window.location.href = "/Paymentnew/" + r.data.data.userEventId + "/" +r.data.data.userId+"/"+ r.data.data._id
      })
      .catch(error=>{
         console.log(error);
      });
   }
   componentDidMount(){
      // saveDarft Api
      axios.get(`${API_BASE_URL}getUserEvents?userId=${this.state.uid}&status=2`)
      .then(r => { 
          this.setState({bookedEvents : r.data.data.data })
      })
      .catch(e =>{
      console.log('save draft error' , e)
      })
   }

   submitStatus = ()=> {
      axios.get(`${API_BASE_URL}getUserEvents?userId=${this.state.uid}&status=${this.state.event_status}`)
      .then(r => { 
          this.setState({bookedEvents : r.data.data.data })
      })
      .catch(e =>{
      console.log('save draft error' , e)
      })
    }

   changeHandler = (e)=>{
      const {name,value} = e.target;
      this.setState({event_status:value});
   }

   //delete event
   cancelEvent = (eventId)=>{
      confirmAlert({
          title: '',
          message: 'Do you want to cancel this event',
          buttons: [
            {
              label: 'Yes',
              onClick: () => { 
                  const header = {
                      'authorization': localStorage.getItem('user-auth-token')
                    } 
                  axios.post(`${API_BASE_URL}delete-event`,{uid:this.state.uid,eventID:eventId,status:9},{headers: header})
                  .then(r => { 
                      window.location.reload();
                      //this.cancelDraft(eventId)
                  })
                  .catch(e =>{
                  console.log('save draft error' , e)
                  })
              }
              }
            ,
            {
              label: 'Cancel',
             
            }
          ]
      });
  }

    render(){
        return (
         <>
<HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
<div className="container">
	<div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
         <div className="back-btn">
            <br />
            <a className="text-dark"href="#"><IoIosArrowBack/> Back</a>
         </div>
      </div>
   </div>
   <section className="application_sec">	
      <div className="row">
         <div className="col-md-3 col-sm-3 col-xs-3 sidemenu">
            <LeftMenu />
         </div>
         <div className="col-md-9 col-sm-9 col-xs-9">
            <div className="form_card form_blk">  
                     <h3>My Events</h3>
                     <div className="row">
               <div className="card-body text-center border-ev">
                  <Link to={'create_event'}><h5 className="mt-2"><b><IoIosAddCircle/> <br />Create Events</b></h5></Link>
               </div>
            </div>
                     {/*<Link to={'create_event'} className="btn btn-primary">New Event</Link>*/}
                     <hr/>
                     <div className="row">
                        <div className="col-md-5">
                           
                           <label htmlFor="eventStatus">Event Status</label>
                              <select className="form-control" onChange={this.changeHandler} name="event_status" value={this.state.event_status} >
                                 <option value="2">Partial Booked</option>
                                 <option value="3">Booked</option>
                                 <option value="9">Cancelled</option>
                             </select>   
                        </div>
                        {/*<div className="col-md-5">
                           <label for="eventStatus">Date</label>
                              <input type="date" name="datefilter" className="form-control" placeholder="Select Date"  />
                         </div>*/}
                        <div className="col-md-1" style={{margin :' 17px -17px'}}>
                              <button onClick={this.submitStatus} className="btn btn-primary form-control tp"><IoIosSearch/></button>
                        </div>
                     </div>
            </div>{/* form card */}
            <br/>
            {/* second card*/ }
            {this.state.bookedEvents.map((event,index)=>{
               let eDate;
               event._id.selectedFilters.map((ev_fil)=>{
                  if(ev_fil.type==3){
                     eDate = ev_fil.date;
                  }
               })
               
               return  <div className="form_card form_blk" key={index} style={{ padding : '0px'}}>
               <div className="card-body" style={{marginBottom: "20px"}}>
               <h5 className="card-title float-left align-self-center tasks statistics breadcrumb" style={{ width : "80%", fontSize : '1.0rem'}}>
               <b>{event._id.eventName}</b>&nbsp; | {moment(parseInt(eDate)).format("dddd, MMMM Do YYYY")} |&nbsp; {event._id.status!=9?<span style={{cursor : "pointer"}} className="badge badge-danger" onClick={()=>this.cancelEvent(event._id.userEventId)}></span>:''}&nbsp;&nbsp;{event._id.isApproved ? <Link target="_blank" to={`invoice/${event._id.userEventId}`}><span style={{cursor : "pointer"}} className="badge badge-danger">Invoice</span></Link>:''} </h5>
               {parseInt(eDate) > moment().format('x') ? 
               event._id.isApproved ? <Link to={`sendinvitation/${event._id.userEventId}`}><h6 className="card-title tasks statistics float-right btn  btn-outline-danger">Invite Friends</h6></Link>:''
               :<Link to={`ratereview/${event._id.userEventId}`}><h6 className="card-title tasks statistics float-right btn  btn-outline-danger">Review Us</h6></Link> }
               {event._id.status==2 ? <h5 className="card-title tasks statistics float-right btn  btn-outline-danger" onClick={()=>this.payBalance(event._id.userEventId)}>Pay Balance</h5>:''}
                     <div className="clearfix"></div>
                        <div className="m-t-10 no-block">
                           <div className="d-flex f-16">
                              <div className="col-lg-12 p-0 row col-md-12">
                                 {event.vendorServices.map((list,i)=>{
                                    return <div key={i} className="add-on-box border border-warning mb-3 position-relative  d-flex align-items-center justify-content-between mr-3 ml-3" >
                                    <div className="add-on">
                                       <h4 className="heading mb-1">{list.serviceType} AED{(list.priceType!=0 ? list.guests : 1) * list.price}</h4>
                                       <span className="mb-2">{list.title}</span>
                                    </div>
                                 </div>
                                 })}
                                 <div className="clearfix"></div> 
                              </div>
                           </div> 
                        </div>    
               </div>
            </div>
           
            })}
            {/* second card*/ }
            
         </div> 
      </div>  	
      </section>
	</div> 
   </>);
    }
}

export default MyEvents;