import React from "react";
import {Link} from 'react-router-dom';
import { FaRegEdit, FaUnlock, FaRegCalendarAlt, FaBell, FaHandHolding, FaThermometerFull, FaSignInAlt,FaDashcube } from "react-icons/fa";

const logout=()=>{
	localStorage.removeItem('user-auth-token');
	localStorage.removeItem('user-auth-id');
	localStorage.removeItem('event_id');
	localStorage.removeItem('user-data');
	localStorage.clear();
	window.location.replace("/");
}
function UserLeftMenu(){
    return (
	<div className="form_card form_mn">			 
		<ul className="cbp-vimenu">
		<li><Link to="/userdashboard" className="icon-profile"><FaDashcube className="FaDashcube"/>&nbsp;&nbsp;Dashboard</Link></li>
		<li><Link to="/usereditprofile" className="icon-profile"><FaRegEdit className="fapencil"/>&nbsp;&nbsp;Edit Profile</Link></li>
		<li><Link to="/changepassword" className="icon-password"><FaUnlock className="faunlock"/>&nbsp;&nbsp;Change Password</Link></li>
		<li><Link to="/myevents" className="icon-events"><FaRegCalendarAlt className="facal"/>&nbsp;&nbsp;My Events</Link></li>
		<li><Link to="/notifications" className="icon-notifications"><FaBell className="fabell"/>&nbsp;&nbsp;Notifications</Link></li>
		<li><Link to="/invitefriend" className="icon-invite"><FaHandHolding className="fahand"/>&nbsp;&nbsp;Invite</Link></li>
		<li><Link target="_blank" to="/termsConditions" className="icon-terms"><FaThermometerFull className="fathermo"/>&nbsp;&nbsp;Terms & Conditions</Link></li>
		<li><Link to="#" className="icon-logout" onClick={logout}><FaSignInAlt className="fasign"/>&nbsp;&nbsp;Logout</Link></li>
		</ul>
	</div>
	);
}
 
export default UserLeftMenu;