import React, { Component } from "react";
import './AppStyle.css';
import axios from "axios";
import './left-menu.css';
import HeaderInner from "./HeaderUser";
import { IoIosArrowBack, IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import { FaCalendar } from 'react-icons/fa';
import moment from 'moment';
import LeftMenu from "./UserLeftMenu";
import { connect } from "react-redux";
import { API_BASE_URL } from "../../constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom'

class UserEditProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			memories: [{ relation: "", eventDate: "", event: "" }],
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			personalMsg: "",
			gender: "",
			bill_add: "",
			bill_city: "",
			bill_state: "",
			dob: "",
			setPrefix:"",
			mailVerify:"false",
			mobVerify: "false",
		}
	}

	componentDidMount() {
		let token = localStorage.getItem("user-auth-token");
		const headers = {
			'Content-Type': 'application/json',
			'authorization': token
		};
		const data = {
			'token': token
		}
		axios
			.post(`${API_BASE_URL}UserProfile`, data, { headers })
			.then(response => {
				this.setState({
					firstName: response.data.data[0].firstName,
					lastName: response.data.data[0].lastName,
					email: response.data.data[0].email,
					phoneNumber: response.data.data[0].phoneNumber,
					gender: response.data.data[0].gender,
					bill_add: response.data.data[0].bill_add.address,
					bill_city: response.data.data[0].bill_add.city,
					bill_state: response.data.data[0].bill_add.state,
					setPrefix : response.data.data[0].phoneNumber!==''?'none':'',
					mailVerify : response.data.data[0].isMailVerified,
					mobVerify : response.data.data[0].isMobileVerified,
				});
				if (response.data.data[0].dob) {
					this.setState({ dob: response.data.data[0].dob })
				}
				if (response.data.data[0].memories.length) {
					this.setState({ memories: response.data.data[0].memories })
				}
			})
			.catch(error => {
				console.log('error', error);
			});
	}

	// for back button
	Back_user = () => {
		this.props.history.replace('/create_event')
	}

	// handle click event of the Add button
	handleAddClick = () => {
		this.setState({ memories: [...this.state.memories, { relation: "", eventDate: "", event: "" }] });
	};
	
	handleRemoveClick = index => {
		const list = [...this.state.memories];
		list.splice(index, 1);
		this.setState({ memories: list });
	};

	handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...this.state.memories];
		list[index][name] = value;
		this.setState({ memories: list });
	};

	handleDateChange = (e, index) => {
		const list = [...this.state.memories];
		list[index]['eventDate'] = moment.unix(e).format("X");
		this.setState({ memories: list });
	};

	handleOnchange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}
	dobDate = (e) => {
		this.setState({ dob: moment.unix(e).format("X") })
    }
	toReadable = (Unix="") =>{
		return Unix === "" ? moment().format("YYYY-MM-DD") : moment.unix(Unix/1000).format("YYYY-MM-DD");
	}

	saveProfile = () => {
		let token = localStorage.getItem("user-auth-token");
		const headers = {
			'Content-Type': 'application/json',
			'authorization': token
		};
		const data = {
			"firstName": this.state.firstName,
			"lastName": this.state.lastName,
			"email": this.state.email,
			"phoneNumber": this.state.phoneNumber,
			"dob": this.state.dob,
			"gender": this.state.gender,
			"address": this.state.bill_add,
			"city": this.state.bill_city,
			"state": this.state.bill_state,
		}
		if(this.state.memories[0].event.length !==0 && this.state.memories[0].event.length !==0 &&this.state.memories[0].event.length !==0){
			data.memories = this.state.memories
		} 
		axios.post(`${API_BASE_URL}updateProfile`, data, { headers })
			.then((r) => {
				this.setState({ personalMsg: 'Profile updated successfully!' })
				this.setState({ ErrorMsg: '' })
			})
			.catch((error) => {
				this.setState({ personalMsg: '' })
				this.setState({ ErrorMsg: error.response.data.message })
			})
	}
	render() {
		return (
			<>
				<HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
				<div className="container">
					<div className="row">
						<div className="col-md-12 col-sm-12 col-xs-12">
							<div className="back-btn">
								<br />
								<Link className="text-dark"  onClick={this.Back_user}><IoIosArrowBack /> Back</Link>
							</div>
						</div>
					</div>
					<section className="application_sec">
						<div className="row">
							<div className="col-md-3 col-sm-3 col-xs-3 sidemenu">
								<LeftMenu />
							</div>
							<div className="col-md-9 col-sm-9 col-xs-9">
								<div className="form_card form_blk">
									<h3>Edit Profile</h3>
									<hr />

									<div className="row">
										<div className="col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="exampleFormControlInput1">First name</label>
												<input type="text" className="form-control" name="firstName" value={this.state.firstName} onChange={this.handleOnchange} placeholder="Enter your name" />
											</div>
										</div>
										<div className="col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="exampleFormControlInput1">Last name</label>
												<input type="text" className="form-control" name="lastName" value={this.state.lastName} onChange={this.handleOnchange} placeholder="Enter last name" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="exampleFormControlInput1">Email ID</label>
												<input type="email" readOnly = {this.state.mailVerify} className="form-control" name="email" value={this.state.email} onChange={this.handleOnchange} placeholder="Enter Email ID " />
											</div>
										</div>
										<div className="col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="exampleFormControlInput1">Contact Number</label>
												<div className="input-group mb-2">
												<div className="input-group-prepend" style={{display:this.state.setPrefix}}>
													<div className="input-group-text">+971</div>
												</div>
												<input readOnly = {this.state.mobVerify} type="text" maxLength={9} className="form-control" name="phoneNumber" value={this.state.phoneNumber} onChange={this.handleOnchange} />
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="exampleFormControlInput1">Date of Birth</label><br></br>
												<div style={{ position: "relative" }} className="singleDate">
													<DatePicker maxDate={new Date()}
														dateFormat="dd/MM/yyyy"
														showYearDropdown
														showMonthDropdown
														placeholderText="dd/mm/yyyy"
														onChange={
															(date) => this.dobDate(date)
														}
														selected = {this.state.dob==="" ? null : new Date(this.toReadable(this.state.dob))}
													/> 
													<span style={{ position: "absolute", right: "10px", top: "3px", fontSize: "20px", color: "#7a7a7a" }}>
														<FaCalendar />
													</span>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="gender">Gender</label>
												<select name="gender" value={this.state.gender} className="form-control" onChange={this.handleOnchange}>
													<option value="male">Male</option>
													<option value="female">Female</option>
													<option value="other">Other</option>
												</select>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="city">City</label>
												<input type="city" className="form-control" name="bill_city" value={this.state.bill_city} onChange={this.handleOnchange} placeholder="Enter City" />
											</div>
										</div>
										<div className="col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="state">State</label>
												<input type="text" className="form-control" name="bill_state" onChange={this.handleOnchange} value={this.state.bill_state} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="address">Address</label>
												<textarea className="form-control" name="bill_add" rows="2" value={this.state.bill_add} placeholder="Address 1" onChange={this.handleOnchange}></textarea>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12 col-sm-12 col-xs-12">
											<div className="form-group ">
												<label for="exampleFormControlInput1">Want to add memories</label>
												{this.state.memories.map((mem, i) => {
													return (
														<div className="row col-md-12" style={{ marginTop: "20px" }}>
															<div className="col-md-3">
																<select name="relation" value={mem.relation} className="form-control mr-1" onChange={e => this.handleInputChange(e, i)}>
																	<option value="">Select Member</option>
																	<option value="father">Father</option>
																	<option value="mother">Mother</option>
																	<option value="husband">Husband</option>
																	<option value="wife">Wife</option>
																	<option value="daughter">Daughter</option>
																	<option value="son">Son</option>
																</select>
															</div>
															<div className="col-md-4">
																<DatePicker maxDate={new Date()}
																	dateFormat="dd/MM/yyyy"
																	showYearDropdown
																	showMonthDropdown
																	placeholderText="dd/mm/yyyy"
																	onChange={(date) => {
																		this.handleDateChange(date, i)
																	}}
																	selected = {this.state.memories[i].eventDate==="" ? null : new Date(this.toReadable(this.state.memories[i].eventDate))}
																/>
																<span style={{ position: "absolute", right: "40px", top: "3px", fontSize: "20px", color: "#7a7a7a" }}>
																	<FaCalendar />
																</span>
															</div>
															<div className="col-md-4">
																<select name="event" value={mem.event} className="form-control mr-1" onChange={e => this.handleInputChange(e, i)}>
																	<option value="">Select Occasion</option>
																	<option value="birthday">Birthday</option>
																	<option value="anniversary">Anniversay</option>
																</select>
															</div>
															{this.state.memories.length !== 1 && <IoIosRemoveCircle className="plusIcon" onClick={() => this.handleRemoveClick(i)} />}
															{this.state.memories.length - 1 === i && <IoIosAddCircle className="plusIcon" onClick={() => this.handleAddClick()} />}
														</div>
													)
												}) 
												}
											</div>
										</div>
										{/* <div className="col-md-12 col-sm-12 col-xs-12">
											<div className="form-group">
												<label for="exampleFormControlInput1"></label>
												<img src={UsrImg} height="153px" alt="" className="mb-4 col-md-3 col-sm-12" />
												<input type="file" className="form-control col-md-6 col-sm-12" id="user" />
											</div>
										</div> */}
									</div>
									<span style={{ color: 'green' }}> {this.state.personalMsg}</span>
									<span style={{ color: 'red' }}> {this.state.ErrorMsg}</span>
									<br />
									<button type="button" className="btn btn-primary pull-right mb-3 " onClick={this.saveProfile}>Save Changes</button>
								</div>
							</div>
						</div>
					</section>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		userDetails: state.userDetailsReducer.userDetails,
	}
}
export default connect(mapStateToProps, null)(UserEditProfile);