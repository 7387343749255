import React, { Component } from 'react';
import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap'
import {Link} from 'react-router-dom';
import world from './assets/landing page pngs/world.png'
import loc from './assets/landing page pngs/location.png'
import wha from './assets/landing page pngs/whatsapp.png'
import Notification from './Notification';
import wlogo from './assets/EventStanWhite.png';

const Footer = () => {
    return (
        <>
        <div className="footer" >
            <div className="footer_desk">
                <Container>
                    <Row>
                        <Col md={3} xs={12} sm={12} className="fline">
                            <div style={{ paddingTop: '20px'}}>
                                <ul>
                                    <li><Link to="/about-us">About us</Link></li>
                                    <li><Link to="/create_event">Book an event</Link></li>
                                    <li><Link to="/create_pro_event">Hire a Professional</Link></li>
                                    <li><a href="https://vendor.eventstan.com">Vendor Portal</a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} xs={12} sm={12} className="sline">
                            <div style={{ paddingTop: '20px'}}>
                            <ul>
                                    <li><Link to="/faq"> FAQs</Link></li>
                                    <li><Link to="/privacy_policy"> Privacy Policy</Link></li>
                                    <li><Link to="/termsConditions"> Terms &copy; Conditions</Link></li>
                                    <li><a href="/#contact-form-section">Contact Us</a></li>
                                    <li><Link to="/partner">Partner With Us</Link></li>
                            </ul>   
                            </div>

                        </Col>
                                               
                        <Col md={6} xs={12} sm={12} className="tline">
                            <div className="mob_info">  
                                <ul>
                                    <li><img src={loc} className="footer-icon" alt="" />EventStan FZCO, Dtec, Technohub 1, Dubai Silicon Oasis, Dubai, UAE</li>
                                    <li><img src={world} className="footer-icon" alt=""/><a href="mailto:hello@eventstan.com">hello@eventstan.com</a></li>
                                    <li><img src={wha} className="footer-icon" alt=""/><b><a href="tel:+971529415444">+971 56 640 5353</a></b></li>
                                </ul>    
                            </div>
                        </Col>
                    </Row>
                    <br />
                    
                </Container>
            </div>
            
        </div>
        <div style={{ backgroundColor:'#f0e3da'}} className="copy">
                      
        <span style={{ fontSize: '12px', padding:'2px'}}>Copyrights © {new Date().getFullYear() } EventStan. All Rights Reserved</span>
    </div>
    <Notification />  
    </>
    );
}
export default Footer;