import React, { useState,useEffect } from 'react';
import LogoPic from './assets/eventstan.png';
import envelop from './assets/envelope.png';
import twi from './assets/twitter.png';
import goo from './assets/googleplus.png';
import face from './assets/facebook.png';


const MailFooter = () => {
    return (
        <table border="0" width="500" align="center" cellpadding="0" cellspacing="0" class="container-middle nav-head">
        <tbody><tr><td>
        <table class="follow" width="200" align="left" border="0" cellpadding="0" cellspacing="0" style={{"borderCollapse": 'collapse', "msoTableLspace": '0pt', "msoTableRspace": '0pt'}}>
											<tbody><tr>
												<td style={{"color": '#fff', "fontSize": '1.3em', "fontFamily": 'Candara', "textAlign": 'left', "lineHeight": '1.6em'}}>Follow us on
												</td>
											</tr>
										</tbody></table></td>
        <td>
        <table class="follow" width="150" align="right" border="0" cellpadding="0" cellspacing="0" style={{"borderCollapse": 'collapse', "msoTableLspace": '0pt', "msoTableRspace": '0pt'}}>
											<tbody><tr>
												<td>
													<table border="0" width="100%">
														<tbody>
															<tr>
																<td width="22">
																	<a href="#">
																		<img src={face} width="22" height="22" alt="" />
																	</a>
																</td>
																<td width="1">
																</td>
																<td width="22">
																	<a href="#">
																		<img src={goo} width="22" height="22" alt="" />
																	</a>
																</td>
																<td width="1">
																</td>
																<td width="22">
																	<a href="#">
																		<img src={twi} width="22" height="22" alt="" />
																	</a>
																</td>
															</tr>
														</tbody>
													</table>  
                                                    
												</td>
											</tr>
										</tbody></table> 
        </td></tr>
    </tbody></table>
        );
}
export default MailFooter;