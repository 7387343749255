import React, { Component } from "react";
import './AppStyle.css';
import './left-menu.css';
import HeaderInner from "./HeaderUser";
import {IoIosArrowBack, IoIosAdd} from 'react-icons/io';
import RefImg from "./images/dashboard/referral-image.png";
import LeftMenu from "./UserLeftMenu";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from "react-redux";
import { API_BASE_URL,BASE_URL } from "../../constant";

class InviteFriend extends Component {
    constructor(props){
        super(props);
        this.state = { 
        copySuccess: '',
        txtDisplay:'', 
        ref_url: `${BASE_URL}reference_signup/${this.props.userdata._id}`,
        uid :localStorage.getItem('user-auth-id'),
        }
    }

    componentDidMount(){
        this.setState({ref_url: `${BASE_URL}reference_signup/${this.state.uid}`});
    }

    copyToClipboard = (e) => {
        navigator.clipboard.writeText(this.state.ref_url);
        this.setState({ copySuccess: 'Copied!', txtDisplay:'none' });
      };
   
    render(){ 
        const copySuccess = this.state.copySuccess;   
        let btnText;
        let txtDisplay;
    if (copySuccess) {
        btnText = 'Copied !';

    } else {
        btnText = 'Copy Text';
    }   
        return (
           <>
<HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
<div className="container">
	<div className="row">
               <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="back-btn">
					      <br />
                     <a className="text-dark"href="#"><IoIosArrowBack/> Back</a>
                  </div>
               </div>
    </div>
    <section className="application_sec">	
        <div className="row">
            <div className="col-md-3 col-sm-3 col-xs-3 sidemenu">
                <LeftMenu />
            </div>
            <div className="col-md-9 col-sm-9 col-xs-9">
                <div className="form_card form_blk">  
                    <h3>Referral Link</h3>
                    <hr/>
                    <div className="card-body mb-4">
                        <h3 className="f-20 c-black" style={{textAlign : 'center' }}>Invite your friends to events<br/> and earn a referral on each joining.</h3>
                        <center> <img src={RefImg} style={{width : '22%'}} /></center><br />   
	                        <center><h4>Invite via social media link</h4></center>
	                    <center><a target="_blank" href={`https://api.whatsapp.com/send?text=Share and Earn ,follow this link ${this.state.ref_url}`}><button className="btn btn-success  btn-sm mt-3 mb-4">WhatsApp</button></a>&nbsp;
	                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${this.state.ref_url}`} target="_blank"><button className="btn btn-info btn-sm mt-3 mb-4">facebook</button></a>
	                    </center>							
	                    <center><h4>OR</h4></center><br/>
	                    <center><h4>More ways to invite your friends</h4></center><br/>
	                    <div className="share-btn-ctr">
		                    <input type="search" name="searchBy" id="searchBy" className="form-control" value={this.state.ref_url} readonly="" style={{display : this.state.txtDisplay, width: '100%' }}/>
		                    <button id="copyCodeBtn" onClick={this.copyToClipboard} className="btn  btn-primary btn-block form-control btn-send1 inv"> {btnText}</button>
	                    </div>
                    </div>
                </div>
            </div> 
        </div>  	
    </section>
</div>
    </>);
    }
}

const mapStateToProps = (state)=>{
    return{
        userdata : state.userDetailsReducer.userDetails,
    }
}
export default connect(mapStateToProps,null)(InviteFriend)