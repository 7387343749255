import React, { Component } from "react";
import './App.css';
import HeaderInner from "./components/HeaderInner";
import account from './assets/account.jpg';
import registersl from './assets/account-sl.jpg';
import bookingsl from './assets/event-faq-sl.jpg';
import booking from './assets/event-faq.jpg';
import vendor from './assets/cancel.jpg';
import vendorsl from './assets/cancel-sl.jpg';
import {IoIosArrowBack} from 'react-icons/io';
import Faqcom from 'react-faq-component';

const dataaccount = {
   title: "Account",
   rows: [
     {
       title: "How to register/login?",
       content: "You can either log in using your social media handles or by registering your email and creating an own account with EventStan. "
     },
     {
       title: "Who can register?",
       content: "Any individual who is looking to do an event, see availability, options and prices can register."
     },
     {
       title: "How to change your password?",
       content: "Password can be changed by requesting for an OTP on your registered mobile number or email. "
     },
     {
       title: "Who can book an event?",
       content: "Anyone who is registered on EventStan and looking to plan an event in the near future."
     },
     {
       title: "How to book an event?",
       content: "Select the 'Create an event' option on the homepage and fill in the suitable filters. "
     },
     {
       title: "Can you book multiple events?",
       content: "Yes, you can book as many events as you want."
     },
     {
       title: "Where can I see all my event bookings?",
       content: "Upon clicking 'menu' on the website, you'll find 'My Events'; that's where all your ongoing events and drafts will be displayed. "
     },
     {
       title: "Can I get a discount on my booking?",
       content: "If you have a coupon code then yes you can get a discount, depending on the % and validity of the coupon. "
     }]
 }

 const dataevent = {
   title: "Event",
   rows: [
     {
       title: "What kind of events can I book?",
       content: "We have a variety of events and packages to choose from, however you can also search for options by selecting the event closest to the event you're planning on having. "
     },
     {
       title: "How many services can I book at once?",
       content: "There is no limit on the amount of services booked either individually or as part of an event."
     },
     {
       title: "Can I only book one service?",
       content: "Yes you can chose just one service and make the booking."
     },
     {
       title: "Do I have to select something in each category?",
       content: "No, you can select only what you need, can range from one to multiple."
     },
     {
       title: "What is the minimum booking amount?",
       content: "There is no minimum booking amount as long as one service provider is booked."
     },
     {
       title: "Do I have to book all services from one provider if they offer more than one service?",
       content: "No, you can continue to choose different services from different providers, as long as there is no compulsion set on the service by the provider."
     }
   ]
 }

 const datacancel = {
   title: "Cancellation & Refund",
   rows: [
     {
       title: "Can I change my service provider after I have confirmed the booking?",
       content: "If the provider has not accepted your booking, you can cancel that service and book a new one under a new event name and booking. "
     },
     {
       title: "Can the booking be cancelled?",
       content: "Yes the booking can be cancelled on reasonable grounds."
     },
     {
       title: "Can some services in the booking be cancelled?",
       content: "Once booking is placed, vendors have 2 hours to accept the booking. In the interim and prior to them accepting the booking, it can be cancelled. "
     },
     {
       title: "What is the refund policy?",
       content: "Refund policies are subjective to each vendor. We request you to read their T&C's before confirming the booking. "
     },
     {
       title: "Will I be charged anything if I cancel my event?",
       content: "It depends on the date of the cancellation and how near it is to your upcoming event."
     },
     {
       title: "Will I get my full refund?",
       content: "Yes, however it solely depends on the date of cancellation and vendor policy. "
     },
     {
       title: "How many days will it take for my refund?",
       content: "Once the payment has been sent by us, it can take anywhere between 7-10 working days for it to show up in your account. "
     },
     {
       title: "Can I pay in instalments?",
       content: "Yes, you can either pay the full booking amount at once or pay it 2 payments of 50% each, however this depends on the date of booking. "
     },
     {
       title: "How do the instalments work?",
       content: "The 1st instalment is required at the time of the booking and the 2nd instalment needs to be paid minimum 5 days before the event date."
     },
     {
       title: "What happens if the vendor cancels for one or more services?at is the package version",
       content: "In such an unfortunate situation, EventStan refunds your money back and gives you alternate options that we believe would rock your event. "
     },
     {
       title: "Do I get a refund when a service is cancelled by the vendor?",
       content: "Yes, you will get a refund if service is cancelled by vendor after accepting it. "
     }]
 }



class TermsConditions extends Component {
   componentDidMount(){
      window.scrollTo(0, 0);
   }
    render(){
        return (
           <>
         <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
            <div className="container">
			<div className="row">
               <div className="col-md-12 col-sm-12 col-xs-12">
                  {/* <div className="back-btn">
					      <br />
                     <a className="text-dark"href="#"><IoIosArrowBack/> Back</a>
                  </div> */}
               </div>
            </div>
            <section className="application_sec">	
            <h3 style={{ textAlign: 'center'}}>Frequently Asked Questions</h3>
            <div class="row">
                <div className="col-md-4 col-sm-6 col-xs-12" >
                  
                <a href="#account" className="faqpg"><div style={{ margin:'15px ', padding:'15px', textAlign:'center', cursor: 'pointer', color: "#f97a25"}} className="faqblk"> 
                <img src={account} /><br/>
                Account</div></a>
               </div>
               <div className="col-md-4 col-sm-6 col-xs-12">
               
               <a href="#event"><div style={{ margin:'15px 0px', padding:'15px', textAlign:'center', color: "#f97a25"}}>
               <img src={booking} /><br/>Event</div></a>
               </div>
               <div className="col-md-4 col-sm-6 col-xs-12">
               
               <a href="#cancel"><div style={{ margin:'15px', padding:'15px', textAlign:'center', color: "#f97a25"}}>
               <img src={vendor} /><br/>Cancellation & Refund</div></a>
               </div>
               </div>      
               <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="form_card form_blk" style={{ borderRadius: '0px', border: '1px solid rgb(239, 239, 239)', boxShadow: 'none'}}>  
                     
                     <div class="row" style={{background:`URL(${registersl}) no-repeat`, backgroundPosition:'right top', marginTop:'-10px', paddingTop:'10px'}}>
                        
                        
                        <div class="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'justify'}}>
                        <a name="account"><Faqcom data={dataaccount} styles={{bgColor: "transparent",  titleTextColor: "#9397bc", rowTitleTextSize: 'medium', rowContentTextSize: 'medium', rowTextColor: "#78789a", rowContentColor: "#f97a25"}}/></a>
                       
                        </div>
                        </div>                
					</div>
               <br/>
               <div className="form_card form_blk" style={{ borderRadius: '0px', border: '1px solid rgb(239, 239, 239)', boxShadow: 'none'}}>  
                     
                     <div class="row" style={{background:`URL(${bookingsl}) no-repeat`, backgroundPosition:'right top', marginTop:'-10px', paddingTop:'10px'}}>
                        
                        <div class="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'justify'}}>
                        <a name="event">
                        <Faqcom data={dataevent} 
                        styles={{bgColor: "transparent",  titleTextColor: "#9397bc", rowTitleTextSize: 'medium', rowContentTextSize: 'medium', rowTextColor: "#78789a", rowContentColor: "#f97a25"}}/>
                        </a></div>
                        </div>                
					</div>
               <br/>
               <div className="form_card form_blk" style={{ borderRadius: '0px', border: '1px solid rgb(239, 239, 239)', boxShadow: 'none'}}>  
                     
                     <div class="row" style={{background:`URL(${vendorsl}) no-repeat`, backgroundPosition:'right top', marginTop:'-10px', paddingTop:'10px'}}>
                        <div class="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'justify'}}>
                        <a name="cancel"><Faqcom data={datacancel} styles={{bgColor: "transparent",  titleTextColor: "#9397bc", rowTitleTextSize: 'medium', rowContentTextSize: 'medium', rowContentColor: "#f97a25"}}/>
                        </a></div>
                        </div>                
					</div>

				</div>
			
      </section>
	</div> 
   </>);
    }
}

export default TermsConditions;