import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from './constant';
import "./App.css";

export default function Payment() {
  let history = useHistory();
  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent"
    );
    axios.post(`${API_BASE_URL}retrieve-setup-intent`,{"stripe_setup_intent_id":clientSecret})
    .then(res=>{
      //history.goBack();
      window.location=document.referrer;
    })
  }, []);

  return (
   null
  )

  
  

 
}