import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Row, Col, Container, Button } from "react-bootstrap";
import "./Createevent.css";
import card_pic from "./assets/create_event/card.jpg";
import "./Venuedetail.css";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery'
import moment from 'moment';
import search_ico from "./assets/landing page pngs/search.svg";
import Select from "react-select";
import Slider from "rc-slider";
import { IoIosArrowBack } from 'react-icons/io'
import "rc-slider/assets/index.css";
import DatePicker from "react-datepicker";
import { TiTick } from 'react-icons/ti'
import { FaCalendar,FaStar } from 'react-icons/fa';
import { API_BASE_URL, VENDOR_API_BASE_URL } from './../src/constant';
import LoginPopup from './components/loginPopup';
import HeaderInner from "./components/HeaderInner";
import jspriceType from "./context/priceType";
import { connect } from 'react-redux';
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);


class Venuedetail extends Component {

  constructor(props) {

    super(props);

    this.state = {

      package: '',

      Name: "",

      addOns: [],

      filters: [],

      primary: [],
      reviews:[],
      primarysidebar: [],

      filterdata1: [],
      Booking:[],
      appliedfilter: [],

      filterlist: {

        commonFilters: [],

        guptaresorts: [],

        Venue: [],

        SelectTime: [],

      },

      minMaxValue: [0, 0],

      filterData: [],

      Addons: [],

      Sidefilter: [],

      fav: false,

      AdData: false,

      Disbtn: true,

      filterpop: false,

      fieldCount: [1],

      endDate: new Date(),

      startDate: new Date(),

      Date: new Date(),

      count1: "",

      count2: "",

      savedraftcount: "",

      showPopup: false,

      redirect: null,

      selectedServices: [],

      title: "",

      eventCost: "",

      location_val: "",

      edate: "",

      age: "",

      startTime: "",

      endTime: "",

      membersMaxRange: "",

      membersMinRange: "",

      locations: [],

    };

    const uid = '';

  }



  componentDidMount() {

    window.scrollTo(0, 0);

    this.uid = localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1';

    //get states data from colection

    axios.get(`${API_BASE_URL}get-locations?status=1`)

      .then(resp => {

        const states = [];

        resp.data.data.map((st, index) => {

          states.push(st.stateName)

        })

        this.setState({ locations: states })

      })

    // get draft api

    axios.get(`${API_BASE_URL}getDraft?userEventId=${this.props.match.params.userEventId}&userId=${this.uid}`)

      .then(r => {

        const selectedService = [];

        for (let i = 0; i < r.data.data.data[0].vendorServices.length; i++) {

          selectedService.push(r.data.data.data[0].vendorServices[i].serviceId);

        }

        this.setState({ selectedServices: selectedService });

        this.setState({ savedraftcount: r.data.data.count });

        this.setState({ eventCost: r.data.data.data[0]._id.packagePrice });

      })

      .catch(e => {

        console.log('save draft error', e)

      })

    // get more filter api

    axios

      .get(

        `${API_BASE_URL}event-filters?eventTypeId=${this.props.match.params.eventTypeId}&userEventId=${this.props.match.params.userEventId}`

      )

      .then(res => {

        this.setState({ Addons: res.data.data.result.addOns })

        this.setState({ primarysidebar: res.data.data.result.primary });

        this.setState({ appliedfilter: res.data.data.appliedFilters, filterlist: res.data.data.filterList })

        res.data.data.appliedFilters.map((fil) => {

          if (fil.type == 8) {

            this.setState({ location_val: fil.singleSelect })



          }

          if (fil.type == 3) {

            let mdate = parseInt(fil.date);

            let edate = moment(new Date(mdate)).format("YYYY-MM-DD");

            this.setState({ edate: edate });

          }



          if (fil.type == 1) {

            let age = fil.singleSelect;

            this.setState({ age: age });

          }

          if (fil.type == 6) {

            this.setState({ startTime: fil.startTime });

            this.setState({ endTime: fil.endTime });

          }



          if (fil.type == 7) {

            this.setState({ membersMaxRange: fil.membersMaxRange });

            this.setState({ membersMinRange: fil.membersMinRange });

          }

        });



        axios.get(`${VENDOR_API_BASE_URL}user-event/${this.props.match.params.eventTypeId}/${this.props.match.params.userEventId}/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}`)
          .then(resp => {
            if (resp.data.results.event.event_details.primary.length > 0) {
              this.setState({ count1: resp.data.results.event.event_details.primary.length })
            }
            if (resp.data.results.event.event_details.addOns.length > 0) {
              this.setState({ count2: resp.data.results.event.event_details.addOns.length })
            }
            if (resp.data.results.event.event_details.addOns.length > 0) {
              this.setState({ AdData: true })
            }
            else {
              this.setState({ AdData: true })
            }
            //this.setState({Addons : resp.data.results.event.event_details.addOns})
            //this.setState({ appliedfilter: res.data.results.appliedFilters, filterlist: res.data.results.appliedFilters })
            this.setState({
              filters: resp.data.results.appliedFilters,
              addOns: resp.data.results.event.event_details.addOns,
              title: resp.data.results.event.event_name,
              primary: resp.data.results.event.event_details.primary.filter(ps => ps.serviceId != '6317218e009db7128f1c6845'),
              //someArray = someArray.filter(person => person.name != 'John');
            });
          })
          .catch(e => {
            console.log('filter error', e.response)
          })

          // Service List Api for package
          axios.get(`${VENDOR_API_BASE_URL}user-event/${this.props.match.params.eventTypeId}/${this.props.match.params.userEventId}/6317218e009db7128f1c6845/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}`)
          .then(response =>{ console.log(response.data.results[0].event_details.service_list);
              this.setState({Booking : response.data.results[0].event_details.service_list})
          })
          .catch(error => {
              console.log('servicelist error' , error)
          })



        const filterdatatest = []

        let date = new Date();

        let curTime = moment().add(1, 'day').format("x")

        let Curtime = +curTime

        let endTime1 = moment().add(1, 'hours').format("x")

        let endTime = +endTime1

        Object.entries(this.state.filterlist).map((filter, index) => {

          this.setState({ Name: filter[0] })

          let fil = filter[0]

          filter[1].forEach((filter, index) => {

            if (filter.type === 1) {

              let Obj;

              Obj = {

                filterId: filter._id,

                type: filter.type,

                name: filter.name,

                singleSelect: filter.singleSelect,

                singleSelectData: filter.singleSelectData ? filter.singleSelectData : ""

              }

              filterdatatest.push(Obj)

            }

            if (filter.type === 2) {

              let Obj;

              Obj = {

                filterId: filter._id,

                type: filter.type,

                name: filter.name,

                multiSelect: filter.multiSelect,

                multiSelectList: filter.multiSelectList

              }

              filterdatatest.push(Obj)

            }

            if (filter.type === 3) {

              let Obj;

              Obj = {

                filterId: filter._id,

                type: filter.type,

                name: filter.name,

                date: filter.date ? filter.date : Curtime

              }

              filterdatatest.push(Obj)

            }

            if (filter.type === 4) {

              let Obj;

              Obj = {

                filterId: filter._id,

                type: filter.type,

                name: filter.name,

                startDate: filter.startDate ? filter.startDate : Curtime,

                endDate: filter.endDate ? filter.endDate : endTime,

              }

              filterdatatest.push(Obj)

            }

            if (filter.type === 5) {

              let Obj;

              Obj = {

                filterId: filter._id,

                type: filter.type,

                name: filter.name,

                time: filter.time ? filter.time : Curtime,

              }

              filterdatatest.push(Obj)

            }

            if (filter.type === 6) {

              let Obj;

              Obj = {

                filterId: filter._id,

                type: filter.type,

                name: filter.name,

                startTime: filter.startTime ? filter.startTime : Curtime,

                endTime: filter.endTime ? filter.endTime : Curtime

              }

              filterdatatest.push(Obj)

            }

            if (filter.type === 7) {

              let Obj;

              Obj = {

                filterId: filter._id,

                type: filter.type,

                name: filter.name,

                membersMinRange: filter.membersMinRange,

                membersMaxRange: filter.membersMaxRange,

                membersMaxRangeData: filter.membersMaxRangeData ? filter.membersMaxRangeData : "",

                membersMinRangeData: filter.membersMinRangeData ? filter.membersMinRangeData : ''

              }

              filterdatatest.push(Obj)

            }

            if (filter.type === 8) {

              let Obj;

              Obj = {

                filterId: filter._id,

                type: filter.type,

                name: filter.name,

                singleSelect: this.state.locations,

                singleSelectData: filter.singleSelectData ? filter.singleSelectData : this.state.location_val

              }

              filterdatatest.push(Obj)

            }

            if (filter.type === 7) {

              this.setState({ minMaxValue: [filter.membersMinRangeData, filter.membersMaxRangeData] })

            }

          })

          this.setState(prevState => ({

            filterlist: {

              "Gupta resorts11": filterdatatest

            }

          }))



        })

      })

      .catch(e => {

        console.log('filter error', e.response)

      })

      axios.get(`${API_BASE_URL}getServiceReview`)
                .then(r=>{ 
                    this.setState({'reviews':r.data.data.result});
                })
    }

    // for get average reviews of service
    get_average_reviews = (id) =>{
      let count = 0;
      let rating = 0;
      this.state.reviews .forEach(element => {
          if(element.vendorServiceId === id){
              count++;
              rating += element.rating;
          }  
      });
      return count>0 ? (rating/count).toFixed(1) : 0;
  }

  AddOnDetail = () => {

    if (this.state.AdData == true) {

      this.setState({ AdData: false })

    }

    else {

      this.setState({ AdData: true })

    }

  }

  // remove applied filter

  RemoveFilter = (filterid) => {

    const userEventId = this.props.match.params.userEventId

    const eventTypeId = this.props.match.params.eventTypeId

    const serviceId = this.props.match.params.serviceId

    const remove_filter = {

      userEventId: userEventId,

      filterId: filterid

    }

    axios.post(`${API_BASE_URL}removeFilter`, remove_filter)

      .then(respon => {

        window.location.reload();

      })

      .catch(e => {

        console.log('error in remove filer', e)

      })

  }



  // get the value of multi select filter

  getmultiSelect = (arr) => {



    const values = [];

    arr.forEach(item => values.push(item.value));

    return values;

  }

  // update the more filter button

  UpdateFilter = () => {

    const userEventId = this.props.match.params.userEventId;

    const eventTypeId = this.props.match.params.eventTypeId;

    const temp = []

    Object.entries(this.state.filterlist).map((filterdata, index) => {

      filterdata[1].forEach((item, index) => {

        if (item.type === 1) {

          temp.push({

            filterId: item.filterId,

            type: item.type,

            singleSelect: item.singleSelectData



          });

        }

        if (item.type === 2) {

          temp.push({

            filterId: item.filterId,

            type: item.type,

            multiSelect: item.multiSelectList

          });

        }

        if (item.type === 3) {

          temp.push({

            filterId: item.filterId,

            type: item.type,

            date: item.date,

            hdate: moment(item.date).format("DD-MM-YYYY"),

          });

        }

        if (item.type === 4) {

          temp.push({

            filterId: item.filterId,

            type: item.type,

            startDate: item.startDate,

            endDate: item.endDate

          });

        }

        if (item.type === 5) {

          temp.push({

            filterId: item.filterId,

            type: item.type,

            time: item.time

          });

        }

        if (item.type === 6) {

          temp.push({

            filterId: item.filterId,

            type: item.type,

            startTime: item.startTime,

            endTime: item.endTime,

            hstartTime: moment(item.startTime).format("HH:mm"),

            hendTime: moment(item.endTime).format("HH:mm"),

          });

        }

        if (item.type === 7) {

          temp.push({

            filterId: item.filterId,

            type: item.type,

            membersMinRange: item.membersMinRangeData,

            membersMaxRange: item.membersMaxRangeData

          });

        }

        if (item.type === 8) {

          temp.push({

            filterId: item.filterId,

            type: item.type,

            singleSelect: item.singleSelectData



          });

        }

      })

    })

    var serviceId = "";

    this.state.primary.forEach((p, index) => {

      serviceId = p.serviceId

    })

    const Filters = {

      userEventId: userEventId,

      eventTypeId: eventTypeId,

      userId: this.uid,

      filters: temp

    }

    axios.post(`${API_BASE_URL}event`, Filters)

      .then(res => {

        this.setState({ filterpop: false })

        axios.get(`${API_BASE_URL}serviceList?userEventId=${userEventId}&eventTypeId=${eventTypeId}&serviceId=${serviceId}`)

          .then(response => {

            this.setState({ appliedfilter: response.data.data.appliedFilters })

            window.location.reload();

          })

          .catch(error => {

            console.log('error g', error)

          })



      })

      .catch(e => {

        console.log('filter error', e.response)

      })

  }



  // for Select the Template 

  BgChange = () => {

    $(".package_card").click(function () {

      $(this).addClass("unpacked")

    })

  }



  // for Show More filter

  filterPopUp = () => {

    this.setState({ filterpop: true })

  }



  // for Hide more filter

  filterPopDown = () => {

    this.setState({ filterpop: false })

  }



  // for back button

  Back_user = () => {

    this.props.history.replace('/create_event')

  }





  // for Single Select Filter

  SelectChange = (e, id) => {

    const temp = { ...this.state.filterlist }

    let index = -1;



    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.singleSelectData = e

        }



      })



    })

    this.setState({ filterlist: temp })

  }



  // for Single Date filter

  singleselectDate = (e, id) => {

    const temp = { ...this.state.filterlist }

    let index = -1;

    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.date = parseInt(e)

        }

      })



    })

    this.setState({ filterlist: temp })

  }

  // for Single Time filter

  singleselectTime = (e, id) => {

    const temp = { ...this.state.filterlist }

    let index = -1;

    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.time = parseInt(e)

        }

      })



    })

    this.setState({ filterlist: temp })

  }



  // for start Date filter

  startDateRange = (e, id) => {

    const temp = { ...this.state.filterlist }

    let index = -1;

    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.startDate = parseInt(e)

        }

      })



    })

    this.setState({ filterlist: temp })

  }



  // for End Date filter

  endDateRange = (e, id) => {

    const temp = { ...this.state.filterlist }

    let index = -1;



    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.endDate = parseInt(e)

        }

      })



    })

    this.setState({ filterlist: temp })

  }



  // for start Time filter

  startTimeRange = (e, id) => {

    const temp = { ...this.state.filterlist }

    let index = -1;



    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.startTime = parseInt(e)

        }



      })



    })

    this.setState({ filterlist: temp })

  }



  // for end Time filter

  endTimeRange = (e, id) => {

    const temp = { ...this.state.filterlist }

    let index = -1;



    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.endTime = parseInt(e)

        }



      })



    })

    this.setState({ filterlist: temp })

  }





  // for multi select filter

  MultiChange = (e, id) => {

    const temp = { ...this.state.filterlist }

    let index = -1;



    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.multiSelectList = e

        }

      })



    })

    this.setState({ filterlist: temp })

  }



  // for Get the range slider value

  rangeSlider = (filtervalue, id) => {



    const temp = { ...this.state.filterlist }

    let index = -1;

    Object.entries(temp).map((filter, index) => {

      filter[1].forEach((item, i) => {

        if (item.filterId == id) {

          index = i

          item.membersMinRangeData = filtervalue[0]

          item.membersMaxRangeData = filtervalue[1]

        }

      })

    })

    this.setState({ filterlist: temp })

  }

  locAdd = () => {

    let add = false;

    return add;

  }



  // for check validation on let's create an Event button

  CheckValidation = () => {

    const temp = [...this.state.appliedfilter]

    let added = false;

    temp.forEach((item, index) => {

      if (item.type === 6 && (parseInt(item.endTime) < parseInt(item.startTime))) {

        added = true

      }

    })

    return added;

  }

  render() {
    const packageConfig = {
      loop: false,
      // margin: 10,
      items: 3,
      autoplay: true,
      rewind: true,
      nav: false,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      responsive: {
          //breakpoint from 0 and up
          0: {
              items: 1,
          },
          // add as many breakpoints as desired , breakpoint from 480 up
          480: {
              items: 1,
          },
          // breakpoint from 768 up
          768: {
              items: 3,
          },
          992: {
              items: 3,
          },
      }
  }

    const userEventId = this.props.match.params.userEventId

    const eventTypeId = this.props.match.params.eventTypeId

    var applied = [];

    this.state.appliedfilter.forEach((appliedfilter, index) => {



      switch (appliedfilter.type) {

        case 1:

          applied[appliedfilter.type] = appliedfilter.singleSelect + "#" + appliedfilter.filterId

          break;

        case 2:

          applied[appliedfilter.type] = appliedfilter.multiSelect + "#" + appliedfilter.filterId;

          break;

        case 3:

          var date = moment(parseInt(appliedfilter.date)).format("DD-MM-YYYY")

          applied[appliedfilter.type] = date + "#" + appliedfilter.filterId

          break;

        case 4:

          var date1 = moment(parseInt(appliedfilter.startDate)).format("DD-MM-YYYY")

          var date2 = moment(parseInt(appliedfilter.endDate)).format("DD-MM-YYYY")

          applied[appliedfilter.type] = date1.concat(' / ', date2) + "#" + appliedfilter.filterId

          break;

        case 5:

          var time = moment(parseInt(appliedfilter.time)).format("hh:mm A")

          applied[appliedfilter.type] = time + "#" + appliedfilter.filterId

          break;

        case 6:

          var time1 = moment(parseInt(appliedfilter.startTime)).format("hh:mm A")

          var time2 = moment(parseInt(appliedfilter.endTime)).format("hh:mm A")

          applied[appliedfilter.type] = time1 + "-" + time2 + "#" + appliedfilter.filterId

          break;

        case 7:

          applied[appliedfilter.type] = appliedfilter.membersMinRange + ' - ' + appliedfilter.membersMaxRange + "#" + appliedfilter.filterId

          break;

        case 8:

          applied[appliedfilter.type] = appliedfilter.singleSelect + "#" + appliedfilter.filterId

          break;



      }

    })



    var date = new Date();

    var curTime = moment(date).format("hh:mm")

    var date = new Date();

    var curTime = moment().format("x")

    var Curtime = +curTime

    var endTime1 = moment().add(1, 'hours').format("x")

    var endTime = +endTime1





    return (

      <div className="create_event">

        <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />

        <br />

        <br />

        <Container>

          <div className="row" >

            <div className="col-md-12">

              <p className="pcontainer">

                <a className="remove_anchor bold_me anchor_back" href="#" onClick={this.Back_user} ><IoIosArrowBack />BACK</a>

              </p>



              <h3 className="bold_me" style={{ textTransform: "capitalize" }}>{this.state.title}</h3>

              <h6 className="greyFont">

                Add your favorite service providers & choose more from the add-ons.

              </h6>



              <div className="inner-filter">

                <ul className="filter-btn">

                  <li className=""><Link to="#" onClick={this.filterPopUp}>Edit Filters <span>&#10095;</span></Link></li>

                </ul>

                <ul className="filter-option">

                  {

                    applied.map((filter, index) => {

                      var filtervalues = filter.split("#");

                      var filtervalue = filtervalues[0]

                      var filterid = filtervalues[1]

                      return filtervalue != '' ? <li key={index}><Link to="#">{filtervalue}</Link></li> : '';

                      // return <li key={index}><Link to="#">{filtervalue}<span onClick={() => this.RemoveFilter(filterid)}>x</span></Link></li>

                    })

                  }

                </ul>

                {

                  this.state.filterpop == false ? "" :

                    <div className="filter-content" >

                      <div className="inside-content">

                        <p onClick={this.filterPopDown} className="can-el-btn">X</p>

                        {

                          Object.entries(this.state.filterlist).map((filter, index) => {

                            return <div className="row">

                              {filter[1].length > 0 ? <h3 className="col-md-12 capBotMargin">{this.state.title}</h3> : null}

                              {filter[1].map((infiler, i) => (

                                <div key={i} className="col-md-6" style={{ marginBottom: '15px' }}>

                                  {infiler.type === 1 && (

                                    <div>

                                      <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>

                                      <Select

                                        options={infiler.singleSelect.map((option, index) => {

                                          return { label: option, value: option };

                                        })}

                                        defaultValue={infiler.singleSelectData != undefined && infiler.singleSelectData != '' ? ({ label: infiler.singleSelectData, value: infiler.singleSelectData }) : ''}

                                        onChange={(e) => this.SelectChange(e.value, infiler.filterId)}

                                        className="basic-multi-select"

                                        classNamePrefix="select"

                                      />

                                    </div>

                                  )}

                                  {infiler.type === 2 && (

                                    <div>

                                      <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>

                                      <Select

                                        options={infiler.multiSelect.map((option, index) => {

                                          return { label: option, value: option };

                                        })}

                                        defaultValue={

                                          (infiler.multiSelectList != undefined) ?

                                            infiler.multiSelectList.map((option, index) => {

                                              return { label: option, value: option };

                                            }) : null}

                                        className="basic-multi-select"

                                        classNamePrefix="select"

                                        isMulti

                                        onChange={(e) => this.MultiChange(this.getmultiSelect(e), infiler.filterId)}

                                      />

                                    </div>

                                  )}

                                  {infiler.type === 3 && (

                                    <div>

                                      <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>

                                      <div style={{ position: "relative" }} className="SingleSelectDate">

                                        <DatePicker minDate={new Date().setDate(new Date().getDate() + 1)}

                                          showYearDropdown

                                          dateFormat="dd/MM/yyyy"

                                          selected={infiler.date ? new Date(infiler.date) : Curtime}

                                          onChange={(date) => this.singleselectDate(moment(date).format("x").toString(), infiler.filterId)}

                                        />

                                        <span className="dtCalender"><FaCalendar /></span>

                                      </div>



                                    </div>

                                  )}

                                  {infiler.type === 4 && (

                                    <div>

                                      <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name}</h6>

                                      <div className="trw-box Doubledatebox">

                                        <div style={{ position: "relative", marginRight: "5px" }} className="DoubleDate">

                                          <DatePicker minDate={new Date()}

                                            showYearDropdown

                                            dateFormat="dd/MM/yyyy"

                                            selected={infiler.startDate ? new Date(infiler.startDate) : Curtime}

                                            onChange={(date) => this.startDateRange(moment(date).format("x").toString(), infiler.filterId)}

                                          />

                                          <span className="dtCalender"><FaCalendar /></span>

                                        </div>

                                        <div style={{ position: "relative" }} className="DoubleDate">

                                          <DatePicker minDate={new Date()}

                                            showYearDropdown

                                            dateFormat="dd/MM/yyyy"

                                            selected={infiler.endDate ? new Date(infiler.endDate) : Curtime}

                                            onChange={(date) => this.endDateRange(moment(date).format("x").toString(), infiler.filterId)}



                                          />

                                          <span className="dtCalender"><FaCalendar /></span>

                                        </div>



                                      </div>

                                    </div>

                                  )}

                                  {infiler.type === 5 && (

                                    <div>

                                      <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>

                                      <div style={{ display: "flex", justifyContent: "left" }}>

                                        <input className="titlecls"

                                          type="time"

                                          id="appt"

                                          defaultValue={infiler.time != undefined && infiler.time != ''

                                            ? moment(parseInt(infiler.time)).format("HH:mm")

                                            : curTime}

                                          onChange={(e) => this.singleselectTime(moment("1 January 2021 " + e.target.value + ":00").format("x"), infiler.filterId)}

                                        />

                                      </div>

                                    </div>

                                  )}

                                  {infiler.type === 6 && (

                                    <div>

                                      <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>

                                      <div className="flxleft time-box">

                                        <input className="txtcol"

                                          type="time"

                                          id="st"

                                          defaultValue={infiler.startTime != undefined && infiler.startTime != ''

                                            ? moment(parseInt(infiler.startTime)).format("HH:mm")

                                            : curTime}

                                          //    value={infiler.startTime}

                                          onChange={(e) => this.startTimeRange(moment("1 January 2021 " + e.target.value + ":00").format("x"), infiler.filterId)}

                                        />

                                        <input className="txtcol"

                                          type="time"

                                          id="et"

                                          placeholder={infiler.endTime}

                                          defaultValue={infiler.endTime != undefined && infiler.endTime != ''

                                            ? moment(parseInt(infiler.endTime)).format("HH:mm")

                                            : curTime}

                                          //    value={infiler.endTime}

                                          onChange={(e) => this.endTimeRange(moment("1 January 2021 " + e.target.value + ":00").format("x"), infiler.filterId)}

                                        />

                                      </div>

                                    </div>

                                  )}

                                  {infiler.type === 7 && (

                                    <div>

                                      <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>

                                      <Row style={{ padding: "0px 10px" }}>

                                        {infiler.membersMinRange}

                                        <span style={{ marginLeft: "auto" }}>

                                          {infiler.membersMaxRange}

                                        </span>

                                      </Row>

                                      <Range

                                        key={i}

                                        step={1}

                                        defaultValue={[infiler.membersMinRangeData,

                                        infiler.membersMaxRangeData

                                        ]}

                                        min={parseInt(infiler.membersMinRange)}

                                        max={parseInt(infiler.membersMaxRange)}

                                        tipFormatter={(filtervalue) => `${filtervalue}`}

                                        onAfterChange={(filtervalue) => {

                                          this.rangeSlider(filtervalue, infiler.filterId)

                                        }}

                                      />

                                    </div>

                                  )}

                                  {infiler.type === 8 && (

                                    <div>

                                      <div>

                                        <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>

                                        <Select

                                          options={infiler.singleSelect.map((option, index) => {

                                            return { label: option, value: option };

                                          })}

                                          defaultValue={infiler.singleSelectData != undefined && infiler.singleSelectData != '' ? ({ label: infiler.singleSelectData, value: infiler.singleSelectData }) : ''}

                                          onChange={(e) => this.SelectChange(e.value, infiler.filterId)}

                                          className="basic-multi-select"

                                          classNamePrefix="select"

                                        />

                                      </div>

                                    </div>

                                  )}

                                </div>

                              ))}

                            </div>

                          })

                        }

                        <div className="col-md-12" style={{ textAlign: "center" }}>

                          <button className="btnother" style={{ backgroundColor: this.CheckValidation() ? "#d0d0d0" : "#f47824", color: this.CheckValidation() ? "#7a7a7a" : "#fff" }}

                            onClick={this.UpdateFilter} disabled={this.CheckValidation()}><strong>Update Filters  </strong></button>

                        </div>

                      </div>

                    </div>

                }

              </div>



              <div className="packages packages-box">
                { this.state.primary.map((p, i) => (
                  <div className="package_card " key={i} onClick={this.BgChange}>
                    {this.state.selectedServices.indexOf(p.serviceId) == -1 ? "" : <span className="tick-right"><TiTick /></span>}
                    <div className="centerblk">
                      <h5
                        style={{ fontWeight: "bold", fontSize: "18px" }}
                        className="bold_me"
                      >
                        {p.serviceName}
                      </h5>
                      {
                        this.state.selectedServices.indexOf(p.serviceId) == -1 ?
                          <h6
                            style={{ color: "rgb(244, 120, 36)", fontSize: "11px" }}
                            className="bold_me"
                          >
                            {p.serviceCount > 0 ? <Link to={`/booking/${userEventId}/${eventTypeId}/${p.serviceId}/${p.serviceName}`}>  ADD</Link> : ''}
                          </h6>
                          :
                          <h6
                            style={{ color: "rgb(244, 120, 36)", fontSize: "11px" }}
                            className="bold_me"
                          >
                            <Link to={`/booking/${userEventId}/${eventTypeId}/${p.serviceId}/${p.serviceName}`}>  Edit</Link>
                          </h6>
                      }
                    </div>
                    <div style={{ color: "grey" }}>
                      <h6 className="bold_me" style={{ fontSize: "14px" }}>{p.serviceCount > 0 ? p.serviceCount : 'Not '} Available</h6>
                      {/*<button onClick={() => this.selectOwn(p.serviceId._id,userEventId)} className="bold_me" style={{ fontSize: "12px" }} >Own {p.serviceId.name}</button>*/}
                    </div>
                  </div>
                ))}
              </div>

              <br />

              <h5

                style={{

                  fontWeight: "bold",

                  fontSize: "18px",

                  color: "rgb(244, 120, 36)",

                }}

              >

                Add-ons

              </h5>

              <br />

              <div className="packages  packages-box">

                {this.state.addOns.map((addOn, i) => (

                  <div className="package_card" key={i}>

                    {this.state.selectedServices.indexOf(addOn.serviceId) == -1 ? "" : <span className="tick-right"><TiTick /></span>}

                    <div className="centerblk">

                      <h5

                        style={{ fontWeight: "bold", fontSize: "18px" }}

                        className="bold_me"

                      >

                        {addOn.serviceName}

                      </h5>

                      {

                        this.state.selectedServices.indexOf(addOn.serviceId) == -1 ?

                          <h6

                            style={{ color: "rgb(244, 120, 36)", fontSize: "11px" }}

                            className="bold_me"

                          >

                            {addOn.serviceCount > 0 ? <Link to={`/booking/${userEventId}/${eventTypeId}/${addOn.serviceId}/${addOn.serviceName}`}>  ADD</Link> : ''}



                          </h6>

                          :

                          <h6

                            style={{ color: "rgb(244, 120, 36)", fontSize: "11px" }}

                            className="bold_me"

                          >

                            <Link to={`/booking/${userEventId}/${eventTypeId}/${addOn.serviceId}/${addOn.serviceName}`}>  Edit</Link>

                          </h6>

                      }

                    </div>

                    <div style={{ color: "grey" }}>

                      <h6 className="bold_me" style={{ fontSize: "14px" }}>{addOn.serviceCount > 0 ? addOn.serviceCount : 'Not '} Available</h6>

                      {/*<h6 className="bold_me" style={{ fontSize: "12px" }}>Own {addOn.name}</h6>*/}

                    </div>

                  </div>

                ))}

              </div>

              <center style={{margin:'40px 0px '}}>
              <Link to={`/Checkout/${userEventId}/${eventTypeId}/${this.uid}`}  className="demoReqBtn btn btn-primary" style={{padding: '10px 100px',pointerEvents:this.state.eventCost?'':'none'}}><span>Book Now</span></Link>
              </center>
            </div>

            {this.state.Booking.length?<div className="col-md-12">
              <div className="all_packages" style={{ display: this.state.package }}>
                <h4 style={{ fontWeight: "bold" }} className="bold_me">
                  All Packages
                </h4>
                <div className="all_packages_combo">
                <OwlCarousel className="owl-theme" {...packageConfig}>
                {this.state.Booking.map((Booking , index) => {
                  return <div className="card_all_packages" key={index}>
                  <Link to={`/ServiceDetail/${Booking._id}/${userEventId}/${eventTypeId}/${Booking.serviceId}/Packages/${Booking.isSelected}`}>
                  <img style={{opacity:"50%"}} src={Booking.images!=''?Booking.images[0]:card_pic} alt={index + 1} />
                  <h5 style={{ paddingRight: "18px" }} className="rating">
                  {this.get_average_reviews(Booking._id)? <><span>{this.get_average_reviews(Booking._id)}</span><FaStar /></>:''}
                  </h5>
                  <div className="info-content">
                  <div style={{float: 'left'}}>
                    <h6 style={{ marginBottom: "1px" }}>{Booking.title}</h6>
                    <p>AED {Booking.price} / {jspriceType[Booking.priceType]}  </p>
                  </div>
                  <div style={{float: 'right'}}><a href="">{Booking.isSelected?'Booked':'Book'}</a></div>
                  </div>
                  </Link>
                </div>
                })}
                </OwlCarousel>
                </div>
              </div>
            </div>:''}
          </div>
        </Container>
        {this.state.showPopup ?
          <LoginPopup
            closePopup={this.state.redirect}
            redirectStatus={this.state.redirect}
          />
          : null
        }
      </div>
    );

  }

}

const mapStateToProps = (state) => {
  return {
    userDetails: state.userDetailsReducer.userDetails,
  }
}

export default connect(mapStateToProps, null)(withRouter(Venuedetail));

