import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "./hireProfessional.css";
import axios from "axios";
import HeaderInner from "../HeaderInner";
import SearchInput from "../SearchInput";
import {IoIosArrowBack} from 'react-icons/io'
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';
import {API_BASE_URL,VENDOR_API_BASE_URL} from '../../constant';
import moment from 'moment';
import { TiTick } from 'react-icons/ti';
import { Link } from "react-router-dom";
 
class ProfessionalServices extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      addOns: [],
      primary: [],
      professionals: [],
      filters: null,
      event_types: null,
      target_event_id: null,
      search_input: "",
      error: "",
      activeFilter: "All",
      BgColor : false,
      loader : true,
      selectedServices:[],
      savedraftcount: "",
      eventCost:"",
      location_val:"",
      edate:"",
      age:"",
      startTime:"",
      endTime:"",
      membersMaxRange:"",
      membersMinRange:"",
      appliedfilter: [],
      filterlist: {
        commonFilters: [],
        guptaresorts: [],
        Venue: [],
        SelectTime: [],
      },
    };
  }

  componentDidMount() {
    
    window.scrollTo(0, 0);
    this.uid = localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1';
    // get draft api
    axios.get(`${API_BASE_URL}getDraft?userEventId=${this.props.match.params.userEventId}&userId=${this.uid}`)
      .then(r => { console.log(r)
        const selectedService=[];
        for (let i = 0; i < r.data.data.data[0].vendorServices.length; i++) {
          selectedService.push( r.data.data.data[0].vendorServices[i].serviceId);
        }
        this.setState({selectedServices:selectedService});
        this.setState({ savedraftcount: r.data.data.count});
        this.setState({ eventCost: r.data.data.data[0]._id.packagePrice});
      })
      .catch(e => {
        console.log('save draft error', e)
      })
      // get more filter api
      axios.get(`${API_BASE_URL}event-filters?eventTypeId=${this.props.match.params.eventTypeId}&userEventId=${this.props.match.params.userEventId}`)
      .then(res => { console.log(res);
        
      this.setState({Addons : res.data.data.result.addOns})
      this.setState({primarysidebar:res.data.data.result.primary});
        this.setState({ appliedfilter: res.data.data.appliedFilters, filterlist: res.data.data.filterList })
                res.data.data.appliedFilters.map((fil)=>{
                    if(fil.type==8){
                        this.setState({location_val:fil.singleSelect})
                        
                    }
                    if(fil.type==3){
                      let mdate = parseInt(fil.date);
                     let edate = moment(new Date(mdate)).format("YYYY-MM-DD");
                      this.setState({edate:edate});
                    }
                    
                    if(fil.type==1){
                        let age = fil.singleSelect;
                        this.setState({age:age});  
                    }
                    if(fil.type==6){
                        this.setState({startTime: fil.startTime}); 
                        this.setState({endTime:fil.endTime}); 
                    }
                    
                    if(fil.type==7){
                        this.setState({membersMaxRange: fil.membersMaxRange}); 
                        this.setState({membersMinRange:fil.membersMinRange}); 
                    }
                });
        
                axios.get(`${VENDOR_API_BASE_URL}user-event/${this.props.match.params.eventTypeId}/${this.props.match.params.userEventId}/hire-professionals?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}`)
                .then(resp => { 
                  if (resp.data.results.event.event_details.primary.length > 0) {
                    this.setState({ count1: resp.data.results.event.event_details.primary.length })
                  }
                  if (resp.data.results.event.event_details.addOns.length > 0) {
                    this.setState({ count2: resp.data.results.event.event_details.addOns.length })
                  }
                  if(resp.data.results.event.event_details.addOns.length > 0){
                    this.setState({AdData : true})
                }
                else{
                    this.setState({AdData : true})
                }
                  this.setState({
                    filters: resp.data.results.appliedFilters,
                    addOns: resp.data.results.event.event_details.addOns,
                    title: resp.data.results.event.event_name,
                    primary: resp.data.results.event.event_details.primary,
                    professionals: resp.data.results.event.event_details.professionals,
                  });
                })
                .catch(e => {
                  console.log('filter error', e.response)
                })
      })
      .catch(e => {
        console.log('filter error', e.response)
      })
  }

  onchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value ,
      BgColor : true 
    });
  };
  backdropCLickHandler = () => {
    this.setState({
      popup_first: false,
      popup: false,
      title: "",
      error: "",
    });
  };

  search_change = (e) => {
    this.setState({
      search_input: e.target.value,
    }); 
  };

  
  render() {
    const userEventId = this.props.match.params.userEventId
    const eventTypeId = this.props.match.params.eventTypeId
    return (
      <div className="create_event">
        {
        this.state.loader == false ? <div className="loading-box lbox"><CircularProgress disableShrink /></div> :
        <div>
        <HeaderInner 
          handleKeyPress={this.handleKeyPress}
          onChange={this.search_change}
        />
        <br />
        <br />
        <Container>
          <p className="pcontainer">
          <a className="remove_anchor bold_me anchor_back" href="/"><IoIosArrowBack />BACK</a>
          </p>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <h4 style={{ marginBottom: "1px" }} className="bold_me">
              Select Service Type to Hire a Professional
              </h4>
              <br/>
              <br/>
              {/* block*/}
          <div className="pack pack-box">
          {this.state.professionals.map((p, i) => (
          <div className="package_card " key={i} onClick={this.BgChange}>
          {this.state.selectedServices.indexOf(p.serviceId) == -1 ? "" : <span className="tick-right"><TiTick /></span>}
          <div className="centerblk">
            <h5
              style={{ fontWeight: "bold", fontSize: "18px" }}
              className="bold_me"
            >
              {p.serviceName}
            </h5>
            {
              this.state.selectedServices.indexOf(p.serviceId) == -1 ?
                <h6
                  style={{ color: "rgb(244, 120, 36)", fontSize: "11px" }}
                  className="bold_me"
                >
                { p.serviceCount>0 ? <Link to={`/professionalbooking/${userEventId}/${eventTypeId}/${p.serviceId}/${p.serviceName}`}>  ADD</Link>:''}
                
            </h6>
                :
                <h6
                  style={{ color: "rgb(244, 120, 36)", fontSize: "11px" }}
                  className="bold_me"
                >
                 <Link to={`/professionalbooking/${userEventId}/${eventTypeId}/${p.serviceId}/${p.serviceName}`}>  Edit</Link>
            </h6>
            }

          </div>
          <div style={{ color: "grey" }}>
            <h6 className="bold_me" style={{ fontSize: "14px" }}>{p.serviceCount} Available</h6>
            {/*<button onClick={() => this.selectOwn(p.serviceId._id,userEventId)} className="bold_me" style={{ fontSize: "12px" }} >Own {p.serviceId.name}</button>*/}
          </div>
        </div>
           ))}
          </div>
          {/* block */}
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="box-shadow1 p-4 mb-4">
            <h2 className="page-title my-3 d-inline-block" style={{textTransform: "capitalize"}}>{this.state.title}</h2>
            {this.state.professionals.map((p, i) => (
            <div key={i} className="border border-warning mb-3 position-relative add-on-box d-flex align-items-center justify-content-between">
            <div class="add-on">
              <h4 class="heading mb-0">{p.serviceName}</h4>
              <p class="mb-0">DJ Chetah</p>
            </div>
                <a class="colored edit" href="#">edit</a>
                <a class="colored edit" style={{cursor: "pointer"}}>remove</a>
                    <div class="position-absolute icon-done">
                        <img src="" alt="" />    
                    </div>
            </div>
              ))}
            <br/>
            <div class="row">
            <div style={{textAlign : "center"}}>
                { this.state.eventCost ?<Link to={`/Checkout/${userEventId}/${eventTypeId}/${this.uid}`} className="demoReqBtn btn btn-primary" style={{marginLeft:'84px'}}><span>Book Now</span></Link> :''}
                </div>
                <br/>    
            </div>
                    
        </div>
        </div>
          </div>
          
        <br /> 
        <br /> 
        <br /> 
        </Container>
        </div>
          }
      </div>
    );
  }
}
export default ProfessionalServices;