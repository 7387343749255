import React, { Component } from "react";
import Homeext from "./Homeext";
import { BrowserRouter, Route, Link,Switch } from "react-router-dom";
import PrivateRoute from "./context/PrivateRoute";
import Createevent from "./Createevent";
import Venuedetail from "./Venuedetail";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import Faq from "./Faq";
import Errorpage from "./Design/Errorpage";
import ContactUs from "./ContactUs";
import CookiePolicy from "./CookiePolicy";
import Booking from './components/booking';
import ProfessionalBooking from './components/hireProfessional/ProfessionalBooking';
import Favourite from './components/favourite'
import ServiceDetail from './components/ServiceDetail'
// import ServiceDetail_new from './components/ServiceDetail_updated'
import SaveDraft from './components/savedraft'
import UserDashboard from './components/userPanel/UserDashboard';
import UserEditProfile from './components/userPanel/UserEditProfile';
import ChangePassword from './components/userPanel/ChangePassword';
import ReferenceSignup from './components/userPanel/ReferenceSignup';
import MyEvents from './components/userPanel/MyEvents';
import Notifications from './components/userPanel/Notifications';
import InviteFriend from './components/userPanel/InviteFriend';
import SendInvitation from './components/userPanel/SendInvitation';
import ProfessionalListing from './components/hireProfessional/ProfessionalListing';
import ProfessionalDetails from './components/hireProfessional/ProfessionalDetails';
import ProfessionalCheckout from './components/hireProfessional/ProfessionalCheckout';
import ProfessionalPayment from './components/hireProfessional/ProfessionalPayment';
import ProfessionalServices from './components/hireProfessional/ProfessionalServices';
import Createproevent from "./components/hireProfessional/Createproevent";
import Mailer from "./Mailer";
import Invoice from "./Invoice";
import BookedServicesDetails from "./Booked_services_details";
import InvitationCard from "./InvitationCard";
import Payment from './Payment';
import payment_auth from './components/payment_auth';
import Paymentnew from './payment_new';
import Thankyou from './Thankyou';
import FailPayment from './FailPayment';
import Login from './components/login';
import Signup from './components/signup';
import Checkout from './Checkout';
import axios from "axios";
import forgetPassword from './components/forgetPassword';
import {API_BASE_URL,ONESIGNALAPP} from './constant';
import {connect} from 'react-redux';
import ReviewRating from './components/userPanel/ReviewRating';
import Footer from './Footer';
import HomeVersion2 from "./Design/Home_v2";
import Aboutus from "./Design/About";
import Partner from "./Design/partner";
import Blog from "./Design/Blog";
import DIYEvent from "./Design/DIYEvent";
import UltimateEvent from "./Design/UltimateEvent";

class App extends Component {
  constructor() {
    super();
  }

  componentDidMount(){
    this.checkLoggedIn();
  }

  checkLoggedIn = async () => { 
    let token = localStorage.getItem("user-auth-token");
    if (token === null || token ==='') {
      localStorage.setItem("user-auth-token", "");
      token = "";
      this.props.userData(2);
    }
    else{
      const headers = {
        'Content-Type': 'application/json',
        'authorization': token
    };
    const data = {
      'token':token
    }
    axios
        .post(`${API_BASE_URL}UserProfile`, data,{ headers })
        .then(response => { 
          this.props.userData(response.data.data[0]);
          localStorage.setItem("user-auth-id", response.data.data[0]._id);
          localStorage.setItem("user-data", JSON.stringify(response.data.data[0]));
        })
        .catch(error => {
            console.log('error', error);
        });
    }
  };

  render() {

    return (

      <div className="app"> 

        <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomeVersion2} />
          <Route exact path="/eventstan" component={HomeVersion2} />
          <Route exact path="/livbank" component={HomeVersion2} />
          <Route exact path="/emiratesplatinum" component={HomeVersion2} />
          <Route exact path="/about-us" component={Aboutus}/>

          <Route exact path="/partner" component={Partner}/>

          <Route exact path="/blog" component={Blog}/>

          <Route exact path="/DIYEvent" component={DIYEvent}/>

          <Route exact path="/UltimateEvent" component={UltimateEvent}/>



          <Route exact path="/ContactUs" component={ContactUs} />

          <Route exact path="/professional" component={Homeext} />

          <Route exact path="/create_event" component={Createevent} />

          <PrivateRoute exact path="/favourite" component={Favourite} />

          <PrivateRoute exact path="/savedraft" component={SaveDraft} />

          <Route exact path="/booking/:userEventId/:eventTypeId/:serviceId/:name" component={Booking} />

          <PrivateRoute exact path="/ServiceDetail/:id/:userEventId/:eventTypeId/:serviceId/:name/:isSelected" component={ServiceDetail} />

          {/* <PrivateRoute exact path="/ServiceDetail_new/:id/:userEventId/:eventTypeId/:serviceId/:name/:isSelected" component={ServiceDetail_new} /> */}

          <Route path="/venuedetail/:eventTypeId/:userEventId" component={Venuedetail} />

          <PrivateRoute exact path="/userdashboard" component={UserDashboard} />

          <PrivateRoute exact path="/usereditprofile" component={UserEditProfile} />

          <PrivateRoute exact path="/changepassword" component={ChangePassword} />

          <Route exact path="/reference_signup/:uid" component={ReferenceSignup} />

          <Route exact path="/privacy_policy" component={PrivacyPolicy} />

          <Route exact path="/cookiePolicy" component={CookiePolicy} />

          <Route exact path="/termsConditions" component={TermsConditions} />

          <Route exact path="/faq" component={Faq} />

          <PrivateRoute exact path="/myevents" component={MyEvents} />

          <PrivateRoute exact path="/notifications" component={Notifications} />

          <PrivateRoute exact path="/invitefriend" component={InviteFriend} />

          <PrivateRoute exact path="/sendinvitation/:userEventId" component={SendInvitation} />

          <PrivateRoute exact path="/Checkout/:userEventId/:eventTypeId/:userId" component={Checkout} />

          <PrivateRoute path="/Payment" component={Payment} />

          <PrivateRoute exact path="/Paymentnew/:userEventId/:userId/:eventBookingId" component={Paymentnew} />

          <PrivateRoute path="/thankyou" component={Thankyou} />

          <PrivateRoute path="/paymentFail" component={FailPayment} />

          <PrivateRoute path="/payment_auth" component={payment_auth} />

          <Route path="/Mailer" component={Mailer} />

          <Route path="/InvitationCard/:userEventId" component={InvitationCard} />

          <PrivateRoute path="/invoice/:userEventId" component={Invoice} />

          <PrivateRoute exact path="/ratereview/:userEventId" component={ReviewRating} />

          <Route path="/login" component={Login} />

          <Route path="/signup" component={Signup} />

          <Route path="/booking-details/:userEventId" component={BookedServicesDetails} />

          <Route path="/forget" component={forgetPassword} />

          <Route exact path="/create_pro_event" component={Createproevent} />

          <Route exact path="/professionallisting/:eventTypeId/:userEventId" component={ProfessionalListing} />

          <Route exact path="/professionaldetails/:id/:userEventId/:eventTypeId/:serviceId/:name/:isSelected" component={ProfessionalDetails} />

          <Route exact path="/professionalcheckout/:userEventId/:eventTypeId/:userId" component={ProfessionalCheckout} />

          <Route exact path="/professionalpayment" component={ProfessionalPayment} />

          <Route exact path="/professionalbooking/:userEventId/:eventTypeId/:serviceId/:name" component={ProfessionalBooking} />

          <Route exact path="/professionalservices/:eventTypeId/:userEventId" component={ProfessionalServices} />
          <Route path='*' component={Errorpage}  />
          </Switch>
          { window.location.pathname != "/booking-details/:userEventId" &&  <Footer /> } 
         
        </BrowserRouter>
      </div>

    );

  }

}



const mapDispatchToProps = (dispatch)=>{

   return { 

     userData  : (payload) =>{ dispatch({type:"USER_DATA" , payload})},

   }

}



const mapStateToProps = (state)=>{

  return{

      userDetails : state.userDetailsReducer.userDetails,

  }

}



export default connect(mapStateToProps,mapDispatchToProps)(App);

