import React, { Component } from "react";
import './App.css';
import HeaderInner from "./components/HeaderInner";
import {IoIosArrowBack} from 'react-icons/io';



class PrivacyPolicy extends Component {
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return (
           <>
         <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
            <div className="container">
			<div className="row">
               <div className="col-md-12 col-sm-12 col-xs-12">
                  {/* <div className="back-btn">
					      <br />
                     <a className="text-dark"href="#"><IoIosArrowBack/> Back</a>
                  </div> */}
               </div>
            </div>
            <section className="application_sec">	
                <div className="row">
                    {/* <div className="col-md-3 col-sm-3 col-xs-3">
                    <LeftMenu />
                  </div> */}
               <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="form_card form_blk">  
                     
                     <div class="row">
                        <div class="col-md-12">
                           <div className="form-group">
                              <h5>Privacy Policy</h5>
							  <hr/>
                           </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'justify'}}>
						<p>Our management has created this Privacy Statement (Policy) in  order to demonstrate our firm commitment to help our users better understand  what information we collect about them and what may happen to that information.<br />
  The terms &quot;We, Eventstan, Us&quot; refer to Eventstan.com and  the terms &quot;You, Your&quot; refer to a user of Eventstan.com.<br />
  In the course of our business of helping our viewers plan their  event, we collect certain information from you.<br />
  While registering for and availing various services we provide  from time to time through our website: In Eventstan, you may be required to  give your Name, address, Email address, phone number.<br />
  The Personal Information is used for three general purposes: to  customize the content you see, to fulfill your requests for certain services,  and to contact you about our services. Unless otherwise stated explicitly, this  Policy applies to Personal Information as disclosed on any of the Media.<br />
  Dear Users, the Data we collect, and use is upon your consent and  none of our activities amount to breach of compliance. We value your security  and privacy and are doing our best to ensure security of the data you provide  to us in any manner whatsoever. We acknowledge your consent for providing such  data to us for smooth execution of our services and note your acceptance to  providing us with all information so collected, including Name, Gender, Email,  Contact Number, Age, Location, Device Details, Browsing Information, SMS, App  Install and Usage, Preferences, Intent etc.</p>
<p><strong>Security</strong><br />
  Personal Information will be kept confidential, and we do not  disclose the information except that in case you have specifically made an  enquiry. Further, the vendors / advertisers who are listed with us, may call  you, based on the query or enquiry that you make with us, enquiring about any  Product / Service they might offer<br />
  We will share Personal Information only under one or more of the  following circumstances: - If we have your consent or deemed consent to do so -  If we are compelled by law (including court orders) to do so.<br />
  In furtherance of the confidentiality with which we treat Personal  Information we have put in place appropriate physical, electronic, and  managerial procedures to safeguard and secure the information we collect  online.<br />
  We give you the ability to edit your account information and  preferences at any time, including whether you want us to contact you regarding  any services. To protect your privacy and security, we will also take  reasonable steps to verify your identity before granting access or making  corrections.<br />
  We treat data as an asset that must be protected against loss and  unauthorized access. We employ many different security techniques to protect  such data from unauthorized access by members inside and outside the company.  However, &quot;perfect security&quot; does not exist on the Internet, or  anywhere else in the world! You therefore agree that any security breaches  beyond the control of our standard security procedures are at your sole risk  and discretion.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Links to other Websites</strong><br />
  We have affiliate links to many other online resources. We are not  responsible for the practices employed by these affiliates, or their websites  linked to or from Eventstan.com nor the information or content contained on  these third-party websites. You should carefully review their privacy  statements and other conditions of use and you agree you provide information or  engage in transactions with these affiliates at your own risk.</p>
<p><strong>Control Of Your Password</strong><br />
  You are responsible for all actions taken with your login  information and password, including fees. Therefore, we do not recommend that  you disclose your account password or login information to any third parties.  If you lose control of your password, you may lose substantial control over  your personally identifiable information and may be subject to legally binding  actions taken on your behalf. Therefore, if your password has been compromised  for any reason, you should immediately change your password.</p>
<p><strong>Content On the Site</strong><br />
  Eventstan.com features some of the latest trends in UAE events  around the world and tries to give its users exposure to quality hand-picked  content. We also feature events and articles where users have given us  permission to use the same.<br />
  However, in the unlikely event of anyone having any objection to  content put up on our site, they are free to contact us immediately and we will  be happy to consider their request and take necessary action.</p>
<p><strong>Updates on Privacy Policy</strong><br />
  We reserve the right to revise these Privacy Policies of  Eventstan.com from time to time by updating this posting. Such revised policies  will take effect as of the date of posting</p>
<p><strong>Contact Us</strong><br />
  If you have any further queries regarding the privacy policy, feel  free to contact us at info@Eventstan.com<br />
  Eventstan - Your Personal event Planner</p>
                        </div>
                        </div>
                  
					</div>
				</div>
			</div>
      </section>
	</div> 
   </>);
    }
}

export default PrivacyPolicy;