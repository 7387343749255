import React from 'react';
import HeaderInner from "./components/HeaderInner";
import {BASE_URL} from './constant';
import "./App.css";
import EventImg from "./assets/event.png"; 

const  Thankyou = ()=>{
    return (
        <>
        <HeaderInner />
        <div className="container">
            <div className="text-center my-5 py-5 event-book">
                <img src={EventImg} alt="" />
                <h2 className="py-2 m-0">Event Created!</h2>
                <p className="pb-4 mb-3">Thank you for choosing EventStan. Well done, your event planning is now complete! EventStan will now take over your event and shortly provide you with confirmation from your service providers. If you would like to view the status of your event or make any changes, please visit My Events.<br/>For any further assistance, drop us an email on <a href="mailto:hello@eventstan.com">hello@eventstan.com</a></p>
                <a href={BASE_URL} className="btn btn-outline-success mr-3">Back to Home Page</a>
                <a href={`${BASE_URL}myevents`}  className="btn btn-outline-success">My Events</a>
            </div>
        </div>
        </>
    )}
export default Thankyou;