import React, { Component } from 'react';
class PopUp_contact extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <>
            <div className="popupy">
                <h5 className="bold_me" style={{ fontSize: '18px' }}>Thank you! <br /> Our representative will get back to you within 2 working days.</h5>
                <br />
                <div style={{ textAlign: 'center' }}><buttom onClick={() => window.location.reload(false)} className="btn-primary">OK</buttom></div>
            </div>
            </>
        )
    }
}
export default PopUp_contact