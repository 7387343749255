import { useState } from 'react';
import './style.css';
import './style2.css';
import NavBar from "./NavBar";
import {Link} from 'react-router-dom';
function Errorpage() {

  return (
    <>
     
<NavBar />
    <div className="header" style={{paddingLeft:'20px'}}>
        <div className="container"style={{paddingLeft:'0'}}>
            <div className='header-left-element'>
            &#8220;We Facilitate, You Create&#8221;
            </div>
            <div className="centered-element">
            <img src={'./v2Images/Logo.png'} />
            </div>
            {/* <div className="header-right-element">
                Login/Register
            </div> */}
        </div>
    </div>
        <div className='about'>
            <div className="about-inner">
                <div className='container'>
                    <div className='about-text' style={{ textAlign:'center'}}>
                    <img src={'./v2Images/404.jpg'} className='errimg'/> 
                      <div  className="errBlk"> 
                        
                        <span className="orghead">Page Not Found</span>
                    <p className="errText">Sorry, the page you are looking for doesn't exist. Please make sure <br/>that you typed the correct url page link, and try again.
                        </p>  <button className='errbtn'><Link to="/eventstan">Go To Home</Link></button> </div>    
                    </div>
                    </div>
                </div>
            </div> 
       
    </>
  );
}
export default Errorpage;
