import React from "react";
import { Row} from "react-bootstrap";
import axios from "axios";
import "./Popup.css";
import "./booking.css";
import { Rating } from 'react-simple-star-rating';
import {API_BASE_URL} from '../constant'

class ReviewPopup extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            services:[],
            rating:'20',
            reviews:'',
            message:''
        }
    }

    // Catch Rating value
    handleRating(rate) { 
      this.setState({rating:rate})
    }

    changeHandler = (e)=>{
        this.setState({reviews:e.target.value});
    }
    //submit review
    handleSubmit = (vendorServiceId)=>{
        let rate = this.state.rating/20;
        let review = this.state.reviews;
        let userId = localStorage.getItem("user-auth-id")
        if( rate=== undefined){
         alert('Please select rating')
        }
        else{
         let data = {
             "reviewText": review,
             "rating": rate,
             "vendorServiceId": vendorServiceId,
             "userId": userId
         }
         axios.post(`${API_BASE_URL}RateReview`,data)
         .then((res)=>{
             this.setState({message:'Thankyou for review the service'})
             setTimeout(()=>{
                window.location.reload();
             },2000)
         })
         .catch(err=>{
             alert('You have already review this service')
         })
        }
    }

    

    componentDidMount(){
        const vendorServiceId = this.props.vendorServiceId;
        let userId = localStorage.getItem("user-auth-id")
        axios.get(`${API_BASE_URL}viewService?vendorServiceId=${vendorServiceId}`)
        .then((res)=>{
            //console.log(res.data.data.vendorService);
            this.setState({services:res.data.data.vendorService});
        })
        .catch(err=>{
            console.log(err);
        })

        //get reviewed data
        axios.get(`${API_BASE_URL}getServiceReview?vendorServiceId=${vendorServiceId}&userId=${userId}`)
        .then((res)=>{
            this.setState({rating:res.data.data.result[0].rating*20,reviews:res.data.data.result[0].reviewText})
        })
        .catch(err=>{
            console.log(err);
        })

    }

    render(){
        // To get current time
        return(
            <div className="">
                <div className="row inside-content1">
                <h2 className="col-md-12" style={{position : "relative" , textAlign : "center" , fontWeight : "600" , color : "#000" , paddingBottom : "15px" , marginBottom : "30px" , textTransform : "capitalize"}}>Share your review</h2>
                    <div className="row">
                        <div className="col-md-5 col-sm-5 col-xs-12">
                                <div className="cov-img-pop">
                                <img src={this.state.services.coverImage} 
                                style={{width : "100%" , height : "100%" , borderRadius : "6px"}}  />  
                                </div>
                                
                            
                        </div>
                        <div className="col-md-7 col-sm-7 col-xs-12">
                                <div className="ext-serv" style={{ }}>
                                <h5 style={{color : "#000" , textTransform : "capitalize"}}>{this.state.services.title}</h5>{this.state.services.venueAddress}<br/>
                                    <span>{this.state.services.serviceName}</span>
                                </div>
                            
                        </div>
                        <hr/>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div style={{width: '100%', marginTop:'20px'}}>
                                <span style={{color : "#000" , textTransform : "capitalize"}}>Please rate service 1 to 5 </span>
                            </div>
                            <div className='App' style={{width: '100%'}}>
                            <Rating onClick={(e)=>this.handleRating(e)}
                                ratingValue={this.state.rating}
                                size={50}
                                transition
                                allowHalfIcon
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div style={{width: '100%',marginTop:'25px'}}>
                                <span style={{color : "#000" , textTransform : "capitalize"}}>Write a short review on this service.</span>
                            </div>
                            <div className="form-group" style={{width: '100%'}}>
                                <textarea onChange={this.changeHandler} value={this.state.reviews} className="form-control" name="review" rows="3" maxLength="250" placeholder="Enter your Review (max 250 characters)"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{textAlign :"center"}}>
                    <span style={{color:"green"}}>{this.state.message}</span><br />
                    <button  onClick={()=>{this.handleSubmit(this.state.services._id)}} style={{backgroundColor  :  "#f47824" , color  :  "#fff" , fontSize : "20px",  fontWeight : "bold" , textTransform : "capitalize" , borderRadius : "8px" , padding : "10px 50px" , margin : "5px 0px" }} ><strong>Submit</strong></button>
                    </div>
                </div>
            </div>
        )
    }
}



export default ReviewPopup;
