import React, { useState,useEffect } from 'react';
import LogoPic from './assets/eventstan.png';
import envelop from './assets/envelope.png';

const MailHeader = () => {
    return (
        <table border="0" width="800" align="center" cellpadding="0" cellspacing="0" class="container-middle nav-head">
        <tbody><tr><td height="15"></td></tr>
        <tr><td>
        <table border="0" align="center" cellpadding="0" cellspacing="0" style={{"borderCollapse": 'collapse', "msoTableLspace": '0pt', "msoTableRspace": '0pt'}} class="logo">
                    <tbody>	
                        <tr>
                            <td align="right">
                                <a href="#" class="logo-text" style={{"textDecoration": 'none'}}><img src={LogoPic} alt=" " /></a>
                            </td>
                        </tr>
                    </tbody>
                </table>	
                </td>
        </tr>
        <tr>
            <td height="15"></td>
        </tr>
        <tr bgcolor="#8489b2">
            <td>
            <table border="0" width="650" align="center" cellpadding="0" cellspacing="0" class="container-middle nav-head">
								<tbody><tr>
									<td height="15"></td>
								</tr>
								<tr>
								<td>
                                <table width="100%" border="0" cellpadding="0" cellspacing="0" style={{"borderCollapse": 'collapse', "msoTableLspace": '0pt', "msoTableRspace": '0pt'}}>
											<tbody>
												<tr>
													<td>
													<table class="mail_left" align="left" border="0" cellpadding="0" cellspacing="0" width="500">
															<tbody>
																<tr> 
																	<td class="mail_gd" align="center" style={{"textAlign": 'left', "fontSize": '1.2em', "fontFamily": 'Candara', "color": '#FFFFFF'}}>
																		<a href="mailto:info@eventstan.com" style={{"color": '#fff', "textDecoration": 'none'}}><img src={envelop} alt="" border="0" height="18" width="18" />&nbsp; &nbsp;info@eventstan.com</a>
																	</td>
																</tr>
															</tbody>
														</table>
														<table class="mail_right" align="right" border="0" cellpadding="0" cellspacing="0" width="150">
															<tbody>
																<tr>			
																	<td align="center" style={{"fontSize": '14px',"color": '#fff',"fontFamily": 'Arial,serif' }}><img src="img/phone.png" alt="" border="0" height="16" width="16" />&nbsp; &nbsp;+91-9871577057</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>	

                                </td>
								</tr>
								<tr>
									<td height="15"></td>
								</tr>
							</tbody></table>   



            </td>
        </tr>
    </tbody></table>

        );
}
export default MailHeader;