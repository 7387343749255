import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoginPopup from "../loginPopup";
//import wlogo from './../../assets/EventStanWhite.png';
import wlogo from './../../assets/evenstan_icon.png';

const HeaderUser = ({ onChange, handleKeyPress }) => {
  let [fav, FavouriteBoxx] = useState(false);
  let [showName, setName] = useState('Menu');
  let [showLogin, setLoginPopup] = useState(false);
  const [userData, setUserData] = useState({
    token: '',
  });
  const checkLoggedIn = async () => {
    let token = localStorage.getItem("user-auth-token");
    if (token === null) {
      localStorage.setItem("user-auth-token", "");
      token = "";
    }
    setUserData({
      token,
    });
    if (localStorage.getItem("user-data") !== null) {
      var userDetails = JSON.parse(localStorage.getItem("user-data"));
      setName(userDetails.firstName.split(" ")[0] === '' ? 'User' : userDetails.firstName.split(" ")[0]);
    }
  };
  useEffect(() => {
    checkLoggedIn();
  }, []);
  let FavouriteBox = () => {
    FavouriteBoxx(true);
  }
  let filterPopDown1 = () => {
    FavouriteBoxx(false);
  }
  let login_click = () => {
    setLoginPopup(!showLogin);
  }
  const logout = () => {
    localStorage.removeItem('user-auth-token');
    localStorage.removeItem('user-auth-id');
    localStorage.removeItem('event_id');
    localStorage.removeItem('user-data');
    window.location.replace("/eventstan");
  }
  return (
    <div className="header_createevent">
      <Row style={{ margin: "0px", padding: "0px" }}>
        <Col xs={0} md={1} />
        <Col xs={12} md={2}>
          <a href="/eventstan"><img src={wlogo} alt="EventStan Logo"/></a>
        </Col>
        <Col xs={0} md={2} />
        <Col xs={12} md={7}>
          <div
            className="create_event_mob1"
            style={{ display: "flex", justifyContent: "center" }}
          >

            <div style={{ display: 'flex' }} className="menu-icon" onClick={() => FavouriteBox()}>
              <span className="navbar-toggler-icon navbar-toggler-icon1" style={{ position: "relative" }}></span>
              <span className="navbar-toggler-icon navbar-toggler-icon2" style={{ position: "relative" }}></span>
              <span className="navbar-toggler-icon navbar-toggler-icon3" style={{ position: "relative" }}></span>
              <span class="ml-0 ml-sm-3 menu" style={{ marginTop: "10px", marginLeft: "0px" }}>{showName}</span>
            </div>

          </div>
        </Col>
      </Row>
      {
        fav === false ? "" :


          <div className="filter-content filter-content2" >
            <div className="inside-content2">
              <p onClick={() => filterPopDown1()} className="can-el-btn">X</p>
              <ul className="" onClick={() => filterPopDown1()}>
                {userData.token == '' ?
                  <>
                    <li><a href="https://eventstan.com/about-us">About us</a></li>
                    <li><a href="https://eventstan.com/#how-does-it-work">How it works</a></li>
                    <li><a href="https://eventstan.com/professional">Partner with us</a></li>
                    <li><a href="https://eventstan.com/#download-app">Download the App</a></li>
                    <li><a href="/ContactUs">Contact us</a></li>
                  </>
                  : ''}
                {userData.token != '' ?
                  <>
                    <li><Link to="/userdashboard">Dashboard</Link></li>
                    <li><Link to="/myevents">My Events</Link></li>
                    <li><Link to="/usereditprofile">Edit Profile</Link></li>
                    <li><Link to="/changepassword">Change Password</Link></li>
                    <li><Link to="/notifications">Notifications</Link></li>
                    <li><Link to="/invitefriend">Invite</Link></li>
                    <li><Link to="/termsConditions">Term & Conditions</Link></li>
                    <li><Link to="/favourite">favourite</Link></li>
                    <li><Link to="/ContactUs">Contact us</Link></li>
                  </>
                  : ''}

                <li>
                  {userData.token != '' ?
                    <Link to="/userdashboard" >Profile</Link>
                    : <button onClick={() => login_click()} >Login</button>}
                </li>
                <li>
                  {userData.token != '' ?
                    <Link to="#" className="icon-logout" onClick={logout}>Logout</Link>
                    : ''}
                </li>
              </ul>
            </div>
          </div>
      }
      {showLogin ? <LoginPopup closePopup={login_click} /> : null}
    </div>
  );
}
export default HeaderUser;