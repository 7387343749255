import React, { useState,useEffect } from 'react';
import './AppStyle.css';
import './left-menu.css';
import HeaderInner from "./HeaderUser";
import {IoIosArrowBack, IoIosAdd} from 'react-icons/io';
import { API_BASE_URL } from "../../constant";
import { Rating } from 'react-simple-star-rating';
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
export default function ReviewRating() {
    const [rating, setRating] = useState([]) 
    const [eventstanRating, setEventstanRating] = useState(100) 
    const [reviews, setReview] = useState([]) 
    const [eventstanReviews, setEventstanReview] = useState([]) 
    const params = useParams();
    const [services, setServices] = useState([]);
   
    // Catch Rating value
    function handleRating(rate,a) { 
        rating[a]=rate;
    }

    function handleeventstanRating(rate){ 
        setEventstanRating(rate)
    }

    const changeHandler = (e,index)=>{
        reviews[index]=e.target.value;
    }

    const changeEventstanReviewHandler = (e)=>{
        setEventstanReview(e.target.value)
    }

    const handleSubmit = (index,vendorServiceId)=>{
       let rate = rating[index]/20;
       let review = reviews[index];
       let userId = localStorage.getItem("user-auth-id")
       if( rating[index]=== undefined){
        confirmAlert({
            title: '',
            message: 'Please select rating',
            buttons: [
            {
                label: 'Ok', 
            },
            ]
        });
       }
       else{
        let data = {
            "reviewText": review,
            "rating": rate,
            "vendorServiceId": vendorServiceId,
            "eventId": params.userEventId,
            "userId": userId
        }
        axios.post(`${API_BASE_URL}RateReview`,data)
        .then((res)=>{
            confirmAlert({
                title: '',
                message: 'Thankyou for the review',
                buttons: [
                {
                    label: 'Ok', 
                },
                ]
            });
        })
        .catch(err=>{
            alert('You have already review this service')
        })
       }
    }

    const eventstanReviewSubmit = ()=>{
        let rate = eventstanRating/20;
       let review = eventstanReviews;
       let userId = localStorage.getItem("user-auth-id")
       if( rate=== undefined){
        confirmAlert({
            title: '',
            message: 'Please select rating',
            buttons: [
            {
                label: 'Ok', 
            },
            ]
        });
       }
       else{
        let data = {
            "reviewText": review,
            "rating": rate,
            "eventId": params.userEventId,
            "userId": userId
        }
        axios.post(`${API_BASE_URL}addTestimonial`,data)
        .then((res)=>{
            confirmAlert({
                title: '',
                message: 'Thankyou for the review',
                buttons: [
                {
                    label: 'Ok', 
                },
                ]
            });
        })
        .catch(err=>{
            console.log(err)
            alert('You have already review this service')
        })
       }
    }

    useEffect(()=>{
     let userId = localStorage.getItem("user-auth-id")
     let userEventId = params.userEventId;
     axios.get(`${API_BASE_URL}getUserEvents?userId=${userId}&userEventId=${userEventId}&status=3`)
     .then((r)=>{
        setServices(r.data.data.data[0].vendorServices);
     })
     .catch(err=>{
        console.log(err)
     })
    },[])
return (
<>
<HeaderInner />
<div className="container">
    <section className="application_sec">	          
        <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form_card form_blk">  
            <h3>Rate & Review</h3><hr/>
            { services.map((service,index)=>{
                return <> <div className="row" key={index}>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="inf-box"> 
                            <div className="cov-img">
                            <img src={service.coverImage} 
                             style={{width : "100%" , height : "100%" , borderRadius : "6px"}}  />  
                            </div>
                            <div className="ext-serv" style={{width:'50%'}}>
                            <h5 style={{color : "#000" , textTransform : "capitalize"}}>{service.title}</h5>{service.venueAddress}<br/>
                                <span>{service.type}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div style={{width: '100%', marginTop:'5px'}}>
                            <h5 style={{color : "#000" , textTransform : "capitalize"}}>Please rate service 1(Bad) to 5(Excellent)</h5>
                        </div>
                        <div className='App' style={{width: '100%'}}>
                        <Rating onClick={(e)=>handleRating(e,index)}
                            ratingValue={rating[index]}
                            size={50}
                            transition
                            allowHalfIcon
                            //showTooltip
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div style={{width: '100%',marginTop:'5px'}}>
                            <h5 style={{color : "#000" , textTransform : "capitalize"}}>Write a short review on this service.</h5>
                        </div>
                        <div className="form-group" style={{width: '100%'}}>
                            <textarea onChange={(e)=>changeHandler(e,index)} value={reviews[index]} className="form-control" name="review" rows="2" maxLength="250" placeholder="Enter your Review (max 250 characters)"></textarea>
                            <button onClick={()=>{handleSubmit(index,service._id)}} type="button" className="btn btn-primary pull-right mb-3 ">Submit</button>
                        </div>
                    </div>
                </div>
                <hr/>
                </>
                })}
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="inf-box"> 
                            <div className="cov-img">
                            <img src="https://eventstan.com/v2Images/evenstan_icon_hd.png" 
                             style={{width : "100%" , height : "100%" , borderRadius : "6px"}}  />  
                            </div>
                            <div className="ext-serv" style={{width:'50%'}}>
                            <h5 style={{color : "#000" , textTransform : "capitalize"}}>EventStan</h5>Dubai<br/>
                                {/* <span>Eventstan</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div style={{width: '100%', marginTop:'5px'}}>
                            <h5 style={{color : "#000" , textTransform : "capitalize"}}>Please rate eventStan service 1(Bad) to 5(Excellent)</h5>
                        </div>
                        <div className='App' style={{width: '100%'}}>
                        <Rating onClick={(e)=>handleeventstanRating(e)}
                            ratingValue={eventstanRating}
                            size={50}
                            transition
                            allowHalfIcon
                            //showTooltip
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div style={{width: '100%',marginTop:'5px'}}>
                            <h5 style={{color : "#000" , textTransform : "capitalize"}}>Write a short review on this service.</h5>
                        </div>
                        <div className="form-group" style={{width: '100%'}}>
                            <textarea onChange={(e)=>changeEventstanReviewHandler(e)} value={eventstanReviews} className="form-control" name="review" rows="2" maxLength="250" placeholder="Enter your Review (max 250 characters)"></textarea>
                            <button onClick={()=>{eventstanReviewSubmit()}} type="button" className="btn btn-primary pull-right mb-3 ">Submit</button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>          
    </section>
</div>
</>);
}
