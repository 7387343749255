import React from 'react';
import { Row, Col, Container, Button, Toast } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import HeaderInner from "./HeaderInner";
import SearchInput from "./SearchInput";
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import moment from 'moment';
import ReviewPopup from './reviewPopup';
import Slide from "rc-slider";
import {IoIosArrowBack} from 'react-icons/io';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlineHeart , AiOutlineStar , AiOutlineShareAlt , AiFillSave, AiFillCopy } from 'react-icons/ai';
import {FiPhoneCall} from 'react-icons/fi'
import {MdLocationOn} from 'react-icons/md'
import { FaUserFriends } from "react-icons/fa";
import axios from "axios";
import {API_BASE_URL} from '../constant'
import $ from 'jquery';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import defaultUser from "./../assets/create_event/defaultUser.png";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {connect} from 'react-redux';
import playBtn from '../assets/landing page pngs/play video@2x.png';
import jspriceType from "./../context/priceType";


class ServiceDetail extends React.Component{
    constructor(props){
        super(props);
        this.state = {
                serviceDetail : {
                about : [],
                images : [],
                videoLinks: [],
                features : [],
                feature : [],
                location : "",
                serviceName : "",
                title :"",
                type : "",
                guests : 0,
                price: 0,
                minGuestRange: 0,
                linkedServices : [
                    {
                        price : 0,
                        guests : 0
                    }
                ],               
            },
            linkedServices : [],
            active : false,
            services : [],
            Bookmarklist : [],
            show:false,
            photoIndex: 0,
            isOpen: false,
            lisOpen: false,
            lphotoIndex: 0, 
            reviews:[],
            avgRating:'',
            popup: false,
            imagesIndex: null,
            sliderDe : true,
            note : '',
            videopopup: false,
            videoLink:'',
            vendorId:'',
            eventDate:'',
        }
        const uid = '';
    }

    AddReview = ()=>{
        this.setState({ popup : true})
    }
    getNote = (e)=>{
        this.setState({ note : e.target.value})
    }

    openVideo = (url)=>{ 
        this.setState({videopopup: true, videoLink:url })
    }
    componentDidMount(){ 
        window.scrollTo(0, 0);
        const id = this.props.match.params.id;
        this.uid = localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1';
        const serviceId = this.props.match.params.serviceId

        // if user already booked the service
        if(this.props.match.params.isSelected == "true"){
            axios.get(`${API_BASE_URL}getDraft?userEventId=${this.props.match.params.userEventId}&userId=${this.uid}`)
            .then(r => { 
            const services = r.data.data.data[0].vendorServices;
            const newservices = [];
            services.forEach((service , index) =>{
            let Obj;
                Obj = {
                    serviceId : service.serviceId,
                    vendorServiceId : service.vendorServiceId,
                    linkedServiceId : service.linkedServiceId,
                    maxGuestRange: service.maxGuestRange,
                    minGuestRange:  service.minGuestRange,
                    price : service.price,
                    priceType : service.priceType,
                    guests : service.guests ,
                    isCompulsory : service.isCompulsory,
                    title : service.title,
                    feature : service.feature,
                    images : service.images,
                }
                newservices.push(Obj);
                if(service.vendorServiceId==id){
                    service.feature = service.feature;
                    this.setState({serviceDetail :service })
                    this.setState({note:service.note})
                }
                // if(service.note !== ''){
                //     this.setState({note:service.note})
                // }
            })
            this.setState({ services : newservices, vendorId: r.data.data.data[0].vendorServices[0].vendorId })
            this.SerivceValidId(id);
            })
            .catch(e =>{
                console.log('save draft error' , e)
            })
        }
        // for Service
        else{
            //get service is selected or not
            axios.get(`${API_BASE_URL}getDraft?userEventId=${this.props.match.params.userEventId}&userId=${this.uid}`)
            .then(res=>{ 
                res.data.data.data[0].vendorServices.forEach((service , index) =>{
                    if(service.serviceId==serviceId){
                        confirmAlert({
                            title: '',
                            message: 'You have already select '+service.serviceType+', new selection will replace with old selection',
                            buttons: [
                            {
                                label: 'Ok', 
                            },
                            ]
                        });  
                    }
                })
            })
            .catch(err=>{
                console.log('save draft error' , err)  
            })

            axios.get(`${API_BASE_URL}viewService?vendorServiceId=${id}`)
            .then(res => { 
                const linked  = res.data.data.vendorService.linkedServices
                const newlinked = [];
                const selected = [];
                linked.forEach((linked , index) =>{
                    let Obj;
                    Obj = {
                        feature : linked.feature,
                        filters : linked.filters,
                        images : linked.images,
                        location : linked.location,
                        price : linked.price,
                        maxGuestRange: linked.maxGuestRange,
                        minGuestRange:  linked.minGuestRange,
                        priceType : linked.priceType,
                        serviceId : linked.serviceId._id,
                        name : linked.serviceId.name,
                        status : linked.status,
                        title : linked.title,
                        guests : linked.minGuestRange,
                        isCompulsory : linked.isCompulsory,
                        _id : linked._id
                    }
                    newlinked.push(Obj);
                    if (linked.isCompulsory) {
                        selected.push(Obj);
                    }
                })
                const title = res.data.data.vendorService.title
                const selectedServiceNames = selected.map(item => (item.title));
                if (selectedServiceNames.length > 0) {
                    //alert(selectedServiceNames.join(', ') +  ' will be selected automatically with parent selection ' + title);
                    confirmAlert({
                        title: '',
                        message: selectedServiceNames.join(', ') +  ' will be selected automatically with parent selection ' + title,
                        buttons: [
                          {
                            label: 'Ok', 
                          },
                        ]
                      });
                }
                this.setState({serviceDetail : { ...res.data.data.vendorService, guests:res.data.data.vendorService.minGuestRange} ,  linkedServices : newlinked,vendorId: res.data.data.vendorService.vendorId })
                
            })
            .catch(err =>{
                console.log('error' , err)
            })
        }
        
        axios.get(`${API_BASE_URL}adminServiceList?userId=${this.uid}`)
        .then(r =>{ 
            if(r.data.data.adminServiceList.length > 0){
                const seviceId = r.data.data.adminServiceList[0]._id
                axios.get(`${API_BASE_URL}getBookmark?userId=${this.uid}&serviceId=${seviceId}`)
                .then(res =>{ 
                    this.setState({ Bookmarklist : res.data.data.vendorServiceList})
                })
                .catch(error =>{
                    console.log('bookmark error' , error)
                })
            }
        })
        .catch(error =>{ 
            console.log('adminlisterror', error)
        })

        axios.get(`${API_BASE_URL}getServiceReview?vendorServiceId=${id}`)
        .then(r=>{ 
            this.setState({'reviews':r.data.data.result});
            this.setState({'avgRating':r.data.data.averageRating})
        })
    }

    Down_pop1 = () =>{
        this.setState({ popup : false , title : "",videopopup: false})
    }
    // for Add to Bookmark
    Addfav = (id, isBookmarks) => {
        const isBookmark = this.get_bookmark(id);
        const bookfav = {
            vendorServiceId : id,
            userId : this.uid ,
            type : 0
        }

        if(isBookmark==1){
              this.CancelBookmark(id)
        }
        else{
            axios.post(`${API_BASE_URL}bookmark` , bookfav)
            .then(respon => {
                $(".heart-box").click(function(){
                    $(this).addClass("heart-box-in")
                })
                window.location.reload();
            })
            .catch(error =>{
                console.log('bookmark error' , error)
            })
        }
        
    }

    // for Cancel the Bookmark
    CancelBookmark = (id) => {
        const bookfav = {
            vendorServiceId : id,
            userId : this.uid ,
            type : 1
        }
        axios.post(`${API_BASE_URL}bookmark` , bookfav)
        .then(respon => {
            window.location.reload();
        })
        .catch(error =>{
            console.log('bookmark error' , error)
        })
    }
    
    // for Bookmark heart Color
    heart_icon = (id) =>{
        let added = false
        this.state.Bookmarklist .forEach(element => {
            if(element._id === id){
                added = true
            }  
        });
        return added ? "#F47824" : "#00000080"
    }

    // for Geting Bookmark
    get_bookmark = (id) =>{
        let added = 0;
        this.state.Bookmarklist .forEach(element => {
            if(element._id === id){
                added = 1
            }  
        });
        return added ;
    }

    // for the Back
    Back_user = () =>{
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const serviceId = this.props.match.params.serviceId
        const name = this.props.match.params.name  
        this.props.history.push(`/booking/${userEventId}/${eventTypeId}/${serviceId}/${name}`)
    }

    // for select the service
    toggleClass = async (serviceId , Vid , linkedServices , price , priceType , guests , isCompulsory,title) => { 
        // console.log(serviceId)
        // console.log(Vid)
        // console.log(linkedServices)
        // console.log(price)
        // console.log(priceType)
        //  console.log(guests)
        // console.log(isCompulsory)
        // return false;
        let id ;
        if(typeof(serviceId) === "object"){
            id = serviceId._id
        } else {
            id = serviceId
        }
        const temp = [...this.state.services]
        //await this.cancelDraft(id)
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.serviceId == id){
                index = i
            }
        })
        if(index > -1){
            temp.splice(index, 1);
            this.setState({ services : temp , active : false });
        }

        let Obj;
        Obj = {
            serviceId : id,
            vendorServiceId : Vid,
            linkedServiceId : linkedServices,
            price : price,
            priceType : priceType,
            guests : parseInt(guests) ,
            isCompulsory : isCompulsory
        }
        const servicenameget = this.state.linkedServices.find((item) =>{ return item.serviceId == Obj.serviceId });
        temp.push(Obj)
        //const messaged = servicenameget!=undefined?`If you select ${this.state.serviceDetail.title} then you will also have to select ${servicenameget.title}.`:`Are you sure to confirm ${this.state.serviceDetail.title}?`
        const messaged = `Are you sure to confirm ${title}`
        if(Vid == linkedServices){
            this.state.linkedServices.forEach((linked , index) =>{
                let Obj;
                Obj = {
                    serviceId : linked.serviceId,
                    vendorServiceId : Vid,
                    linkedServiceId : linked._id,
                    price : linked.price,
                    priceType : linked.priceType,
                    guests : linked.guests ,
                    isCompulsory : linked.isCompulsory
                }
                if (linked.isCompulsory) {
                    temp.push(Obj);
                }
            });
            const messaged = servicenameget!=undefined?`If you select ${this.state.serviceDetail.title} then you will also have to select ${servicenameget.title}.`:`Are you sure to confirm ${this.state.serviceDetail.title}?`
           }
           else{
                if(isCompulsory){
                    let Obj;
                    Obj = {
                        serviceId : this.state.serviceDetail.serviceId,
                        vendorServiceId : Vid,
                        linkedServiceId : Vid,
                        price : this.state.serviceDetail.price,
                        priceType : this.state.serviceDetail.priceType,
                        guests : this.state.serviceDetail.guests ,
                        isCompulsory : this.state.serviceDetail.isCompulsory
                    }
                    temp.push(Obj)
                }
                
            }
            confirmAlert({
                title: '',
                message: messaged,
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {    
                        this.setState({services  : temp , active : true},()=>{
                            console.log(temp)
                        })
                        if(Vid !== linkedServices){
                            this.setState({sliderDe:false})
                        }
                    }
                  },
                  {
                    label: 'Cancel',
                    onClick: () => {this.cancelDraft(this.state.serviceDetail.serviceId)}
                  }
                ]
            });
    };

    // Add to Event 
    addToEvent = () =>{
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const serviceId = this.props.match.params.serviceId

        // if user already booked service , it will remove the previous service then add new one
        if(this.props.match.params.isSelected == "true"){
            axios.get(`${API_BASE_URL}removeSelectedService?serviceId=${serviceId}&userEventId=${userEventId}&userId=${this.uid}`)
            .then(re => {
                const data = {
                    userEventId : userEventId,
                    userId : this.uid,
                    services : this.state.services,
                    note : this.state.note
                }
                
                axios.post(`${API_BASE_URL}draft` , data)
                .then(res =>{
                   this.props.history.push(`/venuedetail/${eventTypeId}/${userEventId}`)
                })
                .catch(err =>{
                    console.log('save to draft error' , err)
                })
            })
            .catch(e =>{
                console.log('hello world 2')
            })
        }
        // when user first time do Add to Event
        else{
            axios.get(`${API_BASE_URL}removeSelectedService?serviceId=${serviceId}&userEventId=${userEventId}&userId=${this.uid}`)
            .then(re => {
            const data = {
                userEventId : userEventId,
                userId : this.uid,
                services : this.state.services,
                note : this.state.note
            }
           
            axios.post(`${API_BASE_URL}draft` , data)
            .then(res =>{
                this.props.history.push(`/venuedetail/${eventTypeId}/${userEventId}`)
            })
            .catch(err =>{
                console.log('save to draft error' , err)
            })
        })
        .catch(e =>{
            console.log('hello world 2')
        })
        }
    }

    // for unselect the service
     cancelDraft = async (objs) =>{
        let id ;
        if(typeof(objs) === "object"){
            id = objs._id
        } else {
            id = objs
        }
        
        let index = -1;
        var temp = [...this.state.services];
        console.log(temp)
        temp.forEach((item , i) =>{
            if(item.serviceId == id){
                index = i
            }
        })
        console.log(index)
        if(index > -1){
            temp.splice(index, 1);
            this.setState({ services : temp , active : false });
            console.log(temp)
        }
    }

    // for remove the service
    removeService = (objs) =>{
        confirmAlert({
            title: '',
            message: 'Do you want to remove this service',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {    
                    const userEventId = this.props.match.params.userEventId;
                    const eventTypeId = this.props.match.params.eventTypeId
                    let id ;
                    if(typeof(objs) === "object"){
                        id = objs._id
                    } 
                    else {
                        id = objs
                    }
                    axios.get(`${API_BASE_URL}removeSelectedService?serviceId=${id}&userEventId=${userEventId}&userId=${this.uid}`)
                    .then(re => {
                        this.props.history.push(`/venuedetail/${eventTypeId}/${userEventId}`)
                    })
                    .catch(e =>{
                        console.log('Service Remove Error')
                    })
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
          });     
    }

    //get service index
    SerivceIndex = (id) =>{
        let index = -1;
        this.state.services.forEach((item , i) =>{
            if(item.vendorServiceId == id){
                index = i
            }
        })
        return index;
    }

    // for select or selected button in service
    SerivceValidId = (id) =>{ 
          let index = -1;
          this.state.services.forEach((item , i) =>{ 
              if(item.linkedServiceId == id){
                  index = i
              }
          })
          return index > -1;
    }
    
    // for Copy the Mobile Number
    copyCodeToClipboard = () => { 
        // const el = this.textArea
        // el.select()
        // document.execCommand("copy")
        let contactData = {
            vendorServiceId: this.props.match.params.id,
            uid : this.uid,
            vendorId : this.state.vendorId,
        }
        axios.post(`${API_BASE_URL}contact-analytics`,contactData)
        .then(res=>{

        })
        .catch(err=>{
            console.log('contact analytics error');
        })
    }

    //   for Copy the Url the of page on share
      Shareurl = () => {
          this.setState({show:true})
          const url = this.textArea1
          url.select()
          document.execCommand("copy")
    }   
    
    // for slider value
    sliderinput = (e, id) =>{ 
        const temp = [...this.state.services];
        let i = -1;
        temp.forEach((srv, index) => {
            if (srv.serviceId === id) {
                i = index;
            }
        });
        
        if (i > -1) {
            temp[i].guests = parseInt(e.target.value, 10);
            this.setState({ services: temp });
        }
        
    }

    
    render(){
   
        // for Range slider UI
        const allRanges = document.querySelectorAll(".range-wrap");
        allRanges.forEach(wrap => {
        const range = wrap.querySelector(".range");
        const bubble = wrap.querySelector(".bubble");

        range.addEventListener("input", () => {
            setBubble(range, bubble);
        });
        setBubble(range, bubble);
        });

        function setBubble(range, bubble) {
        const val = range.value;
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        const newVal = Number(((val - min) * 100) / (max - min));
        bubble.innerHTML = val;

        // Sorta magic numbers based on size of the native UI thumb
        bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        }

        const images = [];
            this.state.serviceDetail.images.map((img , i) =>{
                images.push(img)
            })

        const { photoIndex, isOpen,lphotoIndex, lisOpen,imagesIndex } = this.state;
        //const { lphotoIndex, lisOpen,imagesIndex } = this.state;
        
        
        // for total Value of the slider
        //let subtop =  (this.state.serviceDetail.guests!=0 ? this.state.serviceDetail.guests:1) * this.state.serviceDetail.price;
        let subtop =  0;
        this.state.services.forEach((item , index) =>{ 
            if (item.serviceId !== this.state.serviceDetail.serviceId){
                if(item.guests!=0){
                subtop += item.price * parseInt(item.guests ? item.guests : item.minGuestRange);
                }
                else{
                subtop += item.price ;    
                }
            }
           else
            {
                item.guests = item.guests!=0 ? item.guests : 1;
                subtop = item.price * parseInt(item.guests ? item.guests : item.minGuestRange);
            } 
        });
        let editsubtop = 0;
        this.state.services.forEach((serv , index) =>{
            if (serv.serviceId !== this.state.serviceDetail.serviceId){
                if(this.state.serviceDetail.guests!=0){
                    editsubtop += this.state.serviceDetail.price * parseInt(this.state.serviceDetail.guests ? this.state.serviceDetail.guests : this.state.serviceDetail.minGuestRange);
                }
                else{
                    editsubtop += this.state.serviceDetail.price ;    
                }
            }
            else
            { 
                this.state.serviceDetail.guests = this.state.serviceDetail.guests != 0 ? this.state.serviceDetail.guests : 1;
                editsubtop = this.state.serviceDetail.price * parseInt(this.state.serviceDetail.guests ? this.state.serviceDetail.guests : this.state.serviceDetail.minGuestRange);
            }
        })
        /*this.state.services.forEach((serv , index) =>{console.log(serv)
            serv.guests = serv.guests!=0 ? serv.guests : 1;
            editsubtop += serv.price * serv.guests
        })*/
        const Vid = this.props.match.params.id;
        var settings = {
        customPaging: (i) => (
                <a style={{display : "block" , width : "100%" , height : "100%"}}>
                    <img src={this.state.serviceDetail.images[i]}  style={{display : "block" , width : "100%" , height : "100%" , borderRadius : "6px"}}/>
                </a>
            ),
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
        };

          const isSelected = this.props.match.params.serviceId
        return(
            <div className="service-details-box">
                <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
                        <div className="service-inner-content" style={{padding  :"30px 0px"}}>
                            <div className="container">
                            <p className="para-container">
                                <a className="remove_anchor bold_me back-btn" href="#" onClick={this.Back_user} >
                                    <IoIosArrowBack />
                                BACK
                                </a>
                            </p>
                            <div className="row">
                                <div className="col-md-6" style={{overflow: 'hidden'}}>
                                
                                <Slider {...settings}>
                                {
                                    this.state.serviceDetail.images.map((img , i) =>{
                                        return <div key={i} className="left-img-box abc">
                                            <Link to="#" className="heart-box1" style={{backgroundColor :this.heart_icon(this.state.serviceDetail._id) , cursor : "pointer" }}  onClick={() =>this.Addfav(this.state.serviceDetail._id , this.state.serviceDetail.isBookmark)}><AiOutlineHeart className="heart-shape"/></Link>
                                                <img src={img?img:'default_img'} alt={i+1} style={{width  : "100%" , height : "100%"}} />
                                                </div>
                                    })
                                }
                                </Slider>
                                </div>
                                <div className="col-md-6">
                                    <div className="outer-right-box">
                                        <div className="right-box right-box-text">
                                            <h2 className="h2-black">{this.state.serviceDetail.title}</h2>
                                            <div className="col-md-6" style={{ float:'right'}}><span className="rating-span" onClick={this.AddReview} style={{ align:'right',cursor : "pointer"}}>Add Review</span>&nbsp;
                                            <span className="rating-span"><AiOutlineStar />{this.state.avgRating}</span></div>
                                            
                                        </div>
                                        <div className="loca-box flex-center">
                                            <MdLocationOn className="ico-shape"/> <p className="para-text3">{this.state.serviceDetail.location}</p>
                                            <FaUserFriends /><p className="para-text3">{this.state.serviceDetail.maxCapacity?this.state.serviceDetail.maxCapacity+' Persons':'NA'}</p>
                                        </div>
                                        <div className="detail-box">
                                        <Tabs>
                                            <TabList>
                                            <Tab>Services</Tab>
                                            <Tab>About</Tab>
                                            <Tab>Reviews</Tab>
                                            <Tab>Gallery</Tab>
                                            <Tab>Terms</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <div className="tab-box serv-box">
                                                    { this.state.serviceDetail.type==''  ? "" :
                                                        <div className="inner-serv-box">
                                                            <h6>{this.state.serviceDetail.serviceName}</h6>
                                                            <div className="Out-inf-box">
                                                                <div className="inf-box">
                                                                    <div className="ext-serv">
                                                                        <h6 style={{textTransform  :"capitalize"}}>{this.state.serviceDetail.title}</h6>
                                                                    <ul>
                                                                        {
                                                                            this.state.serviceDetail.feature.map((f , index) =>{
                                                                            return <li key={index}><span></span>{f.featureName}</li>                                                                      
                                                                            })
                                                                        }
                                                                    </ul>                                                                
                                                                </div>
                                                                {
                                                                <div className="cov-img">
                                                                   {this.state.serviceDetail.images.length > 0 ? <img src={this.state.serviceDetail.images[0]} style={{width : "100%" , height : "100%" , borderRadius : "6px"}}  /> : ""}
                                                                </div>
                                                                }
                                                            </div>
                                                            <div className="range-box">
                                                                <p style={{marginBottom : "0px"}}><span>AED {this.state.serviceDetail.price} / {jspriceType[this.state.serviceDetail.priceType]}</span> </p>
                                                                {/* <button type="button" >{this.SerivceValidId(this.state.serviceDetail.serviceId) ? <span className="sele-cted" onClick={() => this.toggleClass(this.state.serviceDetail.serviceId , Vid , Vid , this.state.serviceDetail.price , this.state.serviceDetail.priceType , this.state.serviceDetail.guests , true )}>Edit</span>  : ''}</button> */}
                                                                <button type="button" >{this.SerivceValidId(this.state.serviceDetail._id) ? <span className="sele-cted" onClick={() => this.removeService(this.state.serviceDetail.serviceId)}>Remove</span>  : <span onClick={() => this.toggleClass(this.state.serviceDetail.serviceId , Vid , Vid , this.state.serviceDetail.price , this.state.serviceDetail.priceType , this.state.serviceDetail.guests , true ,this.state.serviceDetail.title)} className="sel-ct">select</span>}</button>
                                                            </div>
                                                            { this.state.serviceDetail.priceType !== 0 ?
                                                                     <div className="abr-range">
                                                                        <p style={{marginBottom : "15px", display  :"flex" , justifyContent : "space-between"}}><span>{this.state.serviceDetail.minGuestRange}</span><span>{this.state.serviceDetail.maxGuestRange}</span></p>
                                                                        <div className="range-wrap">
                                                                            <input type="range" className="range inner-abr-range slider" onChange={(e) => {
                                                                                const temp = {...this.state.serviceDetail};
                                                                                temp.guests = parseInt(e.target.value, 10);
                                                                                this.setState({ serviceDetail: temp});
                                                                            } } min={this.state.serviceDetail.minGuestRange} max={this.state.serviceDetail.maxGuestRange} value={this.state.serviceDetail.guests}/>
                                                                            {/* {this.state.sliderDe?<input disabled type="range" defaultValue={this.state.serviceDetail.guests} onChange={(e) => this.sliderinput(e, this.state.serviceDetail.serviceId) } className="range inner-abr-range slider"  min={this.state.serviceDetail.minGuestRange} max={this.state.serviceDetail.maxGuestRange} />:<input type="range" defaultValue={this.state.serviceDetail.guests} onChange={(e) => this.sliderinput(e, this.state.serviceDetail.serviceId) } className="range inner-abr-range slider"  min={this.state.serviceDetail.minGuestRange} max={this.state.serviceDetail.maxGuestRange} />} */}
                                                                            <output className="bubble">{this.state.serviceDetail.guests}</output>
                                                                        </div>
                                                                    </div>
                                                                    : ""
                                                                }
                                                        </div>                        
                                                    </div>
                                                    }
                                                   
                                                    {
                                                        this.state.linkedServices.length > 0 ? 
                                                        <div className="inner-serv-box" >
                                                        {
                                                            this.state.linkedServices.map((link , index) =>{
                                                                return <div key={index}>
                                                                {
                                                                    link.type ? <h6>{link.type}</h6> : <h6>{link.name}</h6>
                                                                }
                                                                    <div className="Out-inf-box" style={{marginBottom : "30px"}}>
                                                                        <div className="inf-box">
                                                                            <div className="ext-serv">
                                                                                <h6>{link.title}</h6>
                                                                                    <ul>
                                                                                    {
                                                                                        link.feature.map((f , index) =>{
                                                                                        return <li key={index}><span></span>{f}</li>                                                                      
                                                                                        })
                                                                                    }
                                                                                    </ul>
                                                                                </div>
                                                                            <div className="cov-img">     
                                                                            <img src={link.images[0]} style={{width : "100%" , height : "100%" , borderRadius : "6px"}} alt={link.title} onClick={() => this.setState({imagesIndex:index },()=>{this.setState({lisOpen: true})})}/>     
                                                                            </div>
                                                                            { lisOpen && (
                                                                                <Lightbox
                                                                                    mainSrc={this.state.linkedServices[imagesIndex].images[lphotoIndex]}
                                                                                    nextSrc={this.state.linkedServices[imagesIndex].images[(lphotoIndex + 1) % this.state.linkedServices[imagesIndex].images.length]}
                                                                                    prevSrc={this.state.linkedServices[imagesIndex].images[(lphotoIndex + this.state.linkedServices[imagesIndex].images.length - 1) % this.state.linkedServices[imagesIndex].images.length]}
                                                                                    onCloseRequest={() => this.setState({ lisOpen: false,imagesIndex:null })}
                                                                                    onMovePrevRequest={() =>
                                                                                    this.setState({
                                                                                        lphotoIndex: (lphotoIndex + this.state.linkedServices[imagesIndex].images.length - 1) % this.state.linkedServices[imagesIndex].images.length,
                                                                                    })
                                                                                    }
                                                                                    onMoveNextRequest={() =>
                                                                                    this.setState({
                                                                                        lphotoIndex: (lphotoIndex + 1) % this.state.linkedServices[imagesIndex].images.length,
                                                                                    })
                                                                                    }
                                                                                    />
                                                                                )
                                                                            }
                                                                            </div>
                                                                            <div className="range-box">
                                                                                <p style={{marginBottom : "0px"}}><span>AED {link.price} / {jspriceType[link.priceType]}</span>   </p>
                                                                                {this.SerivceValidId(link._id) ? <button type="button"><span className="sele-cted"  onClick={() => this.removeService(link.serviceId)}>Remove</span></button> : <span onClick={() => this.toggleClass(link.serviceId , Vid  , link._id , link.price , link.priceType , link.guests  , link.isCompulsory,link.title
                                                                                )} className="sel-ct" style={{color : "#fff" , backgroundColor : "#8489b2" , fontWeight : "300" , padding : "5px 22px" , borderRadius : "6px" , fontSize : "14px",cursor: "pointer"}}>Select</span>}  
                                                                             </div>
                                                                                {   link.priceType !== 0 ?
                                                                                    <div className="abr-range">
                                                                                        <p style={{marginBottom : "15px", display  :"flex" , justifyContent : "space-between"}}><span>{link.minGuestRange}</span><span>{link.maxGuestRange}</span></p>
                                                                                        <div className="range-wrap">
                                                                                            {this.state.sliderDe?<input disabled type="range" defaultValue={link.guests} onChange={(e) => this.sliderinput(e, link.serviceId) } className="range inner-abr-range slider"  min={link.minGuestRange} max={link.maxGuestRange} />:<input type="range" defaultValue={link.guests} onChange={(e) => this.sliderinput(e, link.serviceId) } className="range inner-abr-range slider"  min={link.minGuestRange} max={link.maxGuestRange} />}
                                                                                            <output className="bubble">{link.guests}</output>
                                                                                        </div>
                                                                                    </div>
                                                                                    : ""
                                                                                }
                                                                        </div>
                                                                            </div>
                                                                        })
                                                                    }   
                                                        </div> 
                                                    : ""
                                                    }
                                                    <hr/> 
                                                    <div className="inner-serv-box" >
                                                        <div>
                                                            <h6>Note</h6>
                                                            <div className="Out-inf-box" style={{marginBottom : "30px"}}>
                                                            <div className="inf-box">
                                                            <textarea onChange={this.getNote} style={{border:'none',outline:'none'}} rows="3" cols="80" placeholder="Leave a remark or request if any...." value= {this.state.note} />
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="add-event-btn">
                                                    <div>
                                                        <ul>
                                                            <li>Total Price</li>
                                                            <li>{this.props.match.params.isSelected == "true" ? <span>AED {editsubtop}</span> : <span>AED {subtop} </span> } </li>
                                                        </ul>
                                                    </div>
                                                    <button type="button"  
                                                    style={{ backgroundColor : (this.state.services.length == 0 || this.props.match.params.isSelected == "true") ? "#e1e1e1" : "#f47824"  , color : (this.state.services.length == 0 || this.props.match.params.isSelected == "true")? "#aaaaaa" : "#fff"  }} 
                                                    disabled={this.state.services.length == 0 || this.props.match.params.isSelected == "true"} 
                                                    onClick={this.addToEvent}>Add to Event</button>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-box about-box">
                                               <div className="cont-in-box">
                                                    <Toast onClose={() => {this.setState({show:false})}} show={this.state.show} delay={2000} autohide>
                                                        <Toast.Header>
                                                            <strong className="mr-auto">Url Copy Successfully</strong>
                                                        </Toast.Header>
                                                    </Toast>
                                                   </div>
                                                    <div className="cont-in-box">
                                                        {/* <div style={{color : "#8489B2", display:"none"}} className="phone-box">
                                                            <span></span>                                                           
                                                            Call to vendor<span className="pone">
                                                                <textarea ref={(textarea) => this.textArea = textarea} value={this.state.serviceDetail.phoneNumber} />
                                                            </span>
                                                            <p onClick={() => this.copyCodeToClipboard()} style={{marginBottom : "0px" , color : "red" , textAlign : "center" , fontSize : "14px"}}>Tap to Copy</p>
                                                        </div> */}
                                                        
                                                        <Dropdown>
                                                            <Dropdown.Toggle  id="dropdown-basic" style={{width: "200"}}>
                                                                Call
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => this.copyCodeToClipboard()} href={`tel:${this.state.serviceDetail.phoneNumber}`}>Call Vendor</Dropdown.Item>
                                                                <Dropdown.Item href="tel:+971566405353">Call EventStan</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        
                                                        <div style={{color : "#8489B2"}}>
                                                            <span><AiOutlineShareAlt style={{color : "#8489B2" , cursor : "pointer"}} onClick={this.Shareurl} /></span> Share
                                                            <textarea style={{opacity : "0"}} ref={(textarea1) => this.textArea1 = textarea1} value={window.location.href} />
                                                        </div>
                                                    </div>
                                                  
                                                    <div className="des">                                                       
                                                        <div>
                                                            <p><b>{this.state.serviceDetail.topic}</b></p>
                                                            <p>{this.state.serviceDetail.description}</p>
                                                            <p>{this.state.serviceDetail.otherInformation}</p>
                                                        </div>
                                                    </div>
                                                    
                                                    <h3>Operational Hours</h3>
                                                    <div className="schedule-box">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                            <th scope="row" style={{color:"#7a7a7a"}}>Monday</th>
                                                            <td>{this.state.serviceDetail.monStart == "" || this.state.serviceDetail.monEnd == "" ? <span>Closed</span> : <span> {moment(parseInt(this.state.serviceDetail.monStart)).format("hh : mm A")} - {moment(parseInt(this.state.serviceDetail.monEnd)).format("hh : mm A")    } </span> }</td>
                                                            </tr>
                                                            <tr>
                                                            <th scope="row" style={{color:"#7a7a7a"}}>Tuesday</th>
                                                            <td>{this.state.serviceDetail.tueStart == "" || this.state.serviceDetail.tueEnd == "" ? <span>Closed</span> : <span> {moment(parseInt(this.state.serviceDetail.tueStart)).format("hh : mm A")} - {moment(parseInt(this.state.serviceDetail.tueEnd)).format("hh : mm A")} </span> }</td>
                                                            </tr>
                                                            <tr>
                                                            <th scope="row" style={{color:"#7a7a7a"}}>Wednesday</th>
                                                            <td>{this.state.serviceDetail.wedStart == "" || this.state.serviceDetail.wedEnd == "" ? <span>Closed</span> : <span> {moment(parseInt(this.state.serviceDetail.wedStart)).format("hh : mm A")} - {moment(parseInt(this.state.serviceDetail.wedEnd)).format("hh : mm A")} </span> }</td>
                                                            </tr>    
                                                            <tr>
                                                            <th scope="row" style={{color:"#7a7a7a"}}>Thrusday</th>
                                                            <td>{this.state.serviceDetail.thuStart == "" || this.state.serviceDetail.thuEnd == "" ? <span>Closed</span> : <span> {moment(parseInt(this.state.serviceDetail.thuStart)).format("hh : mm A")} - {moment(parseInt(this.state.serviceDetail.thuEnd)).format("hh : mm A")} </span> }</td>
                                                            </tr>    
                                                            <tr>
                                                            <th scope="row" style={{color:"#7a7a7a"}}>Friday</th>
                                                            <td>{this.state.serviceDetail.friStart == "" || this.state.serviceDetail.friEnd == "" ? <span>Closed</span> : <span> {moment(parseInt(this.state.serviceDetail.friStart)).format("hh : mm A")} - {moment(parseInt(this.state.serviceDetail.friEnd)).format("hh : mm A")} </span> }</td>
                                                            </tr>    
                                                            <tr>
                                                            <th scope="row" style={{color:"#7a7a7a"}}>Saturday</th>
                                                            <td>{this.state.serviceDetail.satStart == "" || this.state.serviceDetail.satEnd == "" ? <span>Closed</span> : <span> {moment(parseInt(this.state.serviceDetail.satStart)).format("hh : mm A")} - {moment(parseInt(this.state.serviceDetail.satEnd)).format("hh : mm A")} </span> }</td>
                                                            </tr>    
                                                            <tr>
                                                            <th scope="row" style={{color:"#7a7a7a"}}>Sunday</th>
                                                            <td>{this.state.serviceDetail.sunStart == "" || this.state.serviceDetail.sunEnd == "" ? <span>Closed</span> : <span> {moment(parseInt(this.state.serviceDetail.sunStart)).format("hh : mm A")} - {moment(parseInt(this.state.serviceDetail.sunEnd)).format("hh : mm A")} </span> }</td>
                                                            </tr>    
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-box ">
                                                {this.state.reviews.length ?
                                                   this.state.reviews.map((rev,i)=>{
                                                    return <div className="review-box" id={i}>
                                                        <div className="inn-rev">
                                                            <div style={{fontWeight : "600"}}><img style={{width : "20px" , height : "20px" , borderRadius : "50%" , marginRight : "10px"}} src={defaultUser} alt="placeholder img" /> {rev.userId.firstName}</div>
                                                            <span style={{opacity: "0.5", color: "#2C2627" , minWidth : "80px" }}>{new Date(rev.createdAt).toLocaleDateString()}</span>
                                                        </div>
                                                        <span style={{backgroundColor :"#f47824" , color : "#fff" , padding :"2px 10px " , fontSize : "15px" , borderRadius : "5px"}}><AiOutlineStar />{rev.rating}</span>
                                                        <p style={{fontSize  :"12px" , opacity: "0.8", color: "#2C2627" , padding :"10px 0px"}}>{rev.reviewText}</p>
                                                    </div>
                                                    })
                                                :
                                                <div style={{textAlign: 'center',fontSize:'20px',color:'white',background:'#8489b2'}}>No Review</div>
                                                }
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-box">
                                                    <div className="gallery-box">
                                                        {
                                                            this.state.serviceDetail.images.map((img , i) =>{
                                                                return <div  style={{height : "130px" , backgroundColor  :"red"}} >
                                                                    <img src={img} alt={i+1} style={{width  : "100%" , height : "100%" , cursor  :"pointer"}}  onClick={() => this.setState({ isOpen: true })} />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="tab-box">
                                                    <div className="gallery-box">
                                                        {
                                                           this.state.serviceDetail.videoLinks ? this.state.serviceDetail.videoLinks.map((img , i) =>{
                                                                return <div  style={{height : "130px" , backgroundColor  :"red"}} >
                                                                    <img src={playBtn} alt={i+1} style={{width  : "100%" , height : "100%" , cursor  :"pointer"}}  onClick={() => this.openVideo(img)} />
                                                                </div>
                                                            }):''
                                                        }
                                                    </div>
                                                </div>
                                                { isOpen && (
                                                    <Lightbox
                                                        mainSrc={images[photoIndex]}
                                                        nextSrc={images[(photoIndex + 1) % images.length]}
                                                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                                        onMovePrevRequest={() =>
                                                        this.setState({
                                                            photoIndex: (photoIndex + images.length - 1) % images.length,
                                                        })
                                                        }
                                                        onMoveNextRequest={() =>
                                                        this.setState({
                                                            photoIndex: (photoIndex + 1) % images.length,
                                                        })
                                                        }
                                                        />
                                                    )
                                                }
                                              
                                                {this.state.videopopup ? (
                                                <div className="filter-content">
                                                <div className="filter-content-pop2" >
                                                    <p onClick={this.Down_pop1} className="can-el-btn1">X</p>
                                                    <iframe width="560" height="315" src={this.state.videoLink} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                </div>
                                                </div>
                                                ) : null}
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-box about-box">
                                                <h3>Delivery Detail</h3>
                                                    <div className="des">                                                       
                                                        <div>
                                                        <p>{(this.state.serviceDetail.deliveryDetail !='' && this.state.serviceDetail.deliveryDetail != undefined ) ? this.state.serviceDetail.deliveryDetail
                                                        :<div style={{textAlign: 'center',fontSize:'20px',color:'white',background:'#8489b2'}}>No Delivery Detail</div>    
                                                        } </p>
                                                        </div>
                                                    </div>
                                                    <hr/>
                                                <h3>Refund Policy</h3>
                                                    <div className="des">                                                       
                                                        <div>
                                                        <p>{(this.state.serviceDetail.refundPolicy !='' && this.state.serviceDetail.refundPolicy != undefined ) ? this.state.serviceDetail.refundPolicy
                                                        :<div style={{textAlign: 'center',fontSize:'20px',color:'white',background:'#8489b2'}}>No Refund Policy</div>    
                                                        } </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.popup ? (
                            <div className="filter-content">
                            <div className="filter-content-pop2" >
                                <p onClick={this.Down_pop1} className="can-el-btn1">X</p>
                                <ReviewPopup vendorServiceId={this.props.match.params.id}/>
                            </div>
                            </div>
                            ) : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        userDetails : state.userDetailsReducer.userDetails,
    }
}

export default connect(mapStateToProps,null)(ServiceDetail);
