import React, { useEffect, useState } from "react";
import { Row, Col, Button, ThemeProvider } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";
import "./../../components/Popup.css";
import "./../../components/booking.css";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { FaCalendar } from 'react-icons/fa';
import {connect} from 'react-redux'
import "react-datepicker/dist/react-datepicker.css";
import {API_BASE_URL} from './../../constant'
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);



class PopupHireProf extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            filterlist1 : [],
            filterlist2 : [],
            filterlist  : [],
            dis : true,
            fieldCount: [1],
            Inputselect:[],
            name : "",
            minMaxValue : [0, 0],
            createbtn : true,
            endDate : new Date(),
            startDate : new Date(),
            Date : new Date(),
            filterdata1 : [],
            filterdata2 : [],
            uid :'',
            locations:[],
           
        }
    }
    componentDidMount(){
        const target_event_id = this.props.target_event_id;
       this.setState({'uid': localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1'});
       //get states data from colection
       axios.get(`${API_BASE_URL}get-locations?status=1`)
       .then(resp=>{ 
            const states = [];
            resp.data.data.map((st,index)=>{
                states.push(st.stateName)  
            })
            this.setState({locations:states})
       })
        // filters Api for let's create and Event
        axios
        .get(
            `${API_BASE_URL}event-form?eventTypeId=${target_event_id}`
          )
        .then(res =>{ 
            const filterdata = []
            const filter = res.data.data.result.filters
            filter.map((fil , index) =>{
                let today = new Date();
                var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                let curTime = moment("1 January 2021 " + time).format("x")
                let Curtime = +curTime
                let endTime1 = moment("1 January 2021 " + time).add(1 , 'hours').format("x")
                let endTime = +endTime1
                let curTime1 = moment().format("x")
                let Curtime2 = +curTime1 

                if(fil.filterId.type === 1){
                    let Obj;
                    Obj ={
                        filterId : fil.filterId._id,
                        type : fil.filterId.type ,
                        name : fil.filterId.name,
                        singleSelect : fil.filterId.singleSelect,
                        singleSelectData : ""
                    }
                    filterdata.push(Obj)
                }
                if(fil.filterId.type === 2){
                    let Obj;
                    Obj ={
                        filterId : fil.filterId._id,
                        type : fil.filterId.type ,
                        name : fil.filterId.name,
                        multiSelect : fil.filterId.multiSelect,
                        multiSelectList : []
                    }
                    filterdata.push(Obj)
                }
                if(fil.filterId.type === 3){
                    let Obj;
                    Obj ={
                        filterId : fil.filterId._id,
                        type : fil.filterId.type ,
                        name : fil.filterId.name,
                        date : Curtime2,
                    }
                    filterdata.push(Obj)
                }
                if(fil.filterId.type === 4){
                    let Obj;
                    Obj ={
                        filterId : fil.filterId._id,
                        type : fil.filterId.type ,
                        name : fil.filterId.name,
                        startDate : Curtime2,
                        endDate : Curtime2
                    }
                    filterdata.push(Obj)
                }
                if(fil.filterId.type === 5){
                    let Obj;
                    Obj ={
                        filterId : fil.filterId._id,
                        type : fil.filterId.type ,
                        name : fil.filterId.name,
                        time : Curtime,
                    }
                    filterdata.push(Obj)
                }
                if(fil.filterId.type === 6){
                    let Obj;
                    Obj ={
                        filterId : fil.filterId._id,
                        type : fil.filterId.type ,
                        name : fil.filterId.name,
                        startTime : Curtime,
                        endTime : endTime
                    }
                    filterdata.push(Obj)
                }
                if(fil.filterId.type === 7){
                    let Obj;
                    Obj ={
                        filterId : fil.filterId._id,
                        type : fil.filterId.type ,
                        name : fil.filterId.name,
                        membersMinRange : fil.filterId.membersMinRange,
                        membersMaxRange : fil.filterId.membersMaxRange
                    }
                    filterdata.push(Obj)
                }
                if(fil.filterId.type === 8){
                    let Obj;
                    Obj ={
                        filterId : fil.filterId._id,
                        type : fil.filterId.type ,
                        name : fil.filterId.name,
                        singleSelect : this.state.locations,
                        singleSelectData : "Dubai"
                    }
                    filterdata.push(Obj)
                }
                
            })
            this.setState({ filterdata1 : filterdata })
            this.setState({filterlist1 : res.data.data.result.filters})
            this.setState({name : res.data.data.result.name})
            this.state.filterlist1.forEach((fil) =>{
                if(fil.filterId.type === 7){
                    this.setState({minMaxValue : [fil.filterId.membersMinRange , fil.filterId.membersMaxRange]})
                }
                
            })
        })
        .catch(e =>{
            console.log('target_id error' , e)
        })
    }

    // for Update the Filter button
    UpdateFilter = () => {
        const target_event_id = this.props.target_event_id
        const Eventname = this.props.title
        const uid = this.state.uid;
        const temp = []
        this.state.filterdata1.forEach((item , index) =>{
                if(item.type === 1){
                    temp.push({
                        filterId: item.filterId,
                        type : item.type,
                        singleSelect : item.singleSelectData

                    });
                }
                if(item.type === 2){
                    temp.push({
                        filterId: item.filterId,
                        type : item.type,
                        multiSelect : item.multiSelectList
                    });
                }
                if(item.type === 3){
                    temp.push({
                        filterId: item.filterId,
                        type : item.type,
                        date : item.date,
                        hdate : moment(item.date).format("DD-MM-YYYY"),
                    });
                }
                if(item.type === 4){
                    temp.push({
                        filterId: item.filterId,
                        type : item.type,
                        startDate : item.startDate,
                        endDate : item.endDate
                    });
                }
                if(item.type === 5){
                    temp.push({
                        filterId: item.filterId,
                        type : item.type,
                        time : item.time
                    });
                }
                if(item.type === 6){
                    temp.push({
                        filterId: item.filterId,
                        type : item.type,
                        startTime : item.startTime,
                        endTime: item.endTime,
                        hstartTime : moment(item.startTime).format("HH:mm"),
                        hendTime : moment(item.endTime).format("HH:mm"),
                    });
                }
                if(item.type === 7){
                    temp.push({
                        filterId: item.filterId,
                        type : item.type,
                        membersMinRange : item.selectedMembersMinRange!==undefined?item.selectedMembersMinRange:item.membersMinRange,
                        membersMaxRange : item.selectedMembersMaxRange!==undefined?item.selectedMembersMaxRange:item.membersMaxRange
                    });
                }
                if(item.type === 8){
                    temp.push({
                        filterId: item.filterId,
                        type : item.type,
                        singleSelect : item.singleSelectData

                    });
                }
            })
        const Filters = {
            eventTypeId  : target_event_id,
            eventName : Eventname,
            userId : this.state.uid,
            eventType : 2,
            filters : temp,
        }
        axios.post(`${API_BASE_URL}event` , Filters)
            .then(r => { 
                this.props.UserEventId(r.data.data._id);
                localStorage.setItem("event_id",r.data.data._id);
               // window.location.href = "/professionalservices/" + r.data.data.eventTypeId + "/" + r.data.data._id
                window.location.href = "/professionallisting/" + r.data.data.eventTypeId + "/" + r.data.data._id
            })
            .catch(e => {
                this.setState({Error : true})
            })
    }
    // for select Mutli Values in Select Element
    getmultiSelect = (arr) => {
        const values = [];
        arr.forEach(item => values.push(item.value));
        return values;
    }

    // for single Select Type filter
    SelectChange = (e ,id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0) {
            temp.push({
                filterId: id,
                singleSelectData: e,
            });
        } else {
            temp[index].singleSelectData = e;
        }
        this.setState({filterdata1 :  temp })
    }

    // for single Date Select filter
    singleselectDate = (e , id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0){
            temp.push({
                filterId : id ,
                date : e
            })   
        }else{
            temp[index].date = parseInt(e)
        }
        this.setState({filterdata1 :  temp })
    }

    // for Single Select filter
    singleselectTime = (e , id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0){
            temp.push({
                filterId : id ,
                date : e
            })   
        }else{
            temp[index].time = parseInt(e)
        }
        this.setState({filterdata1 :  temp })
    }
    // for Start Date filter
    startDateRange = (e , id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0){
            temp.push({
                filterId : id ,
                date : e
            })   
        }else{
            temp[index].startDate = parseInt(e)
        }
        this.setState({filterdata1 :  temp })
    }
    // for End Date filter
    endDateRange = (e , id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0){
            temp.push({
                filterId : id ,
                date : e
            })   
        }else{
            temp[index].endDate = parseInt(e)
        }
        this.setState({filterdata1 :  temp })
    }
    // for Start Time  filter
    startTimeRange = (e , id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0){
            temp.push({
                filterId : id ,
                date : e
            })   
        }else{
            temp[index].startTime = parseInt(e)
        }
        this.setState({filterdata1 :  temp })
    }

    // for Endtime Range filter
    endTimeRange = (e , id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0){
            temp.push({
                filterId : id ,
                date : e
            })   
        }else{
            temp[index].endTime = parseInt(e)
        }
        this.setState({filterdata1 :  temp })
    }

    // for check validation on location add input  button
    locAdd = () => {
        let add = false;
        const temp = [...this.state.filterdata1];

        temp.forEach((item , index) =>{
            if(item.type === 8 && item.location[0].address == ""){
                add = true
            }
        })

        return add;
    }

    // for MultiSelect Filter
    MultiChange = (e  , id) =>{
            const temp = [...this.state.filterdata1];
           
            let index = -1;
            temp.forEach((item , i) =>{
                if(item.filterId == id){
                    index = i
                }
            })
            if(index < 0){
                temp.push({
                    filterId : id ,
                    date : e
                })   
            }else{
                temp[index].multiSelectList = e
            }
            this.setState({filterdata1 :  temp })
    }

    // for multirange slider 
    rangeSlider = (filtervalue , id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0){
            temp.push({
                filterId : id ,
                selectedMembersMinRange: filtervalue[0],
                selectedMembersMaxRange: filtervalue[1],
            })
        }else{
            temp[index].selectedMembersMinRange = filtervalue[0]
            temp[index].selectedMembersMaxRange  = filtervalue[1]
        }
        this.setState({filterdata1 :  temp })
    }

    // for Show minimum and maximum value of slider
    getslider = (min , max , id) =>{
        const temp = [...this.state.filterdata1];
        let index = -1;
        temp.forEach((item , i) =>{
            if(item.filterId == id){
                index = i
            }
        })
        if(index < 0){
            console.log('max min' , temp[index].membersMinRange, temp[index].membersMaxRange)
            return {
                
                membersMinRange: temp[index].membersMinRange,
                membersMaxRange: temp[index].membersMaxRange
            }  
        }else{
            //console.log('second max min' , min, max)
            return{
                membersMinRange : min,
                membersMaxRange  : max
            }
            
        }
        
    }

    // for check validation on let's create an Event button
    CheckValidation = () =>{
        const temp = [...this.state.filterdata1]
       
        let added = false;
        temp.forEach((item , index) =>{
            if(item.type === 1 && item.singleSelectData == "" ){
                added = true
            }
            if(item.type === 2 && item.multiSelectList.length == 0){
                added = true
            }
            if(item.type === 8 && item.singleSelectData == ""){
                added = true
            }
        })
        return added;
    }
    render(){
        var filtervalue = []
        
        this.state.filterlist1.forEach((filter , i) =>{ 
            if(filter.type === 7){
                filtervalue[0] = filter.membersMinRange
                filtervalue[1] = filter.membersMaxRange
            }
        }) 
        // To get current time
        var date = new Date();
        var curTime= moment(date).format("hh:mm") 
        return(
            <div className="">
                    <div className="row inside-content1">
                <h2 className="col-md-12" style={{position : "relative" , textAlign : "center" , fontWeight : "600" , color : "#000" , paddingBottom : "15px" , marginBottom : "30px" , textTransform : "capitalize"}}>{this.props.title}</h2>
                    {
                        this.state.filterdata1.map((fil , index) =>(
                        <div key={index} className="col-md-6" style={{marginBottom : '15px'}}>
                            {fil.type === 1 && (
                            <div>
                                <h6 className="title-box"  style={{marginBottom : "10px"}}>{fil.name} </h6>
                                <Select
                                options={fil.singleSelect.map((option , index) => {
                                return { label: option, value: option };
                                })}
                                onChange={(e) => this.SelectChange(e.value , fil.filterId)}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                /> 
                            </div>
                            )}
                            {fil.type === 2 && (
                                <div>
                                    <h6 className="title-box" style={{marginBottom : "10px"}}>{fil.name} </h6>
                                    <Select
                                    options={fil.multiSelect && fil.multiSelect.map((option , index) => {
                                    return { label: option, value: option };
                                    })}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isMulti
                                    onChange={(e) => this.MultiChange(this.getmultiSelect(e) , fil.filterId)}
                                    />
                                </div>
                                )}
                                {fil.type === 3 && (
                                <div>
                                    <h6 className="title-box" style={{marginBottom : "10px"}}>{fil.name} </h6>
                                    <div style={{position : "relative"}} className="singleDate">
                                    <DatePicker minDate={new Date()}
                                    showYearDropdown
                                    selected={new Date(fil.date)}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) =>{
                                        this.singleselectDate(moment(date).format("x") , fil.filterId)
                                    }}
                                    />
                                    <span style={{position : "absolute" , right : "10px" , top : "3px" , fontSize : "20px" , color : "#7a7a7a"}}>
                                    <FaCalendar />
                                    </span>
                                </div>
                                </div>
                                )}
                                {fil.type === 4 && (
                                <div>
                                <h6 className="title-box" style={{marginBottom : "10px"}}>{fil.name} </h6>
                                <div className="trw-box">
                                    <div style={{position : "relative"}}>
                                    <DatePicker minDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    selected={new Date(fil.startDate)}
                                    showYearDropdown
                                    onChange={(date) =>{
                                    this.startDateRange(moment(date).format("x"),  fil.filterId)}
                                    } 
                                    />
                                    <span style={{position : "absolute" , right : "10px" , top : "3px" , fontSize : "20px" , color : "#7a7a7a"}}>
                                    <FaCalendar />
                                    </span>
                                        </div>
                                        <div style={{position : "relative"}}>
                                        <DatePicker minDate={new Date()}
                                        showYearDropdown
                                        dateFormat="dd/MM/yyyy"
                                        selected={new Date(fil.endDate)}
                                        onChange={(date) =>{
                                            this.endDateRange(moment(date).format("x"),  fil.filterId)}
                                            }  
                                        />
                                        <span style={{position : "absolute" , right : "10px" , top : "3px" , fontSize : "20px" , color : "#7a7a7a"}}>
                                        <FaCalendar />
                                        </span>
                                    </div>
                                    </div>
                                    </div>
                                    )}
                                    {fil.type === 5 && (
                                        <div className="single-time">
                                        <h6 className="title-box" style={{marginBottom : "10px"}}>{fil.name} </h6>
                                        <div style={{ display: "flex", justifyContent: "left" }}>
                                
                                        <input
                                        style={{ width : "100%" , backgroundColor : "#F7F7F7" , border : "2px solid #DCDCDC" , color : "#7a7a7a" , fontSize : "14px" , outline : "none" , padding : "5px"}}
                                        type="time"
                                        id="appt"
                                        defaultValue={curTime}
                                        onChange={(e) => this.singleselectTime( moment("1 January 2021 "+e.target.value+":00" ).format("x") , fil.filterId)}
                                        />
                                        </div>
                                        </div>
                                        )}
                                        {fil.type === 6 && (
                                            <div>
                                            <h6 className="title-box" style={{marginBottom : "10px"}}>{fil.name} </h6>
                                            <div style={{ display: "flex", justifyContent: "left" }} className="time-box">
                                            <input
                                            style={{ outline : "none" ,backgroundColor : "#F7F7F7" , border : "2px solid #DCDCDC" , color : "#7a7a7a" }}
                                            type="time"
                                            id="st"
                                            defaultValue={moment(fil.startTime).format('HH:mm')}
                                            onChange={(e) => this.startTimeRange(moment("1 January 2021 "+e.target.value+":00" ).format("x") , fil.filterId)}
                                            />
                                            <input
                                            style={{ outline : "none"  , marginLeft: "10px" , backgroundColor : "#F7F7F7" , border : "2px solid #DCDCDC" , color : "#7a7a7a" , }}
                                            type="time"
                                            id="et"
                                            // placeholder={infiler.filterId.endTime}
                                            defaultValue={moment(fil.endTime).format('HH:mm')}
                                            onChange={(e) => this.endTimeRange(moment("1 January 2021 "+e.target.value+":00" ).format("x") , fil.filterId)}
                                            />
                                            </div>
                                            </div>
                                            )}
                                            {fil.type === 7 && (
                                                <div>
                                                <h6 className="title-box" style={{marginBottom : "10px"}}>{fil.name} </h6>
                                                <Row style={{ padding: "0px 10px" , display : "flex" , justifyContent : "space-between" }}>
                                                <span>
                                                {this.getslider(fil.membersMinRange, fil.membersMaxRange, fil.filterId).membersMinRange}
                                                </span>
                                                <span >
                                                {this.getslider(fil.membersMinRange, fil.membersMaxRange, fil.filterId).membersMaxRange}
                                                </span>
                                                </Row>
                                                
                                                <Range
                                                key={index}
                                                step={1}
                                                defaultValue={[
                                                    fil.membersMinRange,
                                                    fil.membersMaxRange
                                                ]}
                                                min={fil.membersMinRange}
                                                max={fil.membersMaxRange}
                                                tipFormatter={(filtervalue) => `${filtervalue}`}
                                                onChange={(filtervalue) => {
                                                    this.rangeSlider(filtervalue , fil.filterId)
                                                }}

                                                /> 
                                                </div>
                                                )}
                                                 {fil.type === 8 && (
                                        <div>
                                        <h6 className="title-box" style={{marginBottom : "10px"}}>{fil.name} </h6>
                                        <div>
                                            <Select
                                            options={fil.singleSelect.map((option , index) => {
                                            return { label: option, value: option };
                                            })}
                                            onChange={(e) => this.SelectChange(e.value , fil.filterId)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            defaultValue={{ label: "Dubai", value: 'Dubai' }}
                                            /> 
                                        </div>
                                        </div>
                                        )}
                        </div>
                     ))
                    }
                    <div className="col-md-12" style={{textAlign :"center"}}>
                    <button  disabled={this.CheckValidation()} style={{backgroundColor  : this.CheckValidation() ? "#d0d0d0" : "#f47824" , color  :  this.CheckValidation() ? "#7a7a7a" : "#fff" , fontSize : "20px",  fontWeight : "bold" , textTransform : "capitalize" , borderRadius : "8px" , padding : "10px 50px" , margin : "5px 0px" }} onClick={this.UpdateFilter}><strong>let's create an Event</strong></button>
                    </div>
                </div>
                </div>
        )
    }
}

// 
const mapDispatchToProps = (dispatch) =>{
    return{
        UserEventId : (payload) =>{dispatch({type:"PERSONAL_DETAIL" , payload})},
      }
}

const mapStateToProps = (state)=>{
    return{
        userDetails : state.userDetailsReducer.userDetails,
    }
}

export default connect(mapStateToProps , mapDispatchToProps)(PopupHireProf);









