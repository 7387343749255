import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Row, Col, Container, Button } from "react-bootstrap";
import HeaderInner from "./HeaderInner";
import {Link} from 'react-router-dom';
import axios from "axios";
import {API_BASE_URL} from './../constant';
import {IoIosArrowBack} from 'react-icons/io'
import { confirmAlert } from 'react-confirm-alert'; 
import moment from 'moment';

class SaveDraft extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            fav : false,
            draft : [],
            uid:''
        }
    }

    
    componentDidMount(){
        this.uid = localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1';
            // saveDarft Api
            axios.get(`${API_BASE_URL}getDraft?userId=${this.uid}&limit=20`)
            .then(r => {
                this.setState({ draft : r.data.data.data})
            })
            .catch(e =>{
            console.log('save draft error' , e)
            })
    }
    //delete event
    deleteEvent = (eventId)=>{
        confirmAlert({
            title: '',
            message: 'Do you want to delete this event',
            buttons: [
              {
                label: 'Yes',
                onClick: () => { 
                    const header = {
                        'authorization': localStorage.getItem('user-auth-token')
                      } 
                    axios.post(`${API_BASE_URL}delete-event`,{uid:this.uid,eventID:eventId,status:0},{headers: header})
                    .then(r => { 
                        //window.location.reload();
                        this.cancelDraft(eventId)
                    })
                    .catch(e =>{
                    console.log('save draft error' , e)
                    })
                }
                }
              ,
              {
                label: 'Cancel',
               
              }
            ]
        });
    }

    cancelDraft = (objs) =>{
        let id ;
        if(typeof(objs) === "object"){
            id = objs._id
        } else {
            id = objs
        }
        
        let index = -1;
        var temp = [...this.state.draft];
        temp.forEach((item , i) =>{
            if(item._id.userEventId == id){
                index = i
            }
        })
        if(index > -1){
            temp.splice(index, 1);
            this.setState({ draft : temp });
        }
    }

    expiredMessage = ()=>{
        confirmAlert({
            title: '',
            message: 'Your event date is expired.Please create a new event',
            buttons: [
            {
                label: 'Ok', 
            },
            ]
        });
    }
    
    
    render(){
        return(
            <div className="save-draft">
                <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
                                <div className="inner-content-save detail-box"  style={{padding : "50px 0px"}}>
                                <div className="container">
                                <div style={{ marginBottom: "5px" , position:"relative"  , paddingBottom : "30px"}}>
                                    <Link className="remove_anchor bold_me" to="/create_event"  style={{display : "inline-block"  , fontSize : "12px", alignItems : "center" , position : "absolute" , left : "-5px"  , color : "#2E262C", opacity : "0.7"}}>
                                    <IoIosArrowBack />BACK</Link>
                                </div>
                                <Tabs>
                                <TabList>
                                <Tab>Drafts</Tab>
                                </TabList>
                                <TabPanel>
                                <div className="row">
                                {this.state.draft.length !=0 ? this.state.draft.map((ser,index)=>{
                                    return <div className="inner-serv-box col-md-6" key={index} style={{marginBottom : "30px" , maxWidth : "500px"}}>
                                    <div  className="Out-inf-box" style={{ border : "1px solid #DCDCDC" , display : "inline-block" , width : "100%"}}>
                                    {ser._id.eventDate >= new Date().setHours(12,0,0,0)?<Link to={`/${ser._id.eventType==1?'venuedetail':'professionallisting'}/${ser._id.eventTypeId}/${ser._id.userEventId}`} style={{cursor : "pointer"}}>
                                        <div className="inf-box">
                                            <div className="ext-serv">
                                             <h3 style={{color : "#000" , textTransform : "capitalize"}}>{ser._id.eventName}</h3>
                                             <h6 style={{color : "#000"}}>Event Date : {moment(ser._id.eventDate).format("DD-MM-YYYY")}</h6>
                                        </div>
                                        <div className="cov-img">     
                                            <img src={ser._id.eventTamplateData.image} style={{width : "100%" , height : "100%" , borderRadius : "6px"}}  />     
                                        </div>
                                        </div>
                                    </Link>:
                                    <Link to='#' onClick={()=> this.expiredMessage()}>
                                        <div className="inf-box">
                                            <div className="ext-serv">
                                             <h3 style={{color : "#000" , textTransform : "capitalize"}}>{ser._id.eventName}</h3>
                                             <h6 style={{color : "#000"}}>Event Date : {moment(ser._id.eventDate).format("DD-MM-YYYY")}</h6>
                                        </div>
                                        <div className="cov-img">     
                                            <img src={ser._id.eventTamplateData.image} style={{width : "100%" , height : "100%" , borderRadius : "6px"}}  />     
                                        </div>
                                        </div>
                                    </Link> }
                                    <div className="range-box">
                                        <p style={{marginBottom : "0px"}}><span>AED {ser._id.packagePrice}</span></p>
                                        <p style={{marginBottom : "0px",cursor : "pointer"}}><span onClick={()=>this.deleteEvent(ser._id.userEventId)}>Delete</span></p>
                                    </div>
                                    </div>
                            </div>
                            }) :'No Draft Found'}
                            </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

export default SaveDraft;
