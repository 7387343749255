import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import HeaderInner from "./HeaderInner";
import {API_BASE_URL, BASE_URL,VENDOR_API_BASE_URL} from './../constant';


export default function Payment_auth() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {    
    const Secret = new URLSearchParams(window.location.search).get("payment_intent");
    axios.post(`${API_BASE_URL}paymentIntent_retrieve`,{"paymentIntent_id":Secret})
      .then(res=>{
        if(res.data.data.status == 'requires_capture'){
          axios.post(`${API_BASE_URL}final-booking`,{"paymentToken":Secret})
          .then((finalBooking)=>{
            const notificationData = {
              slug:"New Booking From Customer Panel",
              slugId: finalBooking.data.data.userEventId,
              title:"Event booked",
              body:"A new booking is recevied or update",
              createdBy:finalBooking.data.data.uid,
              generatedForm:"CustomerPanel"
          }
          axios.post(`${VENDOR_API_BASE_URL}notification`,notificationData)
            .then((r)=>{
              window.location=`${BASE_URL}thankyou`
            })
            .catch(er=>{
                console.log('Notification Error');
                window.location=`${BASE_URL}thankyou`
            })
          window.location=`${BASE_URL}thankyou`
          })
          .catch(err=>{
            console.log(err)
          })
        }
        else{
          window.location=`${BASE_URL}paymentFail`
        }
        
    })
  }, []);
  return (
    <div className="save-draft">
    <HeaderInner  />
    <div style={{ textAlign: 'center',height:'227px',marginTop:'230px' }}>
    <CircularProgress disableShrink />
    <p><b style={{color:'black'}}>Do not press back or refresh button</b></p>
    </div>
    </div>
  )
}