import { useState } from 'react';
import {Container,Nav,Navbar} from 'react-bootstrap';
import { FaAlignLeft ,FaFacebookF,FaInstagram,FaLinkedinIn, FaWhatsapp} from 'react-icons/fa';
import './style.css';
function NavBar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false)  
  return (
    <div>
      <Navbar style={{backgroundColor:'#000'}} expand="lg">
      <Container>
        <Navbar.Brand href="/"> <img src={'./v2Images/evenstan_icon.png'} className="brand-name" alt="eventStan Logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/about-us">About us</Nav.Link>
            <Nav.Link href="/partner">Partner with us</Nav.Link>
            <Nav.Link href="/#contact-form-section">Contact us</Nav.Link>
            <Nav.Link href="https://blog.eventstan.com/" target="_blank">Blog</Nav.Link>
            <div className='social-media'  style={{color:'#f47824'}}>
          <ul>      
            <li><a href="https://www.facebook.com/EVENTSTAN/" target="_blank"><FaFacebookF size={20} className="social-media-list"/></a></li>
            <li><a href="https://www.instagram.com/eventstan.official/" target="_blank"><FaInstagram size={20} className="social-media-list"/></a></li>
            <li><a href="https://www.linkedin.com/company/eventstan/" target="_blank"><FaLinkedinIn size={20} className="social-media-list"/></a></li>
            <li><a href="https://api.whatsapp.com/send?phone=+971566405353&text=Hi" target="_blank"><FaWhatsapp size={20} className="social-media-list"/></a></li>
          </ul>
        </div> 
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}
export default NavBar;