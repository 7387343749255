import React, { useState,useEffect } from 'react'; 
import backImage from "./assets/golden-pg2.png";
import amazonImage from "./assets/amazonIcon.jpg";
import {VENDOR_API_BASE_URL } from "./constant";
import { useParams } from 'react-router-dom';
import axios from "axios";
import moment from 'moment';

const myhtml = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

const mybody = {
    background: 'DodgerBlue',
    color: '#666',
    fontWeight:'300',
    lineHeight: 'normal',
    color: "#181818",
    padding: "10px",
    fontFamily: "Arial"
};

const myprint = {
    lineHeight: '24px',
    fontSize: '15px',
    fontWeight: '600',
    color: '#333',
    backgroundColor: '#e6e6e6',
    borderRadius: '50px',
    padding: '10px 20px',
    display: 'block',
    textAlign: 'center',
    margin: '40px auto',
    maxWidth: '250px',
    transition: '.3s',
    textDecoration: 'none !important',
    outline: 'none!important'
};

const myinvitation = {
    background: 'url('+backImage+') #ffffff',
    width: 'auto',
    maxWidth: '602px',
    padding: '0px',
    margin: '0 auto 60px',
    borderRadius: '10px'
};

const InvitationCard = () => {
    const [eventType,setEventType]=useState();
    const [eventName,setEventName]=useState();
    const [Edate,setEdate]=useState();
    const [Etime,setEtime]=useState();
    const [Elocation,setElocation]=useState();
    const [userAddress,setuserAddress]=useState([]);
    const params = useParams()

    useEffect(() => {
       axios.get(`${VENDOR_API_BASE_URL}booking/${params.userEventId}/services`)
       .then(res=>{
        setEventType(res.data.results.eventTypes);
        setEventName(res.data.results.eventName);
        setEdate(res.data.results.booking.date);
        setEtime(res.data.results.booking.start_time);
        setElocation(res.data.results.booking.venueAddress);
        setuserAddress(res.data.results.user_.bill_add);
        
       })
       .catch(err=>{
           console.log(err);
       })
      },[]);

    return (
		<div id="body" style={mybody}>
		    <a href="javascript:window.print()" style={myprint}>Print this Invitation Card</a>	
            <div id="invitation" style={myinvitation}>
	            <div className="row">
			        <br/><br/><br/><br/>
		        </div>
	            <div className="row">
                    <br/><br/><br/>
		            <div className="col-md-12" style={{paddingTop: '50px', textAlign: 'center'}}>
			            <span style={{textAlign: 'center', color: '#181818', fontSize: '20px', }}>You are cordially invited <br/>to the event of </span>
		            </div>
                    <div className="col-md-12" style={{paddingTop: '50px'}}>
			            <h1 style={{fontWeight:'600', textAlign: 'center', color: '#F87A25'}}>{eventName}</h1>
		            </div>
                    <br/>
                    <div className="col-md-12" style={{textAlign: 'center', paddingTop: '50px',}}>
			            <span style={{textAlign: 'center'}}><strong>Date : {Edate}</strong></span><br/>
                        <span style={{textAlign: 'center'}}><strong>Time : {Etime}</strong></span><br/>
                        { Elocation !=undefined ? <span style={{textAlign: 'center'}}><strong>Location : {Elocation } </strong></span>:
                        <>
                        <span style={{textAlign: 'center'}}><strong>Address : {userAddress.address } </strong></span> <br/>
                        <span style={{textAlign: 'center'}}><strong> City : {userAddress.city } </strong></span><br/>
                        <span style={{textAlign: 'center'}}><strong> Country : {userAddress.country } </strong></span><br/>
                        <span style={{textAlign: 'center'}}><strong> Zip : {userAddress.zip } </strong></span>
                        </>
                        }
		            </div>
		            <br/><br/><br/><br/>
	            </div>
	            <div className="row">
		            <div className="col-md-12" style={{textAlign: 'center', paddingTop: '70px'}}>
                    <span style={{textAlign: 'center'}}><img src={amazonImage} /></span><br/>
			        <a href="https://eventstan.com"><span style={{textAlign: 'center'}}>eventstan.com</span></a>
		        </div>
	        </div>		
        </div>
    </div> 
    );
}
export default InvitationCard;