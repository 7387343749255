import React, { useState,useEffect } from 'react';
import MailHeader from "./MailHeader";
import MailFooter from "./MailFooter";
import LogoPic from './assets/eventstan.png';
import envelop from './assets/envelope.png';

const Mailer = () => {
    return ( 
        <div id="mailer">
        <table border="0" width="100%" cellpadding="0" cellspacing="0">
        <tbody><tr>
            <td width="100%" align="center" valign="top" bgcolor="#ddd" >
				<table>
					<tbody>
						<tr><td class="top_mar" height="50"></td></tr>
					</tbody>
				</table>
				{/* main content */}
				<table style={{"boxshadow": '#E0E0E0'}} width="800" border="0" cellpadding="0" cellspacing="0" align="center" class="top-header-left">	
					<tbody><tr bgcolor="ffffff">
						<td>
							<MailHeader/>
						</td>
					</tr>
					<tr bgcolor="ffffff">
						<td>
						<table border="0" width="650" align="center" cellpadding="0" cellspacing="0" class="container-middle">
								<tbody>
									{/*padding-top */}
									<tr>
										<td class="ser_pad" height="70"></td>
									</tr>
									{/*padding-top */}
									<tr>
										<td class="wel_text" align="center" style={{"fontSize": '2.5em', "color": '#000',"fontFamily": 'Candara', "textAlign": 'center', "fontWeight": '600'}}> 
											Hi, User
										</td>
									</tr>
									<tr>
										<td height="15"></td>
									</tr>
									<tr>
										<td class="ser_text" align="left" style={{"color": '#464646', "fontSize": '1.2em', "fontFamily": 'Candara', "lineHeight": '1.8em'}}>
											Welcome to <a href="https://eventstan.com/">Eventstan.com</a>, your account has been created with below details:
										</td>
									</tr>
									
									
									<tr>
										<td>
										<table class="twelve_three" width="650" align="left" border="0" cellpadding="0" cellspacing="0" style={{"borderCollapse": 'collapse', "msoTableLspace": '0pt', "msoTableRspace": '0pt'}}>
												<tbody>
													
													<tr>
														<td height="15">&nbsp;</td>
													</tr>

													<tr>
														<td class="ser_text2" style={{"textAlign": 'left',"color": '#000',"fontSize": '1.3em',"fontFamily": 'Candara',"fontWeight": '500'}}><b>Name :-</b>  User</td>
													</tr>
													<tr>
														<td class="ser_text2" style={{"textAlign": 'left',"color": '#000',"fontSize": '1.3em',"fontFamily": 'Candara',"fontWeight": '500'}}><b>Contact :-</b>  +00000000</td>
													</tr>
													<tr>
														<td class="ser_text2" style={{"textAlign": 'left',"color": '#000',"fontSize": '1.3em',"fontFamily": 'Candara',"fontWeight": '500'}}><b>Email :-</b>   abcd@xyz.com</td>
													</tr>
													<tr>
														<td height="15">&nbsp;</td>
													</tr>
													<tr>
														<td class="ser_text" align="left" style={{"color": '#464646', "fontSize": '1.2em', "fontFamily": 'Candara', "lineHeight": '1.8em'}}>
															Please verify your mobile no and email Id, to start broker free journey .<br/>
															Verification link : <a href="http://eventstan.com/verification">http://eventstan.com/verification</a>
														</td>
													</tr>
													<tr>
														<td height="15">&nbsp;</td>
													</tr>
													<tr>
														<td class="ser_text" align="left" style={{"color": '#464646', "fontSize": '1.2em', "fontFamily": 'Candara', "lineHeight": '1.8em'}}>
															Thanks <br/>
															The Eventstan Team
														</td>
													</tr>

													<tr>
														<td height="15">&nbsp;</td>
													</tr>

									
													<tr>
														<td height="15">&nbsp;</td>
													</tr>
												
												</tbody>
											</table>
											
											
										</td>
									</tr>
									
									<tr>
										<td height="20">&nbsp;</td>
									</tr>
								</tbody>
							</table>
							</td>
					</tr>
										<tr>
						<td>
							<table align="center" cellpadding="0" cellspacing="0" style={{"borderBottom": '1px solid #f7f7f7' }}>
							</table>
						</td>
					</tr>
					<tr bgcolor="#8489b2">
						<td>
						{}
						<table class="us_on" border="0" width="500" align="center" cellpadding="0" cellspacing="0">
								<tbody><tr><td height="20"></td></tr>
								<tr>
									<td>
										
										<MailFooter/>
									</td>
								</tr>
								<tr><td height="20"></td></tr>
							</tbody></table>
							</td>
					</tr>
					<tr bgcolor="ffffff">
						<td>	
						<table border="0" width="680" align="center" cellpadding="0" cellspacing="0" class="container-middle">
								<tbody><tr>
									<td height="10" style={{"fontSize": '1px', "lineHeight": '10px'}}>&nbsp;</td>
								</tr>
									
									<tr>
										<td>

											<table class="foo" width="650" border="0" align="left" cellpadding="0" cellspacing="0">
												<tbody><tr>
													<td class="ser_text editable" style={{"fontfamily": 'Candara', "fontSize": '1em', "lineHeight": '24px', "textAlign": 'center'}}>
														© 2021 Eventstan. All Rights Reserved
													</td>
												</tr>
											</tbody></table>

											{/*SPACE*/} 
											<table width="1" height="10" border="0" cellpadding="0" cellspacing="0" align="left">
												<tbody><tr>
													<td height="10" style={{"borderCollapse": 'collapse',"paddingLeft": '24px'}}>
														&nbsp;
													</td>
												</tr>
											</tbody></table>
											{/*END SPACE*/} 
										</td>
									</tr>
									
									<tr>
										<td height="10" style={{"fontSize": '1px', "lineHeight": '10px'}}>&nbsp;</td>
									</tr>

							</tbody></table>
						</td>
					</tr>
				</tbody>
				</table>
				<table>
					<tbody><tr><td class="top_mar" height="50"></td></tr>
				</tbody></table>
			</td>
		</tr>
	</tbody></table>
        </div>
    );
}
export default Mailer;