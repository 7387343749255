import React, { Component } from "react";
import './App.css';
import HeaderInner from "./components/HeaderInner";
import {IoIosArrowBack} from 'react-icons/io';



class CookiePolicy extends Component {
   componentDidMount(){
      window.scrollTo(0, 0);
   }
    render(){
        return (
           <>
         <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
            <div className="container">
			<div className="row">
               <div className="col-md-12 col-sm-12 col-xs-12">
                  {/* <div className="back-btn">
					      <br />
                     <a className="text-dark"href="#"><IoIosArrowBack/> Back</a>
                  </div> */}
               </div>
            </div>
            <section className="application_sec">	
                <div className="row">
                    {/* <div className="col-md-3 col-sm-3 col-xs-3">
                    <LeftMenu />
                  </div> */}
               <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="form_card form_blk">  
                     
                     <div class="row">
                        <div class="col-md-12">
                           <div className="form-group">
                              <h5>Cookie Policy</h5>
							  <hr/>
                           </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'justify'}}>
                        <p>This &ldquo;Cookies Notice&rdquo; (the &ldquo;Notice&rdquo;) explains how and why  Eventstan-Fze , DTech ,Technohub 1, Dubai, United Arab Emirates (also referred to as  &ldquo;Eventstan&rdquo; &ldquo;we&rdquo;, &ldquo;our&rdquo; and &ldquo;us&rdquo;) will use different types of cookies and  similar technologies on any websites or apps (the &ldquo;Site&rdquo;) that we own or  control. This Notice also explains how you can manage cookies and similar  technologies.</p>
<p>This Notice was last updated: December 2021</p>
<p>We keep this Notice under regular review and may update it at any  time. Please take a look at the &quot;Last Updated&quot; section at the top of  this Notice to see when it was last changed. Any changes in this Notice will  become effective when we make the revised Notice available on or through the  Site. We also encourage you to check this Notice on a regular basis. We will  notify you of any changes (including when they will take effect) if we are  required to do so by data protection or e-privacy laws.</p>
<p><strong>What Are Cookies?</strong></p>
<p>When you visit our website, one or more &ldquo;cookies&rdquo; are generated  and deployed with your consent, if required.</p>
<p>Cookies are files or pieces of information that are stored on your  computer (or other internet-enabled devices, such as a smartphone or tablet)  when you visit the Site. A cookie will usually contain the name of the website  from which the cookie has come from, the &quot;lifetime&quot; of the cookie  (i.e. how long it will remain on your device) and a value, which is usually a  randomly generated unique number.</p>
<p>We use cookies to make the Site easier to use and to better tailor  our web presence and products to your interests and needs. Cookies are also  used to help speed up your future activities and deliver a more personalised  experience on the Site.</p>
<p>Some cookies may process your personal data (e.g. IP addresses and  other online identifiers). Please see Eventstan Privacy Notice for further  details about how your personal data is processed and your rights in relation  to this.</p>
<p>Other cookies are used to compile anonymous, aggregated statistics  that allow us to understand how people use our web properties and to help us  improve web structure and content. We cannot identify you personally from this  information although the same principles apply regarding these cookies</p>
<p><strong>Categories of Cookies Used on The  Site:</strong></p>
<p>Two types of cookies will be used on the Site, &quot;session  cookies&quot; and &quot;persistent cookies&quot;. Session cookies are temporary  cookies that remain on your device until you leave the Site. A persistent  cookie remains on your device for much longer or until you manually delete it  (how long the cookie remains on your device will depend on the duration or  &quot;lifetime&quot; of the specific cookie and your browser settings).</p>
<p>We have grouped our cookies into the following categories, to make  it easier for you to understand why we use them:</p>
<p>Essential/Necessary: Cookies that are essential to making the Site  work correctly. They enable visitors to move around our Site and use our  features. These cookies prevent crashes, display information, fix bugs and  ensure the security of our website. Without these cookies, some functions of  the website cannot be provided. These cookies do not identify you as an  individual.</p>
<p>Functional: Cookies that allow our web properties to &lsquo;remember&rsquo;  the choices you make (such as your username, language or the region you are in)  to provide a more personalised online experience. If you do not accept these  cookies, it may affect Site performance and functionality and may restrict  access to web content.</p>
<p>Performance/Analytical: Cookies that help us understand how  visitors interact with our web properties by providing information about the  areas visited, the time spent on the Site and any issues encountered, such as  error messages. They help us improve the performance of our Site, alert us to  any concerns and more. They tell us how popular a certain feature is, or  whether you open an email we send. These cookies don&rsquo;t identify you as an  individual, unless stated otherwise.</p>
<p>Targeting/Marketing: These cookies are used to deliver adverts  more relevant to your interests. They are also used to limit the number of  times you see an advertisement as well as help measure the effectiveness of  advertising campaigns. They are usually placed by advertising networks with the  website operator&rsquo;s permission and can be used to remember that you have visited  a website. The information gathered by these cookies is often shared with other  organisations such as advertisers.</p>
<p><strong>Managing Cookies on Your Device</strong></p>
<p>You can manage which optional cookies are deployed on your device  at any time. To do this you can set browser settings accordingly.&nbsp; Guidance on how to control cookies for common  browsers are linked below.</p>
<p>Google Chrome<br />
  Mozilla Firefox<br />
  MacOS Safari<br />
  Microsoft Internet Explorer<br />
  You can also opt out of Google Analytics across all websites by  clicking <a href='http://tools.google.com/dlpage/gaoptout' target='blank'>http://tools.google.com/dlpage/gaoptout</a> . For information on  additional browsers and device types please see <a href='http://www.aboutcookies.org/' target='blank'>http://www.aboutcookies.org/</a> or  <a href='http://www.cookiecentral.com/faq/' target='blank'>http://www.cookiecentral.com/faq/</a>.<br />
  Please be aware that if you disable the cookies that we use, this  may impact your user experience while using the Site.<br />
  <strong>Cookies on this Site</strong></p>
<p>The table below summarises the different types of cookies we use  on the Site, together with their respective purpose and whether their use  involve the transfer of information to a third party:</p>
                          </div>
                </div>       
					</div>
				</div>
			</div>
      </section>
	</div> 
   </>);
    }
}

export default CookiePolicy;