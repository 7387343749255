import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
var firebaseConfig = {
    apiKey: "AIzaSyCtrNH9rsqnZwRBnuTtg-meq8BT736dMr0",
    authDomain: "eventstan-335707.firebaseapp.com",
    databaseURL: "https://eventstan-335707-default-rtdb.firebaseio.com",
    projectId: "eventstan-335707",
    storageBucket: "eventstan-335707.appspot.com",
    messagingSenderId: "908075444068",
    appId: "1:908075444068:web:fd2b65901ffb9e63283c71"
  };
  // Initialize Firebase

  const firebase = initializeApp(firebaseConfig);
  const messaging = getMessaging();
  export const requestForToken  = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BJ8ciXfRg1yfbtD0f5iVrHkjvaKaZ-oFXATyA4Xfr1Qr7h2xUSn_AiNSk6jZQPMZVcKqyyB__k7K9YoZMYCxmls'}).then((currentToken) => {
      if (currentToken) {
        //console.log('current token for client: ', currentToken);
        localStorage.setItem("user-notification-token", currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });
  export default firebase;