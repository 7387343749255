import { useState } from 'react';
import './style.css';
import './style2.css';
import NavBar from "./NavBar";
function UltimateEvent() {

  return (
    <>
     
<NavBar />
    <div className="header" style={{paddingLeft:'20px'}}>
                <div className="container"style={{paddingLeft:'0'}}>
                    <div className='header-left-element'>
                    &#8220;We Facilitate, You Create&#8221;
                    </div>
                    <div className="centered-element">
                    <img src={'./v2Images/Logo.png'} />
                    </div>
                    {/* <div className="header-right-element">
                        Login/Register
                    </div> */}
                </div>
            </div>
        <div className='about'>
            <div className="about-inner">
                <div className='container'>
                    <div className='blog2-text'>
                    <h4>The Ultimate Guide to Party Planning</h4>
                    <p>So, you’ve decided to host a party and don’t know where to start? Well,
you are just like the rest of us then and you’ve come to the right place.
Planning a party can very quickly get very stressful and confusing to
work on. Don’t worry, here’s a step-by-step guide to party planning that
can work as a checklist too with all the must-do things before, during and
after your party to have an amazing and relaxed time even as the party
host.</p>   
<img src={'./v2Images/ultimate-blog.jpg'} style={{width:'100%'}}/>
                    <h3>Before the party:</h3>
                    <p>•	<b>When’s your party?</b> – First things first, one of the most important
steps of planning a party is to make sure to select a date that is
convenient for most of your party guests.</p>
                    <p>•	<b>Who’ll make it into the guest list?</b> - The next thing to do is to
write out a list of all the attendees you plan to invite. It would be
beneficial to keep a rough mental budget in mind so that you don’t
go overboard with your guest list.</p>
                    <p><img src={'./v2Images/ultimate-blog22.jpg'}  style={{ float:'left', paddingRight:'10px'}}/>•	<b>Have a theme in mind?</b> - Your theme for the party will depend on
your occasion and your party invitees,
which also sets the tone of the party. For
example, you may decide to have a
pajama themed Christmas party if your
guests mainly include friends or family.
However, if it also involves your
professional circle you might want to go
with a slightly more formal theme like an
awards night.</p>
                    <p>•	<b>How much can your wallet take?</b> - Now that you know the approximate number of attendees for your event and have decided on a theme it’s time to set a budget range for your party. Don’t forget to keep aside some amount as a buffer for any extra/ unexpected expenses.</p>
                    <p>•	<b>Get your invitations out there</b> - Once your budget is fixed and you have filtered out or added any extra guests to your list, it’s time to roll out the invitations. Get creative here and try designing your invitation card to suit your party theme.</p>
                    <p>•	<b>Time to delve into details</b> - Now starts the challenging and tedious part, searching for venues and relevant service providers for catering, décor, entertainment, etc. Well, here I would suggest using EventStan to find all your vendors under one roof. You can view prices, availability, reviews & ratings whilst booking all your services providers through the app. It really is so simple.</p>
                    <p>•	<b>Come up with an awesome playlist</b> - Set the mood for your party by coming up with a solid playlist to keep the party going through the night. Make sure you either own or rent some good audio equipment rather than play your music through a single small Bluetooth speaker.</p>
                    <p>•	<b>An extra pair of hands never hurt</b> - Being the sole host of any event can be burdensome and stressful so be sure to line up some extra help such as friends and family or hire staff to assist you on the special day.</p>
                    <p><img src={'./v2Images/ultimate-blog23.jpg'}  style={{ float:'right', paddingleft:'10px'}}/>•	<b>Be considerate to your neighbors</b>- If you’re holding the party at your own place be sure to notify your neighbors so that they can make other plans to hang out outside for the duration of the party if they wish to.
                        </p>
                    <p>•	<b>Finish your last shopping run</b>- Make sure you finish any last-minute grocery errands at least a day before the party. If you’re looking to give out party favors, EventStan’s gifting feature can help you quickly find unique and beautiful hampers.</p>
                    <h3>On the day of the party:</h3>
                    <p>•	<b>Get all the delicacies out there</b>- When the caterers arrive, ensure that there is ample space to place the food and condiments. 
                    </p>
                    <p>•	<b>It’s time!</b> - Greet your guests at the door to make them feel more welcome and special. You can also introduce them to other party guests to get the conversation going.
                    </p>
                    <p>•	<b>Have a great time</b>- Being the host of the party does not mean that you don’t get to have just as much fun as everybody else. Relax and enjoy the efforts that you put in to create this amazing party.
                    </p>
                    <h3>After the party:</h3>
                    <p><img src={'./v2Images/ultimate-blog24.jpg'}  style={{ float:'left', paddingRight:'10px'}}/>•	<b>Farewell greetings</b>- Thank your guests for attending your party and let them know that you’re always so happy to see them. 
                    </p>
                    <p>•	<b>Clean up</b>- Call in your cleaning crew if you have arranged one or call in your close family or friends to help you clean up.
                    </p>
                    <p>•	<b>Captured your memories?</b> - If you recorded the event by yourself, check your camera to make sure the footage has been saved, or if you had arranged a photographer, discuss any arrangement as to when your photos will be received.
                    </p>And that’s it, it’s a wrap! Hosting a party is for sure a lot of work, but with the right approach and method, it can become a piece of cake and soon you’ll be counting down the days to your next event. At the end, it can be said that there’s a lot to be done but doesn’t it just feel great when it all turns out just right on the day? 
                
                            </div>
                    </div>
                </div>
            </div>  
    </>
  );
}
export default UltimateEvent;
