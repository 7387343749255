import React, { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Link} from 'react-router-dom';
import axios from 'axios';
import './style.css';
import NavBar from "./NavBar";
import ContactUs from "./ContactUs";
import jspriceType from "./../context/priceType";
import {API_BASE_URL} from './../constant';


const bannerConfig = {
    loop: true,
    margin: 0,
    items: 1,
    autoplay: true,
    nav: false,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
};

const companyLogosConfig = {
    loop: true,
    // margin: 10,
    items: 7,
    autoplay: true,
    rewind: true,
    nav: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
        //breakpoint from 0 and up
        0: {
            items: 3,
        },
        // add as many breakpoints as desired , breakpoint from 480 up
        480: {
            items: 3,
        },
        // breakpoint from 768 up
        768: {
            items: 4,
        },
        992: {
            items: 7,
        },
    }
}

const tabsConfig = {
    loop: true,
    //   margin: 10,
    items: 5,
    autoplay: true,
    rewind: true,
    nav: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
        //breakpoint from 0 and up
        0: {
            items: 1,
        },
        // add as many breakpoints as desired , breakpoint from 480 up
        480: {
            items: 2,
        },
        // breakpoint from 768 up
        768: {
            items: 3,
        },
        992: {
            items: 5,
        },
    }
};

const HomeVersion2 = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [popDisplay, setPopDisplay] = useState('');
    const [topServices, setTopServices] = useState([]);
    const [banners, setBanners] = useState([
        './v2Images/banner1.jpg',
        './v2Images/banner2.jpg',
        './v2Images/banner3.jpg',
        './v2Images/banner4.jpg',
        './v2Images/banner5.jpg',
        './v2Images/banner6.jpg'
    ])
    const [companies, setCompanies] = useState([
        './v2Images/com_logos/1.png',
        './v2Images/com_logos/2.png',
        './v2Images/com_logos/3.png',
        './v2Images/com_logos/4.png',
        './v2Images/com_logos/5.png',
        './v2Images/com_logos/6.png',
        './v2Images/com_logos/7.png',
        './v2Images/com_logos/8.png',
    ])

    const getTopServices = async () => {
        const res = await axios.get(`${API_BASE_URL}getTopServices`);
        setTopServices(res?.data?.data?.data || []);
    }

    useEffect(() => {
        getTopServices()
    }, []);

    return (
        <>
            <div className="PopHom" style={{display:popDisplay}}> 
            <b>See EventStan in ...</b>
             <div className="row">
             <div className="sp-2"> EventStan App</div>
                
             <div className="sp-3">
                    <div className="btn-pop"><a href="https://eventstan.onelink.me/23Lw/6hr3aj5n"><button>Open</button></a></div>
            </div>
                
            </div>
            <div className="row">
                <div className="sp-2">Browser</div>
                <div className="sp-3">
                <div className="btn-pop"><button onClick={()=>{setPopDisplay('none')}}>Continue</button></div>
                </div>
            </div>
            </div>
            <div className="XPromoPopupRplNew__overlay" style={{display:popDisplay}}></div>
            <div className="hompage">
            <NavBar />
            <div className="header">
                <div className="container">
                    <div className='header-left-element'>
                    &#8220;We Facilitate, You Create&#8221;
                    </div>
                    <div className="centered-element">
                        <img src={'./v2Images/Logo.png'} alt="eventStan"/>
                    </div>
                    <Link to="/login"><div className="header-right-element" style={{  cursor: "pointer"}} >
                        Login/Register
                    </div></Link>
                </div>
            </div>

            <div className="banner">
                <div className="banner-inner">
                    <OwlCarousel className="owl-theme" {...bannerConfig}>
                        {banners?.map((banner) => (
                            <div className="item" id="banner-box">
                                <img src={banner} alt="eventStan" />
                            </div>
                        ))}
                    </OwlCarousel>
                    <div className="btn-grp">
                        <Link to="/create_event"><button > <img src={'./v2Images/Create-Event.png'} alt="create an event" />Create an Event</button></Link>
                        <Link to="/create_pro_event"><button style={{ marginLeft: '15px' }} > <img src={'./v2Images/Hire-Professional.png'} alt="Hire-Professional" />Hire a Professional</button></Link>
                    </div>
                </div>
            </div>

            <section className="logo-slider">
                <div className="container">
                    <OwlCarousel className="owl-theme" {...companyLogosConfig}>
                        {companies?.map((company) => (
                            <div className="item" id="logo-box">
                                <img src={company} alt="our partners" />
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            
            <div className="venue-slider">
                <div className="container">
                <div>
                     <h4 style={{ textAlign:'center', fontWeight: '800', textDecoration:'underline', paddingTop:'2px'}}>Trending Services</h4>
                </div>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList className="tab-all">
                            {topServices
                                .map((item, index) => (
                                    <Tab> {item?._id?.serviveName}</Tab>
                                ))
                            }
                        </TabList>
                        {topServices?.map((item, index) => (
                            <TabPanel>
                                <OwlCarousel className="owl-theme" {...tabsConfig}>
                                    {item?.vendorServices?.map((services,i) => (
                                        <Link to="/create_event"><div className="item" id="image-box" key={i}>
                                            <img className="img" src={services?.coverImage || './v2Images/Eventstan-dummy-2.jpg'} alt={services?.title} />
                                            <span title={services?.title} className="price-blk" style={{color:'black'}}><strong>{services?.title.substring(0,20)}</strong></span><br/><span className="price-blk2" style={{color:'black'}}><b>AED </b>{services?.price}/{jspriceType[services?.priceType]}</span>
                                        </div></Link>
                                    ))}
                                </OwlCarousel>
                            </TabPanel>
                        ))
                        }
                    </Tabs>
                </div>
            </div>

            <div className="who-we-are" id="how-does-it-work">
                <div className="design-top">
                    <img src={'./v2Images/purple-wave-top.svg'} alt="how eventStan work" />
                </div>
                <div className="who-we-are-inner">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="centered-text-homepage">
                                    <h4>How does it work?</h4>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="inner-left">
                                    <p>We at EventStan bring you a platform to plan and book your events all in one place just at your fingertips. Simply find venues, caterers, decorators, entertainment and gifting options, choose based on pricing, availability, reviews   &amp; ratings and book your event in minutes. </p>
                                    <p>Planning has never been this simple!</p>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            {/* <div className="col-md-5">
                                <div className="inner-right">
                                    <img src={'./v2Images/room_1.png'} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="design-bottom">
                    <img src={'./v2Images/purple-wave-bottom.svg'} alt="How does it work" />
                </div>
            </div>
            <ContactUs />
            <div className="google-app" id="download-app">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div id="middle-text">
                                <h4>Download the EventStan app NOW!</h4>
                                <div className="custom-flex">
                                    <a href="https://play.google.com/store/apps/details?id=com.mobile.eventstan" target="_blank"> <img src={'./v2Images/google-playstore.png'} alt="eventStan Apple store"/></a>
                                    <a href="https://apps.apple.com/ae/app/eventstan/id1638510267" target="_blank">  <img src={'./v2Images/google-android.png'} alt="eventStan play store"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="mobile_image"><img src={'./v2Images/Phone_evenstan.png'} alt="evenStan mobile app" /></div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )

}
export default HomeVersion2