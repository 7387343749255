import React, { Component } from "react";
import HeaderInner from "./userPanel/HeaderUser";
import { API_BASE_URL } from '../../src/constant';
import { Redirect,Link } from "react-router-dom";
import axios from "axios";

class ForgetPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            loginUsername:'', 
            mobile:'none',
            forgetDiv:"",
            otpDiv:"none",
            changePasswordDiv:"none",
            otp:'',
            forgetError:'',
            otpError:'',
            newPassword:'',
            passwordError:'none',
            error:'',
            logbtn:'none',
            chPassbtn:''
        }
    }

    isNumber(n) {
         return /^-?[\d.]+(?:e-?\d+)?$/.test(n); 
    }

    ValidatePassword(pass)
    {
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(pass))
        {
            return true
        }
        else{
            return false
        }   
    }
    
    ValidateEmail(mail) 
    {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
        return true
    }
    else{
        return false
    }
    }

    changeOption = () =>{
        this.setState({forgetDiv:'',otpDiv:'none',changePasswordDiv:'none'})
    }

    //request for forget password
    myChangeHandler = (event) => {
        this.setState({[event.target.name]: event.target.value});
        if(this.isNumber(event.target.value)){
            this.setState({mobile:''})
        }
        else
        {
            this.setState({mobile:'none'})  
        }
    }

    requestForgetpassword = () =>{
        const emailOrPhoneNumber = this.state.loginUsername;
        if(emailOrPhoneNumber =='' || emailOrPhoneNumber == null)
        {
            this.setState({forgetError:'Please enter email or mobile number'})
            return false;
        }
        else
        {
            if(this.isNumber(emailOrPhoneNumber)){
                if(emailOrPhoneNumber.length != 9){
                    this.setState({forgetError:'Invalid Mobile Number'});
                }
                else{
                    axios.post(`${API_BASE_URL}forgetPassword`,{emailOrPhoneNumber:emailOrPhoneNumber,deviceType:'WEB'})
                    .then(res=>{
                        localStorage.setItem("user-forget-token", res.data.data.accessToken);
                        this.setState({forgetDiv:'none',otpDiv:'',changePasswordDiv:'none'})
                        this.setState({otpError:'OTP sent successfully'})
                    })
                    .catch(err=>{
                        this.setState({forgetError:'Mobile number does not exist'});
                    })
                }
            }
            else{
                if(!this.ValidateEmail(emailOrPhoneNumber)){
                    this.setState({forgetError:'Invalid email address'});
                }
                else{
                    axios.post(`${API_BASE_URL}forgetPassword`,{emailOrPhoneNumber:emailOrPhoneNumber,deviceType:'WEB'})
                    .then(res=>{
                        localStorage.setItem("user-forget-token", res.data.data.accessToken);
                        this.setState({forgetDiv:'none',otpDiv:'',changePasswordDiv:'none'})
                        this.setState({otpError:'OTP sent successfully'})
                    })
                    .catch(err=>{
                        this.setState({forgetError:'Email address does not exist'});
                    })
                } 
            }
        } 
    }

    //verify otp
    verfyOTPRequest = ()=>{
        const otp = this.state.otp;
        if(otp =='' || otp == null){
            this.setState({otpError:'Please enter OTP'});
        }
        else{
            const type = this.isNumber(this.state.loginUsername) ? 'PHONE' : 'EMAIL' ;
            const header = {
                'authorization': localStorage.getItem('user-forget-token')
            }
            axios.post(`${API_BASE_URL}verifyOtp`,{otp:otp,type:type},{headers: header})
            .then(res=>{
                this.setState({forgetDiv:'none',otpDiv:'none',changePasswordDiv:''})   
            })
            .catch(err=>{
                this.setState({otpError:'OTP does not Match'});
            })
        } 
    }

    //change password
    changePassword = () =>{
        const newPassword = this.state.newPassword;
        if(newPassword == '' || newPassword==null){
            this.setState({error:'Please enter new password'});
        }
        else{
            if(!this.ValidatePassword(newPassword)){
                this.setState({passwordError:''}); 
            }
            else{
                this.setState({passwordError:'none'});
                const header = {
                    'authorization': localStorage.getItem('user-forget-token')
                }
                axios.post(`${API_BASE_URL}updatePassword`,{password:newPassword},{headers: header})
                .then(res=>{
                    this.setState({error:'Password change successfully! Please Login',newPassword:'',logbtn:'',chPassbtn:'none'})
                    localStorage.removeItem("user-forget-token");
                })
                .catch(err=>{
                    this.setState({error:'Somthing wrong! Try again'});
                })
            }
        }
    }


    
    render(){ 
        if (localStorage.getItem('user-auth-token') != '') {
            return <Redirect to='create_event' />
          }    
return (
<>
<HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
<div className="container">
    <section className="application_sec">	
        <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form_card form_blk">  
                     <h3>Forget Password</h3>
                     <div class="card-body p-t-20"  style={{display:this.state.forgetDiv}}>
	   					<div className="row">
		  					<div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label for="exampleFormControlInput1">Enter Email/Mobile number</label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend" style={{display:this.state.mobile}}>
                                           <div className="input-group-text">+971</div>
                                        </div>
                                        <input 
                                        type="text"
                                        className="form-control" 
                                        value={this.state.loginUsername} 
                                        name="loginUsername" 
                                        onChange={this.myChangeHandler} 
                                        />
                                    </div>
                                </div>
		  					</div>
	   					</div>
                        <span style={{color:'red'}}>{this.state.forgetError}</span>
                        <div className="row">
		  					<div className="col-md-12 col-sm-12 col-xs-12">
                                <button type="submit" className="btn btn-primary" onClick={this.requestForgetpassword}>Submit</button> 
                            </div>
                        </div>
                    </div>
                    {/* OTP Div*/}
                    <div class="card-body p-t-20" style={{display:this.state.otpDiv}}>
                    <span style={{color:'blue'}}>{this.isNumber(this.state.loginUsername)?'+971'+this.state.loginUsername:this.state.loginUsername}</span><span onClick={this.changeOption} style={{color:'blue',cursor: 'pointer'}}>(Change)</span>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12">
			 				<div className="form-group">
							<label for="exampleFormControlInput1">Enter OTP</label>
                            <div className="input-group">
							<input type="text" 
                            value={this.state.otp}
                            className="form-control" 
                            onChange={this.myChangeHandler} 
                            name="otp" />
                            <div class="input-group-append">
                                <span class="input-group-text" onClick={this.requestForgetpassword} style={{color:'blue',cursor: 'pointer'}}>Resend</span>
                            </div>
                            </div>
			 				</div>
		  				</div>
	   				</div>
                       <span style={{color:'red'}}>{this.state.otpError}</span>
                        <div className="row">
		  					<div className="col-md-12 col-sm-12 col-xs-12">
                                <button type="submit" className="btn btn-primary" onClick={this.verfyOTPRequest}>Submit</button> 
                            </div>
                        </div>
                    </div>
                     {/* Change Password Div*/}
                    <div class="card-body p-t-20" style={{display:this.state.changePasswordDiv}}>
                    <span style={{color:'blue'}}>{this.isNumber(this.state.loginUsername)?'+971'+this.state.loginUsername:this.state.loginUsername}</span>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12">
			 				<div className="form-group">
							<label for="exampleFormControlInput1">Enter New Password</label>
							<input type="password" 
                            className="form-control"
                            onChange={this.myChangeHandler} 
                            value={this.state.newPassword}
                            name="newPassword" />
			 				</div>
		  				</div>
	   				</div>
                    <div style={{display:this.state.passwordError}}>
                        <ul>
                            <li><span style={{color:'red'}}>Password must be 8 characters</span></li>
                            <li><span style={{color:'red'}}>At least one uppercase letter</span></li>
                            <li><span style={{color:'red'}}>One lowercase letter</span></li>
                            <li><span style={{color:'red'}}>One number and one special character</span></li>
                        </ul>
                    </div>
                    <span style={{color:'green'}}>{this.state.error}</span>   
                        <div className="row" style={{display:this.state.chPassbtn}}>
		  					<div className="col-md-12 col-sm-12 col-xs-12">
                                <button type="submit" onClick={this.changePassword} className="btn btn-primary">Submit</button> 
                            </div>
                        </div>
                        <div className="row" style={{display:this.state.logbtn}}>
		  					<div className="col-md-12 col-sm-12 col-xs-12">
                                <Link to="login" className="btn btn-primary">Login</Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>  	
    </section>
</div>
    </>);
    }
}

export default ForgetPassword;