import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
class Name_popup extends Component {
 
  render() {
    return (
      <div className="popup_name">
        <Row>
          <Col>
            <div>
              <h5 className="bold_me">
                Let's name your event !
              </h5>

              <input
                style={{
                  outline: "none",
                  marginTop: "10px",
                  marginBottom: "10px",
                  border: "2px solid #DCDCDC",
                  padding: "6px",
                  backgroundColor : "#F7F7F7",
                  borderRadius: "8px",
                  width: "100%",
                }}
                onChange={this.props.onchange}
                type="text"
                name="title"
                className="enterna"
                maxLength="50"
                placeholder="Get creative.."
              />
              <p style={{ fontWeight: "bold", color: "red" }}>
                {this.props.error}
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Button
            onClick={this.props.submit_popup_first}
            style={{
              margin: "15px auto",
              backgroundColor: this.props.title.length == 0 ?  "#D1D1D1" : "#f47824",
              color: this.props.title.length == 0 ? "#7a7a7a" : "#fff" ,
              padding: "8px 50px",
              fontWeight : "600"
              
            }}
            disabled={!this.props.name || this.props.title.length == 0}
          >
            Submit
          </Button>
        </Row>
      </div>
    );
  }
}
export default Name_popup;
