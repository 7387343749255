import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "./hireProfessional.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { FaCalendar,FaStar } from 'react-icons/fa';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import default_img from "./../../assets/default.jpg";
import DoneArrow from './../../assets/ic_done.png';
import axios from "axios";
import HeaderInner from "../HeaderInner";
import {AiOutlineHeart} from 'react-icons/ai'
import Popup from '../popupp'
import {IoIosArrowBack} from 'react-icons/io'
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';
import {API_BASE_URL,VENDOR_API_BASE_URL} from '../../constant';
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

class ProfessionalListing extends Component {
    constructor() {
        super();
        this.state = {
          filters: null,
          event_types: null,
          target_event_id: null,
          search_input: "",
          error: "",
          activeFilter: "All",
          BgColor : false,
          loader : true,
          professionals:[],
          title:'',
          eventCost:'',
          appliedfilter: [],
          filterpop: false,
          locations:[],
          Bookmarklist : [],
          filterlist: {
            commonFilters: [],
            guptaresorts: [],
            Venue: [],
            SelectTime: [],
          },
          Booking : [],
          serviceName:'',
          selectedServices:[],
          reviews:[],
          Sortby : '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.uid = localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1';
        //get states data from colection
        axios.get(`${API_BASE_URL}get-locations?status=1`)
        .then(resp=>{ 
            const states = [];
            resp.data.data.map((st,index)=>{
                states.push(st.stateName)  
            })
            this.setState({locations:states})
        })
        // get draft api
        axios.get(`${API_BASE_URL}getDraft?userEventId=${this.props.match.params.userEventId}&userId=${this.uid}`)
          .then(r => { 
            const selectedService=[];
            // for (let i = 0; i < r.data.data.data[0].vendorServices.length; i++) {
            //   selectedService.push( r.data.data.data[0].vendorServices[i].serviceId);
            // }
            this.setState({selectedServices:r.data.data.data[0].vendorServices});
            this.setState({ savedraftcount: r.data.data.count});
            this.setState({ eventCost: r.data.data.data[0]._id.packagePrice});
          })
          .catch(e => {
            console.log('save draft error', e)
          })
          // get more filter api
          axios.get(`${API_BASE_URL}event-filters?eventTypeId=${this.props.match.params.eventTypeId}&userEventId=${this.props.match.params.userEventId}`)
          .then(res => { 
            this.setState({ appliedfilter: res.data.data.appliedFilters, filterlist: res.data.data.filterList })
            res.data.data.appliedFilters.map((fil)=>{
                if(fil.type==8){
                    this.setState({location_val:fil.singleSelect})
                    
                }
                if(fil.type==3){
                    let mdate = parseInt(fil.date);
                    let edate = moment(new Date(mdate)).format("YYYY-MM-DD");
                    this.setState({edate:edate});
                }
                
                if(fil.type==1){
                    let age = fil.singleSelect;
                    this.setState({age:age});  
                }
                if(fil.type==6){
                    this.setState({startTime: fil.startTime}); 
                    this.setState({endTime:fil.endTime}); 
                }
                
                if(fil.type==7){
                    this.setState({membersMaxRange: fil.membersMaxRange}); 
                    this.setState({membersMinRange:fil.membersMinRange}); 
                }
            });
    
            axios.get(`${VENDOR_API_BASE_URL}user-event/${this.props.match.params.eventTypeId}/${this.props.match.params.userEventId}/hire-professionals?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}`)
            .then(resp => { 
                this.setState({
                filters: resp.data.results.appliedFilters,
                title: resp.data.results.event.event_name,
                professionals: resp.data.results.event.event_details.professionals,
                });
                const services = resp.data.results.event.event_details.professionals[0].services;
                    const Bookings = [];
                    services.forEach((service , index) =>{
                        let Obj;
                            Obj = {
                                _id : service._id,
                                images : service.images,
                                serviceId : service.serviceId,
                                name : service.servicetypes.name,
                                title : service.title,
                                price : service.price,
                                priceType : service.priceType, 
                                isSelected : this.SerivceValidId(service._id)
                            }
                            Bookings.push(Obj);
                    })
                this.setState({Booking:Bookings});
               
            })
            .catch(e => {
                console.log('filter error', e)
            })

            const filterdatatest = []
            let date = new Date();
            let curTime = moment().format("x")
            let Curtime = +curTime
            let endTime1 = moment().add(1, 'hours').format("x")
            let endTime = +endTime1
            Object.entries(this.state.filterlist).map((filter, index) => {
            this.setState({Name : filter[0]})
            let fil = filter[0]
            filter[1].forEach((filter, index) => {
                if (filter.type === 1) {
                let Obj;
                Obj = {
                    filterId: filter._id,
                    type: filter.type,
                    name: filter.name,
                    singleSelect: filter.singleSelect,
                    singleSelectData: filter.singleSelectData ? filter.singleSelectData : ""
                }
                filterdatatest.push(Obj)
                }
                if (filter.type === 2) {
                let Obj;
                Obj = {
                    filterId: filter._id,
                    type: filter.type,
                    name: filter.name,
                    multiSelect: filter.multiSelect,
                    multiSelectList: filter.multiSelectList
                }
                filterdatatest.push(Obj)
                }
                if (filter.type === 3) {
                let Obj;
                Obj = {
                    filterId: filter._id,
                    type: filter.type,
                    name: filter.name,
                    date: filter.date ? filter.date : Curtime
                }
                filterdatatest.push(Obj)
                }
                if (filter.type === 4) {
                let Obj;
                Obj = {
                    filterId: filter._id,
                    type: filter.type,
                    name: filter.name,
                    startDate: filter.startDate ? filter.startDate : Curtime,
                    endDate: filter.endDate ? filter.endDate : endTime,
                }
                filterdatatest.push(Obj)
                }
                if (filter.type === 5) {
                let Obj;
                Obj = {
                    filterId: filter._id,
                    type: filter.type,
                    name: filter.name,
                    time: filter.time ? filter.time : Curtime,
                }
                filterdatatest.push(Obj)
                }
                if (filter.type === 6) {
                let Obj;
                Obj = {
                    filterId: filter._id,
                    type: filter.type,
                    name: filter.name,
                    startTime: filter.startTime ? filter.startTime : Curtime,
                    endTime: filter.endTime ? filter.endTime : Curtime
                }
                filterdatatest.push(Obj)
                }
                if (filter.type === 7) {
                let Obj;
                Obj = {
                    filterId: filter._id,
                    type: filter.type,
                    name: filter.name,
                    membersMinRange: filter.membersMinRange,
                    membersMaxRange: filter.membersMaxRange,
                    membersMaxRangeData: filter.membersMaxRangeData ? filter.membersMaxRangeData : "",
                    membersMinRangeData: filter.membersMinRangeData ? filter.membersMinRangeData : ''
                }
                filterdatatest.push(Obj)
                }
                if (filter.type === 8) {
                let Obj;
                Obj = {
                    filterId: filter._id,
                    type: filter.type,
                    name: filter.name,
                    singleSelect: this.state.locations,
                    singleSelectData: filter.singleSelectData ? filter.singleSelectData : ""
                }
                filterdatatest.push(Obj)
                }
                if (filter.type === 7) {
                this.setState({ minMaxValue: [filter.membersMinRangeData, filter.membersMaxRangeData] })
                }
            })
            this.setState(prevState => ({
                filterlist: {
                "Gupta resorts11": filterdatatest
                }
            }))
            
            })
          })
          .catch(e => {
            console.log('filter error', e.response)
          })
          //get user bookmark
        axios.get(`${API_BASE_URL}adminServiceList?userId=${this.uid}`)
        .then(r =>{
            if(r.data.data.adminServiceList.length > 0){
                const seviceId = r.data.data.adminServiceList[0]._id
                axios.get(`${API_BASE_URL}getBookmark?userId=${this.uid}&serviceId=${seviceId}`)
                .then(res =>{
                    this.setState({ Bookmarklist : res.data.data.vendorServiceList})
                })
                .catch(error =>{
                    console.log('bookmark error' , error)
                })
            }
            
        })
        .catch(error =>{ 
            console.log('adminlisterror', error)
        })
        //get reviews
        axios.get(`${API_BASE_URL}getServiceReview`)
        .then(r=>{ 
            this.setState({'reviews':r.data.data.result});
        })
    }

    // for get average reviews of service
    get_average_reviews = (id) =>{
        let count = 0;
        let rating = 0;
        this.state.reviews .forEach(element => {
            if(element.vendorServiceId === id){
                count++;
                rating += element.rating;
            }  
        });
        return count>0 ? (rating/count).toFixed(1) : 0;
    }
    // for select or selected vendor service
    SerivceValidId = (id) =>{
        let index = false;
        this.state.selectedServices.forEach((item , i) =>{
            if(item.vendorServiceId == id){
                index = true
            }
        })
        return index;
  }
    // for Show More filter
    filterPopUp = () => {
        this.setState({ filterpop: true })
    }

    // for Hide more filter
    filterPopDown = () => {
        this.setState({ filterpop: false })
    }
    // for Single Select Filter
    SelectChange = (e, id) => {
        const temp = { ...this.state.filterlist }
        let index = -1;
        
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.singleSelectData = e
            }
            
        })

        })
        this.setState({ filterlist: temp })
    }

    // for Single Date filter
    singleselectDate = (e, id) => {
        const temp = { ...this.state.filterlist }
        let index = -1;
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.date = parseInt(e)
            }
        })

        })
        this.setState({ filterlist: temp })
    }
    // for Single Time filter
    singleselectTime = (e, id) => {
        const temp = { ...this.state.filterlist }
        let index = -1;
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.time = parseInt(e)
            }
        })

        })
        this.setState({ filterlist: temp })
    }

    // for start Date filter
    startDateRange = (e, id) => {
        const temp = { ...this.state.filterlist }
        let index = -1;
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.startDate = parseInt(e)
            }
        })

        })
        this.setState({ filterlist: temp })
    }

    // for End Date filter
    endDateRange = (e, id) => {
        const temp = { ...this.state.filterlist }
        let index = -1;
        
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.endDate = parseInt(e)
            }
        })

        })
        this.setState({ filterlist: temp })
    }

    // for start Time filter
    startTimeRange = (e, id) => {
        const temp = { ...this.state.filterlist }
        let index = -1;
        
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.startTime = parseInt(e)
            }
            
        })

        })
        this.setState({ filterlist: temp })
    }

    // for end Time filter
    endTimeRange = (e, id) => {
        const temp = { ...this.state.filterlist }
        let index = -1;
        
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.endTime = parseInt(e)
            }
        
        })

        })
        this.setState({ filterlist: temp })
    }


    // for multi select filter
    MultiChange = (e, id) => {
        const temp = { ...this.state.filterlist }
        let index = -1;
        
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.multiSelectList = e
            }
        })

        })
        this.setState({ filterlist: temp })
    }

    // for Get the range slider value
    rangeSlider = (filtervalue, id) => {
        
        const temp = { ...this.state.filterlist }
        let index = -1;
        Object.entries(temp).map((filter, index) => {
        filter[1].forEach((item, i) => {
            if (item.filterId == id) {
            index = i
            item.membersMinRangeData = filtervalue[0]
            item.membersMaxRangeData = filtervalue[1]
            }
        })
        })
        this.setState({ filterlist: temp })
    }
    locAdd = () => {
        let add = false;
        return add;
    }

    // for check validation on let's create an Event button
    CheckValidation = () =>{
    const temp = [...this.state.appliedfilter]
    let added = false;
    temp.forEach((item , index) =>{
        if(item.type === 6 && (parseInt(item.endTime) < parseInt(item.startTime))){
            added = true
        }
    })
    return added;
    }

    // update the more filter button
    UpdateFilter = () => {
        const userEventId = this.props.match.params.userEventId;
        const eventTypeId = this.props.match.params.eventTypeId;
        const temp = []
        Object.entries(this.state.filterlist).map((filterdata, index) => {
        filterdata[1].forEach((item, index) => {
            if (item.type === 1) {
            temp.push({
                filterId: item.filterId,
                type: item.type,
                singleSelect: item.singleSelectData

            });
            }
            if (item.type === 2) {
            temp.push({
                filterId: item.filterId,
                type: item.type,
                multiSelect: item.multiSelectList
            });
            }
            if (item.type === 3) {
            temp.push({
                filterId: item.filterId,
                type: item.type,
                date: item.date,
                hdate : moment(item.date).format("DD-MM-YYYY"),
            });
            }
            if (item.type === 4) {
            temp.push({
                filterId: item.filterId,
                type: item.type,
                startDate: item.startDate,
                endDate: item.endDate
            });
            }
            if (item.type === 5) {
            temp.push({
                filterId: item.filterId,
                type: item.type,
                time: item.time
            });
            }
            if (item.type === 6) {
            temp.push({
                filterId: item.filterId,
                type: item.type,
                startTime: item.startTime,
                endTime: item.endTime,
                hstartTime : moment(item.startTime).format("HH:mm"),
                hendTime : moment(item.endTime).format("HH:mm"),
            });
            }
            if (item.type === 7) {
            temp.push({
                filterId: item.filterId,
                type: item.type,
                membersMinRange: item.membersMinRangeData,
                membersMaxRange: item.membersMaxRangeData
            });
            }
            if (item.type === 8) {
            temp.push({
                filterId: item.filterId,
                type: item.type,
                singleSelect: item.singleSelectData

            });
            }
        })
        })
        var serviceId = "";
        this.state.professionals.forEach((p, index) => {
        serviceId = p.serviceId
        })
        const Filters = {
        userEventId: userEventId,
        eventTypeId: eventTypeId,
        userId: this.uid,
        filters: temp
        }
        axios.post(`${API_BASE_URL}event`, Filters)
        .then(res => {
            this.setState({ filterpop: false })
            axios.get(`${API_BASE_URL}serviceList?userEventId=${userEventId}&eventTypeId=${eventTypeId}&serviceId=${serviceId}`)
            .then(response => {
                this.setState({ appliedfilter: response.data.data.appliedFilters })
                window.location.reload();
            })
            .catch(error => {
                console.log('error g', error)
            })
            
        })
        .catch(e => {
            console.log('filter error', e.response)
        })
    }

    // for Add to bookmark heart icon color
    heart_icon = (id) =>{
        let added = false
        this.state.Bookmarklist .forEach(element => {
            if(element._id === id){
                added = true
            }  
        });
        return added ? "#F47824" : "#00000080"
    }
    //get serviceId
    getService = (s)=>{ 
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const serviceId = s;
        this.state.appliedfilter.map((fil)=>{
            if(fil.type==8){
                this.setState({location_val:fil.singleSelect})
            }
            if(fil.type==3){
              let mdate = parseInt(fil.date);
              let edate = moment(new Date(mdate)).format("YYYY-MM-DD");
              this.setState({edate:edate});
            }
            
            if(fil.type==1){
                let age = fil.singleSelect;
                this.setState({age:age});  
            }
            if(fil.type==6){
                this.setState({startTime: fil.startTime}); 
                this.setState({endTime:fil.endTime}); 
            }
            
            if(fil.type==7){
                this.setState({membersMaxRange: fil.membersMaxRange}); 
                this.setState({membersMinRange:fil.membersMinRange}); 
            }
        });

        // Service List Api when serviceId not _blank
        if(serviceId !==''){
        axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/${serviceId}/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}`)
        .then(response =>{ 
            response.data.results[0].event_details.service_list.forEach((serviceId , index) =>{
                this.heart_icon(serviceId._id)
            })
            this.setState({Booking : response.data.results[0].event_details.service_list,serviceName:response.data.results[0].event_details.service_name})
        })
        .catch(error => {
            console.log('servicelist error' , error)
        })
        }
        else{
            axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/hire-professionals?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}`)
            .then(resp => { 
                this.setState({
                filters: resp.data.results.appliedFilters,
                title: resp.data.results.event.event_name,
                professionals: resp.data.results.event.event_details.professionals,
                });
                const services = resp.data.results.event.event_details.professionals[0].services;
                    const Bookings = [];
                    services.forEach((service , index) =>{
                        let Obj;
                            Obj = {
                                _id : service._id,
                                images : service.images,
                                serviceId : service.serviceId,
                                name : service.servicetypes.name,
                                title : service.title,
                                price : service.price,
                                priceType : service.priceType, 
                                isSelected : this.SerivceValidId(service._id)
                            }
                            Bookings.push(Obj);
                    })
                this.setState({Booking:Bookings});
               
            })
            .catch(e => {
                console.log('filter error', e)
            })
        }
    }

    sortChange = (e) => {
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        this.setState({Sortby : e.target.value })
        const timer = setTimeout(()=>{
            var sortby = this.state.Sortby
            var sortvalue = sortby.split("_")
            axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/hire-professionals?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}&sort=${sortvalue[0]}&sortType=${sortvalue[1]}`)
            .then(resp => { 
                this.setState({
                filters: resp.data.results.appliedFilters,
                title: resp.data.results.event.event_name,
                professionals: resp.data.results.event.event_details.professionals,
                });
                const services = resp.data.results.event.event_details.professionals[0].services;
                    const Bookings = [];
                    services.forEach((service , index) =>{
                        let Obj;
                            Obj = {
                                _id : service._id,
                                images : service.images,
                                serviceId : service.serviceId,
                                name : service.servicetypes.name,
                                title : service.title,
                                price : service.price,
                                priceType : service.priceType, 
                                isSelected : this.SerivceValidId(service._id)
                            }
                            Bookings.push(Obj);
                    })
                this.setState({Booking:Bookings});
               
            })
            .catch(e => {
                console.log('filter error', e)
            })
        }, 500)
        
    }
    

    // for remove the service
    removeService = (objs) =>{
        confirmAlert({
            title: '',
            message: 'Do you want to remove this service',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {    
                    const userEventId = this.props.match.params.userEventId;
                    const uid = this.props.match.params.userId;
                    let id ;
                    if(typeof(objs) === "object"){
                        id = objs._id
                    } 
                    else {
                        id = objs
                    }
                    axios.get(`${API_BASE_URL}removeSelectedService?serviceId=${id}&userEventId=${userEventId}&userId=${uid}`)
                    .then(re => {
                        window.location.reload();
                    })
                    .catch(e =>{
                        console.log('Service Remove Error')
                    })  
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
          });     
    }

    render() {
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const name = this.state.serviceName 

        var date = new Date();
        var curTime = moment(date).format("hh:mm")
        var date = new Date();
        var curTime = moment().format("x")
        var Curtime = +curTime
        var endTime1 = moment().add(1, 'hours').format("x")
        var endTime = +endTime1

        var applied = [];
        this.state.appliedfilter.forEach((appliedfilter, index) => {

        switch (appliedfilter.type) {
            case 1:
            applied[appliedfilter.type] = appliedfilter.singleSelect + "#" + appliedfilter.filterId
            break;
            case 2:
            applied[appliedfilter.type] = appliedfilter.multiSelect + "#" + appliedfilter.filterId;
            break;
            case 3:
            var date = moment(parseInt(appliedfilter.date)).format("DD-MM-YYYY")
            applied[appliedfilter.type] = date + "#" + appliedfilter.filterId
            break;
            case 4:
            var date1 = moment(parseInt(appliedfilter.startDate)).format("DD-MM-YYYY")
            var date2 = moment(parseInt(appliedfilter.endDate)).format("DD-MM-YYYY")
            applied[appliedfilter.type] = date1.concat(' / ', date2) + "#" + appliedfilter.filterId
            break;
            case 5:
            var time = moment(parseInt(appliedfilter.time)).format("hh:mm A")
            applied[appliedfilter.type] = time + "#" + appliedfilter.filterId
            break;
            case 6:
            var time1 = moment(parseInt(appliedfilter.startTime)).format("hh:mm A")
            var time2 = moment(parseInt(appliedfilter.endTime)).format("hh:mm A")
            applied[appliedfilter.type] = time1 + "-" + time2 + "#" + appliedfilter.filterId
            break;
            case 7:
            applied[appliedfilter.type] = appliedfilter.membersMinRange + ' - ' + appliedfilter.membersMaxRange + "#" + appliedfilter.filterId
            break;
            case 8:
            applied[appliedfilter.type] = appliedfilter.singleSelect + "#" + appliedfilter.filterId
            break;
            
        }
        })

        return ( 
            <div className="create_event">
            {
                this.state.loader == false ? <div className="loading-box lbox"><CircularProgress disableShrink /></div> :
                <div>
                     <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
                    <br />
                    <br />
                    <Container>
                    <p className="pcontainer">
                        <Link className="remove_anchor bold_me anchor_back" to="/create_pro_event"><IoIosArrowBack />BACK</Link>
                    </p>
                    <div className="row">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        
                        <h4 style={{ marginBottom: "1px" }} className="bold_me">
                        Listing of Professionals
                        </h4>
                        <hr></hr>
                        <div className="inner-filter">
                        <ul className="filter-option">
                        {
                            applied.map((filter, index) => {
                            var filtervalues = filter.split("#");
                            var filtervalue = filtervalues[0]
                            var filterid = filtervalues[1]
                            return <li key={index}><Link to="#">{filtervalue}</Link></li>
                            // return <li key={index}><Link to="#">{filtervalue}<span onClick={() => this.RemoveFilter(filterid)}>x</span></Link></li>
                            })
                        }
                        <li>
                            <select value={this.state.Sortby} onChange={this.sortChange}>
                                <option>Sort by</option>
                                <option value="ASC_price">Price :  low to high </option>
                                <option value="DESC_price">Price : high to low</option>
                                <option value="ASC_title"> Title : A to Z</option>
                                <option value="DESC_title"> Title : Z to A</option>
                            </select>
                        </li>
                        <li><Link to="#" className="demoReqBtn" onClick={this.filterPopUp}>Edit Filters <span>&#10095;</span></Link></li>
                        
                        </ul>
                        </div>
                        {
                        this.state.filterpop == false ? "" :
                            <div className="filter-content" >
                            <div className="inside-content">
                                <p onClick={this.filterPopDown} className="can-el-btn">X</p>
                                { 
                                Object.entries(this.state.filterlist).map((filter, index) => {
                                    return <div className="row" key={index}>
                                    {filter[1].length > 0 ? <h3 className="col-md-12 capBotMargin">{this.state.title}</h3> : null}
                                    {filter[1].map((infiler, i) => (
                                        <div key={i} className="col-md-6" style={{ marginBottom: '15px' }}>
                                        {infiler.type === 1 && (
                                            <div>
                                            <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>
                                            <Select
                                                options={infiler.singleSelect.map((option, index) => {
                                                return { label: option, value: option };
                                                })}
                                                defaultValue={infiler.singleSelectData != undefined && infiler.singleSelectData != '' ? ({ label: infiler.singleSelectData, value: infiler.singleSelectData }) : ''}
                                                onChange={(e) => this.SelectChange(e.value, infiler.filterId)}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                            </div>
                                        )}
                                        {infiler.type === 2 && (
                                            <div>
                                            <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>
                                            <Select
                                                options={infiler.multiSelect.map((option, index) => {
                                                return { label: option, value: option };
                                                })}
                                                defaultValue={
                                                (infiler.multiSelectList != undefined) ?
                                                    infiler.multiSelectList.map((option, index) => {
                                                    return { label: option, value: option };
                                                    }) : null}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                isMulti
                                                onChange={(e) => this.MultiChange(this.getmultiSelect(e), infiler.filterId)}
                                            />
                                            </div>
                                        )}
                                        {infiler.type === 3 && (
                                            <div>
                                            <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>
                                            <div style={{ position: "relative" }} className="SingleSelectDate">
                                                <DatePicker minDate={new Date()}
                                                showYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                                selected={infiler.date ? new Date(infiler.date) : Curtime}
                                                onChange={(date) => this.singleselectDate(moment(date).format("x").toString(), infiler.filterId)}
                                                />
                                                <span className="dtCalender"><FaCalendar /></span>
                                            </div>

                                            </div>
                                        )}
                                        {infiler.type === 4 && (
                                            <div>
                                            <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name}</h6>
                                            <div className="trw-box Doubledatebox">
                                                <div style={{ position: "relative", marginRight: "5px" }} className="DoubleDate">
                                                <DatePicker minDate={new Date()}
                                                    showYearDropdown
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={infiler.startDate ? new Date(infiler.startDate) : Curtime}
                                                    onChange={(date) => this.startDateRange(moment(date).format("x").toString(), infiler.filterId)}
                                                />
                                                <span className="dtCalender"><FaCalendar /></span>
                                                </div>
                                                <div style={{ position: "relative" }} className="DoubleDate">
                                                <DatePicker minDate={new Date()}
                                                    showYearDropdown
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={infiler.endDate ? new Date(infiler.endDate) : Curtime}
                                                    onChange={(date) => this.endDateRange(moment(date).format("x").toString(), infiler.filterId)}

                                                />
                                                <span className="dtCalender"><FaCalendar /></span>
                                                </div>

                                            </div>
                                            </div>
                                        )}
                                        {infiler.type === 5 && (
                                            <div>
                                            <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>
                                            <div style={{ display: "flex", justifyContent: "left" }}>
                                                <input className="titlecls"
                                                type="time"
                                                id="appt"
                                                defaultValue={infiler.time != undefined && infiler.time != ''
                                                    ? moment(parseInt(infiler.time)).format("HH:mm")
                                                    : curTime}
                                                onChange={(e) => this.singleselectTime(moment("1 January 2021 " + e.target.value + ":00").format("x"), infiler.filterId)}
                                                />
                                            </div>
                                            </div>
                                        )}
                                        {infiler.type === 6 && (
                                            <div>
                                            <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>
                                            <div className="flxleft time-box">
                                                <input className="txtcol" 
                                                type="time"
                                                id="st"
                                                defaultValue={infiler.startTime != undefined && infiler.startTime != ''
                                                    ? moment(parseInt(infiler.startTime)).format("HH:mm")
                                                    : curTime}
                                                //    value={infiler.startTime}
                                                onChange={(e) => this.startTimeRange(moment("1 January 2021 " + e.target.value + ":00").format("x"), infiler.filterId)}
                                                />
                                                <input className="txtcol" 
                                                type="time"
                                                id="et"
                                                placeholder={infiler.endTime}
                                                defaultValue={infiler.endTime != undefined && infiler.endTime != ''
                                                    ? moment(parseInt(infiler.endTime)).format("HH:mm")
                                                    : curTime}
                                                //    value={infiler.endTime}
                                                onChange={(e) => this.endTimeRange(moment("1 January 2021 " + e.target.value + ":00").format("x"), infiler.filterId)}
                                                />
                                            </div>
                                            </div>
                                        )}
                                        {infiler.type === 7 && (
                                            <div>
                                            <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>
                                            <Row style={{ padding: "0px 10px" }}>
                                                {infiler.membersMinRange}
                                                <span style={{ marginLeft: "auto" }}>
                                                {infiler.membersMaxRange}
                                                </span>
                                            </Row>
                                            <Range
                                                key={i}
                                                step={1}
                                                defaultValue={[infiler.membersMinRangeData,
                                                infiler.membersMaxRangeData
                                                ]}
                                                min={parseInt(infiler.membersMinRange)}
                                                max={parseInt(infiler.membersMaxRange)}
                                                tipFormatter={(filtervalue) => `${filtervalue}`}
                                                onAfterChange={(filtervalue) => {
                                                this.rangeSlider(filtervalue, infiler.filterId)
                                                }}
                                            />
                                            </div>
                                        )}
                                        {infiler.type === 8 && (
                                            <div>
                                            <div>
                                            <h6 className="title-box" style={{ marginBottom: "10px" }}>{infiler.name} </h6>
                                            <Select
                                                options={infiler.singleSelect.map((option, index) => {
                                                return { label: option, value: option };
                                                })}
                                                defaultValue={infiler.singleSelectData != undefined && infiler.singleSelectData != '' ? ({ label: infiler.singleSelectData, value: infiler.singleSelectData }) : ''}
                                                onChange={(e) => this.SelectChange(e.value, infiler.filterId)}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                            </div>
                                            </div>
                                        )}
                                        </div>
                                    ))}
                                    </div>
                                })
                                }
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                <button className="btnother" style={{backgroundColor  : this.CheckValidation() ? "#d0d0d0" : "#f47824" , color  :  this.CheckValidation() ? "#7a7a7a" : "#fff" }}
                                    onClick={this.UpdateFilter} disabled={this.CheckValidation()}><strong>Update Filters  </strong></button>
                                </div>
                            </div>
                            </div>
                        }
                        
                
                        <div className="filter-box">
                        <div className="inner-filter">
                            <ul className="filter-option bgsize">
                            { this.state.professionals.map((p,i)=>(
                                <>
                                    <li key={i} style={{cursor: "pointer"}}><a className={p.serviceName==this.state.serviceName?"demoReqBtn":''} onClick={()=>this.getService(p.serviceId)}>{p.serviceName}({p.serviceCount})</a></li>
                                    {/* <li><a className="demoReqBtn">DJ</a></li> */}
                                </>
                            ))}                       
                            </ul>
                        </div>
                    </div>
                     <div className="row">
                        { this.state.Booking.map((Booking , index) => {
                            return <div key={index} className="col-md-6" style={{ marginBottom: "20px" }}>
                                <Link to={`/professionaldetails/${Booking._id}/${userEventId}/${eventTypeId}/${Booking.serviceId}/${Booking.name}/${Booking.isSelected}`}>
                                    <div className="inner-cont-box">
                                        <div className="inner-img-con" style={{ height: "188px", borderRadius: "8px" }}>
                                            <img src={Booking.images!=''?Booking.images[0]:default_img} alt="2" />
                                            <Link to="#"  className="heart-box" style={{backgroundColor : this.heart_icon(Booking._id) }}><span onClick={() => this.Addfav(Booking._id, Booking.isBookmark)}><AiOutlineHeart /></span></Link>
                                            {this.get_average_reviews(Booking._id)? <p className="pflex"><span>{this.get_average_reviews(Booking._id)}</span><FaStar /></p>:''}
                                        </div>
                                    <div className="info-content flex-type2"><div>
                                        <h6>{Booking.title}<span>2.6km</span></h6>
                                        <p>AED {Booking.price} / {Booking.priceType == 0 &&( <span>Whole</span> )} {Booking.priceType == 1 &&( <span>Per Person</span> )} {Booking.priceType == 2 &&( <span>Per Hr</span> )} </p>
                                    </div>
                                    <div>
                                        {
                                            Booking.isSelected ? <Link style={{color : "white", background:"#f47824"}}to={`/professionaldetails/${Booking._id}/${userEventId}/${eventTypeId}/${Booking.serviceId}/${Booking.name}/${Booking.isSelected}`}>booked</Link> : <Link to={`/professionaldetails/${Booking._id}/${userEventId}/${eventTypeId}/${Booking.serviceId}/${Booking.name}/${Booking.isSelected}`}>book</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    })}
                    </div>
                </div>
                    
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="box-shadow1 p-4 mb-4">
            <h2 className="page-title my-3 d-inline-block" style={{textTransform: "capitalize"}}>{this.state.title}</h2>
            { this.state.eventCost ? this.state.selectedServices.map((ser,i)=>{
                return <div key={i} className="border border-warning mb-3 position-relative add-on-box d-flex align-items-center justify-content-between">
                <div className="add-on"><h4 className="heading mb-0">{ser.serviceType}&nbsp;&nbsp;&nbsp;AED {ser.price*ser.guests}</h4><p className="mb-0">{ser.title}</p></div>
                    {/* <a class="colored edit" href="#">edit</a> */}
                    <a onClick={() => this.removeService(ser.serviceId)} className="colored edit" style={{cursor: "pointer"}}>remove</a>
                        <div className="position-absolute icon-done">
                            <img src={DoneArrow} alt="" />    
                        </div>
                </div>
            }) :
            <div className="border border-warning mb-3 position-relative add-on-box d-flex align-items-center justify-content-between">
            <div className="add-on"><h4 className="heading mb-0"></h4><p className="mb-0">No Service selected</p></div>
            </div>}
            
            {this.state.eventCost?<div className="row">
                <div className="col-md-8 col-lg-8 col-sm-8">
                    <h2 className="heading">Total Amount</h2>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4">
                    <h2 className="heading">AED {this.state.eventCost}</h2>
                </div>        
            </div>:''}
            {this.state.eventCost?<Link className="d-block  btn btn-warning mt-4" to={`/professionalcheckout/${userEventId}/${eventTypeId}/${this.uid}`}><span className="pl-3">Book Now</span></Link>:''}
            </div>    
        </div>
        </div>
        </Container>
        </div>
        }
      </div>
    );
  }
}
export default ProfessionalListing;