import React, { useState,useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import LoginPopup from './components/loginPopup';
import { Link } from 'react-router-dom';
import logo from './assets/EventStan2.png';
import wlogo from './assets/EventStanWhite.png';


const HeaderTop = ()=>{
    
    let [fav,FavouriteBoxx]= useState(false);
    let [showLogin,setLoginPopup] =useState(false);
    let [showName,setName] =useState('Menu');
    const [userData, setUserData] = useState({
        token: '',
      });
      const checkLoggedIn = async () => {
        let token = localStorage.getItem("user-auth-token");

        if (token === null && token === '') {
          localStorage.setItem("user-auth-token", "");
          token = "";
        }
        else{
        setUserData({
          token,
        });
        
        if (localStorage.getItem("user-data") !== null) {
            var userDetails = JSON.parse(localStorage.getItem("user-data"));
            setName(userDetails.firstName.split(" ")[0]===''?'User':userDetails.firstName.split(" ")[0]);
        }
        }
      };
      useEffect(() => {
        checkLoggedIn();
      }, []);
    let FavouriteBox=()=>{
        FavouriteBoxx(true);
    }
    let filterPopDown1 =()=>{
        FavouriteBoxx(false);
    }
    let login_click=()=>{
        setLoginPopup(!showLogin);
    }

    const logout=()=>{
        localStorage.removeItem('user-auth-token');
        localStorage.removeItem('user-auth-id');
        localStorage.removeItem('event_id');
        localStorage.removeItem('user-data');
        localStorage.clear();
        window.location.replace("/eventstan");
    }
    return(
        <div className="header-top">
        <div className="container">
            <div className="row align-items-center">
                <div class="col-9 col-md-11 col-lg-3 col-xl-3">
                    {/* <a href="/eventstan" class="navbar-brand">Event<span class="colored">stan</span></a>*/}
                    <Link to="/eventstan"><img src={wlogo} alt="eventStan Logo"/></Link>
                </div>
                <div className="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 d-none d-lg-block">
                    <div className="input-group md-form form-sm form-2 pl-0">
                        {/* <input className="form-control my-0 py-1" type="text" placeholder="Search here..." aria-label="Search" /> */}
                        {/* <div className="input-group-append">
                            <span className="input-group-text  lighten-3" id="basic-text1">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
                                    <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
                                </svg>
                            </span>
                        </div> */}
                    </div>
                </div>
                <div className="col-5 col-sm-5 col-md-3 col-lg-5 col-xl-5 text-md-left text-xl-right d-none d-lg-block wrap">
                    <Button href="/professional" className="btn event-bg-btn mr-lg-0 mr-xl-2">Become a Professional</Button>
                    {/*<Button className="btn event-bg-btn">Request for Demo</Button>*/}
                </div>
                <div className="col-3 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <div style={{ display: 'flex', cursor : "pointer" }} onClick={()=>FavouriteBox()}>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="ml-0 ml-sm-3 menu">{showName}</span>
                    </div>
                    
                </div>
            </div>
        </div>
        { 
                    fav == false ? "" : 
                    <div className="filter-content filter-content2" >    
                    <div className="inside-content2">
                    <p onClick={()=>filterPopDown1()} className="can-el-btn">X</p>
                    <ul className="" onClick={()=>filterPopDown1()}>
                        {userData.token ==''?
                        <>
                        <li><a href="https://eventstan.com/about-us">About us</a></li>
                        <li><a href="https://eventstan.com/#how-does-it-work">How it works</a></li>
                        <li><a href="https://eventstan.com/professional">Partner with us</a></li>
                        <li><a href="https://eventstan.com/#download-app">Download the App</a></li>
                        <li><a href="/ContactUs">Contact us</a></li>
                        </>
                        :''}
                        {userData.token !=''?
                        <>
                        <li><Link to="/savedraft">Draft Events</Link></li>
                        <li><Link to="/myevents">My Events</Link></li>
                        <li><Link to="/favourite">favourite</Link></li>
                        <li><Link to="/changepassword">Change Password</Link></li>
                        <li><Link to="/ContactUs">Contact us</Link></li>
                        </>
                        :''}

                        <li>
                            {userData.token !=''?
                            <Link to="/userdashboard" >Profile</Link>
                            :<button onClick={()=>login_click()} >Login</button>}
                        </li> 
                        <li>
                            {userData.token !=''?
                            <Link to="#" className="icon-logout" onClick={logout}>Logout</Link>
                            :''}
                        </li>
                    </ul>
                    </div>
                </div>
        }
        {showLogin ? 
          <LoginPopup  closePopup={login_click} hideClose={true}/>
          : null
        }
    </div>
    );
}
const mapStateToProps = (state)=>{
    return{
        userDetails : state.userDetailsReducer.userDetails,
    }
}
export default HeaderTop;
