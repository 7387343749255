import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LogoPic from './assets/eventstan.png';
import axios from "axios";
import { API_BASE_URL,BASE_URL } from "./constant";
import "./Invoice.css";

const Invoice = () => {
	const params = useParams();
	const [eventData,setEventData] = useState([]);
	const [orderData,setOrderData] = useState([]);
	const [userData,setUserData] = useState([]);
	const [billAdd,setbillAdd] = useState();
	const [billCity,setbillCity] = useState();
	const [billCountry,setbillCountry] = useState();
	let userEventId = params.userEventId;
	useEffect(()=>{
		axios.get(`${API_BASE_URL}paymentInvoice?userEventId=${userEventId}`)
		.then(res=>{ 
			setEventData(res.data.data._id);
			setOrderData(res.data.data.vendorServices);
			
			axios.post(`${API_BASE_URL}UserProfileById`,{uid:res.data.data._id.uid})
			.then(r=>{ 
				setUserData(r.data.data[0]);
				setbillAdd(r.data.data[0].bill_add.address);
				setbillCity(r.data.data[0].bill_add.city);
				setbillCountry(r.data.data[0].bill_add.country);
			})
		})
		.catch(err=>{
			console.log(err);
		})
	},[])

    return (
		<div id="body">
			<a href="javascript:window.print()" className="print-button">Print this Payment Receipt</a>
<div id="invoice">
	<div className="row">
		<div className="col-md-6">
			<div id="logo"><img src={LogoPic} alt=" " /></div>
		</div>

		<div className="col-md-6">	
			<p id="details">
				<strong>Booking ID:</strong> {eventData.sysEventId}<br/>
				<strong>Issued Date:</strong> {new Date(eventData.updatedAt).toLocaleDateString()} <br/>
			</p>
		</div>
	</div>


	{ /*Client & Supplier*/ }
	<div className="row">
		<div className="col-md-12">
			<h2 className="head">Invoice</h2>
		</div>

		<div className="col-md-6">	
			<p>
				<b>EVENTSTAN</b> <br/>
				Eventstan-Fze , DTech ,Technohub 1,<br/>
				Dubai Silicon Oasis, Dubai. <br/>
			</p>
		</div>

		<div className="col-md-6">	
			<p>
				<b>{userData.firstName}</b> <b>{userData.lastName}</b><br/>
				{userData.email}<br/>
				{userData.phoneNumber}<br/>
				{billAdd},{billCity},{billCountry}<br/>
			</p>
		</div>
	</div>
	{/*Invoice */}
<div className="row">
<div className="col-md-12">
    <table className="margin-top-20">
        <tr>
            <th>Service</th>
            <th>Description </th>
			<th></th>
            <th>Amount</th>
        </tr>

        { orderData.map((event,index)=>{
			return <tr key={index}>
            <td>{event.type}</td> 
            <td>{event.title}</td>
			<td></td>
            <td>AED {event.priceType==0?event.price:event.price*event.guests}</td>
        </tr>
		})}
		
        
		<tr style={{ borderTop:'1px solid #ddd'}}>
            <td>&nbsp;</td>
			<td>&nbsp;</td>
			<td><b>Total Amount :</b></td>
            <td><span>AED {eventData.packagePrice}</span></td>
        </tr>
		
    </table>
	* 5% Tax Included</div>


</div>
<div className="row">
		<div className="col-md-12">
			<ul id="footer">
				<li className="col-md-5"><span>www.eventstan.com</span></li>
				<li className="col-md-5"><a href="#" className="__cf_email__">info@eventstan.com</a></li>
				<li className="col-md-2">+971566405353</li>
			</ul>
		</div>
	</div>

	</div>
</div> 
    );
}
export default Invoice;