import React from 'react'
import { Row, Col, Container, Button } from "react-bootstrap";
import SearchInput from "./SearchInput";
import card_pic from "./../assets/create_event/card.jpg";
import seperator from "./../assets/devider.png";
import freetalk from "./../assets/freetalk.jpg";
import whatsapp from "./../assets/whatsapp-button.png";
import default_img from "./../assets/default.jpg";
import {Link} from 'react-router-dom'
import "./booking.css";
import LoginPopup from './loginPopup';
import moment from 'moment';
import HeaderInner from "./HeaderInner";
import {AiOutlineHeart} from 'react-icons/ai'
import {FaBlackberry, FaStar} from 'react-icons/fa'
import {BiSearch} from 'react-icons/bi'
import axios from "axios";
import Select from "react-select";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import DatePicker from "react-datepicker";
import { FaCalendar } from 'react-icons/fa';
import {IoIosArrowBack} from 'react-icons/io';
import $ from 'jquery';
import {TiTick} from 'react-icons/ti'
import {API_BASE_URL,VENDOR_API_BASE_URL} from './../constant'
import jspriceType from "./../context/priceType";
import {connect} from 'react-redux'
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);


class Booking extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            package:'',
            Name : "",
            filterpop : false,
            Booking : [],
            SearchInput : "",
            SearchContent : "",
            appliedfilter : [],
            Sortby : '',
            primary : [],
            filterlist : {
                commonFilters : [],
               guptaresorts : [],
               Venue : [],
               SelectTime : [],
               },
            filterData : [],
            Addons : [],
            Sidefilter : [],
            Heart_icon : false,
            AdData : false,
            Disbtn : true,
            minMaxValue : [0,0],
            Bookmarklist : [],
            fieldCount: [1],
            endDate : new Date(),
            startDate : new Date(),
            Date : new Date(),
            savedraftcount : "",
            showPopup: false,
            uid :'',
            eventTitle :'',
            eventCost:'',
            locations:[],
            location_val:"",
            edate:"",
            age:"",
            startTime:"",
            endTime:"",
            membersMaxRange:"",
            membersMinRange:"",
            reviews:[],
            filterCategory:[],
            filterby:'',
            records:'10',
        }
        const uid = '';
    }
    
    componentDidMount(){
        window.scrollTo(0, 0);
        if(localStorage.getItem('user-auth-id') == null || localStorage.getItem('user-auth-id') =='') {
            this.setState({showPopup:true})
        }
        this.uid = localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1';
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const serviceId = this.props.match.params.serviceId
        //get states data from colection
        axios.get(`${API_BASE_URL}get-locations?status=1`)
        .then(resp=>{ 
            const states = [];
            resp.data.data.map((st,index)=>{
                states.push(st.stateName)  
            })
            this.setState({locations:states})
        })
        //get filter category
        axios.get(`${API_BASE_URL}get-filter-category?serviceTypeId=${serviceId}`)
        .then(resp=>{
            console.log(resp) ;
            this.setState({filterCategory:resp.data.data})
        })
        //get event details
        axios.get(`${API_BASE_URL}getEventData?userEventId=${this.props.match.params.userEventId}&userId=${this.uid}`)
        .then(r => { 
          this.setState({eventTitle:r.data.data.eventName,appliedfilter : r.data.data.filters});
        })
        .catch(e =>{
          console.log('Get event error' , e)
        })

        // save draft Api
        axios.get(`${API_BASE_URL}getDraft?userEventId=${this.props.match.params.userEventId}&userId=${this.uid}`)
        .then(r => { 
          this.setState({savedraftcount : r.data.data.count ,eventCost: r.data.data.data[0]._id.packagePrice})
        })
        .catch(e =>{
          console.log('save draft error' , e)
        })

        axios.get(`${API_BASE_URL}event-filters?eventTypeId=${this.props.match.params.eventTypeId}&userEventId=${this.props.match.params.userEventId}`)
        .then(rep => { 
            if(rep.data.data.result.addOns.length > 0){
                this.setState({AdData : true})
            }
            else{
                this.setState({AdData : true})
            }
            this.setState({Sidefilter : rep.data.data.result})
            this.setState({Addons : rep.data.data.result.addOns})
            this.setState({ primary :rep.data.data.result.primary, filterlist : rep.data.data.filterList})

            rep.data.data.appliedFilters.map((fil)=>{
                if(fil.type==8){
                    this.setState({location_val:fil.singleSelect})
                    
                }
                if(fil.type==3){
                  let mdate = parseInt(fil.date);
                 let edate = moment(new Date(mdate)).format("YYYY-MM-DD");
                  this.setState({edate:edate});
                }
                
                if(fil.type==1){
                    let age = fil.singleSelect;
                    this.setState({age:age});  
                }
                if(fil.type==6){
                    this.setState({startTime: fil.startTime}); 
                    this.setState({endTime:fil.endTime}); 
                }
                
                if(fil.type==7){
                    this.setState({membersMaxRange: fil.membersMaxRange}); 
                    this.setState({membersMinRange:fil.membersMinRange}); 
                }
            });
            // Service List Api
            axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/${serviceId}/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}`)
            .then(response =>{ 
                response.data.results[0].event_details.service_list.forEach((serviceId , index) =>{
                    this.heart_icon(serviceId._id)
                })
                this.setState({Booking : response.data.results[0].event_details.service_list})
            })
            .catch(error => {
                console.log('servicelist error' , error)
            })
            const filterdatatest = []
            let date = new Date();
                let curTime = moment().format("x")
                let Curtime = +curTime
                let endTime1 = moment().add(1 , 'hours').format("x")
                let endTime = +endTime1
                    Object.entries(this.state.filterlist).map((filters , index) => {
                        this.setState({Name : filters[0]})
                    filters[1].forEach((filter , index) => {
                        if(filter.type === 1){
                        let Obj;
                        Obj ={
                            filterId : filter._id,
                            type : filter.type ,
                            name : filter.name,
                            singleSelect : filter.singleSelect,
                            singleSelectData : filter.singleSelectData ? filter.singleSelectData : "" 
                        }
                        filterdatatest.push(Obj)
                    }
                    if(filter.type === 2){
                        let Obj;
                        Obj ={
                            filterId : filter._id,
                            type : filter.type ,
                            name : filter.name,
                            multiSelect : filter.multiSelect,
                            multiSelectList : filter.multiSelectList
                        }
                        filterdatatest.push(Obj)
                    }
                    if(filter.type === 3){
                        let Obj;
                        Obj ={
                            filterId : filter._id,
                            type : filter.type ,
                            name : filter.name,
                            date : filter.date ? filter.date : Curtime
                        }
                        filterdatatest.push(Obj)
                    }
                    if(filter.type === 4){
                        let Obj;
                        Obj ={
                            filterId : filter._id,
                            type : filter.type ,
                            name : filter.name,
                            startDate : filter.startDate ? filter.startDate : Curtime ,
                            endDate : filter.endDate ? filter.endDate : endTime ,
                        }
                        filterdatatest.push(Obj)
                    }
                    if(filter.type === 5){
                        let Obj;
                        Obj ={
                            filterId : filter._id,
                            type : filter.type ,
                            name : filter.name,
                            time : filter.time ? filter.time : Curtime,
                        }
                        filterdatatest.push(Obj)
                    }
                    if(filter.type === 6){
                        let Obj;
                        Obj ={
                            filterId : filter._id,
                            type : filter.type ,
                            name : filter.name,
                            startTime : filter.startTime ? filter.startTime : Curtime,
                            endTime : filter.endTime ?  filter.endTime : Curtime
                        }
                        filterdatatest.push(Obj)
                    }
                    if(filter.type === 7){
                        let Obj;
                        Obj ={
                            filterId : filter._id,
                            type : filter.type ,
                            name : filter.name,
                            membersMinRange : filter.membersMinRange,
                            membersMaxRange : filter.membersMaxRange,
                            membersMaxRangeData : filter.membersMaxRangeData ? filter.membersMaxRangeData : "",
                            membersMinRangeData : filter.membersMinRangeData ? filter.membersMinRangeData : ''
                        }
                        filterdatatest.push(Obj)
                    }
                    if(filter.type === 8){
                        let Obj;
                        Obj ={
                            filterId : filter._id,
                            type : filter.type ,
                            name : filter.name,
                            singleSelect: this.state.locations,
                            singleSelectData : filter.singleSelectData ? filter.singleSelectData : this.state.location_val 
                        }
                        filterdatatest.push(Obj)
                        }
                    if(filter.type === 7){
                    this.setState({minMaxValue : [filter.membersMinRangeData , filter.membersMaxRangeData]})
                    }
                    })
                    this.setState( prevState =>({
                        filterlist : {
                            "Gupta resorts" : filterdatatest
                        }
                    }))
                    })

                    })
                    .catch(error =>{
                        console.log('primary error' , error)
                    })

                axios.get(`${API_BASE_URL}adminServiceList?userId=${this.uid}`)
                .then(r =>{
                    if(r.data.data.adminServiceList.length > 0){
                        const seviceId = r.data.data.adminServiceList[0]._id
                        axios.get(`${API_BASE_URL}getBookmark?userId=${this.uid}&serviceId=${seviceId}`)
                        .then(res =>{
                            this.setState({ Bookmarklist : res.data.data.vendorServiceList})
                        })
                        .catch(error =>{
                            console.log('bookmark error' , error)
                        })
                    }
                    
                })
                .catch(error =>{ 
                    console.log('adminlisterror', error)
                })

                axios.get(`${API_BASE_URL}getServiceReview`)
                .then(r=>{ 
                    this.setState({'reviews':r.data.data.result});
                })
    }

    //  for Show the More Filter pop
    filterPopUp = () =>{
        this.setState({filterpop : true})
    }

    // for Hide the More filter Pop
    filterPopDown = () => {
        this.setState({filterpop : false})
    }

    // for Add to bookmark heart icon color
    heart_icon = (id) =>{
        let added = false
        this.state.Bookmarklist .forEach(element => {
            if(element._id === id){
                added = true
            }  
        });
        return added ? "#F47824" : "#00000080"
    }

    // for get average reviews of service
    get_average_reviews = (id) =>{
        let count = 0;
        let rating = 0;
        this.state.reviews .forEach(element => {
            if(element.vendorServiceId === id){
                count++;
                rating += element.rating;
            }  
        });
        return count>0 ? (rating/count).toFixed(1) : 0;
    }
        
    // for Add to Bookmark
    Addfav = (id, isBookmark) => {
        const bookfav = {
            vendorServiceId : id,
            userId : this.uid ,
            type : 0
        }

        if(isBookmark==1){
              this.CancelBookmark(id)
        }else{
            axios.post(`${API_BASE_URL}bookmark` , bookfav)
            .then(respon => {
                window.location.reload();
                $(".heart-box").click(function(){
                    $(this).addClass("heart-box-in")
                })
            })
            .catch(error =>{
                console.log('bookmark error' , error)
            })
        }
        
    }

    togglePopup() { 
        this.setState({
          showPopup: !this.state.showPopup
        });
      } 
    // CanelBookmark
    CancelBookmark = (id) => {
        const bookfav = {
            vendorServiceId : id,
            userId : this.uid ,
            type : 1
        }
        axios.post(`${API_BASE_URL}bookmark` , bookfav)
        .then(respon => {
            window.location.reload();
        })
        .catch(error =>{
            console.log('bookmark error' , error)
        })
    }

    // for remove Applied filters
    RemoveFilter = ( filterid) => {
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const serviceId = this.props.match.params.serviceId
        const remove_filter = {
                userEventId : userEventId,
                filterId : filterid
        }
        axios.post(`${API_BASE_URL}removeFilter`, remove_filter)
        .then(respon => {
             axios.get(`${API_BASE_URL}serviceList?userEventId=${userEventId}&eventTypeId=${eventTypeId}&serviceId=${serviceId}`)
           // axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/${serviceId}/services`)
                .then(response =>{
                    this.setState({Booking : response.data.data.vendorServiceList , appliedfilter : response.data.data.appliedFilters , filterlist : response.data.data.filterList })
                })
                .catch(error => {
                    console.log('error g' , error)
                })
        })
        .catch(e => {
            console.log('error in remove filer' , e)
        })
        
    }
    
    // for Sorting    
    SelectChange = (e) =>{
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const serviceId = this.props.match.params.serviceId
        this.setState({Sortby : e.target.value })
        const timer = setTimeout(() => {
        var records = this.state.records;
        var sortby = this.state.Sortby
        var sortvalue = sortby.split("_")
        //axios.get(`${API_BASE_URL}serviceList?userEventId=${userEventId}&eventTypeId=${eventTypeId}&serviceId=${serviceId}&sort=${sortvalue[0]}&sortType=${sortvalue[1]}`)
        axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/${serviceId}/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}&sort=${sortvalue[0]}&sortType=${sortvalue[1]}&category=${this.state.filterby}&limit=${records}`)
        .then(res =>{
            this.setState({Booking : res.data.results[0].event_details.service_list})
        })
        .catch(err =>{
            console.log('sortby error' , err)
        })
        }, 500);
    }
    // for filter    
    SelectCategory = (e) =>{
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const serviceId = this.props.match.params.serviceId
        this.setState({filterby : e.target.value })
        const timer = setTimeout(() => {
        var records = this.state.records;
        var sortby = this.state.Sortby
        var sortvalue = sortby.split("_")
        axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/${serviceId}/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}&sort=${sortvalue[0]}&sortType=${sortvalue[1]}&category=${this.state.filterby}&limit=${records}`)
        .then(res =>{
            this.setState({Booking : res.data.results[0].event_details.service_list})
        })
        .catch(err =>{
            console.log('sortby error' , err)
        })
        }, 500);
    }

    // for No of records show    
    SelectRecords = (e) =>{
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        const serviceId = this.props.match.params.serviceId
        this.setState({records : e.target.value })
        const timer = setTimeout(() => {
        var records = this.state.records;
        var sortby = this.state.Sortby
        var sortvalue = sortby.split("_")
        console.log(sortvalue)
        //axios.get(`${API_BASE_URL}serviceList?userEventId=${userEventId}&eventTypeId=${eventTypeId}&serviceId=${serviceId}&sort=${sortvalue[0]}&sortType=${sortvalue[1]}`)
        axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/${serviceId}/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}&sort=${sortvalue[0]}&sortType=${sortvalue[1]}&category=${this.state.filterby}&limit=${records}`)
        .then(res =>{
            this.setState({Booking : res.data.results[0].event_details.service_list})
        })
        .catch(err =>{
            console.log('sortby error' , err)
        })
        }, 500);
    }

    // for Search
    handleChange1 = (e) => {
        this.setState({SearchInput : e.target.value})
            const timer = setTimeout(() => {
                const userEventId = this.props.match.params.userEventId
                const eventTypeId = this.props.match.params.eventTypeId
                const serviceId = this.props.match.params.serviceId
                if(e.target.value == ""){
                    axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/${serviceId}/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}`)
                    .then(res =>{
                        this.setState({Booking : res.data.results[0].event_details.service_list})
                    })
                    .catch(err =>{
                        console.log('sortby error' , err)
                    })
                }
                else{
                    axios.get(`${VENDOR_API_BASE_URL}user-event/${eventTypeId}/${userEventId}/${serviceId}/services?location=${this.state.location_val}&date=${this.state.edate}&ageGroup=${this.state.age}&minGuestRange=${this.state.membersMinRange}&maxGuestRange=${this.state.membersMaxRange}&startTime=${this.state.startTime}&endTime=${this.state.endTime}&search=${e.target.value}`)
                    .then(res =>{
                        this.setState({Booking : res.data.results[0].event_details.service_list})
                    })
                    .catch(err =>{
                        console.log('sortby error' , err)
                    })
                }
                
              }, 1500);
    }
    
    // Update filter
    UpdateFilter = () => {
                    const userEventId = this.props.match.params.userEventId;
                    const eventTypeId = this.props.match.params.eventTypeId;
                    const temp = []

                    Object.entries(this.state.filterlist).map((filterdata , index) =>{
                    filterdata[1].forEach((item , index) =>{
                        if(item.type === 1){
                            temp.push({
                                filterId: item.filterId,
                                type : item.type,
                                singleSelect : item.singleSelectData
            
                            });
                        }
                        if(item.type === 2){
                            temp.push({
                                filterId: item.filterId,
                                type : item.type,
                                multiSelect : item.multiSelectList
                            });
                        }
                        if(item.type === 3){
                            temp.push({
                                filterId: item.filterId,
                                type : item.type,
                                date : item.date,
                                hdate : moment(item.date).format("DD-MM-YYYY"),
                            });
                        }
                        if(item.type === 4){
                            temp.push({
                                filterId: item.filterId,
                                type : item.type,
                                startDate : item.startDate,
                                endDate : item.endDate
                            });
                        }
                        if(item.type === 5){
                            temp.push({
                                filterId: item.filterId,
                                type : item.type,
                                time : item.time
                            });
                        }
                        if(item.type === 6){
                            temp.push({
                                filterId: item.filterId,
                                type : item.type,
                                startTime : item.startTime,
                                endTime : item.endTime,
                                hstartTime : moment(item.startTime).format("HH:mm"),
                                hendTime : moment(item.endTime).format("HH:mm"),
                            });
                        }
                        if(item.type === 7){
                            temp.push({
                                filterId: item.filterId,
                                type : item.type,
                                membersMinRange : item.membersMinRangeData,
                                membersMaxRange : item.membersMaxRangeData
                            });
                        }
                        if(item.type === 8){
                            temp.push({
                                filterId: item.filterId,
                                type : item.type,
                                singleSelect : item.singleSelectData
            
                            });
                        }
                    })
                    })
                    var serviceId = "";
                    this.state.primary.forEach((p , index) =>{
                    serviceId = p.serviceId._id
                    })
              
                    const Filters = {
                    userEventId : userEventId,
                    eventTypeId  : eventTypeId, 
                    userId : this.uid,
                    filters : temp
                    }
                    axios.post(`${API_BASE_URL}event` , Filters)
                    .then(res => {
                        this.setState({filterpop : false})
                        axios.get(`${API_BASE_URL}serviceList?userEventId=${userEventId}&eventTypeId=${eventTypeId}&serviceId=${serviceId}`)
                        .then(response =>{
                            
                            this.setState({appliedfilter : response.data.data.appliedFilters})
                            window.location.reload()
                        })
                        .catch(error => {
                            console.log('error g' , error)
                        })
                        
                    })
                    .catch(e => {
                        console.log('filter error' , e.response)
                    })
      }
    
   
    //  for Get the multi values of Select Element
    getmultiSelect = (arr) => {
        const values = [];
        arr.forEach(item => values.push(item.value));
        return values;
    }

    AddOnDetail = () => {
        if(this.state.AdData == true){
            this.setState({AdData : false})
        }
        else{
            this.setState({AdData : true})
        }
    }


    // for back button
    Back_user = () =>{
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        this.props.history.push(`/venuedetail/${eventTypeId}/${userEventId}`)
    }

    // for Single Select filter
      SelectChanges = (e ,id) =>{
        const temp = {...this.state.filterlist}
        let index = -1;
        Object.entries(temp).map((filter , index) =>{
          filter[1].forEach((item , i) =>{
            if(item.filterId == id){
                index = i
                item.singleSelectData = e
            }
        })
        
        })
        this.setState({filterlist :  temp })
    }


    // for Select Single Date
    singleselectDate = (e , id) =>{
        const temp = {...this.state.filterlist}
        let index = -1;
        Object.entries(temp).map((filter , index) =>{
          filter[1].forEach((item , i) =>{
            if(item.filterId == id){
                index = i
                item.date = parseInt(e)
            }
        })
        
        })
        this.setState({filterlist :  temp })
    }

    // select Single Time filter
    singleselectTime = (e , id) =>{
      const temp = {...this.state.filterlist}
      let index = -1;
      
      Object.entries(temp).map((filter , index) =>{
        filter[1].forEach((item , i) =>{
          if(item.filterId == id){
              index = i
              item.time = parseInt(e)
          }
          
      })
      
      })
      this.setState({filterlist :  temp })
    }

    // select StartDate filter
    startDateRange = (e , id) =>{
      const temp = {...this.state.filterlist}
      let index = -1;
      
      Object.entries(temp).map((filter , index) =>{
        filter[1].forEach((item , i) =>{
          if(item.filterId == id){
              index = i
              item.startDate = parseInt(e)
          }
         
      })
      
      })
      this.setState({filterlist :  temp })
    }

    // select EndDate filter
    endDateRange = (e , id) =>{
      const temp = {...this.state.filterlist}
      let index = -1;
      
      Object.entries(temp).map((filter , index) =>{
        filter[1].forEach((item , i) =>{
          if(item.filterId == id){
              index = i
              item.endDate = parseInt(e)
          }
          
      })
      
      })
      this.setState({filterlist :  temp })
    }


    // select StartTime filter
    startTimeRange = (e , id) =>{
      const temp = {...this.state.filterlist}
      let index = -1;
      
      Object.entries(temp).map((filter , index) =>{
        filter[1].forEach((item , i) =>{
          if(item.filterId == id){
              index = i
              item.startTime = parseInt(e)
          }
          
      })
      
      })
      this.setState({filterlist :  temp })
    }

    // select StartTime filter
    endTimeRange = (e , id) =>{
      const temp = {...this.state.filterlist}
      let index = -1;
      
      Object.entries(temp).map((filter , index) =>{
        filter[1].forEach((item , i) =>{
          if(item.filterId == id){
              index = i
              item.endTime = parseInt(e)
          }
      })
      
      })
      this.setState({filterlist :  temp })
    }

    // Select multiChange filter
    MultiChange = (e  , id) =>{
      const temp = {...this.state.filterlist}
      let index = -1;
      Object.entries(temp).map((filter , index) =>{
        filter[1].forEach((item , i) =>{
          
          if(item.filterId == id){
              index = i
              item.multiSelectList = e
          }
      })
      
      })
      this.setState({filterlist :  temp })
    }

    // validation for location add input button
    locAdd = () => {
        let add = false;
        const temp = {...this.state.filterlist};
        temp.forEach((item , index) =>{
            if(item.type === 8 && item.location[0].address == ""){
                add = true
            }
        })
  
        return add;
    }

    // select range slider filter
    rangeSlider = (filtervalue , id) =>{
      
      const temp = {...this.state.filterlist}
      
        let index = -1;
        Object.entries(temp).map((filter , index) =>{
          filter[1].forEach((item , i) =>{
            if(item.filterId == id){
                index = i
                item.membersMinRangeData = filtervalue[0]
                item.membersMaxRangeData  = filtervalue[1]
            }
          })
        })
        this.setState({filterlist :  temp })
    }
    render(){
    const userEventId = this.props.match.params.userEventId
    const eventTypeId = this.props.match.params.eventTypeId
    const serviceId = this.props.match.params.serviceId
    const name = this.props.match.params.name    
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;

        var applied = [];
        this.state.appliedfilter.forEach((appliedfilter , index) =>{
            
            switch(appliedfilter.type){
                case 1 :
                    
                    applied[appliedfilter.type] = appliedfilter.singleSelect+"#"+appliedfilter.filterId
                break ;
                case 2 :
                   
                    applied[appliedfilter.type] = appliedfilter.multiSelect.toString()+"#"+appliedfilter.filterId; 
                break ;
                case 3 :
                    var date = moment(parseInt(appliedfilter.date)).format("DD-MM-YYYY")
                    applied[appliedfilter.type] = date+"#"+appliedfilter.filterId
                    
                break ;
                case 4 :
                        
                    var date1 = moment(parseInt(appliedfilter.startDate)).format("DD-MM-YYYY")
                    var date2 = moment(parseInt(appliedfilter.endDate)).format("DD-MM-YYYY")
                    applied[appliedfilter.type] = date1.concat(' / ' , date2)+"#"+appliedfilter.filterId
                   
                break ;
                case 5 :
                    
                    var time = moment(parseInt(appliedfilter.time)).format("hh:mm A")
                    applied[appliedfilter.type] = time+"#"+appliedfilter.filterId
                    
                break ;
                case 6 :
                     var time1 = moment(parseInt(appliedfilter.startTime)).format("hh:mm A")
                     var time2 = moment(parseInt(appliedfilter.endTime)).format("hh:mm A")
                    applied[appliedfilter.type] = time1 + "-" + time2 + "#"+appliedfilter.filterId 
                break ;
                case 7 :
                    applied[appliedfilter.type] = appliedfilter.membersMinRange+' - ' + appliedfilter.membersMaxRange+"#"+appliedfilter.filterId   
                break ;
                case 8 :
                    applied[appliedfilter.type] = appliedfilter.singleSelect+"#"+appliedfilter.filterId
                break ;
                    
                break ;

            }
            
            
        })

        const he = moment("15", "hh").format('LT');
        var date = new Date();
        var curTime = moment(date).format("hh:mm")
        var curtime = moment().format("x")
        var Curtime = +curtime
        var endTime1 = moment().add(1 , 'hours').format("x")
        var endTime = +endTime1

        return(

            <div className="booking-box">
                <HeaderInner  handleKeyPress={this.handleKeyPress} onChange={this.search_change}  />
                <div className="container">
                     <div className="row" style={{padding : "40px 0px" }}>
                        <div className="col-md-8">
                            <div className="left-content-box" >
                            <p className="paracontainer">
                                <a className="remove_anchor bold_me backa" href="#" onClick={this.Back_user} >
                                    <IoIosArrowBack /> BACK </a>
                            </p>
                            <div className="search-box ser-position">
                            <h2 className="font-type1">{this.props.match.params.name}</h2>
                            {/* onClick={() => this.GetSearch(this.state.SearchInput)} */}
                                <div className="box-flx"><input type="text" className="search-b search-t" placeholder="search"
                                  value={this.state.SearchInput} onChange={this.handleChange1}/><Link to="#" className="se-arch" ><BiSearch /></Link></div>
                            </div>
                            <div className="filter-box">
                                <div className="inner-filter" style={{flex  :"1"}}>
                                    <ul className="filter-btn flex-cen">
                                        <li className=""><Link to="#" onClick={this.filterPopUp}>Edit Filters <span>&#10095;</span></Link></li>
                                        <li className="sort-box"> 
                                        <select value={this.state.filterby} onChange={this.SelectCategory}>
                                            <option>Select Category</option>
                                            {this.state.filterCategory.map((cat,ind)=>{
                                                return <option value={cat._id}>{cat.featureName}</option>
                                            })}
                                        </select>
                                        </li>
                                        <li className="sort-box">
                                        <select value={this.state.Sortby} onChange={this.SelectChange}>
                                            <option>Sort by</option>
                                            <option value="ASC_price">Price :  low to high </option>
                                            <option value="DESC_price">Price : high to low</option>
                                            <option value="ASC_title"> Title : A to Z</option>
                                            <option value="DESC_title"> Title : Z to A</option>
                                        </select>
                                        </li>
                                        {/* <li className="sort-box">
                                        <select value={this.state.records} onChange={this.SelectRecords}>
                                            <option value="10">10 Records</option>
                                            <option value="20">20 Records</option>
                                            <option value="50">50 Records</option>
                                            <option value="100">100 Records</option>
                                        </select>
                                        </li> */}
                                    </ul>
                                    <ul className="filter-option">
                                        {
                                            applied.map((filter , index) => {
                                                var filtervalues=filter.split("#");
                                                var filtervalue=filtervalues[0]
                                                var filterid = filtervalues[1]
                                                return filtervalue !=''? <li key={index}><Link to="#">{filtervalue}</Link></li>:'';
                                               // return <li key={index}><Link to="#">{filtervalue}<span onClick={() => this.RemoveFilter(filterid)}>x</span></Link></li>
                                            })
                                        }
                                    </ul>
                                </div>
                                {
                                    this.state.filterpop == false ? "" : 
                                    <div className="filter-content" >    
                                    <div className="inside-content">
                                    <p onClick={this.filterPopDown} className="can-el-btn">X</p>
                                    <div className="">
                                    
                                    {
                                        Object.entries(this.state.filterlist).map((filter , index) => {
                                            return <div className="row">
                                                    {/*filter[1].length > 0 ? <h3 className="col-md-12" style={{textTransform : "capitalize" , marginBottom: "10px"}}>{this.state.Name}</h3> : null*/}
                                                    {filter[1].map((infiler , i) =>(
                                                       <div key={i} className="col-md-6" style={{marginBottom : '15px'}}>
                                                       {infiler.type === 1 && (
                                                       <div>
                                                           <h6 className="title-box"  style={{marginBottom : "10px"}}>{infiler.name} </h6>
                                                           <Select
                                                               options={infiler.singleSelect.map((option , index) => {
                                                                   return { label: option, value: option };
                                                               })}
                                                               defaultValue={infiler.singleSelectData != undefined  && infiler.singleSelectData != '' ? ({ label: infiler.singleSelectData, value: infiler.singleSelectData } ) : ''}
                                                               onChange={(e) => this.SelectChanges(e.value , infiler.filterId)}
                                                               className="basic-multi-select"
                                                               classNamePrefix="select"
                                                               /> 
                                                       </div>
                                                       )}
                                                       {infiler.type === 2 && (
                                                           <div>
                                                           <h6 className="title-box" style={{marginBottom : "10px"}}>{infiler.name} </h6>
                                                           <Select
                                                               options={infiler.multiSelect.map((option , index) => {
                                                                   return { label: option, value: option };
                                                               })}
                                                               defaultValue={
                                                                (infiler.multiSelectList != undefined) ?
                                                                infiler.multiSelectList.map((option, index) => {
                                                                  return { label: option, value: option };
                                                              }) : null}
                                                               className="basic-multi-select"
                                                               classNamePrefix="select"
                                                               isMulti
                                                               onChange={(e) => this.MultiChange(this.getmultiSelect(e), infiler.filterId)}
                                                               />
                                                       </div>
                                                       )}
                                                       {infiler.type === 3 && (
                                                           <div>
                                                           <h6 className="title-box" style={{marginBottom : "10px"}}>{infiler.name} </h6>
                                                           <div style={{position : "relative" }} className="SingleSelectDate">
                                                                <DatePicker minDate={new Date().setDate(new Date().getDate() + 1)}
                                                                showYearDropdown
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={new Date(infiler.date)}
                                                                onChange={(date) => this.singleselectDate(moment(date).format("x").toString() , infiler.filterId)} 
                                                                />
                                                                <span className="span-post"><FaCalendar /></span>
                                                            </div>    
                                                       </div>
                                                       )}
                                                       {infiler.type === 4 && (
                                                           <div>
                                                           <h6 className="title-box" style={{marginBottom : "10px"}}>{infiler.name}</h6>
                                                           <div className="trw-box Doubledatebox">
                                                           <div style={{position : "relative", marginRight : "5px"}} className="DoubleDate">
                                                                <DatePicker minDate={new Date()}
                                                                showYearDropdown
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={new Date(infiler.startDate)}
                                                                onChange={(date) => this.startDateRange(moment(date).format("x").toString() , infiler.filterId)} 
                                                                />
                                                                <span className="span-post"><FaCalendar /></span>
                                                                </div>
                                                                <div style={{position : "relative"}} className="DoubleDate">
                                                                <DatePicker minDate={new Date()}
                                                                showYearDropdown
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={new Date(infiler.endDate)}
                                                                onChange={(date) => this.endDateRange(moment(date).format("x").toString() , infiler.filterId)}
                  
                                                                />
                                                                <span className="span-post"><FaCalendar /></span>
                                                                </div>
                                                               
                                                           </div>  
                                                       </div>
                                                       )}
                                                       {infiler.type === 5 && (
                                                           <div>
                                                           <h6 className="title-box" style={{marginBottom : "10px"}}>{infiler.name} </h6>
                                                           <div style={{ display: "flex", justifyContent: "left" }}>
                                                               <input className="inpfld" type="time" id="appt"
                                                               defaultValue={infiler.time != undefined && infiler.time != ''
                                                                ? moment(parseInt(infiler.time)).format("HH:mm")
                                                                : curTime}
                                                               onChange={(e) => this.singleselectTime(moment("1 January 2021 "+e.target.value+":00" ).format("x") , infiler.filterId)}
                                                               />
                                                           </div>
                                                       </div>
                                                       )}
                                                       {infiler.type === 6 && (
                                                           <div>
                                                           <h6 className="title-box" style={{marginBottom : "10px"}}>{infiler.name} </h6>
                                                           <div style={{ display: "flex", justifyContent: "left" }} className="time-box">
                                                               <input className="col-border"
                                                               type="time"
                                                               id="st"
                                                               defaultValue={infiler.startTime != undefined && infiler.startTime != ''
                                                                ? moment(parseInt(infiler.startTime)).format("HH:mm")
                                                                : curTime}
                                                            //    value={infiler.startTime}
                                                               onChange={(e) => this.startTimeRange(moment("1 January 2021 "+e.target.value+":00" ).format("x") , infiler.filterId)}
                                                               />
                                                               <input className="col-border"
                                                               type="time"
                                                               id="et"
                                                               placeholder={infiler.endTime}
                                                               defaultValue={infiler.endTime != undefined && infiler.endTime != ''
                                                                ? moment(parseInt(infiler.endTime)).format("HH:mm")
                                                                : curTime}
                                                            //    value={infiler.endTime}
                                                               onChange={(e) => this.endTimeRange(moment("1 January 2021 "+e.target.value+":00" ).format("x") , infiler.filterId)}
                                                               />
                                                           </div>
                                                       </div>
                                                       )}
                                                       {infiler.type === 7 && (
                                                           <div>
                                                           <h6 className="title-box" style={{marginBottom : "10px"}}>{infiler.name} </h6>
                                                           <Row style={{ padding: "0px 10px" }}>
                                                               {infiler.membersMinRange}
                                                               <span style={{ marginLeft: "auto" }}>
                                                               {infiler.membersMaxRange}
                                                               </span>
                                                               </Row> 
                                                               <Range
                                                                    key={i}
                                                                    step={1}
                                                                    defaultValue={[infiler.membersMinRangeData,
                                                                        infiler.membersMaxRangeData
                                                                    ]}
                                                                    min={parseInt(infiler.membersMinRange)}
                                                                    max={parseInt(infiler.membersMaxRange)}
                                                                    tipFormatter={(filtervalue) => `${filtervalue}`}
                                                                    onAfterChange={(filtervalue) => {
                                                                        this.rangeSlider(filtervalue, infiler.filterId)
                                                                    }}
                                                                /> 
                                                       </div>
                                                       )}
                                                       {infiler.type === 8 && (
                                                       <div>
                                                           <h6 className="title-box"  style={{marginBottom : "10px"}}>{infiler.name} </h6>
                                                           <Select
                                                               options={infiler.singleSelect.map((option , index) => {
                                                                   return { label: option, value: option };
                                                               })}
                                                               defaultValue={infiler.singleSelectData != undefined  && infiler.singleSelectData != '' ? ({ label: infiler.singleSelectData, value: infiler.singleSelectData } ) : ''}
                                                               onChange={(e) => this.SelectChanges(e.value , infiler.filterId)}
                                                               className="basic-multi-select"
                                                               classNamePrefix="select"
                                                               /> 
                                                       </div>
                                                       )}
                                                   </div> 
                                                    ))} 
                                            </div>
                                        })
                                    }
                                         <div className="col-md-12" style={{textAlign :"center"}}>
                                            <button className="btn-typeb"
                                            onClick={this.UpdateFilter}><strong>Update Filters  </strong></button>
                                        </div> 
                                    </div>
                                    </div>
                                </div>
                                }
                            </div>
                                <div className="row" >
                                { 
                                    this.state.Booking.map((Booking , index) => {
                                        return <div className="col-md-6" style={{marginBottom : "20px"}} key={index}>
                                        <Link to={`/ServiceDetail/${Booking._id}/${userEventId}/${eventTypeId}/${serviceId}/${name}/${Booking.isSelected}`}>
                                        <div className="inner-cont-box" >
                                            <div className="inner-img-con" style={{ height : "188px" , borderRadius  :"8px"}}>
                                                <img src={Booking.images!=''?Booking.images[0]:default_img} alt={index + 1} />
                                                <Link to="#"  className="heart-box" style={{backgroundColor : this.heart_icon(Booking._id) }}><span onClick={() => this.Addfav(Booking._id, Booking.isBookmark)}><AiOutlineHeart /></span></Link>
                                                   {this.get_average_reviews(Booking._id)? <p className="pflex"><span>{this.get_average_reviews(Booking._id)}</span><FaStar /></p>:''}
                                            </div>
                                            <div className="info-content flex-type2">
                                                <div>
                                                    <h6>{Booking.title}  <span>{/*2.6km */}</span></h6>
                                                    <p>AED {Booking.price} / {jspriceType[Booking.priceType]}  </p>
                                                </div>
                                                <div>
                                                    {
                                                        Booking.isSelected ? <Link style={{color : "white", background:"#f47824"}}to={`/ServiceDetail/${Booking._id}/${userEventId}/${eventTypeId}/${serviceId}/${name}/${Booking.isSelected}`}>booked</Link> : <Link to={`/ServiceDetail/${Booking._id}/${userEventId}/${eventTypeId}/${serviceId}/${name}/${Booking.isSelected}`}>book</Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </Link>
                                    </div>          
                                    })
                                }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                                <div className="right-content-box box-shadow1" >
                            <h3 clasName="heada">{this.state.eventTitle}</h3>
                                {
                                    this.state.primary.map(function(primary , index){
                                        return <div key={index} className="right-inner-box design-diva" >
                                        <div>
                                    <h6 style={{fontSize : "14px" , textTransform : "capitalize"}}>{primary.serviceId.name}</h6>
                                            {primary.isSelected ?  <span className="tick-right"><TiTick/></span> :  ""  }
                                            <p className="design-divb">{primary.isSelected ? <span>{primary.serviceName}(<a href={`/booking/${userEventId}/${eventTypeId}/${primary.serviceId._id}/${primary.serviceId.name}`} style={{fontSize : "11px" , textTransform : "capitalize" ,color: "#F47824" , fontWeight: "600"}}>Change</a>)<br/><span style={{fontWeight:"bold"}}>AED {primary.priceType===0?primary.price:primary.price*primary.guests}</span></span> :<span>Select Service</span>} </p>
                                        </div>
                                        <div>
                                        {
                                            primary.isSelected  ? <a href={`/ServiceDetail/${primary.vendorserviceId}/${userEventId}/${eventTypeId}/${primary.serviceId._id}/${primary.serviceId.name}/${primary.isSelected }`} style={{fontSize : "11px" , textTransform : "capitalize" ,color: "#F47824" , fontWeight: "600"}}>Edit</a> :      <a href={`/booking/${userEventId}/${eventTypeId}/${primary.serviceId._id}/${primary.serviceId.name}`} style={{fontSize : "11px" , textTransform : "capitalize" ,color: "#F47824" , fontWeight: "600"}}>Add</a>
                                        }
                                        </div>
                                    </div>
                                    })
                                }
                                <p className="design-pa" onClick={this.AddOnDetail}>Add-Ons</p>
                                {
                                    this.state.AdData ?
                                        ""
                                    : <div>
                                    {
                                    this.state.Addons.map(function(Addons , index){
                                        return <div key={index} className="right-inner-box design-diva" >
                                        <div>
                                    <h6 style={{fontSize : "14px" , textTransform : "capitalize"}}>{Addons.serviceId.name}</h6>
                                    {Addons.isSelected ?  <span className="tick-right"><TiTick/></span> :  ""  }
                                    <p className="design-divb">{Addons.isSelected ? <span>{Addons.serviceName}(<a href={`/booking/${userEventId}/${eventTypeId}/${Addons.serviceId._id}/${Addons.serviceId.name}`} style={{fontSize : "11px" , textTransform : "capitalize" ,color: "#F47824" , fontWeight: "600"}}>Change</a>)<br/><span style={{fontWeight:"bold"}}>AED {Addons.priceType===0?Addons.price:Addons.price*Addons.guests}</span></span> :<span>Select Service</span>} </p>
                                        </div>
                                        <div>
                                        {
                                          Addons.isSelected  ? 
                                          <a href={`/ServiceDetail/${Addons.vendorserviceId}/${userEventId}/${eventTypeId}/${Addons.serviceId._id}/${Addons.serviceId.name}/${Addons.isSelected }`} style={{fontSize : "11px" , textTransform : "capitalize" ,color: "#F47824" , fontWeight: "600"}}>Edit</a> :
                                          Addons.serviceCount> 0 ?   <a href={`/booking/${userEventId}/${eventTypeId}/${Addons.serviceId._id}/${Addons.serviceId.name}`} style={{fontSize : "11px" , textTransform : "capitalize" ,color: "#F47824" , fontWeight: "600"}}>Add</a> : ''
                                        }
                                        </div>
                                    </div>
                                    })
                                }
                                </div>
                                } 
                                <div style={{textAlign : "center"}}>
                                { this.state.eventCost ? <Link to={`/Checkout/${userEventId}/${eventTypeId}/${this.uid}`} className="demoReqBtn btn btn-primary" ><span>AED {this.state.eventCost} | Book Now</span></Link>  :""}
                                </div>
                                </div>
                                <div className="inner-cont-box" >
                                    {this.state.showPopup ? 
                                    <LoginPopup
                                        closePopup={this.togglePopup.bind(this)}
                                        hideClose={false}
                                    />
                                    : null
                                    }          
                                </div>
                                {/* <div className="package-box" style={{borderRadius:" 8px" , backgroundColor: "#FFFFFF" , boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)" , padding : "20px", display:this.state.package}}>
                                <h2 style={{color : "#000" , fontSize : "18px" , paddingBottom  :"20px"}}>Free Talks</h2>
                                <a href="/contactus"><img src={freetalk} alt="" /></a><br/>
                                <center><span><strong>Want to know more</strong></span></center><br/>
                                <div className="inner-cont-box" >
                                    {this.state.showPopup ? 
                                    <LoginPopup
                                        closePopup={this.togglePopup.bind(this)}
                                        hideClose={false}
                                    />
                                    : null
                                    }          
                                </div>
                                </div> */}
                                <br/>
                                {/* <div className="package-box" style={{borderRadius:" 8px" , backgroundColor: "#FFFFFF" , boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)" , padding : "20px", display:this.state.package}}>
                                <a href="https://api.whatsapp.com/send?phone=+971566405353&text=Hi" target="blank"><img src={whatsapp} alt="" /></a><br/>
                                </div> */}
                        </div>
                     </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        userDetails : state.userDetailsReducer.userDetails,
    }
}
export default connect(mapStateToProps,null)(Booking);