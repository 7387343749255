//Local system 
// export const API_BASE_URL = "http://localhost:3000/user/"
// export const VENDOR_API_BASE_URL = "http://localhost:4007/api/v1/"
// export const BASE_URL = "http://localhost:3004/"
// export const STRIPE_PUB_KEY = "pk_test_51JmBgCSBZrGNm5T2EUyYh85Psegq2dDbc0wOMaE44C8pgbfBaxGlj4ZgZUZwVefqlUMOyMCtjMWs7F4wYDJiKmGe00ndTY5Ejn"
// export const ONESIGNALAPP = "cc630db8-fae7-4012-87e8-b1bc6a3905f6"

//Live system 
export const API_BASE_URL = "https://api.eventstan.com/user/"
export const BASE_URL = "https://customer.eventstan.com/"
export const VENDOR_API_BASE_URL = "https://vendorapi.eventstan.com/api/v1/"
export const STRIPE_PUB_KEY = "pk_live_51JXiMbFOgfcnaQyKTTAbyQzlFv6SNgbp8NNVPIJkoOs2sfEesdtslhad01iIPwvqtB5bV79m9Ht2ie3HX3ef1xv100gVxReV5M"
export const ONESIGNALAPP = "f0ccf17c-3bbc-4f74-979c-590fbf9e7214"

// UAT system
// export const API_BASE_URL = "https://uatapi.eventstan.com/user/"
// export const BASE_URL = "https://uatcustomer.eventstan.com/"
// export const VENDOR_API_BASE_URL = "https://uatvendorapi.eventstan.com/api/v1/"
// export const STRIPE_PUB_KEY = "pk_test_51JmBgCSBZrGNm5T2EUyYh85Psegq2dDbc0wOMaE44C8pgbfBaxGlj4ZgZUZwVefqlUMOyMCtjMWs7F4wYDJiKmGe00ndTY5Ejn"
// export const ONESIGNALAPP = "cc630db8-fae7-4012-87e8-b1bc6a3905f6"