import { useState } from 'react';
import NavBar from './NavBar';
import Form_org from "./../Form_org";
import Form_ind from './../Form_ind';
import {Helmet} from "react-helmet";
import './style.css';
function Partner() {
    const [individual,setindividual] = useState(true);

    const toggle_form = ()=>{
        setindividual(!individual);
    }

    var x, y;
    if (individual) {
        x = "partner-button2";
        y = "partner-button3";
    } else {
        x = "partner-button3";
        y = "partner-button2";
    }
  return (
    <>
        <NavBar/>
        <Helmet>
        <title>Party Planning in Dubai | EventStan</title>
        <meta name="description" content="We facilitate your party planning. Connect with customers & partner with us today with no registration fees" />
        </Helmet>
        <div className="header" style={{paddingLeft:'20px'}}>
            <div className="container"style={{paddingLeft:'0'}}>
                <div className='header-left-element'>
                    &#8220;We Facilitate, You Create&#8221;
                </div>
                <div className="centered-element">
                    <img src={'./v2Images/Logo.png'} />
                </div>
            </div>
        </div>
        <div className='partner_banner'>
            <div className='container'>
                <div className='left-partner-text'>
                    <h2>A Partnership to <br/>Accelerate our Growth</h2>
                    <p>EventStan is just where you need to be! With the digital era growing at such a rapid speed, we bring to the market a digital platform to showcase your services in the events industry.</p>
                    <a href="#who-can-join"><button className="partner-button">  <img src={'./v2Images/partners_button.png'} />Partner with us</button></a>
                    <p>We’d love to have you on board!</p>
                </div>
            </div>
        </div>
        <div className="why-with-eventstan">
        <div className='container'> 
        <div className='row'>
            <div className='col-md-12'>
            <div className="text-center">
                        <h4 className="black">Why Partner with EventStan?</h4>
                    </div>
            </div>
        </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className="image-1">
                    <img src={'./v2Images/why_eventstan1.png'}/>
                    <div class="desc-partner">
                        <img src={'./v2Images/hand_icon.png'}/>  
                        <h6>Pay Nothing</h6>
                        <p>No subscription or registration fee and enjoy hassle free payouts</p>
                    </div>  
                    </div>   
                </div>
                <div className='col-md-4'>        
                <div className="image-2">
                  <img src={'./v2Images/why_eventstan2.png'}/>
                <div class="desc-partner">
                        <img src={'./v2Images/brand.png'}/>  
                        <h6>Easy Find</h6>
                        <p>There is complete transparency on EventStan - showcase your brand identity your way</p>
                    </div>  
                </div>
                </div>
                <div className='col-md-4'>
                    <div className='image-3'>
                <img src={'./v2Images/why_eventstan3.png'}/>
                <div class="desc-partner">
                        <img src={'./v2Images/cooperation.png'}/>  
                        <h6>Partner Portal</h6>
                        <p>The EventStan Partner Portal is the hub for everything a partner needs to track, grow and manage their business</p>
                    </div> 
                    </div> 
                </div>
            </div>
        {/* 2nd */}
        <div className='row'>
                <div className='col-md-4'>
                    <div className='image-4'>
                    <img src={'./v2Images/why_eventstan4.png'}/>
                    <div class="desc-partner">
                        <img src={'./v2Images/target.png'}/>  
                        <h6>Retain more Customers</h6>
                        <p>Improved customer experience with us by your side, we go the extra mile </p>
                    </div>  
                    </div>   
                </div>
                <div className='col-md-4'>  
                <div className='image-5'>     
                <img src={'./v2Images/why_eventstan6.png'}/>
                <div class="desc-partner">
                        <img src={'./v2Images/network.png'}/>  
                        <h6>Generate New Leads</h6>
                        <p>Grow your network by going to market with the #1-portal in the event space</p>
                    </div>  
                    </div> 
                </div>
                <div className='col-md-4'>
                <div className='image-6'>     
                <img src={'./v2Images/why_eventstan7.png'}/>
                <div class="desc-partner">
                        <img src={'./v2Images/light-bulb.png'}/>  
                        <h6>Get Insider Access</h6>
                        <p>Learn about partnership opportunities, market trends and analytics. </p>
                    </div>  
                    </div>
                </div>
            </div>
        
        </div>
        </div>
        <div className='who-can-join' id='who-can-join'>
            <div className='who-can-join-inner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                        <div className='who-can-join-text'>
                            <h2>Who Can Join ?</h2>
                            <p>Any company or freelancer who provides a service in the events space is welcome, as long as it meets our listing guidelines. We would love to get to know you and your business better.</p>
                        </div>
                        </div>
                        <div className='col-md-6'>
                        <div className='who-can-join-right'>
                          <div className='button-flex'>
                            <button className={x} onClick={toggle_form} >Individual</button>
                            <button className={y} onClick={toggle_form}>Organization</button>
                          </div>
                          {individual ? <Form_ind /> : <Form_org />}
                          {/* <form className='partner-form'>
                          <div className="two-label">
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 no-padding">
                                        <label className="main-text">Full Name</label>
                                            <input
                                        placeholder="Enter first Name"
                                        type="text"
                                        name="firstName"
                                        required
                                    />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 no-right">
                                            <label className="main-text">Last Name</label>
                                            <input
                                        placeholder="Enter last Name"
                                        type="text"
                                        name="firstName"
                                        required
                                            />
                                        </div>
                                    </div>
                                  
                                  
                                    <div className="two-label">
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 no-padding">
                                        <label className="main-text">Email ID</label>
                                            <input
                                                placeholder="Enter Your Email"
                                                type="email"
                                                name="email"
                                                required
                                            />
                                           
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 no-right">
                                        <label className="main-text">Mobile Number</label>
                                            <input
                                                placeholder="+971"
                                                type="number"
                                                name="mobilenumber"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="two-label">
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 no-padding">
                                        <label className="main-text">Emirates</label>
                                        <select name="" id="" class="form-control">
                                        <option value="">--Select--</option>
                                        <option value="">Website/Graphic Designing</option>
                                        <option value="">Mobile Apps Development</option>
                                        <option value="">Ecommerce Development</option>
                                        <option value="">Digital Marketing</option>
                                    </select>
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 no-right">
                                        <label className="main-text">View Type</label>
                                        <select name="" id="" class="form-control">
                                        <option value="">--Select--</option>
                                        <option value="">Website/Graphic Designing</option>
                                        <option value="">Mobile Apps Development</option>
                                        <option value="">Ecommerce Development</option>
                                        <option value="">Digital Marketing</option>
                                    </select>
                                        </div>
                                    </div>
                                    <label className="main-text">Specialization</label>
                                     <select name="" id="" class="form-control">
                                        <option value="">--Select--</option>
                                        <option value="">Website/Graphic Designing</option>
                                        <option value="">Mobile Apps Development</option>
                                        <option value="">Ecommerce Development</option>
                                        <option value="">Digital Marketing</option>
                                    </select>
                                    <label className="main-text">Briefly introduce yourself:(Introduce Links/Refrences</label>
                                    <textarea
                                        placeholder="Write here max(250character)"
                                        type="password"
                                        name="password"
                                        required
                                    />
                                    <button type="submit" style={{background:'#F97A25'}}>Submit</button>
                                </form> */}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
export default Partner;