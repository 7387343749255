import { useState } from 'react';
import './style.css';
import './style2.css';
import NavBar from "./NavBar";
function DIYEvent() {

  return (
    <>
     
<NavBar />
    <div className="header" style={{paddingLeft:'20px'}}>
                <div className="container"style={{paddingLeft:'0'}}>
                    <div className='header-left-element'>
                    &#8220;We Facilitate, You Create&#8221;
                    </div>
                    <div className="centered-element">
                    <img src={'./v2Images/Logo.png'} />
                    </div>
                    {/* <div className="header-right-element">
                        Login/Register
                    </div> */}
                </div>
            </div>
        <div className='about'>
            <div className="about-inner">
                <div className='container'>
                    <div className='blog-text'>
                    <h4>DIY Event vs Event Planners: Why plan your own event?</h4>
                    <p>With the relaxed rules and curbs announced post the pandemic in UAE, Dubai particularly as we all know, is a highly coveted location to celebrate both corporate events and personal occasions. So, it comes as no surprise that most people have been
                         looking forward to once again attending and hosting parties/ events frequently. </p>   
                    <p>More often than not, the very thought of hosting an event and the time-consuming process they might have to go through tends to make one shy away from planning it themselves. Naturally, the question that lingers in the mind of most of these event hosts is, ‘Can I do this on my own? Do I really need to hire an event planner?’ While it’s not an easy decision to make, hopefully this article can help in easing your dilemma.</p>     
                    <h3>Event Managers and Event planning:</h3>
                    <p>So, what exactly does an event-planner do? Well typically an event planner is invested in your event right from the beginning until it wraps up.</p>
                    <p>This would include multiple discussions around booking your venue, selecting your caterer, planning your themed decoration, finding the best ways to keep your guests entertained and possibly giving out party favors.</p>
                    <img src={'./v2Images/diy-blog.jpg'} style={{width:'100%'}}/>
                    <p>
                    So, what exactly does an event-planner do? Well typically an event planner is invested in your event right from the beginning until it wraps up. This would include multiple discussions around booking your venue, selecting your caterer, planning your themed decoration, 
                    finding the best ways to keep your guests entertained and possibly giving out party favors.
                    </p>
                    <p>Firstly, all event planners do not possess the same level of competency. This means that it is not possible to know if the event will plan out exactly or even closely to how you’d pictured it. Keeping this in mind, it can be said that planning an event by yourself would be the best way to get the results you would want to see.  In addition, hiring an event planner has a significant impact on your budget and hence it may not be feasible for every event host. Moreover, after the pandemic, the concept of events has switched from rather large gatherings to smaller focused crowds which has made planning events more manageable, thus eliminating the need to hire an event planner altogether.</p>
                    <p>It is well known that event planners often handle multiple events simultaneously, which may often result in confusion and a generic event. It is particularly important to get an event planner who understands the culture of your guest list and the goals for the event, to tailor it to be just perfect for your audience. However, finding such an event planner is easier said than done. It requires a great deal of research, trust and in fact, finding the right event planner can be even more tedious than individually searching for vendors that fit your time and budget criteria. Moreover, not all events may require an event planner; birthdays, baby showers and other festive occasions are usually best planned by yourself in order to get that personalized feel.</p>
                    <p>So, does this mean that planning your own event would be a better option? </p>
                    <h3>DIY event planning:</h3>
                    <p><img src={'./v2Images/diy-blog22.jpg'}  style={{ float:'left'}}/>In some ways perhaps, it is the more feasible option as you get to directly talk to your vendors, negotiate your prices and without any extra commission charges. Additionally, handling and planning your own event can help in creating a unique event by creative ideas and themes that have not been used so commonly before. So yes, you can say that your wallet would be considerably fuller and your event a touch more unique. However, the question now is that, if you can plan an event with your personalized touch, why doesn’t everyone? Well, it’s because what you save in dimes goes in time.</p>
                    <p>Planning your event would require you to search for different vendors/ sources to procure your event requirements and this would start the tedious task of gathering contact details and comparing prices. Even after you’ve finalized your vendors and booked them, you would probably still need to keep following up with each vendor to ensure your event runs smoothly and as planned on the day. </p>
                    <p>Well, luckily enough I can provide a solution to put these concerns at rest at the very least, and that solution lies right in your pocket, and by that, I mean your mobile phone of course. The new marketplace in town and the 1st event planning app, EventStan, aims to tackle just these trepidations. </p>
                    <p>The app supports your event planning by creating a list of vendors based on basic filters entered. Once filtered, explore the city’s top venues, caterers, decorators, entertainers and so much more. After selecting and creating your event, all there’s left to do is sit back, relax and count the days to your event.</p>
                    <p>So, at the end of it all, I hope dear reader that you have been able to unravel at least some of those tangled threads and will now be able to decide on how you would like to plan your next event. </p>
             
                    </div>
                    </div>
                </div>
            </div> 
        
        
       
    </>
  );
}
export default DIYEvent;
