import React, { Component } from "react";
import './AppStyle.css';
import './left-menu.css';
import HeaderInner from "./HeaderUser";
import {IoIosArrowBack} from 'react-icons/io';
import { FaRegEdit, FaUnlock, FaRegCalendarAlt, FaBell, FaHandHolding, FaThermometerFull, FaSignInAlt } from "react-icons/fa";
import ChnPwd from "../../assets/chngPwd.jpg";
import LeftMenu from "./UserLeftMenu";
import axios from "axios";
import { API_BASE_URL } from "../../constant";
import {TiEye} from 'react-icons/ti';


class ChangePassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         newpassword:"",
         confirnPassword:"",
         error:"",
         success:"",
         viewPassword:false,
         reviewPassword:false,
      }

   }

   showPassword = ()=> {
   this.setState({viewPassword: !this.state.viewPassword})
   }

   reshowPassword = ()=> {
   this.setState({reviewPassword: !this.state.reviewPassword})
   }

   ValidatePassword(pass)
   {
      if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(pass))
      {
         return true
      }
      else{
         return false
      }   
   }

   changePasswordSubmit = (e)=> {
      e.preventDefault();
     const newpass = this.state.newpassword;
     const confPass = this.state.confirnPassword;
     if(newpass === ''){
      this.setState({error:'Please enter new password'})
      this.setState({success:''});
      return false;
     }
     if(confPass === ''){
      this.setState({error:'Please re-enter new password'})
      this.setState({success:''});
      return false;
     }
     if(!this.ValidatePassword(newpass)){
      this.setState({error:'Password should be 8 character minimum Alphanumeric must include one special character'})
      this.setState({success:''});
      return false;
     }
     if(newpass !== confPass){
      this.setState({error:'Confirm Password not match'})
      this.setState({success:''});
      return false;
     }
     else{
      const data = {
         'password':newpass
      }
      const header = {
         'authorization': localStorage.getItem('user-auth-token')
       }
      axios.post(`${API_BASE_URL}updatePassword`,data,{headers: header})
      .then(r=>{ 
         this.setState({success:'Password changed successfully'});
         this.setState({error:''});
      })
      .catch(e=>{
         this.setState({error:'Invalid Password'});
         this.setState({success:''});
      })
     }
   }

   changeHandler = (e)=> {
      const name = e.target.name;
      const value = e.target.value;

      this.setState({
         [name]:value
      });
   }
   // for back button
	Back_user = () => {
		this.props.history.replace('/userdashboard')
	}

    render(){
        return (
           <>
         <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
            <div className="container">
			<div className="row">
               <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="back-btn">
					      <br />
                     <a className="text-dark" href="#" onClick={this.Back_user}><IoIosArrowBack /> Back</a>
                  </div>
               </div>
            </div>
            <section className="application_sec">	
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-3 sidemenu">
                    <LeftMenu />
                  </div>
               <div className="col-md-9 col-sm-9 col-xs-9">
                  <div className="form_card form_blk">  
                     <form className="user_form">
                        <div className="row">
                           <div className="col-md-6 col-sm-12 col-xs-12">
                                 <div className="form-group">
                                    <h3>Change Password</h3>
                                 </div>
                                 <br/>
                                 <div className="form-group">
                                    <label for="exampleFormControlInput1">New Password*</label>
                                    <div className="input-group mb-2">
                                    <input type={this.state.viewPassword?'text':'password'} required className="form-control" name="newpassword" onChange={this.changeHandler} placeholder="Enter new password" />
                                    <div className="input-group-prepend">
                                       <div className="input-group-text" onClick={this.showPassword}><TiEye /></div>
                                    </div>
                                    </div>
                                 </div>
                                 <br />
                                 <div className="form-group">
                                    <label for="exampleFormControlInput1">Re-enter Password*</label>
                                    <div className="input-group mb-2">
                                    <input type={this.state.reviewPassword?'text':'password'} required className="form-control" name="confirnPassword" onChange={this.changeHandler} placeholder="Confirm new password" />
                                    <div className="input-group-prepend">
                                       <div className="input-group-text" onClick={this.reshowPassword}><TiEye /></div>
                                    </div>
                                    </div>
                                 </div>
                                 <span style={{color:'red'}}>{this.state.error}</span>
                                 <span style={{color:'green'}}>{this.state.success}</span>
                                 <br/>
                                 <button type="button" className="btn btn-primary" onClick={this.changePasswordSubmit}>Save Changes</button>
                           </div> 
                           <div className="col-md-6 col-sm-12 col-xs-12">
                                 <img src={ChnPwd} height="250px" alt="userImg" className="mb-4" />
                           </div>     
                        </div>    
                     </form>
					</div>
				</div>
			</div>
      </section>
	</div> 
   </> 
        );
    }
}

export default ChangePassword;