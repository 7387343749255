import React, { Component } from "react";
import "./Createevent.css";
import { Link } from 'react-router-dom'
import HeaderInner from "./components/HeaderInner";
import { API_BASE_URL, BASE_URL, STRIPE_PUB_KEY } from './constant'
import axios from "axios";
import { connect } from 'react-redux'
import AddCardpopup from "./components/addCardpopUp";
import CvvValidation from "./cvvValidation";
import PaymentForm from "./PaymentForm";
import CrdImg from "./assets/sprite-map.png";
import moment from 'moment';
import CheckoutForm from "./CheckoutForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { loadStripe } from "@stripe/stripe-js";
import { Elements,ElementsConsumer} from "@stripe/react-stripe-js";
const stripePromise = loadStripe(STRIPE_PUB_KEY);

class payment_new extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appliedfilter: [],
            title: "",
            totalPrice: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            bill_add: "",
            bill_city: "",
            bill_state: "",
            bill_zip: "",
            bill_country: "",
            ship_add: "",
            ship_city: "",
            ship_state: "",
            ship_zip: "",
            ship_country: "",
            totalPay: "0",
            addCradpopup: false,
            userCard: [],
            cvv: '',
            selectedCard: '',
            customerStipeID: '',
            loader: false,
            payment_err: '',
            paymentIntentId: '',
            client_secret: '',
            paymentMethodId: '',
            paymentData:[]
        }
    }


    removeCard = (cardId) => {
        confirmAlert({
            title: '',
            message: 'Do you want to remove this card?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem("user-auth-token")
                        const headers = {
                            'Content-Type': 'application/json',
                            'authorization': token
                        };
                        const data = {
                            'cardId': cardId
                        }
                        axios.post(`${API_BASE_URL}RemoveUserCard`, data, { headers })
                            .then(res => {
                                axios.post(`${API_BASE_URL}getUserCards`, { 'token': token }, { headers })
                                    .then((cardlist) => {
                                        this.setState({ userCard: cardlist.data.data });
                                    })
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        });
    }

    

    // makePayment = () => {
    //     if (this.state.selectedCard === '') {
    //         alert('Please select payment mode');
    //         this.setState({ payment_err: 'Please select payment mode' });
    //     }
    //     else {
    //         this.setState({ loader: true, payment_err: '' })
    //         const paymentData = {
    //             amount: this.state.totalPay,
    //             currency: 'inr',
    //             customer: this.state.customerStipeID,
    //             userID: this.props.match.params.userId,
    //             bookingID: this.props.match.params.eventBookingId,
    //             EventId: this.props.match.params.userEventId,
    //             cardId: this.state.selectedCard,
    //             returnUrl: `${BASE_URL}payment_auth`
    //         }
    //         axios.post(`${API_BASE_URL}hold-amount`, paymentData)
    //             .then(pres => {
    //                 const bookingData = {
    //                     paymentToken: pres.data.data.id,
    //                     cardId: this.state.selectedCard,
    //                     bookingID: this.props.match.params.eventBookingId,
    //                 }
    //                 axios.post(`${API_BASE_URL}add-payment-methodTo-booking`, bookingData)
    //                     .then(res => {
    //                         window.location = pres.data.data.next_action.redirect_to_url.url;
    //                     })
    //                     .catch(err => {
    //                         this.setState({ loader: false })
    //                         this.setState({ payment_err: 'Unable to hold payment.Please use other payment method.' });
    //                     })
    //             })
    //             .catch(perror => {
    //                 console.log(perror);
    //                 this.setState({ loader: false })
    //                 this.setState({ payment_err: 'Unable to hold payment.Please use other payment method.' });
    //             });
    //     }
    // }

    addCard = () => { 
        this.setState({ addCradpopup: true })
    }

    handleOnChange = (e) => {
        this.setState({ cvv: '' });
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            const paymentData = {
                amount: this.state.totalPay,
                currency: 'inr',
                customer: this.state.customerStipeID,
                userID: this.props.match.params.userId,
                bookingID: this.props.match.params.eventBookingId,
                EventId: this.props.match.params.userEventId,
                cardId: this.state.selectedCard,
                returnUrl: `${BASE_URL}payment_auth`
            }
            axios.post(`${API_BASE_URL}hold-amount`, paymentData)
                .then(pres => {
                    this.setState({ paymentIntentId: pres.data.data.id, client_secret: pres.data.data.client_secret, paymentMethodId: pres.data.data.payment_method,paymentData:paymentData })
                })
                .catch(perror => {
                    console.log(perror);
                    this.setState({ loader: false })
                    this.setState({ payment_err: 'Unable to hold payment.Please use other payment method.' });
                });
        });
    }

    Down_pop = () => {
        this.setState({ addCradpopup: false })
    }



    componentDidMount() {
        const uid = this.props.match.params.userId;
        const bookingId = this.props.match.params.eventBookingId;
        let token = localStorage.getItem("user-auth-token");
        //Get booking data
        axios.get(`${API_BASE_URL}getBookingData?userEventId=${this.props.match.params.userEventId}&bookingId=${bookingId}`)
            .then(r => {
                if (r.data.data.status === 1) {
                    window.location.href = "/userdashboard";
                }
                this.setState({ totalPay: r.data.data.intialPaymentPaid })
            })
            .catch(e => {
                console.log('save draft error', e)
            })

        axios.get(`${API_BASE_URL}getEventDraft?userEventId=${this.props.match.params.userEventId}&userId=${uid}`)
            .then(r => {
                this.setState({ title: r.data.data.data[0]._id.eventName, appliedfilter: r.data.data.data[0]._id.selectedFilters, eventImg: r.data.data.data[0]._id.eventTamplateData.image })
            })
            .catch(e => {
                console.log('save draft error', e)
            });
        const headers = {
            'Content-Type': 'application/json',
            'authorization': token
        };
        const data = {
            'token': token
        }
        axios.post(`${API_BASE_URL}getUserCards`, data, { headers })
            .then((cardlist) => {
                this.setState({ userCard: cardlist.data.data });
            })
        axios
            .post(`${API_BASE_URL}UserProfile`, data, { headers })
            .then(response => {
                this.setState({
                    firstName: response.data.data[0].firstName,
                    lastName: response.data.data[0].lastName,
                    email: response.data.data[0].email,
                    phoneNumber: response.data.data[0].phoneNumber,
                    bill_add: response.data.data[0].bill_add.address,
                    bill_city: response.data.data[0].bill_add.city,
                    bill_state: response.data.data[0].bill_add.state,
                    bill_zip: response.data.data[0].bill_add.zip,
                    bill_country: response.data.data[0].bill_add.country,
                    ship_add: response.data.data[0].ship_add.address,
                    ship_city: response.data.data[0].ship_add.city,
                    ship_state: response.data.data[0].ship_add.state,
                    ship_zip: response.data.data[0].ship_add.zip,
                    ship_country: response.data.data[0].ship_add.country,
                    customerStipeID: response.data.data[0].stripeId
                });
                if (response.data.data[0].stripeId === '') {
                    const customerdata = {
                        'id': response.data.data[0]._id,
                        'name': response.data.data[0].firstName,
                        'email': response.data.data[0].email,
                        'phoneNumber': response.data.data[0].phoneNumber,
                    }
                    axios.post(`${API_BASE_URL}CreateStripeCustomer`, customerdata, { headers })
                        .then(r => {
                            this.setState({ customerStipeID: r.data.data.stripeId });
                        })
                        .catch(error => {
                            console.log('Create customer error');
                        })
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    // for the Back
    Back_user = () => {
        const userEventId = this.props.match.params.userEventId
        const eventTypeId = this.props.match.params.eventTypeId
        this.props.history.push(`/venuedetail/${eventTypeId}/${userEventId}`)
    }

    render() {
        var applied = [];
        this.state.appliedfilter.forEach((appliedfilter, index) => {
            switch (appliedfilter.type) {
                case 1:
                    applied[appliedfilter.type] = appliedfilter.singleSelect + "#" + appliedfilter.filterId
                    break;
                case 2:
                    applied[appliedfilter.type] = appliedfilter.multiSelect + "#" + appliedfilter.filterId;
                    break;
                case 3:
                    var date = moment(parseInt(appliedfilter.date)).format("dddd, MMMM Do YYYY")
                    applied[appliedfilter.type] = date + "#" + appliedfilter.filterId
                    break;
                case 4:
                    var date1 = moment(parseInt(appliedfilter.startDate)).format("DD-MM-YYYY")
                    var date2 = moment(parseInt(appliedfilter.endDate)).format("DD-MM-YYYY")
                    applied[appliedfilter.type] = date1.concat(' / ', date2) + "#" + appliedfilter.filterId
                    break;
                case 5:
                    var time = moment(parseInt(appliedfilter.time)).format("hh:mm A")
                    applied[appliedfilter.type] = time + "#" + appliedfilter.filterId
                    break;
                case 6:
                    var time1 = moment(parseInt(appliedfilter.startTime)).format("hh:mm A")
                    var time2 = moment(parseInt(appliedfilter.endTime)).format("hh:mm A")
                    applied[appliedfilter.type] = time1 + "-" + time2 + "#" + appliedfilter.filterId
                    break;
                case 7:
                    applied[appliedfilter.type] = appliedfilter.membersMinRange + ' - ' + appliedfilter.membersMaxRange + "#" + appliedfilter.filterId
                    break;
                case 8:
                    applied[appliedfilter.type] = appliedfilter.singleSelect + "#" + appliedfilter.filterId
                    break;
                default:
                    break;
            }
        })
        return (
            <div id="checkout" className='checkout'>
                <HeaderInner />
                <br />
                <div className="container" style={{ padding: "30px 0px" }}>
                    {/*<p className="para-container">
        <a className="remove_anchor bold_me backa" href="#" onClick={this.Back_user}>
            <IoIosArrowBack />BACK</a>
        </p>*/}
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="border-bottom pb-2 ">
                                <div id="services" className="tab-pane active  my-4">
                                    <h2 className="page-title my-3 d-inline-block">Event Details</h2>
                                    <div className="border-outer mb-4">
                                        <div className=" p-4 d-flex justify-content-between align-items-center">
                                            <div className="inner-left event-title-payment">
                                                <h3 className="event-title">{this.state.title}</h3>
                                                <ul>
                                                    {
                                                        applied.map((filter, index) => {
                                                            var filtervalues = filter.split("#");
                                                            var filtervalue = filtervalues[0]
                                                            if (index === 6) {
                                                                return <p style={{ color: 'black' }} key={index}><span style={{ 'fontWeight': 900 }}>Event Time :</span> {filtervalue}</p>
                                                            }
                                                            if (index === 8) {
                                                                return <p style={{ color: 'black' }} key={index}><span style={{ 'fontWeight': 900 }}>Event Location :</span> {filtervalue}</p>
                                                            }
                                                            if (index === 3) {
                                                                return <p style={{ color: 'black' }} key={index}><span style={{ 'fontWeight': 900 }}>Event Date :</span> {filtervalue}</p>
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <h2 className="page-title my-3 d-inline-block">Personal Details</h2>
                                    <div className="border-outer mb-4">
                                        <div className=" p-4 d-flex justify-content-between align-items-center">
                                            <div className="inner-left event-title-payment">
                                                <ul>
                                                    <p style={{ color: 'black' }}><b>Name&nbsp;:&nbsp;</b> {this.state.firstName} {this.state.lastName}</p>
                                                    <p style={{ color: 'black' }}><b>Email Id&nbsp;:&nbsp;</b>{this.state.email}</p>
                                                    <p style={{ color: 'black' }}><b>Contact&nbsp;:&nbsp;</b>{this.state.phoneNumber} </p>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="page-title my-3 d-inline-block">Billing Address</h2>
                                    <div className="border-outer mb-4">
                                        <div className=" p-4 d-flex justify-content-between align-items-center">
                                            <div className="inner-left event-title-payment">
                                                <ul>
                                                    <p style={{ color: 'black' }}><b>Address&nbsp;:&nbsp;</b>{this.state.bill_add}</p>
                                                    <p style={{ color: 'black' }}><b>City&nbsp;:&nbsp;</b>{this.state.bill_city}</p>
                                                    <p style={{ color: 'black' }}><b>State&nbsp;:&nbsp;</b>{this.state.bill_state} </p>
                                                    <p style={{ color: 'black' }}><b>Country&nbsp;:&nbsp;</b>{this.state.bill_country} </p>
                                                    <p style={{ color: 'black' }}><b>Zip&nbsp;:&nbsp;</b>{this.state.bill_zip} </p>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="box-shadow p-4 mb-4">
                                <h2 className="page-title my-3 d-inline-block">Select a payment method</h2>
                                <span>Amount to be Paid : AED {this.state.totalPay}</span><br /><br />
                                { this.state.customerStipeID?<PaymentForm amount={this.state.totalPay} bookingId={ this.props.match.params.eventBookingId} customer={this.state.customerStipeID} />:'' }
                                

                                {this.state.userCard.map((card, index) => {
                                    // return <div key={index} className="border border-warning mb-3 position-relative add-on-box d-flex align-items-center justify-content-between">
                                    //     <div className="add-on">
                                    //         <h3 className="heading mb-0">Debit Card</h3>
                                    //         <h5>**** **** **** {card.cardNumber}</h5>
                                    //         {card._id===this.state.selectedCard?  <><Elements stripe={stripePromise}><CvvValidation intent={this.state.paymentIntentId} paymentMathod={this.state.paymentMethodId} secret={this.state.client_secret} paymentData={this.state.paymentData}/></Elements></>:'' }
                                    //         {/* {card._id === this.state.selectedCard ? <Elements stripe={stripePromise}><ElementsConsumer>{({ stripe, elements }) => (
                                    //             <CardCvcElement />
                                    //         )}</ElementsConsumer></Elements> : ''} */}
                                    //         <span onClick={(e) => this.removeCard(card._id)}>Remove</span>
                                    //     </div>
                                    //     <div className="add-on">
                                    //         <input type="radio" name="selectedCard" value={card._id} onChange={(e) => this.handleOnChange(e)} />
                                    //     </div>
                                    // </div>
                                })}

                                {/* <div className="border border-warning mb-3 position-relative add-on-box d-flex align-items-center justify-content-between">
                                    <div className="add-on upside">
                                        <h3 className="heading mb-0">Add New Card &nbsp;&nbsp;<Link onClick={this.addCard} className="clkhere">Click Here</Link></h3>
                                        <br />
                                        <img src={CrdImg} alt="img" />
                                    </div>
                                </div> */}
                                {/* {this.state.loader ? <CircularProgress disableShrink /> : <Link to="" onClick={this.checkcvv} className="d-block  btn btn-warning mt-4"><span className="pr-4 d-inline-block border-right">AED {this.state.totalPay}</span><span className="pl-3">Pay Now</span></Link>} */}
                                <span style={{ color: 'red' }}>{this.state.payment_err}</span>
                            </div>
                            {this.state.addCradpopup ? (
                                <div className="filter-content">
                                    <div className="filter-content-pop1">
                                        <p onClick={this.Down_pop} className="can-el-btn1">X</p>
                                        <AddCardpopup customerID={this.state.customerStipeID} />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetailsReducer.userDetails,
    }
}

export default connect(mapStateToProps, null)(payment_new);