import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { API_BASE_URL,BASE_URL } from '../../src/constant';
import axios from "axios";
import "./Popup.css";
import "../App.css";
import "./booking.css";
import { Redirect,Link } from "react-router-dom";
import {connect} from "react-redux";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import {TiSocialFacebookCircular,TiEye} from 'react-icons/ti';
import blogo from '../assets/EventStanBlack.png';

class LoginPopup extends React.Component{
  constructor(){
    super();
    this.state = { 
      loginUsername:'', 
      mobile:'none',
      loginDiv:"",
      otpDiv:"none",
      signupDiv:"none",
      signupOTPDiv:"none",
      passwordDiv:"none",
      otp:'',
      signupotp:'',
      loginError:'',
      otpError:'',
      loginPassworderror:'',
      passwordError:'none',
      error:'',
      loginPassword:'',
      SignupError:'',
      signotpError:'',
      newpassword:'',
      signpasswordError:'',
      viewPassword:false
     } 
  }

isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n); 
}

showPassword = ()=> {
  this.setState({viewPassword: !this.state.viewPassword})
}

ValidatePassword(pass)
{
   if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(pass))
   {
       return true
   }
   else{
       return false
   }   
}

ValidateEmail(mail) 
{
if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
{
   return true
}
else{
   return false
}
}

changeOption = () =>{
  this.setState({loginDiv:'',otpDiv:'none',signupDiv:'none',signupOTPDiv:'none',passwordDiv:'none'})
}

openSignUP = () =>{
  this.setState({loginDiv:'none',otpDiv:'none',signupDiv:'',signupOTPDiv:'none',passwordDiv:'none'})
}

openLogin = () =>{
  this.setState({loginDiv:'',otpDiv:'none',signupDiv:'none',signupOTPDiv:'none',passwordDiv:'none'})
}

myChangeHandler = (event) => {
    this.setState({[event.target.name]: event.target.value});
    if(this.isNumber(event.target.value)){
        this.setState({mobile:''})
    }
    else
    {
        this.setState({mobile:'none'})  
    }
}

myChangeHandler1 = (event) => {
  this.setState({[event.target.name]: event.target.value});
}

// Login With otp
requestOTP = () =>{
  const emailOrPhoneNumber = this.state.loginUsername;
  if(emailOrPhoneNumber =='' || emailOrPhoneNumber == null)
  {
      this.setState({loginError:'Please enter email or mobile number'})
      return false;
  }
  else
  {
      if(this.isNumber(emailOrPhoneNumber)){
          if(emailOrPhoneNumber.length != 9){
              this.setState({loginError:'Invalid Mobile Number'});
          }
          else{
              axios.post(`${API_BASE_URL}requestOTP`,{emailOrPhoneNumber:emailOrPhoneNumber,deviceType:'WEB'})
              .then(res=>{
                  localStorage.setItem("user-forget-token", res.data.data.accessToken);
                  this.setState({loginDiv:'none',otpDiv:'',signupDiv:'none',signupOTPDiv:'none',passwordDiv:'none'})
                  this.setState({otpError:'OTP sent successfully'})
              })
              .catch(err=>{
                  this.setState({loginError:'Mobile number does not exist'});
              })
          }
      }
      else{
          if(!this.ValidateEmail(emailOrPhoneNumber)){
              this.setState({loginError:'Invalid email address'});
          }
          else{
              axios.post(`${API_BASE_URL}requestOTP`,{emailOrPhoneNumber:emailOrPhoneNumber,deviceType:'WEB'})
              .then(res=>{
                  localStorage.setItem("user-forget-token", res.data.data.accessToken);
                  this.setState({loginDiv:'none',otpDiv:'',signupDiv:'none',signupOTPDiv:'none',passwordDiv:'none'})
                  this.setState({otpError:'OTP sent successfully'})
              })
              .catch(err=>{
                  this.setState({loginError:'Email address does not exist'});
              })
          } 
      }
  } 
}

//verify otp
verfyOTPRequest = ()=>{
  const otp = this.state.otp;
  if(otp =='' || otp == null){
      this.setState({otpError:'Please enter OTP'});
  }
  else{
      const type = this.isNumber(this.state.loginUsername) ? 'PHONE' : 'EMAIL' ;
      const header = {
          'authorization': localStorage.getItem('user-forget-token')
      }
      axios.post(`${API_BASE_URL}verifyOtp`,{otp:otp,type:type},{headers: header})
      .then(res=>{
        localStorage.setItem("user-auth-token", res.data.data.passwordResetToken);
        localStorage.setItem("user-auth-id", res.data.data._id);
        this.props.Userdata(res.data.data);
        if(localStorage.getItem('event_id') !==null){
          const data = {
            'eventID':localStorage.getItem('event_id'),
            'uid':res.data.data._id,
          };
          const header = {
            'authorization': localStorage.getItem('user-forget-token')
          }
           axios.post(`${API_BASE_URL}update-event`,data,{headers: header})
           .then((r)=>{
            localStorage.removeItem("user-forget-token");
            this.props.closePopup({});
            window.location.reload();
           }) 
           .catch(error => {
            this.setState({errors:error.res.data.message});
           });
        }
        else{
        localStorage.removeItem("user-forget-token");
        window.location.replace("create_event");
        }
      })
      .catch(err=>{
          this.setState({otpError:'OTP does not Match'});
      })
  } 
}
//Login with password
loginSubmit = ()=>{
  const emailOrPhoneNumber = this.state.loginUsername;
  const password = this.state.loginPassword;
  if(emailOrPhoneNumber =='' || emailOrPhoneNumber == null){
    this.setState({loginError:'Please enter email or mobile number',loginPassworderror:''});
    return false;
  }
  if(password =='' || password == null){
    this.setState({loginPassworderror:'Please enter password',loginError:''});
    return false;
  }
  else{
    const data = {
      'emailOrPhoneNumber':emailOrPhoneNumber,
      'password':password,
      'deviceType':'WEB',
      'notificationToken' : localStorage.getItem("user-notification-token")
    };
    axios.post(`${API_BASE_URL}login`, data)
      .then(response => { 
        localStorage.setItem("user-auth-token", response.data.data.accessToken);
        localStorage.setItem("user-auth-id", response.data.data._id);
        this.props.Userdata(response.data.data);
        if(localStorage.getItem('event_id') !==null){
          const data = {
            'eventID':localStorage.getItem('event_id'),
            'uid':response.data.data._id,
        };
        const header = {
          'authorization': localStorage.getItem('user-auth-token')
        }
        axios.post(`${API_BASE_URL}update-event`,data,{headers: header})
          .then((r)=>{
            this.props.closePopup({});
            window.location.reload();
          }) 
          .catch(error => {
            this.setState({loginPassworderror:error.response.data.message});
          });
      }
      else{
        window.location.replace("create_event");
      }
      this.props.closePopup({});
        this.setState({ loading: false, redirect : this.props.redirectStatus  });
      })
      .catch(error => {
        this.setState({loginPassworderror:error.response.data.message});
      });
  }    
}
//signup Submit
signupSubmit = ()=>{
  const emailOrPhoneNumber = this.state.loginUsername;
  if(emailOrPhoneNumber =='' || emailOrPhoneNumber == null){
    this.setState({SignupError:'Please enter email or mobile number'});
    return false;
  }
  else{
    if(this.isNumber(emailOrPhoneNumber)){
      if(emailOrPhoneNumber.length != 9){
        this.setState({SignupError:'Invalid Mobile Number'});
      }
      else{
        const data = {
          'emailOrPhoneNumber':emailOrPhoneNumber,
          'deviceType':'WEB',
          'notificationToken' : localStorage.getItem("user-notification-token")
        };
       axios.post(`${API_BASE_URL}signUp`, data)
          .then(response => { 
            localStorage.setItem("signup-token", response.data.data.accessToken);
            this.setState({loginDiv:'none',otpDiv:'none',signupDiv:'none',signupOTPDiv:'',passwordDiv:'none'})
          })
          .catch(error => {
            this.setState({SignupError:error.response.data.message});
          });
      }
    }
    else{
      if(!this.ValidateEmail(emailOrPhoneNumber)){
        this.setState({SignupError:'Invalid email address'});
      }
      else{
        const data = {
          'emailOrPhoneNumber':emailOrPhoneNumber,
          'deviceType':'WEB',
          'notificationToken' : localStorage.getItem("user-notification-token")
        };
       axios.post(`${API_BASE_URL}signUp`, data)
          .then(response => { 
            localStorage.setItem("signup-token", response.data.data.accessToken);
            this.setState({loginDiv:'none',otpDiv:'none',signupDiv:'none',signupOTPDiv:'',passwordDiv:'none'})
          })
          .catch(error => {
            this.setState({SignupError:error.response.data.message});
          });
      }
    }
  }
}
//resend signupotp
resendSignupOtp = () =>{
  const emailOrPhoneNumber = this.state.loginUsername;
  if(emailOrPhoneNumber =='' || emailOrPhoneNumber == null)
  {
      this.setState({signotpError:'Please enter email or mobile number'})
      return false;
  }
  else
  {
      if(this.isNumber(emailOrPhoneNumber)){
          if(emailOrPhoneNumber.length != 9){
              this.setState({signotpError:'Invalid Mobile Number'});
          }
          else{
              axios.post(`${API_BASE_URL}requestOTP`,{emailOrPhoneNumber:emailOrPhoneNumber,deviceType:'WEB'})
              .then(res=>{
                localStorage.setItem("signup-token", res.data.data.accessToken);
                this.setState({loginDiv:'none',otpDiv:'none',signupDiv:'none',signupOTPDiv:'',passwordDiv:'none'})
                this.setState({signotpError:'OTP sent successfully'})
              })
              .catch(err=>{
                  this.setState({signotpError:'Mobile number does not exist'});
              })
          }
      }
      else{
          if(!this.ValidateEmail(emailOrPhoneNumber)){
              this.setState({signotpError:'Invalid email address'});
          }
          else{
              axios.post(`${API_BASE_URL}requestOTP`,{emailOrPhoneNumber:emailOrPhoneNumber,deviceType:'WEB'})
              .then(res=>{
                localStorage.setItem("signup-token", res.data.data.accessToken);
                this.setState({loginDiv:'none',otpDiv:'none',signupDiv:'none',signupOTPDiv:'',passwordDiv:'none'})
                this.setState({signotpError:'OTP sent successfully'})
              })
              .catch(err=>{
                  this.setState({signotpError:'Email address does not exist'});
              })
          } 
      }
  } 
}

//verify signup otp
verfySignupOTP = ()=>{
  const signupotp = this.state.signupotp;
  const signupToken = localStorage.getItem('signup-token');
  if(signupotp =='' || signupotp == null){
      this.setState({signotpError:'Please enter OTP'});
  }
  else{
      const type = this.isNumber(this.state.loginUsername) ? 'PHONE' : 'EMAIL' ;
      const header = {
          'authorization': signupToken
      }
      axios.post(`${API_BASE_URL}verifyOtp`,{otp:signupotp,type:type},{headers: header})
      .then(res=>{
        localStorage.setItem("user-auth-token", signupToken);
        localStorage.setItem("user-auth-id", res.data.data._id);
        this.props.Userdata(res.data.data);
        this.setState({loginDiv:'none',otpDiv:'none',signupDiv:'none',signupOTPDiv:'none',passwordDiv:''})
      })
      .catch(err=>{
          this.setState({signotpError:'OTP does not Match'});
      })
  } 
}
// update password on signup
updatePassword=()=>{
  const password = this.state.newpassword;
  if(password == '' || password == null){
      this.setState({signpasswordError:'Please enter password'})
      return false
  }
  else{
    if(!this.ValidatePassword(password)){
      this.setState({passwordError:''}); 
    }
    else{
      this.setState({passwordError:'none'});
      const data = {
        'password':password
      }
      const header = {
        'authorization': localStorage.getItem('signup-token')
      }
      
      axios.post(`${API_BASE_URL}updatePassword`,data,{headers:header})
      .then(response=>{
        localStorage.removeItem("signup-token");
        if(localStorage.getItem('event_id') !==null){
          const data = {
            'eventID':localStorage.getItem('event_id'),
            'uid':response.data.data._id,
        };
        const header = {
          'authorization': localStorage.getItem('user-auth-token')
        }
        axios.post(`${API_BASE_URL}update-event`,data,{headers: header})
          .then((r)=>{
            this.props.closePopup({});
            window.location.reload();
          }) 
          .catch(error => {
            console.log('event update error')
          });
      }
      else{
        window.location.replace("create_event");
      }
      })
      .catch(error=>{
        this.setState({signpasswordError:error.response.data.message})
      })
    }
    
  }
}

  responseGoogleSuccess = (response) => {
    const data = {
      'socialMediaId':response.googleId,
      'email': response.profileObj.email,
      'firstName': response.profileObj.name,
      'loginType':'GOOGLE',
      'deviceType':'WEB',
      'deviceToken':'12345678',
      'notificationToken' : localStorage.getItem("user-notification-token")
    };
    axios.post(`${API_BASE_URL}socialMedia`, data)
    .then(response => { 
      localStorage.setItem("user-auth-token", response.data.data.accessToken);
      localStorage.setItem("user-auth-id", response.data.data._id);
      this.props.Userdata(response.data.data);
      if(localStorage.getItem('event_id') !==null){
        const data = {
          'eventID':localStorage.getItem('event_id'),
          'uid':response.data.data._id,
        };
        const header = {
          'authorization': localStorage.getItem('user-auth-token')
        }
         axios.post(`${API_BASE_URL}update-event`,data,{headers: header})
         .then((r)=>{
          window.location.reload();
         }) 
         .catch(error => {
          this.setState({errors:error.response.data.message});
         });
      }
      else{
        window.location.replace("create_event");
      }
      this.props.closePopup({});
      this.setState({ loading: false, redirect : this.props.redirectStatus  });
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  responseGoogleFail = (response) => {
    console.log(response);
  }

  responseFacebook = (response) => {
    const data = {
      'socialMediaId':response.id,
      'email': response.email,
      'firstName': response.name,
      'loginType':'FACEBOOK',
      'deviceType':'WEB',
      'deviceToken':'123456789',
      'notificationToken' : localStorage.getItem("user-notification-token")
    };
    axios.post(`${API_BASE_URL}socialMedia`, data)
    .then(response => { 
      localStorage.setItem("user-auth-token", response.data.data.accessToken);
      localStorage.setItem("user-auth-id", response.data.data._id);
      this.props.Userdata(response.data.data);
      if(localStorage.getItem('event_id') !==null){
        const data = {
          'eventID':localStorage.getItem('event_id'),
          'uid':response.data.data._id,
        };
        const header = {
          'authorization': localStorage.getItem('user-auth-token')
        }
         axios.post(`${API_BASE_URL}update-event`,data,{headers: header})
         .then((r)=>{
          window.location.reload();
         }) 
         .catch(error => {
          this.setState({errors:error.response.data.message});
         });
      }
      else{
        window.location.replace("create_event");
      }
      this.props.closePopup({});
      this.setState({ loading: false, redirect : this.props.redirectStatus  });
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  render(){
    return (
      <div className='filter-content'>
        <div className='filter-content-pop'>
        {this.props.hideClose?<p onClick={this.props.closePopup} className="can-el-btn1">X</p>:''}
        {/*Login Div */}
        <div style={{ display: this.state.loginDiv }}>
        <span style={{textAlign:'center', display: 'block'}}><img src={blogo} /></span>
        <br/>
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1" className="thklab">Email id/Mobile number</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend" style={{display:this.state.mobile}}>
                    <div className="input-group-text">+971</div>
                  </div>
                  <input 
                  type="text"
                  className="form-control" 
                  value={this.state.loginUsername} 
                  name="loginUsername" 
                  onChange={this.myChangeHandler} 
                  />
                  </div>
                </div>
              </div>
          </div>
          <span style={{color:'red'}}>{this.state.loginError}</span>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1" className="thklab">Password </label>
                  <div className="input-group mb-2">
                  <input 
                  type={this.state.viewPassword?'text':'password'}
                  className="form-control" 
                  value={this.state.loginPassword} 
                  name="loginPassword" 
                  onChange={this.myChangeHandler1} 
                  />
                  <div className="input-group-prepend">
                    <div className="input-group-text" onClick={this.showPassword}><TiEye /></div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <span style={{color:'red'}}>{this.state.loginPassworderror}</span>
            <button className="btn btn-primary fullbtn" onClick={this.loginSubmit}>Login</button>
            <button className="btn btn-primary fullbtn" onClick={this.requestOTP}>Request OTP</button>
            <div className="rightTxt" >
                  <Link to="/forget" className="orangeTxt">FORGOT PASSWORD?</Link>  
            </div>     
            <div className="rightTxt">
                  Don't have an account? <button onClick={this.openSignUP} className="orglab">Sign Up</button>           
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12" >
              <FacebookLogin
             appId ="3391569767772617"
              autoLoad={false}
              fields="name,email,picture"
              callback={this.responseFacebook} 
              cssClass="my-facebook-button-class" 
              textButton="Facebook"
              redirectUri = "window.location.href"
              isMobile = {false}
              icon={<TiSocialFacebookCircular />}/>
              </div>
             <div className="col-md-12 col-sm-12 col-xs-12">
                <GoogleLogin
                  clientId="908075444068-pimnfg386p54vk8okq33cgeau82jkf2q.apps.googleusercontent.com"
                  buttonText="Continue with Google"
                  onSuccess={this.responseGoogleSuccess}
                  onFailure={this.responseGoogleFail}
                  cookiePolicy={'single_host_origin'} 
                  className="my-google-button"
                />
              </div>
            </div> 
        </div>
        {/* Enter OTP Div*/}
        <div style={{ display: this.state.otpDiv }}>
        <span style={{textAlign:'center', display: 'block'}}><img src={blogo} /></span>
        <br/>
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
              <span style={{color:'blue'}}>{this.isNumber(this.state.loginUsername)?'+971'+this.state.loginUsername:this.state.loginUsername}</span><span onClick={this.changeOption} style={{color:'blue',cursor: 'pointer'}}>(Change)</span>
              </div>
            </div>
          </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1" className="thklab">Enter OTP </label>
                  <div className="input-group mb-2">
                  <input 
                  type="text"
                  className="form-control" 
                  value={this.state.otp} 
                  name="otp" 
                  onChange={this.myChangeHandler} 
                  />
                  <div class="input-group-append">
                      <span class="input-group-text" onClick={this.requestOTP} style={{color:'blue',cursor: 'pointer'}}>Resend</span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <span style={{color:'red'}}>{this.state.otpError}</span>
            <button className="btn btn-primary fullbtn" onClick={this.verfyOTPRequest}>Submit</button>  
            <div className="rightTxt">
                  Don't have an account? <button onClick={this.openSignUP} className="orglab">Sign Up</button>           
            </div>
        </div>
        {/*signup Div */}
        <div style={{ display: this.state.signupDiv }}>
        <span style={{textAlign:'center', display: 'block'}}><img src={blogo} /></span>
        <br/>
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1" className="thklab">Email id/Mobile number</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend" style={{display:this.state.mobile}}>
                    <div className="input-group-text">+971</div>
                  </div>
                  <input 
                  type="text"
                  className="form-control" 
                  value={this.state.loginUsername} 
                  name="loginUsername" 
                  onChange={this.myChangeHandler} 
                  />
                  </div>
                </div>
              </div>
          </div>
          <span style={{color:'red'}}>{this.state.SignupError}</span>
          <button className="btn btn-primary fullbtn" onClick={this.signupSubmit}>Continue</button>  
            <div className="rightTxt">
                  Don't have an account? <button onClick={this.openLogin} className="orglab">Login</button>           
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
              <FacebookLogin
              appId="436787061248205"
              autoLoad={false}
              fields="name,email,picture"
              callback={this.responseFacebook} 
              cssClass="my-facebook-button-class" 
              textButton="Facebook"
              icon={<TiSocialFacebookCircular />}/>
              </div>
             <div className="col-md-12 col-sm-12 col-xs-12 ">
                <GoogleLogin
                  clientId="908075444068-pimnfg386p54vk8okq33cgeau82jkf2q.apps.googleusercontent.com"
                  buttonText="Google"
                  onSuccess={this.responseGoogleSuccess}
                  onFailure={this.responseGoogleFail}
                  cookiePolicy={'single_host_origin'} 
                  className="my-google-button"
                />
              </div>
            </div> 
        </div>
        {/*signup otp Div */}
        <div style={{ display: this.state.signupOTPDiv }}>
        <span style={{textAlign:'center', display: 'block'}}><img src={blogo} /></span>
        <br/>
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
              <span style={{color:'blue'}}>{this.isNumber(this.state.loginUsername)?'+971'+this.state.loginUsername:this.state.loginUsername}</span>
              </div>
            </div>
          </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1" className="thklab">Enter OTP </label>
                  <div className="input-group mb-2">
                  <input 
                  type="text"
                  className="form-control" 
                  value={this.state.signupotp} 
                  name="signupotp" 
                  onChange={this.myChangeHandler} 
                  />
                  <div class="input-group-append">
                      <span class="input-group-text" onClick={this.resendSignupOtp} style={{color:'blue',cursor: 'pointer'}}>Resend</span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          <span style={{color:'red'}}>{this.state.signotpError}</span>
          <button className="btn btn-primary fullbtn" onClick={this.verfySignupOTP}>Submit</button>  
        </div>
        {/*Create password div */}
        <div style={{ display: this.state.passwordDiv }}>
        <span style={{textAlign:'center', display: 'block'}}><img src={blogo} /></span>
        <br/>
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
              <span style={{color:'blue'}}>{this.isNumber(this.state.loginUsername)?'+971'+this.state.loginUsername:this.state.loginUsername}</span>
              </div>
            </div>
          </div>
            <div className="row">
            {/* <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1" className="thklab">Enter first name </label>
                  <div className="input-group mb-2">
                  <input 
                  type="test"
                  className="form-control" 
                  value={this.state.newpassword} 
                  name="newpassword" 
                  onChange={this.myChangeHandler} 
                  />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1" className="thklab">Enter last name </label>
                  <div className="input-group mb-2">
                  <input 
                  type="test"
                  className="form-control" 
                  value={this.state.newpassword} 
                  name="newpassword" 
                  onChange={this.myChangeHandler} 
                  />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1" className="thklab">Enter mobile number </label>
                  <div className="input-group mb-2">
                  <input 
                  type="test"
                  className="form-control" 
                  value={this.state.newpassword} 
                  name="newpassword" 
                  onChange={this.myChangeHandler} 
                  />
                  </div>
                </div>
              </div> */}
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1" className="thklab">Enter Password </label>
                  <div className="input-group mb-2">
                  <input 
                  type="password"
                  className="form-control" 
                  value={this.state.newpassword} 
                  name="newpassword" 
                  onChange={this.myChangeHandler} 
                  />
                  </div>
                </div>
              </div>
            </div>
            <div style={{display:this.state.passwordError}}>
                <ul>
                    <li><span style={{color:'red'}}>Password must be 8 characters</span></li>
                    <li><span style={{color:'red'}}>At least one uppercase letter</span></li>
                    <li><span style={{color:'red'}}>One lowercase letter</span></li>
                    <li><span style={{color:'red'}}>One number and one special character</span></li>
                </ul>
            </div>
          <span style={{color:'red'}}>{this.state.signpasswordError}</span>
          <button className="btn btn-primary fullbtn" onClick={this.updatePassword}>Submit</button>  
        </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
  return{
      UserEventfield : state.UserEventIdreducer.fields
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
    Userdata : (payload) =>{dispatch({type:"USER_DATA" , payload})},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginPopup);