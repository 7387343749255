import React, { Component } from "react";
import './AppStyle.css';
import HeaderInner from "./../HeaderInner";
import { API_BASE_URL,BASE_URL } from "../../constant";
import axios from "axios";
import 'react-phone-number-input/style.css';

class ReferenceLogin extends Component{
    constructor(props){
        super();
        this.state = {
            referBy:'',
            referId:'',
            valid: true,
            contactno:'',
            fname:'',
            lname:'',
            email:'',
            password:'',
            fnameErr:'',
            lnameErr:'',
            emailErr:'',
            contactnoErr:'',
            passwordErr:'',
            passwordError:'none',
            otpdiv:'none',
            signupdiv:'',
            otp:'',
            otpErr:'',
            signupError:''
        }
    }
componentDidMount(){
    const uid =  this.props.match.params.uid;
    axios.post(`${API_BASE_URL}UserProfileById`,{uid:uid})
    .then(res=>{
        this.setState({refralErr:'', referId:res.data.data[0]._id })
        this.setState({referBy:res.data.data[0].firstName !==''? res.data.data[0].firstName+' '+res.data.data[0].lastName:'Anonymous',valid:false})
    })
    .catch(err=>{
       this.setState({refralErr:'Invalid referral link'})
    })
}

on_login_tele_change = (e) => { 
    this.setState({ contactno: e })
}

isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n); 
}

validateString(_string)
{
  let matchPattern =_string.match(/\d+/g);
  if (matchPattern != null) {
    return false;
   }
  else{
    return true;
 }
}

ValidatePassword(pass)
{
   if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(pass))
   {
       return true
   }
   else{
       return false
   }   
}

ValidateEmail(mail) 
{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
        return true
        }
    else{
        return false
        }
}

changeHandler = (event) => {
    this.setState({[event.target.name]: event.target.value});
}

register = () => {
   const fname = this.state.fname;
   const lname =  this.state.lname;
   const email = this.state.email;
   const contactno = this.state.contactno;
   const password = this.state.password;

    if(fname === '' || fname === null){
       this.setState({fnameErr:'Please enter first name',lnameErr:'',emailErr:'',contactnoErr:'',passwordErr:'',passwordError:'none',fname:''})
       return false;
    }
    else if(!this.validateString(fname)){
        this.setState({fnameErr:'Invalid first name',lnameErr:'',emailErr:'',contactnoErr:'',passwordErr:'',passwordError:'none',fname:''})
        return false;
    }
    else if(email==='' || email=== null){
        this.setState({fnameErr:'',lnameErr:'',emailErr:'Please enter email address',contactnoErr:'',passwordErr:'',passwordError:'none',email:''})
        return false;
    }
    else if(!this.ValidateEmail(email)){
        this.setState({fnameErr:'',lnameErr:'',emailErr:'Invalid email address',contactnoErr:'',passwordErr:'',passwordError:'none',email:''})
        return false;
    }
    else if(contactno==='' || contactno=== null){
        this.setState({fnameErr:'',lnameErr:'',emailErr:'',contactnoErr:'Please enter contact number',passwordErr:'',passwordError:'none',contactno:''})
        return false;
    }
    else if(!this.isNumber(contactno) || contactno.length !== 9){
        this.setState({fnameErr:'',lnameErr:'',emailErr:'',contactnoErr:'Invalid contact number',passwordErr:'',passwordError:'none',contactno:''})
        return false;
    }
    else if(password==='' || password=== null){
        this.setState({fnameErr:'',lnameErr:'',emailErr:'',contactnoErr:'',passwordErr:'Please enter password',passwordError:'none',password:''})
        return false;
    }
    else if(!this.ValidatePassword(password)){
        this.setState({fnameErr:'',lnameErr:'',emailErr:'',contactnoErr:'',passwordErr:'Invalid password!',passwordError:'',password:''})
        return false;
    }
    else
    {
        const data = {
            deviceType: 'WEB',
			firstName: fname,
            lastName: lname,
            email: email,
            phoneNumber: contactno,
            referBy: this.state.referId,
			password: password
        }
    axios.post(`${API_BASE_URL}referralsignUp`,data)
        .then(res=>{
            localStorage.setItem("signup-token", res.data.data.accessToken);
            this.setState({signupdiv:'none',otpdiv:'',otpErr:'OTP have sent to your mobile number'})
        })
        .catch(err=>{
            this.setState({fnameErr:'',lnameErr:'',emailErr:'',contactnoErr:'',passwordErr:'',passwordError:'',password:''})
            this.setState({signupError:err.response.data.message})
        })
    } 
}

//verify signup otp
verfySignupOTP = ()=>{
    const signupotp = this.state.otp;
    const signupToken = localStorage.getItem('signup-token');
    if(signupotp ==='' || signupotp === null){
        this.setState({otpErr:'Please enter OTP'});
    }
    else{
        const type = 'PHONE' ;
        const header = {
            'authorization': signupToken
        }
        axios.post(`${API_BASE_URL}verifyOtp`,{otp:signupotp,type:type},{headers: header})
        .then(res=>{
            localStorage.removeItem("signup-token");
            this.setState({otpErr:'OTP successfully verify'});
            window.setTimeout(function(){
                window.location.href = `${BASE_URL}login`;
            }, 3000);
        })
        .catch(err=>{
            this.setState({otpErr:'OTP does not Match'});
        })
    } 
  }

render(){  
    if (localStorage.getItem('user-auth-token') !== '') {
        window.location.href = `${BASE_URL}create_event`;
    }   
    return (
    <>
<HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
<div className="imgback">
<div className="container">
    <section className="application_sec">	
        <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form_card form_blk whtback">  
                     <h3>Reference Sign up</h3>
                    {/*Signup form*/}
                     <div class="card-body p-t-20"  style={{display:this.state.signupdiv}}>
	   					<div className="row">
		  					<div className="col-md-6 col-sm-12 col-xs-12">
			 					<div className="form-group">
									<label for="exampleFormControlInput1">First name</label>
									<input type="text" 
                                    className="form-control" 
                                    name="fname" 
                                    value={this.state.fname} 
                                    onChange={this.changeHandler} 
                                    placeholder="Enter your name" />
                                    <span style={{color:'red'}}>{this.state.fnameErr}</span>
			 					</div>
		  					</div>
		  					<div className="col-md-6 col-sm-12 col-xs-12">
			 					<div className="form-group">
									<label for="exampleFormControlInput1">Last name</label>
									<input type="text" 
                                    className="form-control" 
                                    name="lname" 
                                    value={this.state.lname} 
                                    onChange={this.changeHandler} 
                                    placeholder="Enter last name" />
                                    <span style={{color:'red'}}>{this.state.lnameErr}</span>
			 					</div>
		  					</div>
	   					</div>
	   					<div className="row">
		  					<div className="col-md-6 col-sm-12 col-xs-12">
			 					<div className="form-group">
									<label for="exampleFormControlInput1">Email ID</label>
									<input type="email" 
                                    className="form-control" 
                                    name="email" 
                                    value={this.state.email} 
                                    onChange={this.changeHandler}
                                    placeholder="Enter Email ID " />
                                    <span style={{color:'red'}}>{this.state.emailErr}</span>
			 					</div>
		  					</div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
			 					<div className="form-group">
									<label for="exampleFormControlInput1">Contact Number</label>
									<div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                           <div className="input-group-text">+971</div>
                                        </div>
                                        <input 
                                        type="text"
                                        className="form-control" 
                                        name="contactno" 
                                        value={this.state.contactno} 
                                        onChange={this.changeHandler} 
                                        />
                                    </div>
                                    <span style={{color:'red'}}>{this.state.contactnoErr}</span>
			 					</div>
		  					</div>
	   					</div>
                        <div className="row">
		  					<div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label for="exampleFormControlInput1">Enter Password</label>
                                    <input type="password"
                                    className="form-control"
                                    name="password" 
                                    value={this.state.password} 
                                    onChange={this.changeHandler}
                                    placeholder="Enter password" />
                                    <span style={{color:'red'}}>{this.state.passwordErr}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label for="exampleFormControlInput1">Refer By</label>
                                    <input disabled type="text" className="form-control" value={this.state.referBy}/>
                                    <span style={{color:'red'}}>{this.state.refralErr}</span>
                                </div>
		  					</div>
	   					</div> 
                        <div style={{display:this.state.passwordError}}>
                        <ul>
                            <li><span style={{color:'red'}}>Password must be 8 characters</span></li>
                            <li><span style={{color:'red'}}>At least one uppercase letter</span></li>
                            <li><span style={{color:'red'}}>One lowercase letter</span></li>
                            <li><span style={{color:'red'}}>One number and one special character</span></li>
                        </ul>
                        </div> 
                        <span style={{color:'red'}}>{this.state.signupError}</span>
                        <div className="row">
		  					<div className="col-md-12 col-sm-12 col-xs-12">
                                <button disabled={this.state.valid} onClick={this.register} className="btn btn-primary">Sign Up</button> 
                            </div>
                        </div>
                    </div>
                    {/*otp div*/}
                    <div class="card-body p-t-20" style={{display:this.state.otpdiv}}>
	   					<div className="row">
		  					<div className="col-md-6 col-sm-12 col-xs-12">
			 					<div className="form-group">
									<label for="exampleFormControlInput1">Enter OTP</label>
									<input type="text" 
                                    className="form-control" 
                                    name="otp" 
                                    value={this.state.otp} 
                                    onChange={this.changeHandler} 
                                    placeholder="Enter otp" />
                                    <span style={{color:'red'}}>{this.state.otpErr}</span>
			 					</div>
		  					</div>
	   					</div>
                        <div className="row">
		  					<div className="col-md-12 col-sm-12 col-xs-12">
                                <button onClick={this.verfySignupOTP} className="btn btn-primary">Submit</button> 
                            </div>
                        </div>
                    </div>

                </div>
            </div> 
        </div>  	
    </section>
</div></div>
    </>);
    }
}

export default ReferenceLogin;