const uState = {
    userDetails : [],
}

export default function userDetailsReducer(state = uState, action){

        switch(action.type){ 
            case 'USER_DATA' : 
            
            return {
                userDetails:action.payload,
            };
            default:
            return state;
        }
}