import React, { useState,useEffect } from 'react';
import { Button, Col, Container, Row,Nav,Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SearchInput from "./SearchInput";
import LoginPopup from './loginPopup';
import axios from "axios";
import {API_BASE_URL} from './../constant';
import wlogo from '../assets/evenstan_icon.png';
import drftbg from '../assets/draft.png';
import { FaAlignLeft ,FaFacebookF,FaInstagram,FaLinkedinIn, FaWhatsapp} from 'react-icons/fa';
import "./header.css";


const HeaderInner = ({ onChange, handleKeyPress })=>{
    let [fav,FavouriteBoxx]= useState(false);
    let [draftCount,setDraftCount]= useState(0);
    let [showName,setName] =useState('Menu');
    let [showLogin,setLoginPopup] =useState(false);
    let [menubtn,setmenubtn] =useState(true);
    const [userData, setUserData] = useState({
      token: '',
    });
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("user-auth-token");
      if (token === null) {
        localStorage.setItem("user-auth-token", "");
        token = "";
      }
      setUserData({
        token,
      });
      if (localStorage.getItem("user-data") !== null) {
        var userDetails = JSON.parse(localStorage.getItem("user-data"));
        setName(userDetails.firstName.split(" ")[0]===''?'User':userDetails.firstName.split(" ")[0]);
    }
    };
    useEffect(() => {
      const uid = localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1';
            // saveDarft Api
            axios.get(`${API_BASE_URL}getDraft?userId=${uid}`)
            .then(r => {
                setDraftCount( r.data.data.data.length);
            })
            .catch(e =>{
            console.log('save draft error' , e)
            })
      checkLoggedIn();
      if(window.location.href === 'https://eventstan.com/login'){
        setmenubtn(false)
      }
    }, []);
    let FavouriteBox=()=>{
        FavouriteBoxx(true);
    }
    let filterPopDown1 =()=>{
        FavouriteBoxx(false);
    }
    let login_click=()=>{
      setLoginPopup(!showLogin);
    }
    const logout=()=>{
      localStorage.removeItem('user-auth-token');
      localStorage.removeItem('user-auth-id');
      localStorage.removeItem('event_id');
      localStorage.removeItem('user-data');
      localStorage.clear();
      window.location.replace("/eventstan");
    }
    return(
        <>       
        <div className="header_createevent">
          <Row style={{ margin: "0px", padding: "0px" }}>
            <Col xs={0} md={1} lg={1} sm={1}/>
            <Col xs={2} md={2} sm={2} lg={2}>
              {/*<a href="/eventstan" className="navbar-brand">
                Event<span className="colored">stan</span>
            </a>*/}
              <Link to="/eventstan"><img src={wlogo} alt="EventStan logo"/></Link>
            </Col>
            <Col xs={6} md={6} sm={6} lg={6}>
            <div className='social-media'  style={{color:'#f47824', float:'right'}}>
          <ul>      
            <li><a href="https://www.facebook.com/EVENTSTAN/" target="_blank"><FaFacebookF size={20} className="social-media-list"/></a></li>
            <li><a href="https://www.instagram.com/eventstan.official/" target="_blank"><FaInstagram size={20} className="social-media-list"/></a></li>
            <li><a href="https://www.linkedin.com/company/eventstan/" target="_blank"><FaLinkedinIn size={20} className="social-media-list"/></a></li>
            <li><a href="https://api.whatsapp.com/send?phone=+971566405353&text=Hi" target="_blank"><FaWhatsapp size={20} className="social-media-list"/></a></li>
          </ul>
        </div>
        </Col>
            { menubtn?  <Col xs={4} md={1} sm={1} lg={1}>
             <div
                className="create_event_mob1"
                style={{ display:"flex"  }}
              >
                <div style={{ display: "flex", color:"white", paddingRight:"1em", textTransform: "capitalize"}} className="menu-icon" onClick={()=>FavouriteBox()}>{showName}</div>
                { userData.token!='' ? <span><Link to="/savedraft"><span className="mn-white-small" style={{ background: "url(https://eventstan.com/assets/img/draft.png) no-repeat"}}>{draftCount}</span></Link></span>:''}
                </div>
                   
            </Col> :"" }
                      
                    </Row>
                    {
                    fav == false ? "" :                                   
                    <div className="filter-content filter-content2" >    
                    <div className="inside-content2">
                    <p onClick={()=>filterPopDown1()} className="can-el-btn">X</p>
                    <ul className="" onClick={()=>filterPopDown1()}>
                        {userData.token ==''?
                        <>
                       <li><a href="https://eventstan.com/about-us">About us</a></li>
                        <li><a href="https://eventstan.com/#how-does-it-work">How it works</a></li>
                        <li><a href="https://eventstan.com/professional">Partner with us</a></li>
                        <li><a href="https://eventstan.com/#download-app">Download the App</a></li>
                        <li><a href="/ContactUs">Contact us</a></li>
                        </>
                        :''}
                        {userData.token !=''?
                        <>
                        <li><Link to="/savedraft">Draft Events</Link></li>
                        <li><Link to="/myevents">My Events</Link></li>
                        <li><Link to="/favourite">favourite</Link></li>
                        <li><Link to="/changepassword">Change Password</Link></li>
                        <li><Link to="/ContactUs">Contact us</Link></li>
                        </>
                        :''}

                        <li>
                            {userData.token !=''?
                            <Link to="/userdashboard" >Profile</Link>
                            :<button onClick={()=>login_click()} >Login</button>}
                        </li> 
                        <li>
                            {userData.token !=''?
                            <Link to="#" className="icon-logout" onClick={logout}>Logout</Link>
                            :''}
                        </li>
                    </ul>
                    
                    </div>
                    
                </div>
                }
                { showLogin ?  <LoginPopup closePopup={login_click} hideClose={true} /> : null }
        </div></>
    );
}
export default HeaderInner;