import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "./hireProfessional.css";
import CrdImg from "../../assets/sprite-map.png";
import HPopup from "./PopupHireProf";
import axios from "axios";
import HeaderInner from "../HeaderInner";
import SearchInput from "../SearchInput";
import Popup from '../popupp'
import {IoIosArrowBack} from 'react-icons/io'
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';
import {API_BASE_URL} from '../../constant';
import AddCardpopup from "../addCardpopUp";
import { withRouter, Link } from "react-router-dom";

class ProfessionalPayment extends Component {
    constructor(props) {
        super(props);
        this.state = { 
        appliedfilter: [],
        title : "",
        totalPrice  : "",
        firstName : "",
        lastName:"",
        email:"",
        phoneNumber:"",
        bill_add:"",
        bill_city:"",
        bill_state:"",
        bill_zip:"",
        bill_country:"",
        ship_add:"",
        ship_city:"",
        ship_state:"",
        ship_zip:"",
        ship_country:"",
        totalPay:"0",
        addCradpopup:false,
    }
  
    }

    addCard = () => {
        this.setState({ addCradpopup : true })
    }

    Down_pop = () =>{
        this.setState({ addCradpopup : false })
      }

    
    render() {
       
        return (
            <div className='checkout'>
            <HeaderInner />
            <br />
          <div className="container" style={{padding  :"30px 0px"}}>
        {/*<p className="para-container">
        <a className="remove_anchor bold_me backa" href="#" onClick={this.Back_user}>
            <IoIosArrowBack />BACK</a>
        </p>*/}
    <div className="row">
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div className="border-bottom pb-2 ">
                <div id="services" className="tab-pane active  my-4">
                        <h2 className="page-title my-3 d-inline-block">Professional Details</h2>
                        <div className="border-outer mb-4">
                        <div class=" p-4 d-flex justify-content-between align-items-center">
                            <div class="inner-left event-title-payment">
                                <h3 class="event-title">Chhappan Bhog</h3>
                                <ul>
                                    <li><span>Service Type :</span> Catrrer</li>
                                    <li><span>Location :</span> Dubai</li>
                                    <li><span>Event :</span> Birthday</li>
                                    <li><span>Date and Timing :</span> 16-01-2022 (7:00 pm - 10:00 pm)</li>
                                </ul>
                            </div>
                        </div>
                        </div>

                        <h2 className="page-title my-3 d-inline-block">Personal Details</h2>
                        <div className="border-outer mb-4">
                            <div className=" p-4 d-flex justify-content-between align-items-center">
                                <div className="inner-left event-title-payment">
                                    <ul>
                                        <li><b>Name&nbsp;:&nbsp;</b> this.state.firstName this.state.lastName</li>
                                        <li><b>Email Id&nbsp;:&nbsp;</b>this.state.email</li>
                                        <li><b>Contact&nbsp;:&nbsp;</b>this.state.phoneNumber</li>
                                    </ul>
								</div>	
							</div>    
                        </div>

                        <h2 className="page-title my-3 d-inline-block">Billing Address</h2>
                        <div className="border-outer mb-4">
                            <div className=" p-4 d-flex justify-content-between align-items-center">
                                <div className="inner-left event-title-payment">
                                    <ul>
                                        <li><b>Address&nbsp;:&nbsp;</b>this.state.bill_add</li>
                                        <li><b>City&nbsp;:&nbsp;</b>this.state.bill_city</li>
                                        <li><b>State&nbsp;:&nbsp;</b>this.state.bill_state </li>
                                        <li><b>Country&nbsp;:&nbsp;</b>this.state.bill_country </li>
                                        <li><b>Zip&nbsp;:&nbsp;</b>this.state.bill_zip</li>
                                    </ul>
								</div>	
							</div>    
                        </div>   
				</div>
            </div>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="box-shadow p-4 mb-4">
                <h2 className="page-title my-3 d-inline-block">Select a payment method</h2>
                    <span>Amount to be Paid : $160</span><br/><br/>

                   <div  className="border border-warning mb-3 position-relative add-on-box d-flex align-items-center justify-content-between">
                    <div className="add-on">
                        <h3 className="heading mb-0">Debit Card</h3>
                        <h5>**** **** **** 4242</h5>
                        Enter CVV <input type="number" name="cvv1" />
                    </div>
                    <div className="add-on">
                        <input type="radio" name="card" checked/>
                    </div>
                    </div>

                
                <div  className="border border-warning mb-3 position-relative add-on-box d-flex align-items-center justify-content-between">
                    <div className="add-on upside">
                        <h3 className="heading mb-0">Add New Card &nbsp;&nbsp;<a onClick={this.addCard} className="clkhere">Click Here</a></h3>
                        <br/>
                        <img src={CrdImg} />
                    </div>
                </div>
				<Link to='#' className="d-block  btn btn-warning mt-4"><span className="pr-4 d-inline-block border-right">$160</span><span className="pl-3">Pay Now</span></Link>
                
            </div>
            {this.state.addCradpopup ? (
          <div className="filter-content">
          <div className="filter-content-pop1">
            <p onClick={this.Down_pop} className="can-el-btn1">X</p>
            <AddCardpopup/>
          </div>
        </div>
        ) : null}
        </div>
    </div>
</div>
</div>
);
    }
    
}

const mapStateToProps = (state)=>{
    return{
        userDetails : state.userDetailsReducer.userDetails,
    }
}

export default ProfessionalPayment;