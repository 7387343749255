import { useState } from 'react';
import './style.css';
import './style2.css';
import NavBar from "./NavBar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import blog11 from '../assets/blog11.jpg';
import blog12 from '../assets/blog12.jpg';
import {Helmet} from "react-helmet";

const tabsConfig = {
    loop: true,
    //   margin: 10,
    items: 5,
    autoplay: true,
    rewind: true,
    nav: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
        //breakpoint from 0 and up
        0: {
            items: 1,
        },
        // add as many breakpoints as desired , breakpoint from 480 up
        480: {
            items: 2,
        },
        // breakpoint from 768 up
        768: {
            items: 3,
        },
        992: {
            items: 5,
        },
    }
};



function Blog() {
    const [tabIndex, setTabIndex] = useState(0);
    
  return (
    
    <>
        <NavBar />
        <Helmet>
        <title>Our Blog | EventStan</title>
        <meta name="description" content="Create unforgettable memories with new ideas & ways to host your event or plan your party with EventStan. Read our blog now for some tips" />
        </Helmet>
    <div className="header" style={{paddingLeft:'20px'}}>
                <div className="container"style={{paddingLeft:'0'}}>
                    <div className='header-left-element'>
                    &#8220;We Facilitate, You Create&#8221;
                    </div>
                    <div className="centered-element">
                    <img src={'./v2Images/Logo.png'} />
                    </div>
                    
                </div>
            </div>
        
        <div className='blog-banner' style={{ paddingTop:'0px'}}>
        <div className="blog-banner-inner">
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 dm-none' style={{height:'650px'}}>  
                   <div className='about-image'>
                   
                    </div>
                    </div>
                    <div className='col-md-6'>
                    </div>
                </div>
            </div>
           </div>
        </div> 
        <section className='about-google'>
            <div className='container'>
                <div className='row'>
                <div className='col-md-6'>
                    <div className='back-image-wave'>
                    <img src={'./v2Images/back_eventstan_Blog.png'} />              
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='about-google-righttext'>
                        <div class="centered-text" style={{ justifyContent:'left'}}><h4>Latest from us</h4></div>
                            <p><strong>The Ultimate Guide to Party Planning</strong></p>
                            <span style={{ textAlign:'center'}}>With the relaxed rules and curbs announced post the pandemic in UAE, Dubai particularly as we all know.</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <div className='blog-bottom'>
            <div className='container'>
                <div className='row'>
                   
                <div className="blog">
                <div className="container">
                <div className="centered-text">
                    <h4>Explore</h4>
                </div>
                
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList className="tab-all">
                            <Tab> View All Topic</Tab>  
                            <Tab> Event Planning</Tab>     
                        </TabList>
                        <TabPanel>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                <a href="/DIYEvent"><img src={blog11} alt="" className="mb-4" /></a>
                                <h4>DIY Event vs Event Planners: Why plan
your own event?</h4>
                                <p style={{ textAlign:'justify', color:'#000000', paddingTop:'5px'}}>With the relaxed rules and curbs announced post the pandemic in UAE, Dubai particularly as we all know, is a highly coveted location to celebrate both corporate
events and personal occasions. So, it com...</p><span style={{ color:'grey'}}>3 November 2022</span>
                                </div>
                                <div className="col-md-4">
                                <a href="/UltimateEvent"><img src={blog12} alt="" className="mb-4" /></a>
                                <h4>The Ultimate Guide to Party Planning</h4>
                                <p style={{ textAlign:'justify', color:'#000000', paddingTop:'5px'}}>So, you’ve decided to host a party and don’t know where to start? Well,
you are just like the So, you’ve decided to host a party and don’t know where to start? Well,
you are just like the very quickly...
</p><span style={{ color:'grey'}}>3 November 2022</span>
                                </div> 
                                <div className="col-md-4">
                                <a href="/DIYEvent"><img src={blog11} alt="" className="mb-4" /></a>
                                <h4>DIY Event vs Event Planners: Why plan
your own event?</h4>
                                <p style={{ textAlign:'justify', color:'#000000', paddingTop:'5px'}}>With the relaxed rules and curbs announced post the pandemic in UAE, Dubai particularly as we all know, is a highly coveted location to celebrate both corporate
events and personal occasions. So, it com...</p><span style={{ color:'grey'}}>3 November 2022</span>
                                </div>   
                            </div>
                        </div> 
                        </TabPanel>
                        <TabPanel>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                <a href="/DIYEvent"><img src={blog11} alt="" className="mb-4" /></a>
                                <h4>DIY Event vs Event Planners: Why plan
your own event?</h4>
                                <p style={{ textAlign:'justify', color:'#000000', paddingTop:'5px'}}>With the relaxed rules and curbs announced post the pandemic in UAE, Dubai particularly as we all know, is a highly coveted location to celebrate both corporate
events and personal occasions. So, it com...</p><span style={{ color:'grey'}}>3 November 2022</span>
                                </div>
                                <div className="col-md-4">
                                <a href="/UltimateEvent"><img src={blog12} alt="" className="mb-4" /></a>
                                <h4>The Ultimate Guide to Party Planning</h4>
                                <p style={{ textAlign:'justify', color:'#000000', paddingTop:'5px'}}>So, you’ve decided to host a party and don’t know where to start? Well,
you are just like the So, you’ve decided to host a party and don’t know where to start? Well,
you are just like the very quickly...
</p><span style={{ color:'grey'}}>3 November 2022</span>
                                </div> 
                                <div className="col-md-4">
                                <a href="/DIYEvent"><img src={blog11} alt="" className="mb-4" /></a>
                                <h4>DIY Event vs Event Planners: Why plan
your own event?</h4>
                                <p style={{ textAlign:'justify', color:'#000000', paddingTop:'5px'}}>With the relaxed rules and curbs announced post the pandemic in UAE, Dubai particularly as we all know, is a highly coveted location to celebrate both corporate
events and personal occasions. So, it com...</p><span style={{ color:'grey'}}>3 November 2022</span>
                                </div>   
                            </div>
                        </div> 
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
            

                </div>
            </div>
        </div>
    </>
  );
}
export default Blog;
