import React, { Component } from "react";

import './App.css';

import HeaderInner from "./components/HeaderInner";

import {IoIosArrowBack} from 'react-icons/io';

class TermsConditions extends Component {

   componentDidMount(){

      window.scrollTo(0, 0);

   }

    render(){

        return (

           <>

         <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />

            <div className="container">

			<div className="row">

               <div className="col-md-12 col-sm-12 col-xs-12">

                  {/* <div className="back-btn">

					      <br />

                     <a className="text-dark"href="#"><IoIosArrowBack/> Back</a>

                  </div> */}

               </div>

            </div>

            <section className="application_sec">	

                <div className="row">

                    {/* <div className="col-md-3 col-sm-3 col-xs-3">

                    <LeftMenu />

                  </div> */}

               <div className="col-md-12 col-sm-12 col-xs-12">

                  <div className="form_card form_blk">  

                     

                     <div class="row">

                        <div class="col-md-12">

                           <div className="form-group">

                              <h5>Terms & Conditions</h5>

							  <hr/>

                           </div>

                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'justify'}}>

                        <p>Special for YOU. Special for US.<br />

  Lets plan EVENTS at EVENTSTAN.<br />

</p>

<p><b>Terms &amp; Conditions</b></p >
<p>
These terms and conditions (the &quot;Agreement&quot;) govern the use and access of application and website including any content, functionality, product, and services offered by EventStan (Hereinafter referred to as the &quot;we&quot;, &quot;us&quot; or &quot;Company&quot;), incorporated in Dubai under license no. 4204 with its address : Dtec, Techno Hub, DSO, Dubai </p>

<p>This agreement is entered into between you as the client (Hereinafter referred to as the &quot;you&quot; or &quot;Client&quot;) and company (Referred to individually as a &quot;Party&quot; or collectively referred as the &quot;Parties&quot;.), where you wish to use the website or application to engage event service professionals or an entity who provide such product and services (collectivity or individually referred to as &quot;Professional/s&quot;) who wish to provide professional services related to events to you using the EventStan application or website .<u> If you do not agree to accept and be bound by this agreement, you must immediately stop using the EventStan application and website.</u></p>

<p>The Parties wish to enter into this agreement setting out the terms and conditions for both the parties.</p>

<p><b>Article 1:</b> <b>GENERAL</b></p >
<p>

  This  agreement constitutes a legally binding agreement between company and the  client. The website and application are owned by EventStan FZCO with all  requisite rights and license to authorize Company to engage with the Client as  set out in the agreement.<br />

  You  acknowledge that the Company platform serves as a venue for the online  distribution and publication of information submitted and exchanged between  client and the professionals, reservation for professional services and by  using, visiting, registering for, and/or otherwise in the website or  application including hiring any professional, render any services presented,  promoted and displayed on the company platform, by clicking on the “I have read  and agree to the terms and conditions”.<br />

  </p >
<p><b>Acknowledgement</b></p >
<p>

  You  hereby certify that (1) you are a client, (2) you have the authority to enter  into this agreement, (3) upon confirmation of a booking by you, you authorize  to transfer of payment for professional services requested from website or  application of company, and (4) you agree to be bound by all terms and  conditions of this agreement and any other documents incorporated by reference.  (5) you confirm that you are at least 18 years of age or the age of majority in  the relevant jurisdiction, whichever is greater, and are fully able and  competent to enter and comply with this agreement.<br />

  </p >
<p><b>Article 2:</b> <b>General Terms</b></p >
<p>

  Company  enables client to render services on the company application and website. While  company as a marketplace helps facilitate transactions that are carried out on  the company application and/or website, the client acknowledges that company is  a technology service provider and is not providing the professional services.  The professional services are provided by the third-party who are independent  contractors and are not employed by company.<br />

  The  client agrees that, in accepting this agreement, it does so to render services  in order to connect with different professionals for availing professional  services related to events mentioned on company platform.<br />

  </p >
<p><b>Registration of  Account&nbsp;</b></p >
<p>

  Client must create an account in order  to use some of the features offered by the company. Use of any personal  information client provide to the company during the account creation process  is governed by our Privacy Policy. Client must keep the password confidential  and client is solely responsible for maintaining the confidentiality and  security of his account, all changes, updates submitted through client’s  account, and all activities that occur in connection with client’s account. </p>

<p>Client may also be able to register to  use the Services by logging into his account with his credentials from certain  third-party social networking sites (e.g., Facebook, Google, etc). In this  case, the client confirms that client is the owner of any such social media  account and that client is entitled to disclose his social media login  information to company. Client authorizes company to collect client’s  authentication information, and other details that may be available on or  through client’s social media account, consistent with his applicable settings  and instructions. </p>

<p>In creating an account, client warrants  to company that all information provided to company in such a process is true,  accurate and correct, and that clients shall update his information as and when  necessary, in order to keep it accurate. </p>

<p>Client shall be responsible for all  activities that occur in client’s account. Client agrees to notify company  immediately of any unauthorized use of his/her account in order to enable  company to take necessary corrective action. The client agree that client shall  not allow any third party to use clients account for any purpose and that  client shall be liable for such unauthorized access. </p>

<p>By creating an account, Client agrees to  receive certain communications in connection with company’s Platform or  Services. For example, client might receive comments from other Customers, or  other Customers may follow the activity to do on client’s account. Client can  opt-out or manage your preferences regarding non-essential communications  through account settings. </p>

<p><b>Reservation of  Services</b> </p >
<p>

  Client shall be solely responsible for the  services the client chooses and books via company application or website on the  basis of the availability, pricing, reviews &amp; ratings. By booking  professional(s) via company website or application, client agree to working  with these professional(s).</p>

<p><b>Clients Representations</b></p >
<p>

  Client  represents and warrants to company as follows:

<ol>

  <li>The client undertakes that they shall conduct itself in accordance with  all applicable laws, in respectful manner and allow professionals a safe and  conductive environment to provide the services.</li>

  <li>The client agrees that they  will be solely responsible to ensure its own safety and security and that of  the professionals.</li>

  <li>The client ensures not to  undertake or assist in any unlawful or illegal activity.</li>

  <li>The Client has full right and  power to enter into and act according to the terms of this Agreement, and that  such acts shall not violate any obligation between Client and company or any  third party.</li>

  <li>The Client agrees that use of  company platform of first party is solely at Client’s risk.</li>

  <li>The Client warrants that  payment for services render through company website or application will be  uninterrupted and timely. </li>

  <li>The client may have the  right to provide a rating and feedback of the professional on the company  website and application. Such feedback must at all times, comply with the  applicable laws of jurisdiction.</li>

</ol>
</p>
<p><b>Company’s liability</b></p >
<p>

<ol>

  <li>Company shall be liable to rectify any  technical issues related to the website and application promptly.</li>

  <li>Provide the Client with access to client’s  registered account to reserve any services, to visit and to provide rating and  feedback. </li>

  <li>Company obliges to issuing a payment summary to the client for the fee  paid.</li>

</ol></p>

<p><b>Content Removal</b></p >
<p>

  Company  reserves the right in its sole discretion, at any time and without prior  notice, to remove, block, or disable access to any Content that Company, for  any reason or no reason, consider to be objectionable, in violation of the  Terms and conditions or otherwise harmful to the services or professional.  Subject to the requirements of applicable law, company is not obligated to  return any of the Content to second party under any circumstances. </p>

<p><b>Return and Refund</b></p >
<p>

  In  Certain Cases, and within Certain limits, a payment shall be refunded to the  client within seven (14) working days if client is responsible for cancellation  of the booking. In case, where Professional with reasonable grounds cancel the  booking, company shall refund the payment to client within four (10) working  days.   </p>

<p><b>Confidentiality</b><br /></p >
<p>

    <b>(a)General.</b>&nbsp;This clause shall govern all disclosures of Confidential  Information between the parties. The existence of terms and conditions and any  disclosure of confidential information related to parties shall be considered  Confidential Information.  In accordance  with the terms of this agreement and any information such as identification  information (including, without limitation, client information and personal  information of any variety acquired parties pursuant to this agreement or in  connection with the performance of the work and regardless of the source;  transactional, sales and activity information; and client profile information  (all of the foregoing collectively “Client Information”). However, company will  have the right to compile and use aggregated data derived from client  information and/or from professional, and data and other sources, for company’s  internal business purposes.<br />

  Upon  termination of this agreement, the party in possession of the other’s  confidential information must promptly return or destroy the confidential  information, unless retention of the information is required under the  applicable law.<br />

  </p >
<p><b>&nbsp;(b)Privacy Policy</b>&nbsp;  For  the purposes of this clause, “Personal Information” means any information  provided by client or 
  collected by professionals in connection with this  agreement </p >
<p>

<ol>

  <li>that identifies or can be used to  identify, contact, or locate the person to whom such information pertains, or </li>

  <li>from which identification or contact  information of an individual person can be derived. Personal Information  includes, but is not limited to: name, address, phone number, fax number, email  address, EID number or other government-issued identifier, bank and credit card  information. Additionally, to the extent any other information is associated or  combined with Personal Information, then such information also will be  considered Personal Information. </li>

</ol></p>

<p>Company’s  Privacy Policy describe policies and procedure on the collection, use and disclosure  of client’s information when client use the service and provide information  about client’s privacy rights and how the law protects client.<br />

  Company  use the personal data to provide and improve the service. By using the  services, client agree to the collection and use of information in accordance  with privacy Policy. &nbsp;<br />

  </p >
<p><b>Intellectual property rights</b></p >
<p>

  This agreement does not transfer any  intellectual property or rights owned by the company or any third party to the  client. All the rights, title and interest in and to such property will remain  solely with the company. All trademarks, service mark, graphics and logos used  in connection with website and application of company are vested in company.  Client do not have the right to reproduce or use any trademarks of company  and/or third party. Subject to the terms of this agreement, the company grants  the client a limited, revocable non-exclusive right to use and access the  website and/or application solely for the purpose of rendering the professional  services.</p>

<p><b>Article 3: Additional Terms</b></p >
<p>

    <b>Force Majeure</b></p >
<p>

  Company  shall not be liable for nonperformance under this agreement for any delay,  hindered or prevention in performing hereunder if such delay, hindered or  prevention is caused by conditions beyond its control includes War, Natural  Disasters and other occurrences recognized as such according to international  practice or as agreed upon by the parties. </p>

<p><b>Indemnification</b></p >
<p>

  The  client shall indemnify, defend, and hold the company and its representatives  harmless for any loss or damage by the professionals. Furthermore, the client  agrees to hold the company harmless against any loss or damage, save in cases  of gross misconduct or negligence by the professionals, its representatives.</p>

<p><b>Termination</b></p >
<p>

  Company  may terminate this agreement by providing a written notice to the client. The  company possess the advantage to terminate this agreement in the event when  client engages in inappropriate acts/omissions or has committed a breach of  this agreement. Upon termination of this agreement for any reason, the right of  clients to use the services and client account will cease immediately.  Termination of this agreement will not prejudice any rights of the parties that  may have accrued prior to such termination.</p>

<p><b>Notices</b></p >
<p>

  Any  and all notification with regard to this agreement shall be conducted in  written form and delivered either in person or via official email. Any notice  shall be deemed to have been received&nbsp;at the time of delivery if sent by  email, if sent in person then on the date of delivery of such notice.</p>

<p><b>Severability</b></p >
<p>

  If any provision of this Agreement will be  held to be invalid or unenforceable for any reason, the remaining provisions  will continue to be valid and enforceable. If a court finds that any provision  of this Agreement is invalid or unenforceable, but that by limiting such  provision it would become valid and enforceable, then such provision will be  deemed to be written, construed, and enforced as so limited.</p>

<p><b>Entire Agreement</b> </p >
<p>

  This Agreement contains and constitutes  the entire agreement of or among the Parties with respect to the subject matter  of this Agreement, and supersedes all other prior or contemporaneous  understandings, communications, commitments, undertakings, representations and  agreements, oral or written, expressed or implied, of or among the Parties with  respect to the subject matter of this Agreement.</p>

<p><b>Application Law and  Dispute Resolution</b></p >
<p>

  This Agreement shall be interpreted under  and governed by the laws of the United Arab Emirates. This  agreement shall be construed and the respective rights of the parties hereto  determined according to the law of United Arab Emirates. Exclusive jurisdiction  of all matters arising under this agreement shall be vested in the Dubai courts.  And the parties hereto expressly consent and submit to such jurisdiction</p>

<p><b>Amendments and  modifications</b></p >
<p>

  Company reserves the right, at their sole  and absolute discretion, to change, modify, supplement or delete any of the  terms and conditions.</p>

<p><b>Assignment</b></p >
<p>

  The client may not assign or transfer any  of their rights, interest or obligations under the agreement to any third party  without the prior written consent of company. Company may assign its rights and  interests under this agreement to any person whosoever.<br />

  <b>Miscellaneous</b></p >
<p>

  Paragraph headings used in this Agreement  are for reference only and shall not be used or relied upon in the  interpretation of this Agreement.</p>

                        </div>

                        </div>       

					</div>

				</div>

			</div>

      </section>

	</div> 

   </>);

    }

}



export default TermsConditions;