import React from 'react';
import HeaderInner from "./components/HeaderInner";
import "./App.css";
import EventImg from "./assets/event.png"; 
import {BASE_URL} from './constant';

const  FailPayment = ()=>{
    return (
        <>
        <HeaderInner />
        <div className="container">
            <div className="text-center my-5 py-5 event-book">
                <img src={EventImg} alt="" />
                <h2 className="py-2 m-0">Your payment is unsuccessful.</h2>
                <p className="pb-4 mb-3">Try again</p>
                <a href={BASE_URL} className="btn btn-outline-success mr-3">Back to home screen</a>
                <a href={`${BASE_URL}myevents`}  className="btn btn-outline-success">My events</a>
            </div>
        </div>
        </>
    )}
export default FailPayment;