import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import HeaderTop from './HeaderTop';
import { Component } from 'react';
import axios from 'axios';
import Backdrop from './Backdrop/Backdrop';
import PopUp_contact from './PopUp_contact'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {API_BASE_URL} from './../src/constant'
import CircularProgress from '@material-ui/core/CircularProgress';

class ContactUs extends Component {
    constructor() {
        super();
        this.state = {
            popup: false,
            phone_number: null,
            name: null,
            email: null,
            message: null,
            message1: "Mouse Event",
            loader : true,
        }
    }
    onchange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    on_tele_change = (e) => {
        this.setState({ phone_number: e })
    }

    toogle_popup = () => {
        this.setState({
            popup: !this.state.popup
        })
    }
    backdropCLickHandler = () => {
        this.setState({
            popup: false,
        });
    }

    set_null = () => {
        this.setState({
            phone_number: null,
            name: null,
            email: null,
            message: null
        })
    }

    onsubmit = (e) => {
        e.preventDefault();
        const curr_state = { phone_number: this.state.phone_number, name: this.state.name, email: this.state.email, message: this.state.message, userType: "USER" }
        if (curr_state.phone_number == null || !(curr_state.phone_number.length >= 10 && curr_state.phone_number.length <= 17)) {
            document.getElementById("message_contact2").innerHTML = ""
            document.getElementById("message_contact").innerHTML = ""
            document.getElementById("message_contact2").innerHTML = "Please fill mobile number correctly "
            setTimeout(
                () => {
                    document.getElementById("message_contact").innerHTML = "";
                    document.getElementById("message_contact2").innerHTML = "";
                },
                5000
            )
            return;
        }
        if (curr_state.email == null || curr_state.name == null || curr_state.message == null) {
            document.getElementById("message_contact2").innerHTML = ""
            document.getElementById("message_contact").innerHTML = ""
            document.getElementById("message_contact2").innerHTML = "Please fill all details!"
            setTimeout(
                () => {
                    document.getElementById("message_contact").innerHTML = "";
                    document.getElementById("message_contact2").innerHTML = "";
                },
                5000
            )
            return;
        }
        axios.post(`${API_BASE_URL}contactUs`,
            curr_state
        )
            .then((resp) => {
                document.getElementById("message_contact2").innerHTML = ""
                document.getElementById("message_contact").innerHTML = ""
                this.set_null();
                // document.getElementById("message_contact").innerHTML = "Thanks for contacting, Our Member reach out to you soon"
                this.toogle_popup();
                var frm = document.getElementById('contact_abc');
                setTimeout(
                    () => {
                        document.getElementById("message_contact").innerHTML = "";
                        document.getElementById("message_contact2").innerHTML = "";
                    },
                    5000
                );
                frm.reset();  // Reset all form data
                document.getElementById('tele_1').value = '';

            })
            .catch((err) => {
                document.getElementById("message_contact2").innerHTML = ""
                document.getElementById("message_contact").innerHTML = ""
                document.getElementById("message_contact2").innerHTML = "Please fill all details!"
                setTimeout(
                    () => {
                        document.getElementById("message_contact").innerHTML = "";
                        document.getElementById("message_contact2").innerHTML = "";
                    },
                    5000
                );
            });
    }
   
    handleEvent = (event) => {
        if (event.type === "mousedown") {
               this.setState({ message1: "2px solid "});
           } else {
               this.setState({ message1: "Mouse Up"});
           }
       }
    render() {
        // console.log('loader' ,this.state.loader)
        let backdrop;
        if (this.state.popup) {
            backdrop = <Backdrop click={this.backdropCLickHandler} />;
        }
        return (
        <div className="contactus">
            <div className="App">
                     {
                    this.state.loader == false ? <div className="loading-box" style={{display : "flex" , alignItems: 'center', justifyContent : "center" }}><CircularProgress disableShrink /></div> :
                <div>
                <div className="upper_part">
                    <HeaderTop />
                   

                    <div className="banner-content">
                        <h2 className="banner-title  bold_me">Contact Us</h2>
                        
                    </div>
                    
                    
                </div>
                
                
                <div className="Contact_sec mr-0" style={{ marginTop:'0px'}}>
                    <Container id="contactform_home" className="contact_container">
                        
                        <br />
                        <form autoComplete="nope" id="contact_abc" onSubmit={this.onsubmit} >
                            <div className="form-group">
                                <label>Full name</label>
                                <input  maxlength="50" autoComplete="nope" pattern="[A-Za-z ]{1,32}" required onChange={this.onchange} type="text" className="form-control" name="name" placeholder="Enter your name" />
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Mobile number</label>
                                        <PhoneInput
                                            value={this.state.phone_number}
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry="AE"
                                            id="tele_1"
                                            placeholder="Enter Mobile number"
                                            onChange={this.on_tele_change} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label >Email ID</label>
                                        <input maxlength="50" autoComplete="nope" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required onChange={this.onchange} type="email" className="form-control" name="email" placeholder="Enter Email ID " />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label >Message</label>
                                <textarea maxlength="250" autoComplete="nope" required onChange={this.onchange} className="form-control" rows="6" name="message" placeholder="Write here(Max 250 characters )"></textarea>
                            </div>
                            <h6 id="message_contact" style={{ color: 'green' }}></h6>
                            <h6 id="message_contact2" style={{ color: 'red' }}></h6>
                            <Button type="submit" className="btn">Submit</Button>
                        </form>
                    </Container>
                </div>
                {
                    this.state.popup ?
                        < PopUp_contact />
                        : null
                }
                { backdrop}
                </div>
    }
            </div ></div>
        );

    }

}

export default ContactUs;