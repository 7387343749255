import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {API_BASE_URL,STRIPE_PUB_KEY} from './../constant'
import CheckoutForm from "../CheckoutForm_old";
import axios from "axios";
import "./Popup.css";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.

const stripePromise = loadStripe(STRIPE_PUB_KEY);
export default function App(props) {
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
     const data = {
        stripe_cust_id:props.customerID,
     }
     
    // Create PaymentIntent as soon as the page loads
    axios.post(`${API_BASE_URL}create-setup-intent`,data)
      .then((data) => { 
         setClientSecret(data.data.data)
      });
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    
    <div>
    {/* <h2 className="col-md-12 popup-head">Add a credit or debit card</h2> */}
     <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 back-loader" >
        {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div> 
     </div>
</div>
  );
}