import React, { Component } from "react";
import './AppStyle.css';
import './left-menu.css';
import HeaderInner from "./HeaderUser";
import {Link} from 'react-router-dom';
import {IoIosArrowBack, IoIosAdd} from 'react-icons/io';
import LeftMenu from "./UserLeftMenu";
import { API_BASE_URL } from "../../constant";
import axios from "axios";

class Notification extends Component {
    constructor(props){
        super(props);
        this.state = {
            uid :localStorage.getItem('user-auth-id'),
            eventId:'',
            bookedEvents:[],
            notificationLog:[]
           }
    }

    componentDidMount(){
        // saveDarft Api
        axios.get(`${API_BASE_URL}getUserEvents?userId=${this.state.uid}`)
        .then(r => { 
            this.setState({bookedEvents : r.data.data.data})
            axios.get(`${API_BASE_URL}get-notification?eventId=${r.data.data.data[0]._id.userEventId}`)
            .then((log)=>{ 
                this.setState({notificationLog:log.data.data})
            })
            .catch(err=>{
                console.log(err)
            })
        })
        .catch(e =>{
        console.log('user event error' , e)
        })
    }

    getEvent = (e)=>{
        let eventsId = e.target.value ; 
        this.setState({eventId:eventsId})
        axios.get(`${API_BASE_URL}get-notification?eventId=${eventsId}`)
        .then((log)=>{ 
            this.setState({notificationLog:log.data.data})
        })
        .catch(err=>{
            console.log(err)
        })
    }

    render(){
        return (
           <>
<HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
<div className="container">
	<div className="row">
               <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="back-btn">
					      <br />
                     <Link className="text-dark" to="/userdashboard"><IoIosArrowBack/> Back</Link>
                  </div>
               </div>
    </div>
    <section className="application_sec">	
        <div className="row">
            <div className="col-md-3 col-sm-3 col-xs-3 sidemenu">
                <LeftMenu />
            </div>
            <div className="col-md-9 col-sm-9 col-xs-9">
                <div className="form_card form_blk">  
                    <div className="row">
                        <div className="col-md-8">
                        <h3>Notifications</h3>
                        </div>
                        <select className="form-control col-md-4" value={this.state.eventId} onChange={this.getEvent}>
                            {this.state.bookedEvents.map((item)=>{ 
                                return <option value={item._id.userEventId}>{item._id.eventName}</option>
                            })}
                        </select>
                     </div>
                     <div class="card-body p-t-20">
                            {this.state.notificationLog.map((log,index)=>{
                                return <div class="card-notification">
                                <div class="card-noti-conatin">
                                    <small>{new Date(log.createdAt).toLocaleString()}</small>
                                    <p class="text-muted m-b-30">{log.description}</p>
                                </div>
                            </div>
                            })}
                      </div>
                </div>
            </div> 
        </div>  	
    </section>
</div>
    </>);
    }
}

export default Notification;