import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "./Createevent.css";
import {Link} from 'react-router-dom'
import card_pic from "./assets/create_event/card.jpg";
import Name_popup from "./Name_popup";
import Backdrop from "./Backdrop/Backdrop";
import axios from "axios";
import HeaderInner from "./components/HeaderInner";
import SearchInput from "./components/SearchInput";
import Popup from './components/popupp'
import {IoIosArrowBack} from 'react-icons/io'
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery'
import {API_BASE_URL} from './../src/constant'
import {VENDOR_API_BASE_URL} from './../src/constant'
 
class Createevent extends Component {
  constructor() {
    super();
    this.state = {
      popup_first: false,
      popup: false,
      title: "",
      filters: null,
      event_types: null,
      target_event_id: null,
      search_input: "",
      error: "",
      activeFilter: "All",
      BgColor : false,
      loader : false,
     
    };
  }
  componentDidMount() {
    axios
      .get(`${API_BASE_URL}eventCategories`)
      .then((resp) => {
        // console.log('eventCategories' , resp)
        this.setState({
          filters: resp.data.data,
        });
        this.setState({loader : true}) 
      })
      .catch((err) => {
        console.log("error");
      });
    axios
      .get(`${API_BASE_URL}event-types`)
      .then((resp) => {
        // console.log('event_types' , resp)
        this.setState({
          event_types: resp.data.data.result,
        });
        this.setState({loader : true})
      })
      .catch((err) => {
        console.log("error");
      });
  }
  toogle_popup = (id) => {
    this.setState({
      popup_first: !this.state.popup_first,
      target_event_id: id,
    });
  };
  submit_popup_first = () => {
    if (this.state.title) {
      this.setState({
        popup_first: false,
        popup: true,
        
      });
    } else {
      this.setState({
        error: "Required",
        BgColor : false
      });
    }
  };
  onchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value ,
      BgColor : true 
    });
  };
  backdropCLickHandler = () => {
    this.setState({
      popup_first: false,
      popup: false,
      title: "",
      error: "",
    });
  };



  handleKeyPress = (event) => {
    //if (event.key === "Enter") {
      axios
        .get(
          `${API_BASE_URL}event-types?search=${this.state.search_input}`
        )
        .then((resp) => {
          this.setState({
            event_types: resp.data.data.result,
          });
        })
        .catch((err) => {
          console.log("error");
        });
    //}
  };

  search_change = (e) => {
    this.setState({
      search_input: e.target.value,
    }); 
  };

  filterOutEvents = (s, id = null) => {
    this.setState({
      activeFilter: s,
    });
    if (id) {
      axios
        .get(`${API_BASE_URL}event-types?eventCategoryId=${id}`)
        .then((resp) => {
          this.setState({
            event_types: resp.data.data.result,
          });
        })
        .catch((err) => {
          console.log("error");
        });
    } else {
      axios
        .get(`${API_BASE_URL}event-types`)
        .then((resp) => {
          this.setState({
            event_types: resp.data.data.result,
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }
  };

  Down_pop = () =>{
    this.setState({ popup_first : false  , title : ""})
  }
  Down_pop1 = () =>{
    this.setState({ popup : false , title : ""})
  }

  FavouriteBox = () => {
    this.setState({fav : true})
}
  filterPopDown1 = () => {
      this.setState({fav : false})
  }

  render() {
    // console.log('asf', this.state.title)
    return (
      <div className="create_event">
        {
        this.state.loader == false ? <div className="loading-box lbox"><CircularProgress disableShrink /></div> :
        <div>
        <HeaderInner 
          handleKeyPress={this.handleKeyPress}
          onChange={this.search_change}
        />
        <br />
        <br />
        <Container>
          <p className="pcontainer">
          <a className="remove_anchor bold_me anchor_back" href="/eventstan"><IoIosArrowBack />BACK</a>
          </p>
          <h4 style={{ marginBottom: "1px" }} className="bold_me">
            YAY! What are we
          </h4>
          <h4 className="bold_me">celebrating today?</h4>
          <div className="filter_buttons blkbtn" >
            <div className="blkbtn">
              <Button
                style={{
                  color: this.state.activeFilter === "All" ? "#fff" : "#7a7a7a",
                  backgroundColor:
                    this.state.activeFilter === "All" ? "#8489B2" : "#D0D0D0",
                }}
                onClick={(e) => this.filterOutEvents("All")}
              >
                All
              </Button>
              {this.state.filters &&
                this.state.filters.map((filter, i) => {
                  return (
                    <Button
                      key={i}
                      style={{
                        color : this.state.activeFilter === filter.name ? "#fff" : "#7A7A7A",
                        textTransform :"capitalize",
                        backgroundColor:
                          this.state.activeFilter === filter.name
                            ? "#8489B2"
                            : "#D0D0D0",
                      }}
                      onClick={(e) =>
                        this.filterOutEvents(filter.name, filter._id)
                      }
                    >
                      {filter.name}
                    </Button>
                  );
                })}
            </div>
            <div style={{ position: "relative" }}>
              <SearchInput
                handleKeyPress={this.handleKeyPress}
                onChange={this.search_change}
              />
            </div>
          </div>
          <br />
          <div className="cards_create_event">
            {this.state.event_types == null ||
            this.state.event_types.length === 0 ? (
              <h1 className="data_not_found">No Match Found!</h1>
            ) : (
              this.state.event_types.map((single_event, i) => {
                return (
                  <div
                    onClick={() => this.toogle_popup(single_event._id)}
                    className="card_create_event"
                    key={i}
                  >
                    <img
                      src={single_event.image == "" ? card_pic : single_event.image }
                      height="150px"
                      width="150px"
                      alt="event dp"
                    />
                    <h6 style={{ Width: '150px', textAlign: 'center' }}>{single_event.name}</h6>
                  </div>
                );
              })
            )}
          </div>
        </Container>
        {this.state.popup_first ? (
          <div className="filter-content">
            <div className="filter-content-pop">
                <p onClick={this.Down_pop} className="can-el-btn1">X</p>
                <Name_popup
                name = {this.state.BgColor}
                title ={this.state.title}
                submit_popup_first={this.submit_popup_first}
                onchange={this.onchange}
                error={this.state.error}
              />
            </div>
          </div>
          
        ) : null}
        {this.state.popup ? (
          <div className="filter-content">
          <div className="filter-content-pop1">
            <p onClick={this.Down_pop1} className="can-el-btn1">X</p>
            <Popup
            title={this.state.title}
            target_event_id={this.state.target_event_id}
          />
          </div>
        </div>
        ) : null}
        
        </div>
          }
      </div>
    );
  }
}
export default Createevent;
