import React from 'react'
import { Row, Col, Container, Button } from "react-bootstrap";
import SearchInput from "./SearchInput";
import {Link} from 'react-router-dom'
import "./booking.css";
import HeaderInner from "./HeaderInner";
import {AiOutlineHeart} from 'react-icons/ai'
import {FaStar} from 'react-icons/fa'
import {BiSearch} from 'react-icons/bi'
import axios from "axios";
import {IoIosArrowBack} from 'react-icons/io'
import {API_BASE_URL} from './../constant'
import jspriceType from "./../context/priceType";

class Favourite extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            filterpop : false,
            Bookmarklist : [],
            SearchInput : "",
            serviceId  : "",
            name : "",
            AdminServiceList : [],
            uid:''
        }
    }

    componentDidMount(){
        this.uid = localStorage.getItem('user-auth-id') !== null ? localStorage.getItem('user-auth-id') : '1';
        // Select ServiceId Api
        axios.get(`${API_BASE_URL}adminServiceList?userId=${this.uid}`)
        .then(r =>{
            this.setState({AdminServiceList : r.data.data.adminServiceList})
            this.setState({name : r.data.data.adminServiceList[0].name})
            const seviceId = r.data.data.adminServiceList[0]._id
            this.setState({serviceId:seviceId});
            axios.get(`${API_BASE_URL}getBookmark?userId=${this.uid}&serviceId=${seviceId}`)
            .then(res =>{ 
                this.setState({ Bookmarklist : res.data.data.vendorServiceList})
                })
                .catch(error =>{
                    console.log('bookmark error' , error)
                })
        })
        .catch(error =>{
            console.log('adminlisterror')
        })
       
    }

    // Get Bookmark
    GetBookmark = (id) => {
        this.setState({serviceId:id});
        axios.get(`${API_BASE_URL}getBookmark?userId=${this.uid}&serviceId=${id}`)
        .then(res =>{
        this.setState({ Bookmarklist : res.data.data.vendorServiceList})
        })
        .catch(error =>{
            console.log('bookmark error' , error)
        })
    }
   
    handleChange = (e) => {
            this.setState({SearchInput : e.target.value})
    }

    // Cancel Bookmark
    CancelBookmark = (id) => {
        const bookfav = {
            vendorServiceId : id,
            userId : this.uid,
            type : 1
        }
        axios.post(`${API_BASE_URL}bookmark` , bookfav)
        .then(respon => {
            window.location.reload();
        })
        .catch(error =>{
            console.log('bookmark error' , error)
        })
    }

    // for Back Button 
    Backfunction = () =>{
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="Favourite-box">
                <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
                <div className="container">
                     <div className="row" style={{padding : "40px 0px" }}>
                        <div className="col-md-12">
                            <div className="left-content-box" >
                            <div style={{ marginBottom: "5px" , position:"relative"  , paddingBottom : "30px"}}>
                            <a className="remove_anchor bold_me" href="#" onClick={this.Backfunction} style={{display : "inline-block"  , fontSize : "12px", alignItems : "center" , position : "absolute" , left : "-5px"  , color : "#2E262C", opacity : "0.7"}}><IoIosArrowBack />BACK</a>
                            </div>
                            <div className="search-box" style={{paddingBottom : "20px" , marginBottom : "20px" , borderBottom : "2px solid rgba(151,151,151,0.2)"}}>
                                <h2 style={{color : "#000" , fontSize : "18px" , margin : "0px"}}>favourite <span>({this.state.Bookmarklist.length})</span></h2>
                                <div style={{display : "flex" , border: "1px solid #DDDDDD" ,borderRadius: "6px"}}><input type="text" className="search-b" placeholder="search" style={{marginRight :"10px" ,  flex : "1" , outline : "none" , padding : "10px", height : "36px"  , border : "none" , borderRadius : "8px"}} value={this.state.SearchInput} onChange={this.handleChange}/><Link to="#" className="se-arch" onClick={() => this.GetSearch(this.state.SearchInput)}><BiSearch /></Link></div>
                            </div>
                            <div className="filter-box">
                                <div className="inner-filter" style={{flex  :"1"}}>
                                    {
                                        this.state.AdminServiceList.length > 0 ?
                                        <ul className="filter-option favourite-filter">
                                            { this.state.AdminServiceList.map((ser,ind)=>{
                                               return <li key={ind}><Link to="#" className={this.state.serviceId==ser._id?'selectedFavService':''} onClick={() => this.GetBookmark(ser._id)}>{ser.name}</Link></li>
                                            }) }
                                        </ul>
                                        : ""
                                    }
                                </div>
                            </div>
                            {
                                this.state.Bookmarklist.length > 0 ? <div className="row" >
                                {
                                    this.state.Bookmarklist.map((bookmark , index) => {
                                        return <div key={index} className="col-md-4" style={{marginBottom : "20px"}}>
                                        <div className="inner-cont-box inner-cont-box1" >
                                            <div className="inner-img-con" style={{ height : "188px" , borderRadius  :"8px"}}>
                                                <img src={bookmark.images[0]} alt={index + 1} />
                                                <Link to="#" className="heart-box"><span onClick={() => this.CancelBookmark(bookmark._id)} className="heart_icon"><AiOutlineHeart /></span></Link>
                                                <p style={{display : "flex" , alignItems : "center" , justifyContent : "space-between" , width : "48px", borderRadius  :"4px"}}><FaStar /><span>4.5</span></p>
                                            </div>
                                            <div className="info-content" style={{display : "flex" , alignItems : "center" , justifyContent : "space-between" , padding : "5px 0px"}}>
                                                <div>
                                                    <h6>{bookmark.title}</h6>
                                                    <p>AED {bookmark.price}/{jspriceType[bookmark.priceType]}</p>
                                                </div>
                                                <div>
                                                    <Link to="/create_event">book</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    })
                                }
                                
                        </div> : ""
                            }
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        )
    }
}
export default Favourite;