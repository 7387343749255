import { useState } from 'react';
import './style.css';
import './style2.css';
import NavBar from "./NavBar";
import {Helmet} from "react-helmet";
function Aboutus() {

  return (
    <>
     
<NavBar />
    <Helmet>
        <title>Event Planning Service | EventStan</title>
        <meta name="description" content="Filter, Scan, Choose & Book –  the 1st Event Planning Marketplace web & app solution in Dubai to locate all event vendors under one roof. " />
    </Helmet>
    <div className="header" style={{paddingLeft:'20px'}}>
        <div className="container"style={{paddingLeft:'0'}}>
            <div className='header-left-element'>
            &#8220;We Facilitate, You Create&#8221;
            </div>
            <div className="centered-element">
            <img src={'./v2Images/Logo.png'} />
            </div>
            {/* <div className="header-right-element">
                Login/Register
            </div> */}
        </div>
    </div>
        <div className='about'>
            <div className="about-inner">
                <div className='container'>
                    <div className='about-text'>
                    <h4>Where did it all start ?</h4>
                    <p>The start of <span className='evenstancolor'>EventStan</span> was with a simple problem that the founders Mehak and Suraj faced on a daily basis being in the events industry. Even though Google truly does have all the answers, it was almost impossible to narrow down the best vendors for their events just on the basis of a  phone call or the website. Upon checking with family and friends, they soon realised that this simple problem wasn’t so simple anymore as many others were facing the same issue. That is when they decided to solve this problem once and for all through EventStan!</p>        
                    </div>
                    </div>
                </div>
            </div> 
        <div className='about-purple'>
        <div className="about-purple-inner">
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 dm-none'>  
                   <div className='about-image'>
                   <img src={'./v2Images/About-girl.png'} />
                    </div>
                    </div>
                    <div className='col-md-6'>
                    <div className='about-image-right'>
                         {/* <img src={'./v2Images/eventstan_About us-page.png'} /> */}
                         <div className="prpl-des">
                        <h3 className="first-txt">
                        Who are we?
                        </h3>
                        <div className="second-txt">The Perfect combination of technology and event planning -
                        EventStan brings to you the <span className='purple-text'>VOV</span> Factor allowing you to easily connect with a<span className='purple-text'> Variety of Vendors</span> across the events industry to easily plan your events your way.<br/><br/>
                        <span className='dm-none'>Now simply plan your events by scanning through the best service providers on the EventStan website and UAE’s first event planning marketplace APP. Book vendors on the basis of budget, availability, reviews and ratings with just a few clicks.</span>
                        </div>
                         </div>
                         </div>
                         </div>
                </div>
            </div>
           </div>
        </div> 
        <section className='about-google'>
            <div className='container'>
                <div className='row'>
                <div className='col-md-6'>
                    <div className='back-image-wave'>
                    <img src={'./v2Images/back_eventstan_Aboutus.png'} />              
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='about-google-righttext'>
                    <h4>Download Now</h4>
                    {/* <p>IOS © Android Apps in your Brand<br/>for Book Events and Check Availabilities</p> */}
                    <div className="button-flex">
                        <a href="https://play.google.com/store/apps/details?id=com.mobile.eventstan" target="_blank"> <img src="./v2Images/google-playstore.png"/> </a>
                        <a href="https://apps.apple.com/ae/app/eventstan/id1638510267" target="_blank"> <img src="./v2Images/google-android.png"/> </a>
                       </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <div className="about-variety">
            <div className='about-variety-inner'>
                <div className='container'>  
                <div className='centered-text'>
                   <h4>What's in it for you ?</h4>
                    </div>             
                        <div className='boxes'>
                        <div className='row'>
                    
                        <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                        <div className="image-shape1 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Variety of Vendors</h5>
                        </div>
                        </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                        <div className="image-shape2  m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Peace of mind</h5>
                        </div>
                        </div>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                            <div className="image-shape3  m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Quality of service</h5>
                        </div>
                        </div>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                            <div className="image-shape4 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Successful events</h5>
                        </div>
                        </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                        <div className="image-shape5 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">360 event planning</h5>
                        </div>
                        </div>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                            <div className="image-shape6 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Customisable packages</h5>
                        </div>
                        </div>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                        <div className="image-shape7 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Complete transparency</h5>
                        </div>
                        </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                        <div className="image-shape8 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Broad spectrum of events</h5>
                        </div>
                        </div>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                            <div className="image-shape9 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Get a pro on demand</h5>
                        </div>
                        </div>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                            <div className="image-shape10 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Planning at your fingertips</h5>
                        </div>
                        </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                        <div className="image-shape11 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">Choose on the basis of reviews &amp; ratings</h5>
                        </div>
                        </div>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
                            <div className="image-shape12 m-bx">
                        <div className="elementor-icon-box-content">
                            <h5 className="elementor-icon-box-title">No extra fees or charges</h5>
                        </div>
                        </div>
                            </div>
                            </div>
                            <div className='row'id="second-mbox">
                      
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='about-bottom'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                    <div className="about-bottom-images">
                    {/* <img src={'./v2Images/cloud-left.png'}/> */}
                    <div className="desc">
                    <img src={'./v2Images/mission-3.png'}/>  
                    <h6>Mission</h6>
                            <p>Establish liaison to plan, book and celebrate any event at anytime from anywhere through our apps.</p>
                        </div>
                        </div> 
                  
                    </div>
                    <div className="col-md-6">
                    <div className="about-bottom-images">
                    <div className="mision-v">
                    {/* <img src={'./v2Images/cloud-left.png'}/> */}
                    <div className="desc">
                    <img src={'./v2Images/eye-scan-3.png'}/>  
                    <h6>Vision</h6>
                            <p>Curating a hassle-free event planning journey for all.</p>
                        </div> 
                        </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </>
  );
}
export default Aboutus;
