import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LogoPic from './assets/EventStanBlack.png';
import axios from "axios";
import { API_BASE_URL } from "./constant";
import "./Invoice.css";

const BookedServicesDetails = () => {
	const params = useParams();
	const [eventData,setEventData] = useState([]);
	const [orderData,setOrderData] = useState([]);
	let userEventId = params.userEventId;
	useEffect(()=>{
		axios.get(`${API_BASE_URL}bookedServiceDetails?userEventId=${userEventId}`)
		.then(res=>{ 
			setEventData(res.data.data._id);
			setOrderData(res.data.data.vendorServices);
		})
		.catch(err=>{
			console.log(err);
		})
	},[])

    return (
		<div id="body">
			<a href="javascript:window.print()" className="print-button">Print this booking Receipt</a>
<div id="invoice">
	<div className="row">
		<div className="col-md-6">
			<div id="logo"><img src={LogoPic} alt=" " /></div>
		</div>

		<div className="col-md-6">	
			<p id="details">
				<strong>Booking ID:</strong> {eventData.sysEventId}<br/>
				<strong>Event Date:</strong> {new Date(parseInt(eventData.eventDate)).toDateString()} <br/>
			</p>
		</div>
	</div>


	{ /*Client & Supplier*/ }
	<div className="row">
		<div className="col-md-12">
			<h2 className="head">Service Details</h2>
		</div>
	</div>
	{/*Invoice */}
<div className="row">
<div className="col-md-12">
    <table className="margin-top-20">
        <tr>
		    <th>SNo.</th>
            <th>Listing</th>
            <th>Service</th>
			<th>Feature</th>
			<th>No. Person/Hr</th>
            <th>Amount</th>
        </tr>

        { orderData.map((event,index)=>{
			let features='';
			return <tr key={index}>
			<td>{index+1}</td> 
			<td>{event.title}</td>
            <td>{event.type}</td> 
			{event.feature.forEach((feat)=>{
				features += feat.featureName + ","; 
			})}
			<td>{features.replace(/,\s*$/, "")}</td>
			<td>{event.priceType===0?'Full':event.guests}</td>
            <td>AED {event.priceType===0?event.price:event.price*event.guests}</td>
        </tr>
		})}
		
        
		<tr style={{ borderTop:'1px solid #ddd'}}>
            <td>&nbsp;</td>
			<td>&nbsp;</td>
			<td>&nbsp;</td>
			<td>&nbsp;</td>
			<td><b>Total Amount :</b></td>
            <td><span>AED {eventData.packagePrice}</span></td>
        </tr>
		
    </table>
	* 5% Tax Included
	</div>


</div>

	</div>
	<hr style={{ backgroundColor: '#8489b2' }} />
		
           <table width="100%">
            <tr  style={{ color: '#000' }} >
                <td align="center">
                    <div class="flex1">
                        <b>Download our App</b>
                    </div>
                </td>
                
            </tr>
			<tr>
			<td style={{ textAlign: 'center' }}>
			<br/>
				<a href="https://apps.apple.com/ae/app/eventstan/id1638510267" style={{ textDecoration: 'underline', color: '#fff' }}>
					<img src="https://eventstan.com/assets/img/apple.png" style={{ padding: '5px'}}/>
				</a>
				<a href="https://play.google.com/store/apps/details?id=com.mobile.eventstan" style={{ textDecoration: 'underline', color: '#fff' }}>
					<img src="https://eventstan.com/assets/img/googleplay.png" style={{ padding: '5px'}}/>
				</a>
			</td>
			</tr>
			<tr  style={{ color: '#000' }} >
                <td align="center">
				<br/>
                    <div class="flex1">
                        <b>Follow Us</b>
                    </div>
                </td>
                
            </tr>
			<tr  style={{ color: '#000' }}>
                <td class style={{ textAlign: 'center' }}>
                    <div class="flex1">
                        
            <a href="https://www.facebook.com/EVENTSTAN/" style={{ textDecoration: 'underline', color: '#fff' }}>
                <img src="https://eventstan.com/assets/img/facebook.png" width="22" height="22" alt="" style={{ padding: '1px'}}/>
            </a>
            <a href="https://www.instagram.com/Eventstanofficial/" style={{ textDecoration: 'underline', color: '#fff' }}>
                <img src="https://eventstan.com/assets/img/insta.png" width="22" height="22" alt="" style={{ padding: '1px'}}/>
            </a>
            <a href="https://www.linkedin.com/company/eventstan/" style={{ textDecoration: 'underline', color: '#fff' }}>
                <img src="https://eventstan.com/assets/img/linkedin.png" width="22" height="22" alt="" style={{ padding: '1px'}}/>
            </a>
            </div>
                </td>
                
            </tr>
			<tr  style={{ color: '#000' }} >
                <td align="center">
                    <div class="flex1"><br/> 
                        Copyright &copy 2022 Eventstan<br/><br/><a href="">Privacy Policy</a>&nbsp;<a href="">Terms of Use</a>
                    </div>
                </td>
                
            </tr>
        </table>
</div> 
    );
}
export default BookedServicesDetails;