import {useEffect, useState} from 'react';
import {API_BASE_URL,STRIPE_PUB_KEY} from './constant'
import { loadStripe } from "@stripe/stripe-js";
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import axios from "axios";
const stripePromise = loadStripe(STRIPE_PUB_KEY);
function PaymentForm(props) {
  const [ clientSecret, setClientSecret ] = useState('');

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const data = {
        amount:props.amount,
        customer:props.customer,
    }
    const bookingData = {
        bookingID:props.bookingId
    }
     axios.post(`${API_BASE_URL}start-payment-flow`,data)
     .then((data) => { 
        setClientSecret(data.data.data.client_secret)
        bookingData.paymentToken = data.data.data.id
        axios.post(`${API_BASE_URL}add-payment-methodTo-booking`, bookingData)
        .then(res => {
        })
        .catch(err => {
            this.setState({ loader: false })
            this.setState({ payment_err: 'Unable to hold payment.Please use other payment method.' });
        })
     });
  }, []);


  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, }}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

export default PaymentForm;
