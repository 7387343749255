const iState = {
  fields: "",
}

export default function UserEventIdreducer(state = iState, action){
  switch(action.type) {
      case "PERSONAL_DETAIL":
        return {
          ...state,
          fields : action.payload
        };
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
}