import React, { Component } from "react";
import './AppStyle.css';
import {Link} from 'react-router-dom';
import HeaderInner from "./HeaderUser";
import {IoIosArrowBack, IoIosSearch, IoIosAddCircle, IoIosPaper, IoIosNotifications} from 'react-icons/io';
import LeftMenu from "./UserLeftMenu";
import {connect} from "react-redux";
import axios from "axios";
import { API_BASE_URL,BASE_URL } from "../../constant";
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; 


class UserDashboard extends Component {
   constructor(props){
      super(props);
      this.state = {
          copySuccess: '', 
          txtDisplay:'', 
          ref_url: `${BASE_URL}reference_signup/${this.props.userdata._id}`,
          uid :localStorage.getItem('user-auth-id'),
          bookedEvents:[],
          fullyBookedEvents:[],
         }
   }

   componentDidMount(){
      this.setState({ref_url: `${BASE_URL}reference_signup/${this.state.uid}`});
      // saveDarft Api
      axios.get(`${API_BASE_URL}getUserEvents?userId=${this.state.uid}&status=2&limit=1`)
      .then(r => {
          this.setState({bookedEvents : r.data.data.data })
      })
      .catch(e =>{
      console.log('save draft error' , e)
      })

      axios.get(`${API_BASE_URL}getUserEvents?userId=${this.state.uid}&status=3&limit=1`)
      .then(r => {
          this.setState({fullyBookedEvents : r.data.data.data })
      })
      .catch(e =>{
      console.log('save draft error' , e)
      })
   }

   payBalance = (userEventId)=>{
      let data = {
         UserEventId:userEventId,
         uid : this.state.uid
      }
      axios.post(`${API_BASE_URL}payBalance`,data)
      .then((r)=>{
         window.location.href = "/Paymentnew/" + r.data.data.userEventId + "/" +r.data.data.userId+"/"+ r.data.data._id
      })
      .catch(error=>{
         console.log(error);
      });
   }

   //delete event
   cancelEvent = (eventId)=>{
      confirmAlert({
          title: '',
          message: 'Do you want to cancel this event',
          buttons: [
            {
              label: 'Yes',
              onClick: () => { 
                  const header = {
                      'authorization': localStorage.getItem('user-auth-token')
                    } 
                  axios.post(`${API_BASE_URL}delete-event`,{uid:this.state.uid,eventID:eventId,status:9},{headers: header})
                  .then(r => { 
                      window.location.reload();
                      //this.cancelDraft(eventId)
                  })
                  .catch(e =>{
                  console.log('save draft error' , e)
                  })
              }
              }
            ,
            {
              label: 'Cancel',
             
            }
          ]
      });
  }

   // for back button
	Back_user = () => {
		this.props.history.replace('/create_event')
	}

   changeHandler = (e)=>{
      const {name,value} = e.target;
      this.setState({event_status:value})
   }

   copyToClipboard = (e) => {
      navigator.clipboard.writeText(this.state.ref_url);
      this.setState({ copySuccess: 'Copied!', txtDisplay:'none' });
    };

   render(){
      const copySuccess = this.state.copySuccess;   
        let btnText;
        let txtDisplay;
    if (copySuccess) {
        btnText = 'Copied !';

    } else {
        btnText = 'Copy Text';
    }
   return (
   <>
   <HeaderInner handleKeyPress={this.handleKeyPress} onChange={this.search_change} />
   <div className="container">
	   <div className="row">
         <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="back-btn">
               <br />
               <a className="text-dark" href="#" onClick={this.Back_user}><IoIosArrowBack /> Back</a>
            </div>
         </div>
      </div>
      <section className="application_sec">	
      <div className="row">
         <div className="col-md-3 col-sm-3 col-xs-3 sidemenu">
            <LeftMenu />
         </div>
         <div className="col-md-9 col-sm-9 col-xs-9">
         {/* <div class="row dashboard">
            <div class="col-lg-4 col-md-6">
               <div class="card">
                  <div class="card-body p-15">
                     <div class="d-flex pb-2 no-block">
                        <div class="align-self-center mr-5 ml-4">
                           <IoIosPaper className="dash-icon" />
                        </div>
                        <div class="align-slef-center mr-auto">
                              <h2 class="m-b-2 text-uppercase font-30 font-medium lp-5 text-danger">12</h2>
                              <h6 class="text-muted m-b-0">Upcoming Events</h6>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4 col-md-6">
               <div class="card">
                  <div class="card-body p-15">
                     <div class="d-flex pb-2 no-block">
                        <div class="align-self-center mr-5 ml-4">
                           <IoIosNotifications className="dash-yellow-icon"/>
                        </div>
                        <div class="align-slef-center mr-auto">
                              <h2 class="m-b-2 text-uppercase font-30 font-medium lp-5 text-danger">5</h2>
                              <h6 class="text-muted m-b-0">Draft  Events</h6>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4 col-md-12">
               <div class="card">
                  <div class="card-body p-15">
                     <div class="d-flex pb-2 no-block">
                        <div class="align-self-center mr-5 ml-4">
                           <IoIosPaper className="dash-icon" />
                        </div>
                        <div class="align-slef-center mr-auto">
                           <h2 class="m-b-2 text-uppercase font-30 font-medium lp-5 text-danger">5</h2>
                           <h6 class="text-muted m-b-0">Event Archive</h6>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}
         <div className="form_card form_blk text-center">
            <div className="row">
               <div className="card-body text-center border-ev">
                  <Link to="create_event"><h5 className="mt-2"><b><IoIosAddCircle/> <br />Create Events</b></h5></Link>
               </div>
            </div>
        </div>
        <br/>
         <div className="form_card form_blk">  
            <h5><b>Referal Link</b></h5>
               <hr/>
            <div className="share-btn-ctr" style={{display: 'flex'}}>
               <input type="search" name="searchBy" id="copy-text" className="form-control mr-1" value={this.state.ref_url} readonly="" style={{display : this.state.txtDisplay, width: '80%' }}/>
               <button id="copyCodeBtn" onClick={this.copyToClipboard} className="btn  btn-primary btn-block form-control btn-send1 inv" style={{width: '20%', marginTop: '-2px'}}> {btnText}</button>
            </div>
         </div>
            <br/>
            {/* second card*/ }
            {this.state.bookedEvents.map((event,index)=>{
            let eDate;
            event._id.selectedFilters.map((ev_fil)=>{
               if(ev_fil.type==3){
                  eDate = ev_fil.date;
               }
            })
            return <div className="form_card form_blk" key={index} style={{ padding : '0px'}}>
               <div className="card-body">
               <h5 className="card-title float-left align-self-center tasks statistics breadcrumb" style={{ width : "80%", fontSize : '1.0rem'}}>
               <b>{event._id.eventName}</b>&nbsp; | {moment(parseInt(eDate)).format("dddd, MMMM Do YYYY")} |&nbsp;&nbsp;&nbsp;&nbsp; {event._id.status!=9?<span style={{cursor : "pointer"}} className="badge badge-danger" onClick={()=>this.cancelEvent(event._id.userEventId)}></span>:''} </h5>
               {event._id.status==2 ? <h5 className="card-title tasks statistics float-right btn  btn-outline-danger" onClick={()=>this.payBalance(event._id.userEventId)}>Pay Balance</h5>:''}
                     <div className="clearfix"></div>
                        <div className="m-t-10 no-block">
                           <div className="d-flex f-16">
                              <div className="col-lg-12 p-0 row col-md-12">
                              {event.vendorServices.map((list,i)=>{
                                    return <div key={i} className="add-on-box border border-warning mb-3 position-relative  d-flex align-items-center justify-content-between mr-3 ml-3" >
                                    <div className="add-on">
                                       <h4 className="heading mb-1">{list.serviceType}   ${(list.priceType!=0 ? list.guests : 1) * list.price}</h4>
                                       <span className="mb-2">{list.title}</span>
                                    </div>
                                 </div>
                                 })}
                                 <div className="clearfix"></div> 
                              </div>
                           </div> 
                        </div>    
               </div>
            </div>
            })}
            {/* second card*/ }
            {/* third card */}
            <br/>
            {this.state.fullyBookedEvents.map((event,index)=>{
            let eDate;
            event._id.selectedFilters.map((ev_fil)=>{
               if(ev_fil.type==3){
                  eDate = ev_fil.date;
               }
            })
            return <div className="form_card form_blk" key={index} style={{ padding : '0px'}}>
               <div className="card-body">
               <h5 className="card-title float-left align-self-center tasks statistics breadcrumb" style={{ width : "80%", fontSize : '1.0rem'}}>
               <b>{event._id.eventName}</b>&nbsp; | {moment(parseInt(eDate)).format("dddd, MMMM Do YYYY")} |&nbsp;&nbsp;&nbsp;&nbsp; {event._id.status!=9?<span style={{cursor : "pointer"}} className="badge badge-danger" onClick={()=>this.cancelEvent(event._id.userEventId)}></span>:''} </h5>
               {event._id.isApproved ? <Link target="_blank" to={`invoice/${event._id.userEventId}`}><h5 className="card-title tasks statistics float-right btn  btn-outline-danger">Print Invoice</h5></Link>:''}
                     <div className="clearfix"></div>
                        <div className="m-t-10 no-block">
                           <div className="d-flex f-16">
                              <div className="col-lg-12 p-0 row col-md-12">
                              {event.vendorServices.map((list,i)=>{
                                    return <div key={i} className="add-on-box border border-warning mb-3 position-relative  d-flex align-items-center justify-content-between mr-3 ml-3" >
                                    <div className="add-on">
                                       <h4 className="heading mb-1">{list.serviceType}   ${(list.priceType!=0 ? list.guests : 1) * list.price}</h4>
                                       <span className="mb-2">{list.title}</span>
                                    </div>
                                 </div>
                                 })}
                                 <div className="clearfix"></div> 
                              </div>
                           </div> 
                        </div>    
               </div>
            </div>
            })}
            {/* third card */}
         </div> 
      </div>  	
      </section>
	</div> 
   </>);
    }
}
const mapStateToProps = (state)=>{
   return{
       userdata : state.userDetailsReducer.userDetails,
   }
}
export default connect(mapStateToProps,null)(UserDashboard)